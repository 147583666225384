
import { useState, useMemo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HubConnectionBuilder } from '@microsoft/signalr';

import useAuth from 'hooks/use-auth';
import { mergePhaseCounters } from 'store/counter-actions';
import { formattedPMT_v2 } from 'lib/calc';
import { aesDecrypt } from 'lib/helpers';
import useDealV2 from 'hooks/deal/use-deal_v2';

const WebSocketMessage = () => {
    const { countRecords } = useDealV2();

    let audio = useMemo(() => new Audio("/ding.mp3"));
    let audioKaChing = useMemo(() => new Audio("/ka-ching.mp3"));

    const [message, setMessage] = useState({
        id: "",
        title: "",
        description: "",
        btnText: "",
        link: ""
    });

    const [messageCounter, setMessageCounter] = useState(0);
    const [transformClasses, setTransformClasses] = useState("-bottom-full");

    const { token: jwt } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!messageCounter) return;
        var audioTimer = setTimeout(function() {
            try {
                if(message.id===1) audio.play();
                if(message.id===2) audioKaChing.play();
            } catch (err) {

            }
        }, 500);
        setTransformClasses("bottom-0");
        var timer = setTimeout(function() {
            hideMessageHandler();
        },10000);
        return () => {
            clearTimeout(timer);
            clearTimeout(audioTimer);
        }
    },[messageCounter]);

    const hideMessageHandler = () => {
        setTransformClasses("-bottom-full")
    }

    useEffect(() => {
        countRecords();
    }, [countRecords]);

    useEffect(() => {
         if(!jwt) return;
 
        const connection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_SLD_API_BASE_URL+'/dealCountingHub', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).withAutomaticReconnect().build();

        connection.start().then(() => {

            connection.on('CountDealOnPhase', encryptedData => {
                console.log("CountDealOnPhase data", encryptedData);
                console.log(process.env.REACT_APP_WSS_IV);
                var data = aesDecrypt(encryptedData, process.env.REACT_APP_WSS_IV);
                console.log("1 data", data);

                setMessage({id: 1, title: "Nieuwe aanvraag ingediend", description: "Goed nieuws! Er is weer een nieuwe aanvraag ingediend!", btnText: "Bekijk aanvraag", link: "/crm/inbox"});
                countRecords(data);
                setMessageCounter(prevState => prevState + 1);
            });
            
            connection.on('PhaseSpecificDeal', encryptedData => {
                console.log("PhaseSpecificDeal data", encryptedData);
                console.log(process.env.REACT_APP_WSS_IV);
                var data = aesDecrypt(encryptedData, process.env.REACT_APP_WSS_IV);
                console.log("2 data", data);

                var commissionAmountSLD = +data.commissionAmountSLD || 0;
                var administrationCost = +data.administrationCost || 0;
                var totalCommission = (commissionAmountSLD + administrationCost);
                setMessage({id: 2, title: `Hoppa!! \u20AC ${formattedPMT_v2(totalCommission)}`, description: "Weer een uitbetaling binnen!", btnText: "Bekijk deal", link: "/crm/verkopen/"+data.dealID});
                setMessageCounter(prevState => prevState + 1);
            });

        }).catch(e => console.log('Connection failed: ', e));

        // setMessageCounter(prevState => prevState + 1); 
        // setMessage({id: 1, title: "Nieuwe aanvraag ingediend", description: "Goed nieuws! Er is weer een nieuwe aanvraag ingediend!", btnText: "Bekijk aanvraag", link: "/crm/inbox"});
        // setMessage({id: 2, title: `Hoppa!! \u20AC ${formattedPMT_v2(400)}`, description: "Er is weer een nieuwe deal gesloten!", btnText: "Bekijk deal", link: "/crm/verkopen/1"});

    }, [jwt]);

    return (
        <>
            <div className={`fixed duration-1000 transition-all right-5 w-[372px] flex flex-col gap-y-3 mt-5 bg-white py-5 px-8 rounded-xl shadow-xl ${transformClasses}`}>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold">{message.title} {message?.id===1 && <>&#128512;</>}{message?.id===2 && <>&#129297;</>}</div>
                    <span className="fa fa-xmark cursor-pointer px-5 relative -right-5 text-end" onClick={hideMessageHandler}></span>
                </div>
                <p className="text-sm">{message.description}</p>
                <div className="flex flex-row"><NavLink to={message.link} reloadDocument={true} className="btn-primary">{message.btnText}</NavLink></div>
            </div>
        </>
    ); 
};

export default WebSocketMessage;