import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "./use-auth";
import { sortDataByKey } from "lib/voertuig";
import { authActions } from "store/auth-slice";
import { uiActions } from "store/ui-slice";
import { aesDecrypt } from "lib/helpers";

const useUser = () => {
    const dispatch = useDispatch();

    const [changeLog, setChangeLog] = useState("");

    const [user, setUser] = useState({
        user: [],
        admin: []
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    
    const { token: jwt } = useAuth();
    
    const getUser = useCallback(async (role) => {
        setIsLoading(true);
        if(!role) return;
        var baseUrl = process.env.REACT_APP_SLD_API_BASE_URL;
        var queryParams = "?role="+role;
        var fetchUrl = baseUrl+"/api/User"+queryParams;

        var resp = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });

        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);

        var sortedData = sortDataByKey(decryptedData, 'userName');
        var filteredData = sortedData.filter(user => user.firstName!==null);
        setUser(prevState => ({...prevState, [role]: filteredData}));
        setIsRequested(true);
        setIsLoading(false);

    },[jwt]);

    const getChangeLog = useCallback(async() => {

    },[]);

    const showTermsAndConditions = useCallback(async(visible) => {
        dispatch(uiActions.showBackdrop());
        if(!visible && typeof visible!=="boolean") {
            console.log("argument ontbreekt voor showTermsAndConditions functie");
            return;
        }
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/User', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt,
            },
            method: "PATCH",
            body: JSON.stringify({showTermsAndConditions: visible})
        });
        var respJson = await resp.json();
        dispatch(authActions.updateProfile({data: {...respJson.data_objects}}));
    },[jwt]);

    const showChangeLog = useCallback(async(visible) => {
        dispatch(uiActions.showBackdrop());
        if(!visible && typeof visible!=="boolean") {
            console.log("argument ontbreekt voor showChangeLog functie");
            return;
        }
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/User', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt,
            },
            method: "PATCH",
            body: JSON.stringify({showChangeLog: visible})
        });
        var respJson = await resp.json();
        dispatch(authActions.updateProfile({data: {...respJson.data_objects}}));
    },[jwt]);

    return {
        isLoading,
        adminList: user.admin,
        userList: user.user,
        isRequested,
        getUser,
        getChangeLog,
        showTermsAndConditions,
        showChangeLog,
        getChangeLog,
        changeLog
    }
}

export default useUser;