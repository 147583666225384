// import { escapeSpecialChars, getDateTimeFromDate, intTo2DecStr } from "assets/js/helpers";

import LicensePlate from "components/ui/LicensePlate/LicensePlate";
import { useNavigate } from "react-router-dom";
import DealProgressBar from "./DealProgressBar";
import LicensePlate2 from "components/ui/LicensePlate/LicensePlate2";
import { escapeSpecialChars } from "lib/voertuig";
import { getDateTimeFromDate, getFormattedLicensePlate } from "lib/helpers";
import { intTo2DecStr } from "lib/calc";

const DealItem = ({deal}) => {

    const navigate = useNavigate();

    const make = escapeSpecialChars(deal.vehicleDetail.make);
    const model = escapeSpecialChars(deal.vehicleDetail.model);
    const licensePlate = deal.vehicleDetail.licensePlate ? deal.vehicleDetail.licensePlate : 'NNB';
    var navPath = "/verkopen/"+deal.dealID;

  return(
            <div key={deal.dealID} id={deal.dealID} className={`grid grid-cols-2 md:grid-cols-5 gap-x-2 gap-y-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer`+(deal.isArchive ? ' opacity-50' : '')} onClick={() => navigate(navPath)}>
              <div className="col-span-2 md:col-span-5 border-b border-solid border-slate-200">
                <div className="flex flex-row justify-between text-sm opacity-75 border-b-slate-50">
                  <div className="flex flex-col sm:flex-row gap-x-2">
                    <div><span>Verkoop ID: </span><span>{deal.dealID}</span></div>
                  </div>
                  <div className="flex flex-col items-center sm:flex-row gap-x-2">
                    <span>{getDateTimeFromDate(deal.createdDate)}</span>
                  </div>
                </div>
              </div>
              {(/(FinancialLease|Verzekering)/.test(deal.saleType) || !deal.saleType)
              ?
                <div className={`flex flex-col relative ${deal.saleType!=="FinancialLease" && 'mb-5'}`}> 
                  {deal.saleType==="Verzekering" && <div className="bg-slate-600 rounded-xl text-white text-center px-5 py-.5 mb-3 w-fit">Verzekering</div>}
                  {deal.saleType==="FinancialLease" && <div className="bg-slate-600 rounded-xl text-white text-center px-5 py-.5 mb-3 w-fit">Financial Lease</div>}
                  <LicensePlate2 value={getFormattedLicensePlate(licensePlate)} size="md" />
                  <div className="flex flex-row gap-x-3">{make} {model}</div>
                </div>
                :
                <div className="bg-slate-600 rounded-xl text-white text-center px-5 py-.5 mb-3 w-fit">BTW-financiering</div>
              }
              <div className="flex flex-col">
                <span className="whitespace-nowrap overflow-hidden">{deal.customerDetail.companyName.slice(0, 25)}{deal.customerDetail.companyName?.length>25 && "..."}</span>
                <span>{deal.customerDetail.postalCode && deal.customerDetail.postalCode+''}{deal.customerDetail.postalCode&&deal.customerDetail.place && ', '}{deal.customerDetail.place && deal.customerDetail.place}</span>
              </div>
              <div className="flex flex-col">
                <span>{deal.customerDetail.firstName} {deal.customerDetail.lastName}</span>
                <span>{deal.customerDetail.phone}</span>
              </div> 
              <div className="flex flex-col">
                <div>&euro; {intTo2DecStr(deal.commissionAmountDealer)}</div>
              </div> 
              <div className="flex flex-col col-start-2 md:col-start-auto">
                <DealProgressBar phase={deal.phase} showPhase={false} />
                <div>{deal.phase}</div>
              </div>

            </div>
            );
}

export default DealItem;