export const validateFormField = (fieldToValidate, value, required=true, exclude=null) => {
    var response = true;
    switch(fieldToValidate) {
        case "kvkSearchQuery": 
            if(value) response = `\u201C${value}\u201D is niet bekend bij de KvK. Probeer een andere zoekterm, of probeer het 8-cijferig KvK-nummer`;
            if(!value) response = "Bedrijfsnaam is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "companyName": 
            if(!value) response = "Bedrijfsnaam is een verplicht veld";
            break;
        case "chamberNumber":
            if(!/^\d{8}$/.test(value)) response = "KvK-nummer dient uit 8 cijfers te bestaan";
            if(!/^\d+$/.test(value)) response = "KvK-nummer mag alleen uit cijfers te bestaan";
            if(!value) response = "KvK-nummer is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "address": 
            if(!value) response = "Adres is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "place": 
            if(!value) response = "Plaats is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "email":
            if(!/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(value)) response = "Geef een geldig e-mailadres op";
            if(exclude && value?.endsWith(exclude)) response = "Geef het e-mailadres van de klant op";
            if(!value) response = "E-mailadres is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "firstName": 
            if(/\d/.test(value)) response = "Voornaam mag geen cijfers bevatten";
            if(!value) response = "Voornaam is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "lastName": 
            if(!/^[^\d]{2,}$/.test(value)) response = "Achternaam dient uit minimaal 2 karakters te bestaan";
            if(/\d/.test(value)) response = "Achternaam mag geen cijfers bevatten";
            if(!value) response = "Achternaam is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "phone":
            if(!/^[\d\+\-\(\)]{8,}$/.test(value)) response = "Geef een geldig telefoonnummer op";
            // if(!/^\d+$/.test(value)) response = "Telefoonnummer mag alleen uit cijfers bestaan";
            if(!value) response = "Telefoonnummer is een verplicht veld";
            if(!required && !value) return true;
            break;
         case "mileage": 
            if(!/^[0-9 .]/.test(value)) response = "De kmstand mag alleen uit cijfers bestaan";
            if(value?.length>6) response = "De kmstand mag niet meer dan 6 karakters bevatten";
            if(!value) response = "Kmstand is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "driverLicenseNumber": 
            if(!/^\d{10}$/.test(value)) response = "Het rijbewijsnummer dient uit 10 cijfers te bestaan";
            if(!value) return true;
            break;
        case "postalCode":
            if(!/^\d{4}( ?[A-Za-z]{2})$/.test(value)) response = "Geef een geldige postcode op";
            if(!value) response = "Postcode is een verplicht veld";
            if(!required && !value) return true;
            break;
        case "iban":
            if(!/^NL\d{2}[A-Z]{4}\d{10}$/i.test(value)) response = "Geef een geldig IBAN nummer op";
            // if(!/^[A-Za-z0-9]{18}$/.test(value)) response = "IBAN dient uit 18 karakters te bestaan";
            if(!required && !value) return true;
            break;
        case "financeAmount": 
            if(!value || value==="0") response = "Grootte van financiering is een verplicht veld"
            break;
        case "digitsOnly": 
            if(!/^\d{1,}$/g.test(value)) response = "De waarde dient alleen uit cijfers te bestaan";
            if(!required && !value) return true;
            break;
        default: 
            if(!value) response = fieldToValidate;
        }

    return response;
}