import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";
import Button from "components/ui/Button/Button";
import { formatThousandNotation, formattedPMT_v2 } from "lib/calc";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";
import TextArea3 from "components/ui/TextArea/TextArea3";
import InputKvk3 from "components/ui/InputKvk/InputKvk3";
import DateSelects from "components/ui/DateSelects/DateSelects";
import LicensePlate2 from "components/ui/LicensePlate/LicensePlate2";

import useDealV2 from "hooks/deal/use-deal_v2";
import useAnalytics from "hooks/use-analytics";
import useActivity from "hooks/activity/use-activity";

import { dealActions } from "store/deal-slice";
import { getExludedEmailDomains, getFormattedLicensePlate } from "lib/helpers";

const StapContact = () => {
    const { createDeal } = useDealV2();
    const { sendPageView } = useAnalytics();
    const { createActivity } = useActivity();
    const [showImage, setShowImage] = useState(false);

    const { vehicleDetail, calcDetail, customerDetail } = useSelector(state => state.deal);
    const { dealer: dealerDetail } = useSelector(state => state.dealer);
    var { email: domainToExclude } = dealerDetail;
    if(domainToExclude) domainToExclude = getExludedEmailDomains(domainToExclude);
       
    var storedCustomerDetail;

    if(Storage) {
        try {
            storedCustomerDetail = JSON.parse(sessionStorage.getItem("customerDetail"));
        } catch {};
    }

    const { register, formState: { errors }, watch, handleSubmit, getValues, setValue, setFocus } = useForm({
        mode: "onChange",
        delayError: 750,
        defaultValues: {
            companyName: customerDetail?.companyName || storedCustomerDetail?.companyName || "",
            chamberNumber: customerDetail?.chamberNumber || storedCustomerDetail?.chamberNumber || "",
            salutation: customerDetail?.salutation || storedCustomerDetail?.salutation || "",
            firstName: customerDetail?.firstName || storedCustomerDetail?.firstName || "",
            lastName: customerDetail?.lastName || storedCustomerDetail?.lastName || "",
            email: customerDetail?.email || storedCustomerDetail?.email || "",
            phone: customerDetail?.phone || storedCustomerDetail?.phone || "",
            dateofBirth: {
                day: customerDetail?.dateofBirth?.day || storedCustomerDetail?.dateofBirth?.day || "",
                month: customerDetail?.dateofBirth?.month || storedCustomerDetail?.dateofBirth?.month || "",
                year: customerDetail?.dateofBirth?.year || storedCustomerDetail?.dateofBirth?.year || "",
            },
            driverLicenseNumber: customerDetail?.driverLicenseNumber || storedCustomerDetail?.driverLicenseNumber || "",
            iban: customerDetail?.iban || storedCustomerDetail?.iban || "",
            salesDescription: customerDetail?.salesDescription || storedCustomerDetail?.salesDescription || "",
            agree: customerDetail?.agree || false,
            agreeSendOffer: customerDetail?.agreeSendOffer || false,
            requestedInsurance: customerDetail?.requestedInsurance || false
        }
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // in case kvk is offline
    const [planB, setPlanB] = useState(false);
  
    const kvkErrorHandler = (message) => {

        if(!message) {
            return;
        }
        if(!/Probeer/.test(message)) setPlanB(true);
    }


    const submitHandler = async (formData) => {
        if(!formData?.chamberNumber) return;
        
        sendPageView({category: "financiallease", action: "Klik", label: "Versturen"});

        var salesDescription = "";
        if(formData?.agreeSendOffer) salesDescription="Ja, ik geef toestemming dat SLD de goedgekeurde offerte aan de klant toestuurt\n";
        if(formData?.salesDescription) salesDescription+=formData.salesDescription;

        var deal = {
            calcDetail: calcDetail,
            vehicleDetail: vehicleDetail,
            customerDetail: {
                ...customerDetail,
                ...formData,
                salesDescription
            },
            dealerDetail: dealerDetail            
        };

        var resp = await createDeal(deal);
        if(resp.message==="Deal created") {
            createActivity("Deal", "create", {dealID: resp.data_object.dealID, saleType: "FinancialLease"}, {...deal});
            navigate("/financiering/financial-lease/bedankpagina", { state: resp.data_object});
        }
    }
    
    const changeHandler = (fieldName, fieldValue, objName=false) => {
        var customer = JSON.stringify(getValues());
        if(Storage) sessionStorage.setItem("customerDetail", customer);
        if(objName) {
            dispatch(dealActions.setDateForCustomer({objName, fieldName, fieldValue}));
            return;
        }
        dispatch(dealActions.setCustomerData({[fieldName]: fieldValue}));
    }

    const { companyName, chamberNumber, postalCode, address, place, firstName, lastName, email, phone, driverLicenseNumber, iban, salutation, salesDescription, agree, agreeSendOffer, dateofBirth, kvkSearchQuery, requestedInsurance } = watch();

    const kvkCompleteHandler = () => {
        if(customerDetail?.chamberNumber && (!customerDetail?.postalCode || !customerDetail?.address || !customerDetail?.place)) {
            setValue("companyName", customerDetail?.companyName ? customerDetail?.companyName : "");
            setPlanB(true);
        }
    }

    return (
        <>
            <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
                <ProgressBar />
            </div>
            <Container>
                <form onSubmit={handleSubmit(submitHandler)} className="container mx-auto max-w-screen-xl px-5 xl:px-0">
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10 xl:gap-x-28 mt-10">
                        <div className="flex flex-col gap-3">
                        <div className="text-xl font-bold lg:col-span-2 leading-snug select-none">Bedrijfsnaam</div>
                        {!planB
                        ?
                        <>
                            <InputKvk3 fieldToValidate="Bedrijfsnaam of KvK-nummer is een verplicht veld" onError={kvkErrorHandler} onComplete={kvkCompleteHandler} onBlur={changeHandler} setFocus={setFocus} historyChamberNumber={getValues("chamberNumber")} register={register} errors={errors} getValues={getValues} setValue={setValue} inputValue={kvkSearchQuery} errorMessage={errors?.kvkSearchQuery?.message} />    
                        </>
                        :
                        <div className="flex flex-col gap-y-1">
                            <div className="border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] lg:col-span-3 text-sm px-3 py-2 w-fit rounded-xl">Bedrijfsgegevens kunnen momenteel niet (volledig) opgehaald worden bij de Kvk. Gelieve de gegevens handmatig in te voeren.</div>
                            <Input3 name="companyName" onBlur={changeHandler} register={register} fieldToValidate="Bedrijfsnaam is een verplicht veld" placeholder="Bedrijfsnaam" label="Bedrijfsnaam" inputValue={companyName} errorMessage={errors?.companyName?.message} />
                            <Input3 name="chamberNumber" onBlur={changeHandler} register={register} fieldToValidate="chamberNumber" placeholder="KvK-nummer" label="KvK-nummer" inputValue={chamberNumber} errorMessage={errors?.chamberNumber?.message} />
                            <Input3 name="postalCode" onBlur={changeHandler} register={register} fieldToValidate="postalCode" placeholder="Postcode" label="Postcode" inputValue={postalCode} errorMessage={errors?.postalCode?.message} />
                            <Input3 name="address" onBlur={changeHandler} register={register} fieldToValidate="Adres is een verplicht veld" placeholder="Adres" label="Adres" inputValue={address} errorMessage={errors?.address?.message} />
                            <Input3 name="place" onBlur={changeHandler} register={register} fieldToValidate="Plaats is een verplicht veld" placeholder="Plaats" label="Plaats" inputValue={place} errorMessage={errors?.place?.message} />
                        </div>
                        }
                    
                    <div className="flex flex-col gap-y-1">
                        <div className="text-xl font-bold leading-snug select-none">Klantgegevens</div>
                        <div className="grid grid-cols-2 gap-x-3">
                            <Select3 onChange={changeHandler} name="salutation" inputValue={salutation} register={register} fieldToValidate="Aanhef is een verplicht veld" placeholder="Aanhef" label="Aanhef" errorMessage={errors?.salutation?.message}>
                                <option value="Dhr">Dhr</option>
                                <option value="Mevr">Mevr</option>
                            </Select3>
                            <Input3 name="firstName" onBlur={changeHandler} register={register} fieldToValidate="Voornaam is een verplicht veld" placeholder="Voornaam" label="Voornaam" inputValue={firstName} errorMessage={errors?.firstName?.message} />
                        </div>
                        <Input3 name="lastName" onBlur={changeHandler} register={register} fieldToValidate="Achternaam is een verplicht veld" placeholder="Achternaam" label="Achternaam" inputValue={lastName} errorMessage={errors?.lastName?.message} />
                        <div className="grid grid-cols-5 gap-x-3">
                            <div className="relative col-span-3 grow">
                                <Input3 name="email" onBlur={changeHandler} register={register} fieldToValidate="email" exclude={domainToExclude} placeholder="E-mailadres" label="E-mailadres" inputValue={email} errorMessage={errors?.email?.message} />
                            </div>
                            <div className="col-span-2">
                                <Input3 name="phone" onBlur={changeHandler} register={register} fieldToValidate="phone" placeholder="Telefoonnummer" label="Telefoonnummer" inputValue={phone} errorMessage={errors?.phone?.message} />
                            </div>
                        </div>
                        <DateSelects maxYear={(new Date().getFullYear() - 18)} onChange={changeHandler} name="dateofBirth" register={register} getValues={getValues} errors={errors} fieldToValidate="Geboortedatum is een verplicht veld" placeholder="Geboortedatum" label="Geboortedatum" inputValue={dateofBirth} errorMessage={errors?.dateofBirth?.message} />                      
                        <div className="lg:col-span-3 flex flex-row gap-x-3 items-center select-none">
                            <span className="text-xl font-bold leading-snug">Aanvullende gegevens</span>
                            <span className="text-xs">(niet verplicht)</span>
                        </div>
                        <Input3 name="driverLicenseNumber" onBlur={changeHandler} required={false} register={register} fieldToValidate="driverLicenseNumber" placeholder="Rijbewijsnummer" label="Rijbewijsnummer" inputValue={driverLicenseNumber} errorMessage={errors?.driverLicenseNumber?.message} />
                        <Input3 upperCase={true} name="iban" onBlur={changeHandler} register={register} required={false} fieldToValidate="iban" placeholder="IBAN" label="IBAN" inputValue={iban} errorMessage={errors?.iban?.message} />                       
                        <TextArea3 onBlur={changeHandler} name="salesDescription" inputValue={salesDescription} register={register} label="Opmerkingen" placeholder="Opmerkingen" />
                        
                        <InputCheckbox3 onChange={changeHandler} name="agreeSendOffer" inputValue={agreeSendOffer} register={register} label="Ja, ik geef toestemming dat SLD de goedgekeurde offerte aan de klant toestuurt" infoText="Als de aanvraag in de ingediende vorm wordt goedgekeurd, zal SLD onmiddellijk de goedgekeurde offerte naar de klant verzenden, waarbij het opgegeven rentepercentage in acht wordt genomen." />
                        <InputCheckbox3 onChange={changeHandler} name="requestedInsurance" inputValue={requestedInsurance} register={register} label="Ja, ik wil een verzekeringsvoorstel" infoText="Hiermee geeft u akkoord dat uw gegevens gedeeld mogen worden met onze verzekeringspartner Actua. U ontvangt per mail een unieke link waar uw premie kunt berekenen en eventueel direct de verzekering kan afsluiten." errorMessage={errors?.requestedInsurance?.message} />
                        <InputCheckbox3 onChange={changeHandler} name="agree" fieldToValidate="Akkoord is verplicht" inputValue={agree} register={register} label={["Ja, ik ga akkoord met de ", <object className="inline-flex"><a href="https://sld.nl/wp-content/themes/sld/assets/docs/av-sld-eindklant-def.pdf" target="_blank" rel="noreferrer" className="underline">algemene voorwaarden</a></object>]} errorMessage={errors?.agree?.message} />

                        
                        <div className="flex flex-row justify-between">
                            <Button type="button" secondary={true} onClick={() => navigate("/financiering/financial-lease/calculator")} className="flex flex-row disabled:scale-100 hover:scale-105 items-center">
                                <div className="fa fa-chevron-left mr-2"></div><span>Vorige</span>
                            </Button>

                            <Button postIcon={true} className="w-full justify-center btn-primary" type="submit">
                                <span>Versturen</span>
                            </Button>
                        </div>
                    </div>
                    </div>
                    

                            <div className="bg-[#F1F5F9] border-[#012169] border border-solid rounded-[4px] flex flex-col p-8 gap-y-5 h-fit">
                                <h3 className="">Samenvatting aanvraag</h3>

                                <div className="grid md:grid-cols-2 gap-x-5">
                                    {showImage && vehicleDetail?.image && (
                                        <>
                                            <div className="">
                                                <img alt="" src={vehicleDetail?.image} onError={() => setShowImage(false)} />
                                            </div>
                                        </>
                                    )}
                                    <div className="flex flex-col justify-center">
                                        <div className="font-semibold text-xl">{vehicleDetail?.make}</div>
                                        <div>{vehicleDetail?.model}</div>
                                        {vehicleDetail?.licensePlate && <LicensePlate2 value={getFormattedLicensePlate(vehicleDetail?.licensePlate)} />}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-x-10">

                                </div>
                                <div className="grid grid-cols-2">
                                    <div>Aanschafwaarde</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.amountpurchase ? formatThousandNotation(calcDetail?.amountpurchase) : ""}</span></div>
                                    {/btw/i.test(calcDetail?.typeOfDeal) && <><div>BTW-bedrag</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.vat ? formatThousandNotation(calcDetail?.vat) : ""}</span></div></>}
                                    {/btw/i.test(calcDetail?.typeOfDeal) && <><div>Aanschaf Excl. BTW</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.amountpurchaseExclVAT ? formatThousandNotation(calcDetail?.amountpurchaseExclVAT) : ""}</span></div></>}
                                    <div>Totaal aan te betalen</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.totalToPay ? formatThousandNotation(calcDetail?.totalToPay) : "0"}</span></div>
                                    <div>Aanbetaling</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.downpayment ? formatThousandNotation(calcDetail?.downpayment) : "0"}</span></div>
                                    <div>Inruil</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.tradeInAmount ? formatThousandNotation(calcDetail?.tradeInAmount) : "0"}</span></div>
                                    <div className=""></div><div className="py-5"><div className="w-full h-[1px] bg-black block"></div></div>
                                    <div>Te financieren bedrag</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.leaseAmount ? formatThousandNotation(calcDetail?.leaseAmount) : ""}</span></div>
                                    <div>Slottermijn</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{calcDetail?.finalpayment ? formatThousandNotation(calcDetail?.finalpayment) : ""}</span></div>
                                    <div>Looptijd</div><div className="flex flex-row justify-between"><span></span><span>{calcDetail?.duration} maanden</span></div>
                                    <div>Maandbedrag</div><div className="flex flex-row justify-between"><span>&euro;</span><span>{formattedPMT_v2(calcDetail?.monthlyamount)}</span></div>
                                </div>

                            </div>

                    </div>
                </form>
            </Container>
        </>
    )
}

export default StapContact;