import { useState } from "react";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import useAuthentication from "hooks/use-authentication";

const NewSubUserForm = ({dealerId, onUpdate}) => {
    const { registerSubUser } = useAuthentication();

    const [user, setUser] = useState({
        email: "",
        dealerId: ""
    });

    const submitHandler = () => {
        if(!user.email) return;
        const userInfo = {
            email: user.email,
            dealerId: dealerId
        }
        registerSubUser(userInfo);
        onUpdate();
    }

    return (
    <div className="flex flex-row justify-between gap-x-3 mb-5">
        <Input className="grow" type="email" name="email" placeholder="E-mail" label="E-mail" value={user.email} onChange={evt => setUser(prevState => ({...prevState, [evt.target.name]: evt.target.value}))}/>
        <Button type="button" onClick={submitHandler}>Gebruiker toevoegen</Button>
    </div>
    )
}

export default NewSubUserForm;