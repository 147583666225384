import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/use-auth";
import { getUserInfo } from "store/auth-actions";

const ProtectedSuperAdminRoute = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role, userInfo, token, userIsLoggedIn } = useAuth();
    
    useEffect(() => {
        if(!userInfo && token) {
            dispatch(getUserInfo(token));
        }
        if(!role || !userInfo) return;
        if(!role.includes("SuperAdmin")) navigate("/");
    },[dispatch, navigate, userIsLoggedIn, role, token, userInfo]);

    if(!role.includes("SuperAdmin")) return <></>;
    return props.children;
}

export default ProtectedSuperAdminRoute;