import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useAuth from "hooks/use-auth";
import { aesDecrypt } from "lib/helpers";
import { uiActions } from "store/ui-slice";
import { sortDataByKey } from "lib/voertuig";
import useActivity from "hooks/activity/use-activity";

const useDealerV2 = (init, initQuery=null) => {
    const { createActivity } = useActivity();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();

    const [dealerOptions, setDealerOptions] = useState([]);
    const [allDealers, setAllDealers] = useState([]);
    const [dealer, setDealer] = useState({});
    const [dealers, setDealers] = useState([]);

    const [dealerUser, setDealerUser] = useState({});
    const [dealerUsers, setDealerUsers] = useState([]);

    const archiveDealer = useCallback(async (dealerID, updatedFormData, isArchive=true) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Dealer/"+dealerID, {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer "+jwt
                },
                body: updatedFormData
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData.message==="Dealer updated") {
                dispatch(uiActions.setAlert({ message: "Dealer succesvol gearchiveerd!", type: "success"}));
                dispatch(uiActions.hideBackdrop());

                var activityArchive = "archive";
                if(!isArchive) activityArchive = "dearchive";
                createActivity("Dealer", activityArchive, decryptedData);

                setDealer(decryptedData.data_object);
                navigate("..");
            }
        } catch (err) {
            dispatch(uiActions.setAlert({ message: "Fout bij het archiveren van de dealer!", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    }, []);

    const deArchiveDealer = (dealerID, updatedFormData) => {
        archiveDealer(dealerID, updatedFormData, false);
    };

    const deleteDealer = useCallback(async (dealerID) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Dealer/"+dealerID, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData.message==="Dealer deleted") {
                dispatch(uiActions.setAlert({ message: "Dealer succesvol verwijderd!", type: "success"}));
                dispatch(uiActions.hideBackdrop());
                createActivity("Dealer", "delete", decryptedData);
                navigate("..");
            }
        } catch (err) {
            dispatch(uiActions.setAlert({ message: "Fout bij het verwijderen van de dealer!", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    }, []);

    const getDealers = useCallback(async (query, searchParams=false) => {
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Dealer"+(query ? (searchParams ? "?" : "")+query : ""), {
                headers: {
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            setDealers(decryptedData);
            return decryptedData;
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Fout bij het ophalen van de dealer lijst", type: "danger"}));
        }
    },[]);

    const updateDealer = useCallback(async (dealerID, dealerObj) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+dealerID, {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                },
                body: dealerObj 
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData.message==="Dealer updated") {
                createActivity("Dealer", "update", decryptedData);
                dispatch(uiActions.setAlert({ message: "Dealer succesvol bijgewerkt!", type: "success"}));
                dispatch(uiActions.hideBackdrop());
            }
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.setAlert({ message: "Dealer kan niet bijgewerkt worden", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    },[]);

    const getAllDealers = useCallback(async (transformMethod) => {
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Dealer?pageSize=9999&isActive=true', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },        
            })
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);   
            setAllDealers(decryptedData.data);
            var options = decryptedData.data.map(option => {
                return {
                    label: option.name+' ('+option.dealerID+') - '+(option?.sourceOfDealer ? option?.sourceOfDealer : "SLD"),
                    value: option.dealerID
                }
            });
            if(transformMethod===2) {
                var options = decryptedData.data.map(item => ({label: item.name+' ('+item.importID+') '+(item?.sourceOfDealer ? item?.sourceOfDealer : "SLD"), id: item.importID, value: item.importID, dealerID: item.dealerID}));
            }
            var sortedOptions = sortDataByKey(options, 'label');
            setDealerOptions(sortedOptions);
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het ophalen van de dealer lijst", type: "danger"}));
        }
    }, []);

    const getDealerById = useCallback(async (dealerID) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+dealerID, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);   
            var transformedData = {
                ...decryptedData,
                status: (decryptedData.status ? decryptedData.status : ""),
                phone: (decryptedData.phone ? decryptedData.phone : ""),
                iban: (decryptedData.iban ? decryptedData.iban : "")
            }
            dispatch(uiActions.hideBackdrop());
            setDealer(transformedData);
            return transformedData;
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het ophalen van de dealer", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    },[]);

    const dealerChangeHandler = (evt, addObj=false) => {
            const dataName = evt?.target?.name || addObj.name;
            var dataValue = evt?.target?.value || addObj.value;
          
            if(dataName==="dealerDomain" && dataValue) dataValue=dataValue.trim().toLowerCase();
      
            setDealer(prevState => {
              return {
                ...prevState,
                [dataName]: (dataValue ? dataValue : "")
              }
            });
    }

    const getDealerUsers = useCallback(async (dealerId) => {
        const dealers = await getDealerById(dealerId);
        const { dealerUserDetail, subUserDetails } =  dealers;
        let users = [{...dealerUserDetail, type: "Beheerder"}, ...subUserDetails];
        setDealerUsers(users);
    }, []);

    const getDealerUserById = (id) => {
        var specificUser = dealerUsers?.filter(item => item.id===id)[0] || {};
        setDealerUser(specificUser);
        return specificUser; 
    }

    const clearDealerState = () => setDealer({});
    const clearDealerUserState = () => setDealerUser({});

    useEffect(() => {
        if(init==="getDealers") getDealers("?"+initQuery);
    }, [getDealers]);

    return {
        dealer,
        allDealers,
        dealerChangeHandler,
        archiveDealer,
        deArchiveDealer,
        dealers: dealers,
        dealerUser,
        dealerUsers,
        deleteDealer,
        getDealers,
        updateDealer,
        getAllDealers,
        getDealerUsers,
        getDealerUserById,
        getDealerById,
        setDealerOptions,
        dealerOptions,
        clearDealerState,
        clearDealerUserState
    }
}

export default useDealerV2;