import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import useHead from "hooks/use-head";
import CalculatorV3 from "components/Calculator/Calculator_v3";

const StapCalculator = props => { 
    useHead({title: "Calculator"});
    
    return (
        <>
            <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
                <ProgressBar />
            </div>
            <Container>
                <CalculatorV3 />
                {/* <Calculator2 /> */}
                {/* <PrevNext prevLink="/stap-btw-marge" nextLink="/financiering/financial-lease/contactgegevens" /> */}
            </Container>
        </>
    )
}

export default StapCalculator;