import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import useDealerV2 from "hooks/dealer/use-dealer_v2";
import { calculateElapsedTime, getDateTimeFromDate } from "lib/helpers";
import Button from "components/ui/Button/Button";
import Input3 from "components/ui/Input/Input3";
import { createPortal } from "react-dom";
import Modal from "components/hoc/Modal";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import useAuthenticate from "hooks/authenticate/use-authenticate";

const SubUserForm = ({dealerId, updateCounter, onUpdate}) => {
    const dispatch = useDispatch();
    
    const [mailSent, setMailSent] = useState({
        verification: false,
        forgotpwd: false
    });

    const { dealerUser, dealerUsers, getDealerUsers, getDealerUserById, clearDealerUserState } = useDealerV2();
    const { resendEmailVerification, sendForgotPassword, updateEmail } = useAuthenticate();

    const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm({
        mode: "onChange",
        defaultValues: {
            oldEmail: "",
            newEmail: ""
        }
    });
    const watchAll = watch();

    useEffect(() => {
        getDealerUsers(dealerId);
    }, [updateCounter]);

    const submitHandler = async (formData) => {
        var resp = await updateEmail(formData?.oldEmail, formData?.newEmail, false);
        closeHandler();
        if(resp==="SUCCESS") onUpdate();
    };

    const closeHandler = () => {
        clearDealerUserState();
        dispatch(uiActions.hideBackdrop());
        setValue("oldEmail", "");
        setValue("newEmail", "");
        setMailSent({forgotpwd: false, verification: false});
    }

    const resendEmailVerificationHandler = async (...inputObj) => {
        setMailSent(prevState => ({...prevState, verification: true}));
        var resp = await resendEmailVerification(...inputObj);
        if(resp?.message==="FAIL") setMailSent(prevState => ({...prevState, verification: false}));
    }

    const sendForgotPasswordHandler = async (...inputObj) => {
        setMailSent(prevState => ({...prevState, forgotpwd: true}));
        var resp = await sendForgotPassword(...inputObj);
        if(resp?.message==="FAIL") setMailSent(prevState => ({...prevState, forgotpwd: false}));
    }

    const getDealerUserByIdHandler = (userId) => {
        var resp = getDealerUserById(userId);
        setValue("oldEmail", resp?.email);
    }

    return (    
        <div className="">
            <div className="grid grid-cols-5 gap-y-3 mb-3 font-semibold">
                <div className="col-span-2">Gebruiker</div><div>Rol</div><div className="col-span-2">Laatst ingelogd</div>
            </div>
            {dealerUsers.map(user => {
                return (
                <div className={`grid grid-cols-5 gap-y-3 items-center hover:bg-slate-200 py-1 cursor-pointer select-none`} key={user.id} onClick={() => getDealerUserByIdHandler(user?.id)}>
                    {/* <div key={user.id}>{user.id}</div> */}
                    <div className="col-span-2">{user.email}</div>
                    <div>{user.type ? user.type : "Gebruiker"}</div>
                    <div className="flex flex-row justify-between col-span-2">
                        <div>{user?.lastLogin ? <>{getDateTimeFromDate(user?.lastLogin)} ({calculateElapsedTime(new Date(user?.lastLogin.slice(0, 19)).getTime(), new Date().getTime())})</> : "NNB"}</div>
                        {/* <div><span className="fa fa-pen-to-square p-5 cursor-pointer text-sm relative hover:scale-105"></span></div>  */}
                    </div>
                </div>
                )
            })}

        {dealerUser?.id && (
                        <>
                        {createPortal(<Modal show={true} title={dealerUser?.email} onClose={closeHandler}>
                            <form onSubmit={handleSubmit(submitHandler)} className="col-span-5 grid grid-cols-2">
                            <div className="col-span-2 flex flex-col">
                                <h3 className="mt-0">Mail versturen</h3>
                                <div className="flex flex-row gap-3">
                                    <Button type="button" onClick={() => resendEmailVerificationHandler("subUser", dealerUser?.id, dealerUser?.userName, false)} disabled={mailSent.verification}>Verstuur activatiemail</Button>
                                    <Button type="button" onClick={() => sendForgotPasswordHandler(dealerUser?.email, dealerUser?.userName, false)} disabled={mailSent.forgotpwd}>Verstuur wachtwoord reset</Button>
                                </div>
                            </div> 
                            <div className="col-span-2 flex flex-col justify-between gap-y-1">
                                <h3 className="">E-mailadres wijzigen</h3>
                                <Input3 readOnly={true} name="oldEmail" register={register} fieldToValidate="email" label="Huidig e-mailadres" placeholder="Huidig e-mailadres" inputValue={watchAll?.oldEmail} errorMessage={errors?.oldEmail?.message} />
                                <Input3 name="newEmail" register={register} fieldToValidate="email" label="Nieuw e-mailadres" placeholder="Nieuw e-mailadres" inputValue={watchAll?.newEmail} errorMessage={errors?.newEmail?.message} />
                                <Button className="btn-primary" type="button" onClick={handleSubmit(submitHandler)}>Wijzigen</Button>
                            </div>

                        </form></Modal>, document.body)}
                        </>
                    )}
        </div>
    )
}

export default SubUserForm;