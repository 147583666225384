import React, { useEffect } from "react";
import Content from "components/Content/Content";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";
import Button from "components/ui/Button/Button";
import useAnalytics from "hooks/use-analytics";
import useDealV2 from "hooks/deal/use-deal_v2";
import { useNavigate } from "react-router-dom";

const PageInsuranceThankYou = () => {
  const navigate = useNavigate();
  const { sendPageView } = useAnalytics();
  const { clearDealFromStore } = useDealV2();

  useEffect(() => {
    sendPageView({category: "verzekering", action: "View", label: "Verzekering succesvol (bedankpagina)"});
    clearDealFromStore(false);
  },[sendPageView]);
  
  return (
    <>
            <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
                <ProgressBar thankyoupage={true} />
            </div>
      <Container>

        <Content
          title="Bedankt voor uw aanvraag!"
          description={["Wij hebben uw aanvraag in goede orde ontvangen. De best passende verzekeringspartner voor deze aanvraag neemt binnen 24 uur contact op met de klant.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen op het nummer 0180 23 41 41"]}
          contentAlign="left"
        />
        <div className="flex flex-row gap-5">
          <Button onClick={() => navigate("/")} className="btn-secondary mb-5">Home</Button>
        </div>
      </Container>
    </>
  );
};

export default PageInsuranceThankYou;