import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";
import Card from "components/ui/Card/Card";
import useDealV2 from "hooks/deal/use-deal_v2";
import Select3 from "components/ui/Select/Select3";
import Input3 from "components/ui/Input/Input3";
import useActivity from "hooks/activity/use-activity";
import { findObjectDifference } from "lib/helpers";

const InsuranceForm = ( { title, inModal, onClose, onUpdate, dealDetail }) => {
    const { createActivity } = useActivity();
    const [formChanged, setFormChanged] = useState(false);

    const defaultValues = {
      amountpurchase: dealDetail?.amountpurchase || "",
      typeOfInsurance: dealDetail?.typeOfInsurance || "",
      damageFreeYearsInsurance: dealDetail?.damageFreeYearsInsurance || "",
      damageHistoryInsurance: dealDetail?.damageHistoryInsurance || "",
      kmPerYear: dealDetail?.kmPerYear || ""
    }

    const { register, formState: { errors }, watch, handleSubmit } = useForm({
      mode: "onChange",
      defaultValues: defaultValues
    });

    const { patchDeal} = useDealV2();
    const { role } = useAuth();

    const submitHandler = async (formData) => { 
      var dataToChange = findObjectDifference(defaultValues, formData);
      var resp = await patchDeal(dealDetail.dealID, dataToChange);
      if(resp==="SUCCESS") {
        var activityResp = await createActivity("Deal", "update", dealDetail, dataToChange, "Verzekeringsgegevens");
        setFormChanged(false);
        if(activityResp?.message==="SUCCESS") {
          onUpdate();
        }
      }
    }
    const watchAll = watch();

    if(!role.includes("Admin")) return <>Je hebt onvoldoende rechten</>; 

return (
    <form onSubmit={handleSubmit(submitHandler)} onChange={() => setFormChanged(true)} className={`text-xs lg:text-base ${inModal ? "mt-0" : "mt-5"}`}>
    <Card title={title} showClose={inModal} onClose={onClose}>
      <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
        <div className="grid col-span-2 gap-3 p-5">
          <div className="grid grid-cols-2 gap-3">
            <Input3 register={register} fieldToValidate="digitsOnly" name="amountpurchase" placeholder="Verkoopprijs incl. BTW" inputValue={watchAll.amountpurchase} errorMessage={errors?.amountpurchase?.message} />
            <Select3 register={register} set="typeOfInsurance" name="typeOfInsurance" placeholder="Type verzekering" inputValue={watchAll.typeOfInsurance} />
            <Input3 register={register} name="damageFreeYearsInsurance" placeholdersm="Schadevrije jaren" placeholder="Aantal geregistreerde schadevrije jaren" inputValue={watchAll.damageFreeYearsInsurance} />
            <Input3 register={register} name="damageHistoryInsurance" placeholdersm="Schade afgelopen 5 jaar" placeholder="Schadeverleden (auto) afgelopen 5 jaar" inputValue={watchAll.damageHistoryInsurance} />
            <Select3 register={register} set="kmperjaar" name="kmPerYear" placeholdersm="Kilometers per jaar" placeholder="Kilometers per jaar" label="Hoeveel kilometers per jaar" inputValue={watchAll.kmPerYear} />
          </div>
        </div>
        <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
          <Button className="bg-gradient" disabled={!formChanged}>Opslaan</Button>
        </div>
      </div>
    </Card>
  </form>
    )
}

export default InsuranceForm;

//   <div className={blokWrapperClass}> 
//     <div className="md:col-span-3 xl:col-span-3 grid grid-cols-1 md:grid-cols-2 items-center gap-y-3">

//     </div>
//     </div>