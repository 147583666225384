import useRdw from "hooks/rdw/use-rdw";
import useAuth from "hooks/use-auth";
import { toyyyymmdd } from "lib/calc";
import { safeParseInt } from "lib/helpers";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

export const useActua = () => {
    const { rdwData, getByLicensePlate } = useRdw();

    const dispatch = useDispatch();
    const { token: jwt } = useAuth();
    const [healthResponse, setHealthResponse] = useState({});
    const [latestStatus, setLatestStatus] = useState({});
    const [requestStatus, setRequestStatus] = useState({});

    const [submitResponse, setSubmitResponse] = useState({
        actuaResponse: null,
        errors: null,
        missingFields: null,
        status: null
    });

    const clearAllStatus = () => {
        setHealthResponse({});
        setLatestStatus({});
        setRequestStatus({});
    }

    const submitInsuranceRequest = async (dealID, dealDetail) => {
        dispatch(uiActions.showBackdrop());
        var listPrice = dealDetail?.vehicleDetail?.listPrice || dealDetail?.amountpurchase || 0;
        var massEmpty = dealDetail?.vehicleDetail?.massEmpty || 1;

        // var [ rdwData ] = await getByLicensePlate(dealDetail?.vehicleDetail?.licensePlate);
        
        // if(!listPrice) {
        //     if(!rdwData?.catalogusprijs || !rdwData?.massa_ledig_voertuig) return;
        //     if(rdwData?.catalogusprijs) {
        //         var listPrice = rdwData?.catalogusprijs ? parseInt(rdwData?.catalogusprijs) : 0;
        //         var brutoBPM = parseInt(dealDetail?.grossBPM);
        //         // priceEx = (catalogusprijs-brutobpm/1.21)+brutobpm
    
        //         if(dealDetail?.typeOfDeal==="BTW") {
        //             listPrice = parseInt(( (listPrice-brutoBPM) / 1.21) + brutoBPM);
        //         }
        //     }
        //     if(rdwData?.massa_ledig_voertuig) massEmpty = rdwData?.massa_ledig_voertuig;
        // }

        var licensePlate = dealDetail?.vehicleDetail?.licensePlate;
        var make = dealDetail?.vehicleDetail?.make;
        var model = dealDetail?.vehicleDetail?.model;
        var mileage = dealDetail?.vehicleDetail?.mileage;
        
        // if(!licensePlate) {
        //     alert("Kenteken is vereist!");
        //     return;
        // }

        const body = {
            "customerDetail": {
                "lastName": dealDetail?.customerDetail?.lastName,
                "dateofBirth": dealDetail?.customerDetail?.dateofBirth,
              },
              "vehicleDetail": {
                "licensePlate": licensePlate,
                "mileage": mileage || 0,
                "make": make || "string",
                "model": model || "string",
                "listPrice": safeParseInt(listPrice),
                "massEmpty": massEmpty,
                "firstRegistrationDate":  dealDetail?.vehicleDetail?.firstRegistrationDate,
                "type": dealDetail?.vehicleDetail?.type || "",
              }
        };

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActuaInsurance/SubmitRequest/"+dealID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(body)
            });
            dispatch(uiActions.hideBackdrop());

                var respJson = await resp.json();
                setSubmitResponse(respJson);
                if(respJson?.status==="Failed") {
                    throw new Error("fail");
                }
                dispatch(uiActions.setAlert({type: "success", message: "Verzekering succesvol ingediend bij Actua" }))
                return "SUCCESS";

        } catch {
            dispatch(uiActions.hideBackdrop());

        };
    }

    const checkHealth = async () => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActuaInsurance/HealthCheck", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
        });
        try {
            var respJson = await resp.json();
            setHealthResponse(respJson);
        } catch {}
    }

    const getLatestStatus = async (dealID) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActuaInsurance/GetLatestStatus/"+dealID, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
        });

        var respJson = await resp.json();
        setLatestStatus(respJson);
    }

    const getRequestStatus = async (dealID) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActuaInsurance/GetRequestStatus/"+dealID, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
        });

        var respJson = await resp.json();
        setRequestStatus(respJson);
    }

    
    const createInsurance = useCallback(async (deal) => {
        dispatch(uiActions.showBackdrop());

        // Wat vullen we in bij Verkoper? Naam is niet bekend bij autobedrijven: actua verkoper..
        // Wat doen we met voorletters/tussenvoegsel? We vragen zelf voornaam/achternaam uit
        // Geboortedatum hoofdbestuurder
        // catalogusWaarde, massEmpty, toevoegen aan Vehicle

        var json = {
            "Verzekering": {
                "autobedrijf": deal?.dealerName, // (required) Naam autobedrijf
                "verkoper": { // Informatie over de verkoper
                    "naam": deal?.sellerName, // (required) 
                    "email": deal?.sellerEmail // (required) 
                },
                "relatie": {
                    "geslacht": deal?.salutation?.replace("Dhr", "Man").replace("Mevr", "Vrouw"), // (required) Man/Vrouw
                    "voorletters": deal?.initials,
                    "tussenvoegsels": deal?.preposition,
                    "naam": deal?.customerName, // (required) Naam van de verzekerde
                    "adres": {
                        "adres": deal?.address, // (required) 
                        "postcode": deal?.postalCode, // (required) 1234XX
                        "plaats": deal?.place // (required) 
                    },
                    "telefoonNummer": deal?.phone, // (required) 010-4477100
                    "emailAdres": deal?.email, // (required) 
                    "geboortedatum": toyyyymmdd(deal?.dateofBirth), // (required) 1986-03-28
                    "geboortedatumHoofdbestuurder": "1994-10-10", // 1986-03-28, Note:verplicht indien bestuurder afwijkt van de aanvrager
                    "kvkNummer": deal?.chamberNumber, // (required) string
                    "btwVerekenen": !!(deal?.typeOfDeal==="BTW"), // (required) boolean, Geeft aan of BTW verrekend kan worden door relatie                     
                    "schadevrijeJaren": deal?.damageFreeYearsInsurance ? parseInt(deal?.damageFreeYearsInsurance) : 0, // (required) integer
                    "sbiCode": deal?.sbiCode, // (required) string
                    "kilometrage": parseInt(deal?.kmPerYear) // integer
                },
                "voertuig": {
                    "kenteken": deal?.licensePlate?.replace(/\s/g, ""), // (required) 2KDF77
                    "merk": deal?.make, // (required)
                    "model": deal?.model, // (required)
                    "uitvoering": deal?.type,
                    "kilometerstand": parseInt(deal?.mileage), // (required) integer
                    "bouwjaar": toyyyymmdd(deal?.firstRegistrationDate), // (rqeuired) yyyy-mm-dd (datum_eerste_toelating)
                    "gewicht": parseInt(deal?.massEmpty), // (required) integer (massa ledig gewicht)
                    "catalogusWaarde": parseInt(deal?.listPrice), // (required)  Note: indien klant WEL btw kan verrekenen moet deze waarde exclusief BTW zijn, Indien klant GEEN btw kan verrekenen moet deze waarde inclusief BTW zijn, o.b.v. kenteken R248LR is dit - catalogusprijs excl btw: 37897, - catalogusprijs incl btw: 44149
                    "soort": deal?.vehicleType?.replace("Personenauto", "auto").replace("Bedrijfsauto", "bestelwagen") // auto|bestelwagen
                }   
            }
        }

        try {
            var resp = await fetch("https://sld.verzekerjeauto.nl/api/insurance-create", {
                method: "POST",
                body: JSON.stringify(json)
            });
    
            var respJson = await resp.json();
            if(respJson?.error===true) throw new Error(respJson?.message);
            if(respJson?.status==="ok" && respJson?.redirect_uri) {
                return respJson?.redirect_uri;
            }
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Verzekering succesvol aangevraagd!", type: "success"}));
            return "SUCCESS";
        } catch (err) {
            var errMessage = err?.message || "Fout bij het aanvragen van de verzekering!";
            dispatch(uiActions.setAlert({message: errMessage, type: "danger", time: 20000}));
        }
    }, [dispatch]);

    return {
        createInsurance,
        checkHealth,
        healthResponse,
        getLatestStatus,
        latestStatus,
        submitInsuranceRequest,
        submitResponse,
        getRequestStatus,
        requestStatus,
        clearAllStatus
    }
}

export default useActua;