import React, { useState, useEffect, Suspense, useRef } from "react";
import { createPortal } from "react-dom";
import EmailEditor, { EditorRef, EmailEditorProps} from 'react-email-editor';
import { useForm } from "react-hook-form";

import Select3 from "components/ui/Select/Select3";
import useEmail from "hooks/email/use-email";
import Modal from "components/hoc/Modal";
import Input3 from "components/ui/Input/Input3";
import Button from "components/ui/Button/Button";
import InputRadio3 from "components/ui/InputRadio/InputRadio3";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";
import TextArea3 from "components/ui/TextArea/TextArea3";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

const MailTemplateManager = () => {
    const dispatch = useDispatch();
    const emailEditorRef = useRef(null);
    const [showHTMLPreview, setShowHTMLPreview] = useState(false);
    const [design, setDesign] = useState({
        loaded: false,
        json: {}
    });
    
    const [changeCounter, setChangeCounter] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const { email, emails, getEmails, getEmailById, createEmail, updateEmail, deleteEmail, stateClearEmail } = useEmail("getEmails");   

    const { register, formState: { errors }, watch, clearErrors, setValue, reset, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: {
            emailId: "",
            selected_emailId: "",
            referenceId: "",
            subject: "",
            templateName: "",
            isActive: "",
            toCustomer: "",
            toDealer: "",
            toEmail: "",
            to: "",
            emailVariables: "",
            category: "",
            htmlBody: ""
        }
    });
    
    const watchAll = watch();
    const selected_emailId = watch("selected_emailId");

    useEffect(() => {
        async function getEmail() {
            var respJson = await getEmailById(selected_emailId);
            if(respJson?.message==="SUCCESS") {
                setValue("emailId", respJson?.data?.emailId);
                setValue("referenceId", respJson?.data?.referenceId);
                setValue("subject", respJson?.data?.subject);
                setValue("templateName", respJson?.data?.templateName);
                setValue("isActive", respJson?.data?.isActive);
                setValue("toCustomer", respJson?.data?.toCustomer);
                setValue("toDealer", respJson?.data?.toDealer);
                setValue("toEmail", respJson?.data?.toEmail);
                setValue("to", respJson?.data?.to);
                setValue("emailVariables", respJson?.data?.emailVariables);
                setValue("category", respJson?.data?.category);
                setValue("htmlBody", respJson?.data?.htmlBody);
                setDesign({
                    loaded: true,
                    json: respJson?.data?.designJson
                });              
            }            
        }
        if(selected_emailId) {
            getEmail();
        } else {
            reset();
            stateClearEmail();
        }
    }, [getEmailById, selected_emailId, changeCounter]);

    const changeHandler = async (fieldName, fieldValue) => {
        setDesign({loaded: false, json: {}});

        if(fieldName==="selected_emailId") {
            clearErrors();

            if(fieldValue) {
                const resp = await getEmailById(fieldValue);
            }  else {
                reset();
                emailEditorRef?.current?.editor.loadBlank();
                // emailEditorRef.current.editor.loadDesign({});
            }

        }
    }

    const deleteEmailHandler = async () => {
        var respJson = await deleteEmail(email?.emailId)
        if(respJson?.message==="SUCCESS") {
            setChangeCounter(prevState => prevState + 1);
            getEmails();

        }
    }

    const onReady = (unlayer) => {
        try {
            emailEditorRef.current.editor.loadDesign(JSON.parse(design?.json));
        } catch (err) {

        }

        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadDesign();
        // unlayer.loadDesign(() => console.log("x"));
    
        // const templateJson = {};
        // unlayer.loadDesign(templateJson);
    };

    const submitHandler = (formData) => {

        const unlayer = emailEditorRef.current?.editor;
    
        unlayer?.exportHtml(async (data) => {
          const { design, html } = data;        

           var dataToUpdate = {
                ...formData,
                htmlBody: html,
                designJson: JSON.stringify(design),
                isActive: !!formData?.isActive,
                toCustomer: !!formData?.toCustomer,
                toDealer: !!formData?.toDealer,
                toEmail: !!formData?.toEmail,
            }

            // remove selected_referenceId, because its not asked for request data
            delete dataToUpdate.selected_emailId;

            // when template doesn't exist, remove emailId, because we need to create a email template
            if(!formData?.emailId) {
                delete dataToUpdate?.emailId;
                // create new email template
                var respJson = await createEmail(dataToUpdate);
                if(respJson?.message==="SUCCESS") getEmails();
            } else {
                // update email template
                var respJson = updateEmail(formData.emailId, dataToUpdate);
                if(respJson?.message==="SUCCESS") setChangeCounter(prevState => prevState + 1);
            }

        });
        
    }


    return (
        <>
        <h1>Mailtemplate Manager</h1>
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="">
                <Select3 name="selected_emailId" onChange={changeHandler} inputValue={watchAll?.selected_emailId} label="Template" placeholder="Selecteer template om te wijzigen" register={register} options={emails?.map(item => ({value: item.emailId, label: item.subject+" ("+item.emailId+")"}))}></Select3>

                <div className="flex flex-row gap-x-3">
                    <Input3 name="referenceId" register={register} placeholder="referenceId" label="referenceId" inputValue={watchAll?.subject}/>
                    <Input3 readOnly={true} name="emailId" register={register} placeholder="emailId" label="emailId" inputValue={watchAll?.emailId}/>
                </div>

                <div className="grid grid-cols-3 gap-x-3">
                    <Input3 name="subject" register={register} placeholder="Onderwerp" label="Onderwerp" inputValue={watchAll?.subject}/>
                    <Input3 name="category" register={register} placeholder="category" label="category" inputValue={watchAll?.subject}/>
                    <Input3 name="templateName" register={register} placeholder="templateName" label="templateName" inputValue={watchAll?.subject}/>
                </div>
        
                <InputCheckbox3 name="isActive" register={register} label="Actief" placeholder="Actief" />
                <InputCheckbox3 name="toCustomer" register={register} label="toCustomer" placeholder="toCustomer" />
                <InputCheckbox3 name="toDealer" register={register} label="toDealer" placeholder="toDealer" />
                <InputCheckbox3 name="toEmail" register={register} label="toEmail" placeholder="toEmail" />
                <Input3 name="to" register={register} placeholder="to" label="to" inputValue={watchAll?.to}/>
                <Input3 name="emailVariables" register={register} placeholder="emailVariables" label="emailVariables" inputValue={watchAll?.subject}/>
            </div>

{(design?.loaded || !selected_emailId) && (
            <div className="flex flex-col">
                {/* <iframe className="w-full xh-full border-b scale-75 h-[600px]" srcDoc={watchAll?.htmlBody} /> */}
                <EmailEditor ref={emailEditorRef} onReady={onReady} style={{minHeight: "640px"}} options={{locale: "nl", safeHtml: true, displayMode: "email"}} />
                {/* <TextArea3 name="htmlBody" register={register} parentClasses="min-h-full font-code text-xs p-3" inputValue={watchAll?.htmlBody} label="HTML" placeholder="HTML" /> */}
            </div>
)}

            <div className="flex flex-row justify-between items-center">
            {email?.emailId ?
                <details className="text-sm cursor-pointer ">
                    <summary>Verwijderen</summary>
                    <span className="text-[red]  hover:font-bold relative top-10" onClick={deleteEmailHandler}>Bevestigen</span>
                </details> 
            : <>&nbsp;</>}
            <Button type="submit" className="bg-gradient">{watchAll?.selected_emailId ? "Wijzigen" : "Toevoegen"}</Button>
            </div>

    </form>
    <Button type="button" onClick={() => setShowHTMLPreview(true)}>Bekijk voorbeeld (HTML)</Button>

    {showHTMLPreview && 
    <>
    <Suspense fallback="Laden..">
    <Modal xl={true} show={true} onClose={() => {setShowHTMLPreview(false);dispatch(uiActions.hideBackdrop())}} title="HTML voorbeeld">
        <iframe className="w-full xh-full border-b scale-75 h-[800px]" srcDoc={watchAll?.htmlBody} />
        <TextArea3 name="htmlBody" register={register} parentClasses="flex flex-grow h-screen font-code text-xs p-3" inputValue={watchAll?.htmlBody} label="HTML" placeholder="HTML" />
    </Modal>
    </Suspense>
    </>
    }

    </>)

}

export default MailTemplateManager;