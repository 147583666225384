import { useRef } from "react";
import Input from "components/ui/Input/Input";

const SearchForm = (props) => {
  const keywordRef = useRef();

  const submitHandler = (evt) => {
    evt.preventDefault();
    var searchKeyword = keywordRef.current.value;
    props.onSearch(searchKeyword);
  };


  return (
    <>
      <form onSubmit={submitHandler} className={"flex flex-row items-center"}>
        <>
        <Input
          ref={keywordRef}
          name="keyword"
          label="Trefwoord"
          placeholder="Trefwoord"
        />
        <button type="submit" className="px-3">
          <span className="fa fa-magnifying-glass"></span>
        </button>
      </>
      </form>
    </>
  );
};

export default SearchForm;
