import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useDealV2 from "hooks/deal/use-deal_v2";

import { escapeSpecialChars } from "lib/voertuig";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import Button from "components/ui/Button/Button";
import { serialize } from "lib/helpers";

const Zoekmachine = ({searchFor, disableArchive, phase, saleType }) => {
  const [engineVisible, setEngineVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams(); 
  const [searchObj, setSearchObj] = useState({
    saleType: saleType || (searchParams.get("saleType") ? searchParams.get("saleType") : ""),
    customerId: searchParams.get("customerId") || "",
    dealerId: searchParams.get("dealerId") || "",
    name: searchParams.get("name") || "",
    chamberNumber: searchParams.get("chamberNumber") || "",
    dealID: searchParams.get("dealID") || "",
    relatedID: searchParams.get("relatedID") || "",
    status: searchParams.get("status") || "",
    place: searchParams.get("place") || "",
    source: searchParams.get("source") || "",
    dealerName: searchParams.get("dealerName") || "",
    postalCode: searchParams.get("postalCode") || "",
    companyName: searchParams.get("companyName") || "",
    licensePlate: searchParams.get("licensePlate") || "",
    phase: searchParams.get("phase") || phase || "",
    isArchive: (searchParams.get("isArchive")==="true" ? true : false),
    make: searchParams.get("make") || "",
    contractNumber: searchParams.get("contractNumber") || "",
    contactFirstName: searchParams.get("contactFirstName") || "",
    contactLastName: searchParams.get("contactLastName") || "",
    phone: searchParams.get("phone") || "",
    phoneNumber: searchParams.get("phoneNumber") || "",
    sourceOfDealer: searchParams.get("sourceOfDealer") || ""
  });

  const { getDealFilters, dealFilters } = useDealV2();

  useEffect(() => {
    getDealFilters();
  },[]);

  const archiveHandler = () => {
    var currentArchiveState = searchParams.get("isArchive") || false;
    if(currentArchiveState==="false" || !currentArchiveState) currentArchiveState = false;
    if(currentArchiveState==="true") currentArchiveState = true;
    searchParams.set("isArchive", !currentArchiveState);
    setSearchParams(searchParams);
    setSearchObj(prevState => {
      return {
        ...prevState,
        isArchive: !currentArchiveState
      }
    });
  }

  const searchHandler = (evt) => {
    evt.preventDefault();
    setSearchParams(serialize(searchObj));
  }

  const changeHandler = (evt) => {
    var fieldName = evt.target.name || "";
    var fieldValue = evt.target.value || "";
    if(!fieldName) return;

    if(fieldName==="licensePlate") fieldValue = fieldValue.replace(/(\s|\-)/g, "").toLowerCase().trim();

    setSearchObj(prevState => {
      return {
        ...prevState,
        [fieldName]: fieldValue
      }
    });
  }
   
  const resetHandler = () => {
    setSearchObj({
      dealID: "",
      saleType: saleType || "",
      customerId: "",
      dealerId: "",
      name: "",
      chamberNumber: "",
      status: "",
      place: "",
      relatedID: "",
      source: "",
      dealerName: "",
      postalCode: "",
      companyName: "",
      licensePlate: "",
      phase: "",
      make: "",
      contractNumber: "",
      contactFirstName: "",
      contactLastName: "",
      phone: "",
      phoneNumber: ""
    });
    setSearchParams("");
  }

  const searchParamExists = searchParams.toString();

  return (
        <>
        <div className="flex flex-row justify-start lg:hidden select-none">
          <span className="fa fa-magnifying-glass text-xl" onClick={() => setEngineVisible(prevState => !prevState)}></span>
        </div>
        <form onSubmit={searchHandler} className={`${engineVisible ? "" : "hidden"} lg:flex flex-col gap-1 lg:gap-3 mb-5 p-5 rounded-xl`}>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center gap-1 lg:gap-3">
                
                {searchFor==="customers" &&
                <>
                <Input name="customerId" value={searchObj.customerId} onChange={changeHandler} type="text" placeholder="Klant ID" />
                <Input name="chamberNumber" value={searchObj.chamberNumber} onChange={changeHandler} type="text" placeholder="KvK nummer" />
                </>
                }

                {searchFor==="dealers" &&
                <>
                <Input name="dealerId" value={searchObj.dealerId} onChange={changeHandler} type="text" placeholder="Dealer ID" />
                <Input name="chamberNumber" value={searchObj.chamberNumber} onChange={changeHandler} type="text" placeholder="KvK nummer" />
                <Select set="statusDealer" name="status" value={searchObj.status} onChange={changeHandler} type="text" label="Status" placeholder="Status"></Select>
                </>
                }

                {searchFor==="deals" &&
                <>
                <Input name="dealID" value={searchObj.dealID} onChange={changeHandler} type="text" placeholder="Verkoop ID" />
                {/* <Select name="saleType" value={searchObj.saleType} onChange={changeHandler} placeholder="Financieringsvorm" label="Financieringsvorm" options={filters.saleTypes?.filter(item => item!=="").map(item => ({label: item.replace("FinancialLease", "Financial Lease").replace("BtwFinanciering", "Btw Financiering"), value: item}))}></Select> */}
                <Select name="source" value={searchObj.source} onChange={changeHandler} placeholder="Source" label="Source" set="source"></Select>
                <Input name="contractNumber" value={searchObj.contractNumber} onChange={changeHandler} type="text" placeholder="Contractnummer" />
                </>
                }
                {!disableArchive &&
                <div className="hidden lg:block lg:col-start-4">
                  <Button type="button" onClick={archiveHandler} name="isArchive" value={searchObj.isArchive}>{searchObj.isArchive ? 'Actueel': 'Geschiedenis'}</Button>
                </div>
                }
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3">
                {/* <Select name="dealerId" value={searchObj.dealerId} onChange={changeHandler} label="Autobedrijf" options={dealerFilters} /> */}
                {searchFor==="dealers" &&
                <Input name="name" value={searchObj.name} onChange={changeHandler} type="text" placeholder="Bedrijfsnaam" />
                }
                {searchFor==="deals" &&
                <Input name="dealerName" value={searchObj.dealerName} onChange={changeHandler} type="text" placeholder="Autobedrijf" />
                }
                <Input name="postalCode" value={searchObj.postalCode} onChange={changeHandler} type="text" placeholder="Postcode" />

                {/(dealers|customers)/.test(searchFor) &&
                <Input name="place" value={searchObj.place} onChange={changeHandler} type="text" placeholder="Plaats" />
                }
                {/dealers/.test(searchFor) &&
                <>
                <div></div>
                <Input name="contactFirstName" value={searchObj.contactFirstName} onChange={changeHandler} type="text" placeholder="Voornaam contactpersoon" />
                <Input name="contactLastName" value={searchObj.contactLastName} onChange={changeHandler} type="text" placeholder="Achternaam contactpersoon" />
                <Input name="phone" value={searchObj.phone} onChange={changeHandler} type="text" placeholder="Telefoonnummer" />
                <Select name="sourceOfDealer" value={searchObj.sourceOfDealer} onChange={changeHandler} label="Partner" placeholder="Partner">
                  <option value="Actua">Actua</option>
                </Select>
                </>
                }
                {/(deals|customers)/.test(searchFor) &&
                <Input name="companyName" value={searchObj.companyName} onChange={changeHandler} type="text" placeholder="Klant" />
                }
              </div>
              {searchFor==="deals" &&
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3">
                <Input name="licensePlate" value={searchObj.licensePlate} onChange={changeHandler} type="text" placeholder="Kenteken" />
                <Select name="make" label="Merk" value={searchObj.make} onChange={changeHandler} options={dealFilters.makesfiltered?.filter(item => item!=="").sort().map(item => ({label: escapeSpecialChars(item), value: escapeSpecialChars(item).trim()}))}></Select>
                <Select name="phase" set={(searchObj.isArchive) ? 'isArchive_phase': 'phase'} label="Fase" value={searchObj.phase} onChange={changeHandler}></Select>
                <Input name="phoneNumber" value={searchObj.phoneNumber} onChange={changeHandler} type="text" placeholder="Telefoonnummer" />
              </div>
              }
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3 items-center">
                <Button type="submit" className="btn-secondary !w-full !max-w-full">Zoeken</Button>
                {searchParamExists && 
                <Button type="button" className="cursor-pointer ml-2 !bg-[#D20B00]" onClick={resetHandler}><span className="fa fa-xmark"></span></Button>
                }
                <div className="lg:col-start-4">
                  {/* {searchParams.get("perpage")!=="99999" &&
                  <Select disableFirst={true} value={props.perpage} onChange={evt => props.onChangePageSize(evt.target.value)} label="Aantal resultaten" placeholder="Aantal resultaten" options={[{value: 25},{value: 50},{value: 100}, {value: 9999, label: "Alles"}]} />
                  } */}
                  </div>
              </div>
          </form>
          </>
    )
}

export default Zoekmachine;