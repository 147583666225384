import { useLocation } from "react-router-dom";
import Content from "components/Content/Content";
import Container from "components/hoc/Container";

const Bedankpagina = () => {
    const { state } = useLocation();
    if(!state?.lastName) return <></>
    const { lastName } = state;

    return (
        <>
        <Container>
        <Content
          title="Bedankt voor uw aanmelding!"
          description={[`Beste heer/mevrouw${lastName ? ' '+lastName : ','},`, <><br /><br /></>, "Wij hebben uw aanmelding in goede orde ontvangen. Op werkdagen ontvangt u binnen 24 uur bericht.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen op het nummer 0180 23 41 41"]}
          contentAlign="left"
        />
        </Container>
        </>
    )
}

export default Bedankpagina;