import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uiActions } from "store/ui-slice";
import { dossierActions } from "store/dossier-slice";
import Button from "components/ui/Button/Button";
import useDealV2 from "hooks/deal/use-deal_v2";

const Popup = () => {
    const { updateDeal, archiveDeal } = useDealV2();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notification = useSelector(state => state.ui.notification);
    const buttons = useSelector(state => state.ui.notification.buttons);
    const { title, message, visible, errors } = notification;

    useEffect(() => {
        if (visible) document.body.style.overflow = "hidden";
        if (!visible) document.body.style.overflow = "scroll";
    }, [visible]);

    const buttonHandler = (actie, url, data, attachment) => {
        switch (actie) {
            case "otherVehicle":
                archiveDeal(data);
                // dispatch(archiveDeal(data, jwt));
                // dispatch(dossierActions.clearCalculation()); 
                dispatch(dossierActions.reCalculate({dealID: data.relatedID}));
                // dispatch(dossierActions.reCalculate({dealID: data.dealID}));

                dispatch(uiActions.clearNotification());
                dispatch(uiActions.hideBackdrop());
                if (url) navigate(url);
                break;
            case "reserveFinance":
                updateDeal(data);
                // dispatch(updateDeal(data, jwt));               
                dispatch(uiActions.clearNotification());
                dispatch(uiActions.hideBackdrop());
                break;
            case "sendOffer":
                updateDeal(data, attachment, 'Offerte verzonden');
                // dispatch(updateDeal(data, jwt, attachment, 'Offerte verzonden'));
                dispatch(uiActions.clearNotification());
                dispatch(uiActions.hideBackdrop());
                break;
            case "restart":
                dispatch(dossierActions.clearVoertuig());
                dispatch(uiActions.clearNotification());
                if (url) navigate(url);
                break;
            case "cancel":
                dispatch(uiActions.clearNotification());
                dispatch(uiActions.hideBackdrop());
                break;
            default:
                break;
        }
    }

    return (
        <>
            {visible && (
                <>
                    <div className="flex flex-col left-0 top-0 fixed w-full h-full bg-black opacity-75 z-20"></div>
                    <div className="flex flex-col left-0 top-0 fixed w-full h-full items-center justify-center z-50">
                        <div className="bg-white p-8 w-fit rounded-xl text-center relative shadow-xl">
                            <div className="text-3xl font-black mb-5 border-b border-[#D9D9D9] pb-3">{title}</div>
                            <div className="mb-5 whitespace-pre-wrap text-left">{message}</div>
                            {errors &&
                            <div className="mb-5 whitespace-pre-wrap text-left">
                                <ul>
                                    {errors.map(err => {
                                        return <li key={err}>{err}</li>
                                    })}
                                </ul>
                            </div>
                            }

                            <div className="flex flex-row justify-end gap-2">
                                {!buttons.length && <Button className="btn-primary" onClick={(actie) => buttonHandler('cancel')}>Sluiten</Button>}
                                {buttons.map(button => {
                                    return (
                                        <button key={button.label} className={button.primary ? 'btn-primary' : 'btn-secondary'} onClick={() => buttonHandler(button.action, button.url, button.data, button.attachment)}>{button.label}</button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Popup;