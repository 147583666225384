import { validateFormField } from "lib/validation";

const InputRadio3 = ({register, name, type, inputValue, fieldToValidate, required, label, placeholdersm, errorMessage, onChange}) => {
    var focussedOrWithValue = !!inputValue;

    return (
        <>
        <div className="flex flex-row items-center gap-x-2 relative mb-3">
            <span>{label}</span>
            <label htmlFor={`radio-${name}-yes`} className="select-none">Ja</label>
            <input id={`radio-${name}-yes`} {...register(name, { onChange: (e) => onChange ? onChange(name, e.target.checked) : null, validate: (value) => validateFormField(fieldToValidate, value, required) })} className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                `}
                type="radio"
                spellCheck={false}
                autoComplete="off"
                />
            <label htmlFor={`radio-${name}-no`} className="select-none">Nee</label>
            <input id={`radio-${name}-no`} {...register(name, { onChange: (e) => onChange ? onChange(name, e.target.checked) : null, validate: (value) => validateFormField(fieldToValidate, value, required) })} className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                `}
                type="radio"
                spellCheck={false}
                autoComplete="off"
                />
            {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-5">{errorMessage}</span>}
        </div>
        </>
    )
}

export default InputRadio3;