import { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import useAuth from "hooks/use-auth";

import Card from "components/ui/Card/Card";
import Input from "components/ui/Input/Input";
import InputKvk from "components/ui/InputKvk/InputKvk";
import Select from "components/ui/Select/Select";
import Button from "components/ui/Button/Button";
import { newDealer } from "store/dealer-actions";
import DropArea from "components/ui/DropArea/DropArea";
import InputColor from "components/ui/InputColor/InputColor";
import { uiActions } from "store/ui-slice";

const NewDealerForm = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formIsValid, setFormIsValid] = useState(true);
    const { token } = useAuth();

    const bedrijfsnaamRef = useRef();
    const logoImageUrlFormRef = useRef();
    const primaryColorRef = useRef();
    const buttonTextColorRef = useRef();

    const [company, setCompany] = useState({
      primaryColor: "",
      buttonTextColor: "",
      chamberNumber: "",
      rating: "",
      rechtsvorm: "",
      sbiCodes: "",
      website: "",
      vaTnumber: "",
      status: "",
      telephone: "",
      name: "",
      phone: "",
      place: "",
      postalCode: "",
      address: "",
      contactFirstName: "",
      contactLastName: "",
      dealerCategory: "B",
      email: "",
      iban: "",
      importID: "",
      latitude: "",
      longitude: "",
      mailAddress: "",     
    });
 
    const setKvkDataHandler = useCallback((data) => {
      const { handelsnaam: name, kvkNummer: chamberNumber, sbiActiviteiten, rechtsvorm, latitude, longitude } = data;
      var sbiCodes = sbiActiviteiten?.map(item => item.sbiCode).join(",") || "";
      var { adres: address, plaats: place, postcode: postalCode } = data.visitingAddress;

      setCompany(prevState => {
        return {
          ...prevState,
          name,
          rechtsvorm,
          chamberNumber,
          place,
          address,
          postalCode,
          sbiCodes,
          latitude: ""+latitude,
          longitude: ""+longitude
        }
      });
    },[]);

  const submitHandler = useCallback(async (evt) => {
      evt.preventDefault();
      var errorMessage = "";
      var logo = logoImageUrlFormRef.current?.files[0];
      if(!company.email) errorMessage = "Ongeldig e-mailadres";
      if(!company.telephone) errorMessage = "Telefoonummer ontbreekt";
      if(!company.importID) errorMessage = "Vul een importID in om de auto voorraad van het bedrijf te kunnen tonen.";
      if(!company.place) errorMessage = "Plaats ontbreekt";
      if(!company.address) errorMessage = "Adres ontbreekt";
      if(!company.chamberNumber) errorMessage = "KvK nummer ontbreekt";
      if(!company.name) errorMessage = "Vul de bedrijfsnaam in";

      if(!company.name||!company.chamberNumber||!company.address||!company.place||!company.importID|!company.telephone||!company.email) {
        dispatch(uiActions.setAlert({ message: errorMessage, type: "danger"}));
        return;
      }

      // add https:// to website
      var website = company.website;
      if(!/http/.test(website)) website="https://"+website;

      // remove spaces, and uppercase like 2324VT
      var postalCode = company.postalCode;
      postalCode = postalCode.replace(/\s/g, "").toUpperCase();

      var vaTnumber = company.vaTnumber;
      if(!vaTnumber) vaTnumber = 0;

      var formData = new FormData();
      formData.append("contactFirstName", company.contactFirstName);
      formData.append("contactLastName", company.contactLastName);
      formData.append("status", company.status);
      formData.append("chamberNumber", company.chamberNumber);
      formData.append('logoImageUrlForm', logo);
      formData.append("name", company.name);
      formData.append("address", company.address);
      formData.append("importID", company.importID);
      formData.append("postalCode", postalCode);
      formData.append("telephone", company.telephone);
      formData.append("website", website);
      formData.append("vaTnumber", vaTnumber);
      formData.append("email", company.email);
      formData.append("iban", company.iban);
      formData.append("phone", company.phone);
      formData.append("place", company.place);
      formData.append("buttonTextColor", buttonTextColorRef.current?.value ? buttonTextColorRef.current?.value : "#fff");
      formData.append("primaryColor", primaryColorRef.current?.value ? primaryColorRef.current?.value : "#F8700D");
      formData.append("dealerCategory", "B");
      formData.append("rating", 0);
 
      const response = await dispatch(newDealer(formData, token));
      if(response==="SUCCESS") {
        navigate("../");
        return;
      }
      if(response.message==="User already exists with this email or user name!") {
        errorMessage = "Er bestaat al een gebruiker met dit e-mailadres!";
        dispatch(uiActions.setAlert({ message: errorMessage, type: "danger"}));
        return;
      }
  },[company]);

  const valueChangeHandler = useCallback((evt) => {
    const inputName = evt.target.name;
    const inputValue = evt.target.value;

    setCompany(prevState => {
        return {
            ...prevState,
            [inputName]: inputValue
        }
    });
  },[]);

  const { name, chamberNumber, address, place, importID, telephone, email } = company;

  useEffect(() => {
    if(company.name&&company.chamberNumber&&company.address&&company.place&&company.importID&&company.telephone&&company.email) setFormIsValid(true);
  },[name, chamberNumber, address, place, importID, telephone, email]);
  
  return (
    <form onSubmit={submitHandler}>
      <Card title="Nieuw autobedrijf">
        <div className="bg-white grid grid-cols-3 items-start group">
          <div className="grid grid-cols-2 col-span-3 lg:col-span-2 gap-3 p-5">
            <InputKvk placeholder="KvK zoeken.." onSetKvk={setKvkDataHandler} ref={bedrijfsnaamRef} className="col-start-1 col-span-2" />

            <h3 className="col-span-2">Bedrijfsgegevens</h3>
            <Input name="name" value={company.name} onChange={valueChangeHandler} placeholder="Bedrijfsnaam*" />      
            <Input name="chamberNumber" value={company.chamberNumber} onChange={valueChangeHandler} placeholder="KvK nummer*" />      
            <Select name="dealerCategory" value={company.dealerCategory} onChange={valueChangeHandler} set="dealerCategory" errorMessage="Classificatie is een verplicht veld" label="Classificatie" />
            <Input name="postalCode" value={company.postalCode} onChange={valueChangeHandler} placeholder="Postcode"  />
            <Input name="address" value={company.address} onChange={valueChangeHandler} placeholder="Adres*" />      
            <Input name="place" value={company.place} onChange={valueChangeHandler} placeholder="Plaats*" />
            <Input name="iban" value={company.iban} onChange={valueChangeHandler} placeholder="IBAN" errorMessage="IBAN dient uit 18 karakters te bestaan"   />
            <Input name="vaTnumber" value={company.vaTnumber} onChange={valueChangeHandler} placeholder="BTW nummer"  />
            <Input name="importID" value={company.importID} onChange={valueChangeHandler} placeholder="Import ID*" />
            <Input name="website" value={company.website} onChange={valueChangeHandler} placeholder="Website"  />

            <h3 className="col-span-2">Hoofcontactpersoon</h3>
            <Input name="contactFirstName" value={company.contactFirstName} onChange={valueChangeHandler} placeholder="Voornaam"  />
            <Input name="contactLastName" value={company.contactLastName} onChange={valueChangeHandler} placeholder="Achternaam"  />
            <Input name="telephone" value={company.telephone} onChange={valueChangeHandler} placeholder="Telefoonnummer*" />
            <Input name="phone" value={company.phone} onChange={valueChangeHandler} placeholder="Mobiel nummer" />
            <Input name="email" value={company.email} onChange={valueChangeHandler} placeholder="E-mail*" />

            <h3 className="col-span-2">Aanvullende gegevens</h3>
            <Input name="rechtsvorm" value={company.rechtsvorm} onChange={valueChangeHandler} placeholder="Rechtsvorm" />
            <Input name="sbiCodes" value={company.sbiCodes} onChange={valueChangeHandler} placeholder="SBI-codes" />
            <Input name="latitude" value={company.latitude} onChange={valueChangeHandler} placeholder="Breedtegraad" />
            <Input name="longitude" value={company.longitude} onChange={valueChangeHandler} placeholder="Lengtegraad" />
            
            <h3 className="col-span-2">Vormgeving</h3>
            <div className="col-span-2">
              <DropArea ref={logoImageUrlFormRef} name="logoImageUrlForm" uploadText="Upload logo (.PNG)" />
            </div>
              <InputColor ref={primaryColorRef} placeholder="Primaire kleur" name="primaryColor" />
              <InputColor ref={buttonTextColorRef} placeholder="Button tekstkleur" name="buttonTextColor" />
          </div>
          <div className="flex flex-col col-span-3 lg:col-span-1 gap-5 p-5 bg-slate-50 h-full justify-between">
            <div>
              <div className="flex flex-col gap-y-5 mb-5">
                <div><Select name="status" value={company.status} onChange={valueChangeHandler} set="statusDealer" label="Dealer status" /></div>
              </div>
              <Button disabled={!formIsValid} className="bg-gradient">Registreren</Button>
            </div> 
          </div>
        </div>
      </Card>
    </form>
  );
};

export default NewDealerForm;
