import { createSlice } from "@reduxjs/toolkit";

var initialState = {
    currentCase: "FinancialLease",
    subCase: "", // licensePlate, licensePlateUnknown, inventory
    currentStep: 1,
    completedSteps: [],
    activeFunnel: false,
};

export const progressSlice = createSlice({
    name: 'progress',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        changeStep: (state, actions) => {
            state.currentStep = actions.payload;
            state.activeFunnel = !!(state.currentStep > 1)
        },
        changeCase: (state,actions) => {
            state.currentCase = actions.payload;
        },
        changeSubCase: (state,actions) => {
            state.subCase = actions.payload;
        }
    }
});

export const progressActions = progressSlice.actions;