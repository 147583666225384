import Container from "components/hoc/Container";
import DealFormDealer from "components/Forms/DealForm/DealFormDealer";

const DealDetail = () => {
    return (
        <Container>
            <DealFormDealer />
        </Container>
    )
}

export default DealDetail;