import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, NavLink } from "react-router-dom";

import useHuisstijl from "hooks/use-huisstijl";
import { getCarByLicensePlate } from "store/dossier-actions";
import styled from "styled-components";
import useAnalytics from "hooks/use-analytics";
import { progressActions } from "store/progress-slice";

const StyledButton = styled.button`
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
    color: ${props => props.$buttonTextColor ? props.$buttonTextColor : 'white'};
`;

const StyledActieBlok = styled.div`
    &:hover {
        color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
        border-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
    }
`;

const Actieblokken = ({currentCase="FinancialLease"}) => {
    const location = useLocation();

    const { sendPageView } = useAnalytics();

    const [enteredKenteken, setEnteredKenteken] = useState("");
    const kentekenRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataIsFetched = useSelector(state => state.dossier.dataIsFetched);
    const { primaryColor, buttonTextColor } = useHuisstijl();

    useEffect(() => {
        if(dataIsFetched) navigate("voertuiggegevens");
    }, [dataIsFetched, navigate]);

    const kentekenSubmitHandler = useCallback((evt) => {
        evt.preventDefault();
        var typeOfDeal = (/verzekering/i.test(location.pathname)) ? "verzekering" : "financiallease";
        sendPageView({category: typeOfDeal, action: "Kenteken ingevuld", label: "Kentekenplaat invoer"});
        dispatch(progressActions.changeCase(currentCase));
        dispatch(progressActions.changeSubCase("licensePlate"));
        dispatch(getCarByLicensePlate(enteredKenteken));
    },[enteredKenteken, location, dispatch]);

    const kentekenChangeHandler = () => {
        var kenteken = kentekenRef.current.value;
        kenteken = kenteken.replace(/-/g, "").replace(/(\+|\=)/g,"").toUpperCase();
        setEnteredKenteken(kenteken);
    }

    // all 1s ease
    const [transitionClasses, setTransitionClasses] = useState(" scale-75");

    useEffect(() => {
        setTransitionClasses("");
    }, []);

    const [intervalClass, setIntervalClass] = useState("");

    const actionHandler = (actionType, typeOfDeal='financiallease') => {
        if(actionType==="inventory") {
            sendPageView({category: typeOfDeal, action: "Klik", label: "Voorraad auto"});
            dispatch(progressActions.changeCase(currentCase));
            dispatch(progressActions.changeSubCase("inventory"));
            navigate('voertuiggegevens?type=voorraad');
        }
        if(actionType==="licensePlate_unknown") {
            sendPageView({category: typeOfDeal, action: "Klik", label: "Kenteken onbekend"});
            dispatch(progressActions.changeSubCase("licensePlateUnknown"));
            navigate('/financiering/financial-lease/voertuiggegevens');
        }
    }
    
    return (
        <div className="mx-auto max-w-5xl pb-5 px-5 xmd:p-10 text-center">
            <div className={"grid "+(currentCase === "Verzekering" ? "md:grid-cols-2" : "md:grid-cols-3")}>
                <>
                <StyledActieBlok onClick={() => actionHandler('inventory', currentCase)} $primaryColor={primaryColor} className={`relative actie-blok py-5 md:py-14 px-3 hover:z-30 rounded-tl-xl rounded-tr-xl md:rounded-r-none md:rounded-l-xl ${transitionClasses} ${intervalClass}`}>
                    <div className="flex flex-row-reverse md:flex-col items-center justify-between">
                        <div className="fa fa-car"></div>
                        <div className="p-1 text-xl">Voorraad auto</div>
                    </div>
                </StyledActieBlok>
                </>
   
                <>
               <StyledActieBlok onClick={() => kentekenRef.current.focus()} $primaryColor={primaryColor} className={(currentCase === "Verzekering" ? " rounded-tr-none rounded-b-xl md:rounded-tr-xl md:rounded-bl-none " : "")+` actie-blok py-5 md:py-14 px-3 hover:z-30 justify-center flex flex-col relative ${transitionClasses}`}>
                    <form onSubmit={kentekenSubmitHandler}>
                        <div className="bg-yellow rounded-lg p-1 text-2xl border-2">
                            <input value={enteredKenteken} onChange={kentekenChangeHandler} className="kenteken-plaat text-black" type="text" name="kenteken" placeholder="1-SLD-23" autoComplete="off" ref={kentekenRef} />
                        </div>
                        {enteredKenteken.length===6 &&
                        <div className="absolute right-1 top-[calc(50%_-_32px)] md:top-[calc(100%_-_46px)] md:left-[calc(50%_-_46px)]">
                            <StyledButton $primaryColor={primaryColor} $buttonTextColor={buttonTextColor} className={"rounded-full w-[64px] h-[64px] md:w-[92px] md:h-[92px] flex flex-col items-center justify-center font-black text-3xl hover:scale-105"}>Ga</StyledButton>
                        </div>
                        }
                    </form>
                </StyledActieBlok>
                </>              
                {currentCase !== "Verzekering" &&
                <>
                <StyledActieBlok onClick={() => actionHandler('licensePlate_unknown', currentCase)} $primaryColor={primaryColor} className={`relative actie-blok hover:z-30 border-2 py-5 md:py-14 px-3 rounded-br-xl rounded-bl-xl md:rounded-bl-none md:rounded-r-xl ${transitionClasses}`}>
                        <div className="flex flex-row-reverse md:flex-col items-center justify-between">
                            <div className="fa fa-circle-question"></div>
                            <div className="py-2 px-1 text-xl">Kenteken onbekend</div>
                        </div>
                </StyledActieBlok>
                </>
                }


            </div>

            <StyledActieBlok $primaryColor={primaryColor} className={`transition max-w-lg mx-auto duration-300 ease-in-out bg-white cursor-pointer ${transitionClasses}`}>
                <NavLink to="/verkopen">
                <div className="flex flex-row items-center justify-center mt-32 mb-20 border-2 rounded-xl py-8">
                    <span className="fa fa-handshake mr-5 text-3xl"></span>
                    <span className="text-xl">Ga naar mijn verkopen</span>
                </div>
                </NavLink>
            </StyledActieBlok>
        </div>
    )
}

export default Actieblokken;