import Button from "components/ui/Button/Button";
import { useSearchParams } from "react-router-dom";

const Pagination = ({pageNumber, totalRecords, pageSize}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const nextPageHandler = () => {
    searchParams.set("pageNumber", pageNumber+1);
    setSearchParams(searchParams);
  }

  const prevPageHandler = () => {
    searchParams.set("pageNumber", pageNumber-1);
    setSearchParams(searchParams);
  }

  return (
    <div className="flex flex-row justify-between mt-5">
        <div>
        {pageNumber!==1 &&
        <Button className="btn-secondary relative z-0" onClick={prevPageHandler}>Vorige</Button>
        }
        </div>
        <div>
        {totalRecords > (pageNumber*pageSize) &&
        <Button className="btn-secondary relative z-0" onClick={nextPageHandler}>Volgende</Button>
        }
        </div>
  </div>
  );
};

export default Pagination;
