import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import useAuth from "hooks/use-auth";
import { uiActions } from "store/ui-slice";

const useTermsAndConditions = (init, initQuery=null) => {
    const { token: jwt } = useAuth();
    const dispatch = useDispatch();

    const [termsAndConditions, setTermsAndConditions] = useState("");

    const createTermsAndConditions = useCallback(async (src, body) => {
        dispatch(uiActions.showBackdrop());        
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TermsAndConditions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify({src: src, ...body})
            });
            dispatch(uiActions.hideBackdrop());        
        } catch (err) {
            dispatch(uiActions.hideBackdrop());        
        }
    },[]);

    const stateUpdateTermsAndConditions = (html) => {
        setTermsAndConditions(prevState => ({...prevState, termAndConditions: html}));
    }

    const getTermsAndConditions = useCallback(async (src) => {
        if(!src) return;
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TermsAndConditions/GetBySrc?src="+src, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var respJson = await resp.json();
            setTermsAndConditions(respJson);
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            setTermsAndConditions("");
            dispatch(uiActions.hideBackdrop());
        }       
    },[]);

    
    const updateTermsAndConditions = useCallback(async (id, body) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TermsAndConditions/"+id, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(body)
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());    
        } catch (err) {
            dispatch(uiActions.hideBackdrop());    
        }    
    },[]);

    useEffect(() => {
        if(init==="getTermsAndConditions") getTermsAndConditions(initQuery);
    }, []);


    return {
        termsAndConditions,
        createTermsAndConditions,
        getTermsAndConditions,
        updateTermsAndConditions,
        stateUpdateTermsAndConditions
    }
}

export default useTermsAndConditions;