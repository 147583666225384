import licensePlateSVG from "./kenteken.svg";
import classes from "./LicensePlate2.module.css";

export const LicensePlate2 = ({value}) => {

    return (
        <input style={{backgroundImage: `url(${licensePlateSVG})`}} className={`${classes.LicenseInput}`} value={value ? value : ""} readOnly={true} type="text" name="kenteken" placeholder="1-SLD-23" autoComplete="off" />
    )
}

export default LicensePlate2;