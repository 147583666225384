import AdminDealList from "components/Tables/AdminDealList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const GetekendeOffertes = () => {
    const [transformedPhaseRecords, setTransformedPhaseRecords] = useState([]);
    const { phaseRecords } = useSelector(state => state.counter);

    useEffect(() => {
        setTransformedPhaseRecords(Object.values(phaseRecords));
    },[phaseRecords]);

    return (
        <details open>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Getekende offertes</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Klant is akkoord met de offerte").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="Klant is akkoord met de offerte" />
        </details>
    )
}

export default GetekendeOffertes;