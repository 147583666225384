import React, { useEffect, useRef, useState } from "react";
import useKvk from "hooks/kvk/use-kvk";
import { useSelector } from "react-redux";
import Input3 from "../Input/Input3";

const InputKvk3 = ({ publicApi, errorMessage, historyChamberNumber, register, errors, getValues, setValue, setFocus, inputValue, className, onError, onBlur, onComplete, fieldToValidate }) => {
  const containerRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);

  const [isFocussed, setIsFocussed] = useState(false);
  const { kvkSelected, errorMessage:errorMessage2, kvkData, getByFilter, clearKvkData, kvkResults, changeQuery, getByKvk, isLoading, hasError, clearErrorMessage } = useKvk(null, publicApi);

  var { chamberNumber, companyName, address, place, postalCode } = useSelector(state => state.deal.customerDetail);

  useEffect(() => {
    if(!chamberNumber) return;
    setValue("chamberNumber", chamberNumber, { shouldDirty: true, shouldTouch: true, shouldValidate: true});
    setValue("companyName", companyName, { shouldDirty: true, shouldTouch: true, shouldValidate: true});
    setValue("address", address, { shouldDirty: true, shouldTouch: true, shouldValidate: true});
    setValue("postalCode", postalCode, { shouldDirty: true, shouldTouch: true, shouldValidate: true});
    setValue("place", place, { shouldDirty: true, shouldTouch: true, shouldValidate: true});
  }, [chamberNumber]);

  var kvkSearchQuery = getValues("kvkSearchQuery");
  useEffect(() => {
    if(!kvkSearchQuery) return;
    const timer = setTimeout(function() {
        getByFilter(kvkSearchQuery);
    }, 450);
    return () => clearTimeout(timer);    
  }, [kvkSearchQuery, getByFilter]);

  useEffect(() => {
    if(historyChamberNumber) getByKvk(historyChamberNumber);
  },[]);

  const { kvkNummer } = kvkData;

  useEffect(() => {
    if(hasError) {
      onError(errorMessage || errorMessage2);
    } else if(kvkNummer) onComplete();
  }, [hasError, kvkNummer, errorMessage2]);

  useEffect(() => {
    if(!kvkSearchQuery) {
      clearErrorMessage();
      setActiveIndex(0);
    }
  },[kvkSearchQuery])

  useEffect(() => {
    var kvkWrapperInput = document.querySelector(".kvkWrapper");

    if(activeIndex===-1) {
      setActiveIndex(0);
      setFocus("kvkSearchQuery");
      return;
    }
    const handleKeyPress = (evt) => {
      
      var updatedIndex;
      if (evt.key === 'ArrowUp') {
        evt.preventDefault();
        setActiveIndex(prevState => {
          updatedIndex = prevState - 1;
          if(prevState===0) {
            setFocus("kvkSearchQuery");
            updatedIndex = 0;
          }
          return updatedIndex;
        })
      } else if (evt.key === 'ArrowDown') {
        evt.preventDefault();
        setActiveIndex(prevState => {
          if(prevState===containerRef.current?.numResults) {
            updatedIndex = prevState;
            return updatedIndex;
          } else {
            updatedIndex = prevState + 1;
            return updatedIndex;
          }
        });
      }
  };
  
  kvkWrapperInput.addEventListener('keydown', handleKeyPress);
  return () => {
    kvkWrapperInput.removeEventListener('keydown', handleKeyPress);
  };
    
  }, []);

  useEffect(() => {

    const handleEnter = (evt) => {
      if (evt.key === 'Enter') {
        if(!kvkResults?.length) return; 
        evt.preventDefault();
        var chamberNumber = kvkResults?.resultaten[containerRef?.current?.tabIndex].kvkNummer || false;
        if(chamberNumber) getByKvk(chamberNumber);
      }
    }
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [kvkResults]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const element = container.querySelector(`[tabIndex="${activeIndex}"]`);

      if (element) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        // Check if the element is not fully visible
        if (
          elementRect.top < containerRect.top ||
          elementRect.bottom > containerRect.bottom
        ) {
          // Scroll to make the element fully visible
          container.scrollTop = element.offsetTop;
        }
      }
    }
  }, [activeIndex]);

  const clearKvkHandler = () => {
    setIsFocussed(false);
    clearKvkData();
    setValue("kvkSearchQuery", "", { shouldDirty: false, shouldTouch: false, shouldValidate: true});
    setValue("chamberNumber", "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    setValue("companyName", "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    setValue("address", "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    setValue("postalCode", "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    setValue("place", "", { shouldDirty: false, shouldTouch: false, shouldValidate: false});
    setActiveIndex(0);
  }
  
  return (
        <div className={`kvkWrapper relative ${className ? className : ""}`}>

          {(!chamberNumber && !kvkSelected) &&          
          <Input3
            fieldToValidate={fieldToValidate}
            disableMB={true}
            name="kvkSearchQuery"
            inputValue={inputValue}
            errorMessage={errorMessage || errorMessage2}
            register={register}
            onBlur={onBlur}
            placeholder="Bedrijfsnaam of KvK-nummer"
            onFocus={() => setIsFocussed(true)}
            isLoading={isLoading}
            showSpinner={isLoading}
             />
          }

            {(!chamberNumber && kvkResults?.resultaten && isFocussed && !hasError && kvkSearchQuery) &&
            <div ref={containerRef} tabIndex={activeIndex} numResults={kvkResults?.aantal || 0} className={`${!kvkResults?.resultaten?.length ? 'border-bx' : ''} bg-slate-50 w-full border-x rounded-b-xl border-slate-300 absolute top-100 left-0 md:absolute max-h-96 overflow-y-scroll z-20 shadow ${(isFocussed && kvkResults.length) ? 'min-h-[100px]' : ''}`}>
            {kvkResults?.resultaten.map((company, index) => {
                return (
                  <div tabIndex={index} data-chamberNumber={company?.kvkNummer} key={index} onClick={() => getByKvk(company?.kvkNummer)} className={`${activeIndex===index ? 'bg-orange text-white' : ''} items-center gap-3 grid md:grid-cols-5 hover:bg-orange hover:text-white px-3 py-1 cursor-pointer border-b border-slate-100`}> 
                    <div className="col-span-3">{company?.handelsnaam}</div>
                    <div className="">{company?.adres?.binnenlandsAdres?.plaats || company?.plaats}</div>
                    <div className="">{company?.kvkNummer}</div>
                  </div>
                );
              }
              )}
              </div>
            }

        {((!isLoading && chamberNumber) || kvkSelected) && (
          <div className={`flex flex-row justify-between items-center select-none cursor-default rounded-xl gap-x-5 bg-slate-100 top-1 p-3 w-fit`}>
            <div className="flex flex-row items-center gap-x-3">
              <span className="fa !text-lg fa-circle-check text-[green]"></span>
              <div className="flex flex-col">
                <span className="font-semibold">{companyName ? companyName : kvkData?.companyName}</span> 
                <span className="text-sm">{address ? address : kvkData?.address}, {place ? place : kvkData?.place}</span>   
              </div>               
                    </div>
            <div onClick={clearKvkHandler} className="fa fa-circle-xmark hover:text-slate-400 text-xl cursor-pointer"></div>
          </div>
        )}
        </div>
  );
}

export default InputKvk3;