import React, { useEffect, useState } from "react";
import Input from "../Input/Input";

import useKvk from "hooks/use-kvk";

const InputKvk = React.forwardRef((props, ref) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const { kvkData, hasError, clearKvkData, kvkResults, changeQuery, getByKvk, isLoading, errorMessage } = useKvk(props.historyChamberNumber);
  
  useEffect(() => {
    if(hasError) props.onError();
    if(!kvkData?.kvkNummer) return;
    props.onSetKvk(kvkData);
  }, [kvkData, hasError]);

  const clearKvkDataHandler = () => {
    clearKvkData();
    props.onSetKvk({handelsnaam: "", name: "", rechtsvorm: "", latitude: "", longitude: "", visitingAddress: {postcode: "", adres: "", plaats: ""}, kvkNummer: ""});
  }

  return (
    <>
      <div className={props.className}>

        <div className="relative">       
          {!kvkData?.kvkNummer &&          
          <Input
            hasError={!!errorMessage}
            errorMessage={errorMessage}
            ref={ref}
            inputClasses={((isFocussed && ref.current?.value) ? 'rounded-bl-none rounded-br-none focus:outline-none': '')}
            name="bedrijfsnaam"
            placeholder={props.placeholder ? props.placeholder : "Bedrijfsnaam of KvK-nummer"}
            onKeyUp={evt => changeQuery(evt.target.value)}
            onFocus={() => setIsFocussed(true)}
            onChange={props.onChange}
            showSpinner={isLoading}
             />
          }
            {(!kvkData?.kvkNummer && kvkResults?.resultaten && isFocussed) &&
            <div className={`${!kvkResults?.resultaten?.length ? 'border-bx' : ''} bg-slate-50 w-full border-x rounded-b-xl border-slate-300 absolute top-100 left-0 md:absolute bg-white max-h-96 overflow-y-scroll z-20 shadow ${(isFocussed && kvkResults.length) ? 'min-h-[100px]' : ''}`}>
            {kvkResults?.resultaten.map((company, index) => {
                return (
                  <div key={index} onClick={() => getByKvk(company.kvkNummer)} className="items-center gap-3 grid md:grid-cols-5 hover:bg-orange hover:text-white px-3 py-1 cursor-pointer border-b border-slate-100"> 
                    <div className="col-span-3">{company.handelsnaam}</div>
                    <div className="">{company?.adres?.binnenlandsAdres?.plaats || company?.plaats}</div>
                    <div className="">{company.kvkNummer}</div>
                  </div>
                );
              }
              )}
              </div>
            }

        {(!isLoading && kvkData?.kvkNummer) && (
          <div className={(kvkData?.kvkNummer ? 'cursor-default items-center border rounded-xl border-slate-300' : 'border-slate-500 border-1')+" flex flex-row justify-between gap-x-5 bg-white top-1 p-3"}>
            <div className="">
              <span>{kvkData?.handelsnaam}</span>
              {/* {JSON.stringify(kvkData)} */}
              {/* <span className="ml-5">{kvkData.straatnaam}, {kvkData.plaats}</span>    */}
              <span className="ml-5">{kvkData?.visitingAddress?.adres}, {kvkData?.visitingAddress?.plaats}</span>
              {/* <span className="ml-5">{kvkData.handelsnaam} {bedrijf_huisnummer} {bedrijf_huisnummertoevoeging ? bedrijf_huisnummertoevoeging: ""} {bedrijf_straat && ','} {bedrijf_postcode} {bedrijf_plaats}</span>            */}
        
            </div>
            <div onClick={clearKvkDataHandler} className="fa fa-circle-xmark hover:text-slate-400 text-xl cursor-pointer"></div>
          </div>
        )}
        </div>
      </div>
    </>
  );
});

export default InputKvk;