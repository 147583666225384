import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { dateToDutch } from "lib/kvk";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import { calcAgeInMonths, dateToISO, localDate, toyyyymmdd } from "lib/calc";

import useCustomer from "hooks/customer/use-customer";
import useAuth from "hooks/use-auth";
import useKvk from "hooks/kvk/use-kvk";

import { dateObjToDateISO, findObjectDifference, getDateTimeFromDate, kvkDateToISODate, kvkDateToYYYYMMDD, monthsToYearsAndMonths } from "lib/helpers";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import useActivity from "hooks/activity/use-activity";

const CustomerForm = ( { customerDetail, title, inModal, onClose, onUpdate, customerID, dealID } ) => {
    const { createActivity } = useActivity();
    const [formChanged, setFormChanged] = useState(false);

    const [dateofEsteb, setDateOfEsteb] = useState(customerDetail?.dateofEsteb);

    const defaultValues = {
        age: customerDetail?.age || "",
        companyName: customerDetail?.companyName || "",
        chamberNumber: customerDetail?.chamberNumber || "",
        address: customerDetail?.address || "",
        place: customerDetail?.place || "",
        postalCode: customerDetail?.postalCode || "",
        dateofEsteb: (customerDetail?.dateofEsteb ? toyyyymmdd(customerDetail.dateofEsteb) : ""),
        salutation: customerDetail?.salutation || "",
        firstName: customerDetail?.firstName || "",
        lastName: customerDetail?.lastName || "",
        phone: customerDetail?.phone || "",
        email: customerDetail?.email || "",
        dateofBirth: (customerDetail?.dateofBirth ? toyyyymmdd(customerDetail.dateofBirth) : ""),
        driverLicenseNumber: customerDetail?.driverLicenseNumber || "",
        iban: customerDetail?.iban || "",
        rechtsvorm: customerDetail?.rechtsvorm || "",
        sbiCodes: customerDetail?.sbiCodes || "",
        latitude: customerDetail?.latitude || "",
        longitude: customerDetail?.longitude || "",
    };

    const { register, formState: { errors}, handleSubmit, watch, setValue } = useForm({
        mode: "onChange",
        defaultValues: defaultValues
    });

    const { kvkData, getByKvk } = useKvk();
    const { role } = useAuth();

    const { patchCustomer } = useCustomer();   
    const watchAll = watch();

    useEffect(() => {
        if(!kvkData) return;
        const { rechtsvorm, sbiStr, longitude, latitude, materieleRegistratie, naam } = kvkData;
        const { adres, plaats, postcode } = kvkData.visitingAddress;

        var age = calcAgeInMonths(kvkDateToISODate(materieleRegistratie));
        if(age) setValue("age", ""+age);
        setDateOfEsteb(kvkDateToISODate(materieleRegistratie));

        setValue("companyName", naam);
        setValue("place", plaats);
        setValue("address", adres);
        setValue("postalCode", postcode, { shouldValidate: true });
        setValue("sbiCodes", sbiStr);
        setValue("rechtsvorm", rechtsvorm);
        setValue("latitude", ""+latitude);
        setValue("longitude", ""+longitude);
        setValue("dateofEsteb", materieleRegistratie ? kvkDateToYYYYMMDD(materieleRegistratie) : "");
        setFormChanged(true);
    }, [kvkData]);

    const submitFormHandler = async (formData) => {
      var dateofBirth = (formData.dateofBirth!=="1-01-01") ? formData.dateofBirth : new Date().toLocaleDateString("nl-NL");
      var dateofEsteb = (formData.dateofEsteb!=="1-01-01") ? formData.dateofEsteb : new Date().toLocaleDateString("nl-NL");

      // remove spaces, and uppercase like 2324VT
      formData.postalCode = formData.postalCode?.replace(/\s/g, "").toUpperCase();
    
      // transform iban to uppercase
      formData.iban = formData?.iban?.toUpperCase();

      var differences = findObjectDifference(defaultValues, formData);

      var dateofBirthChanged = defaultValues.dateofBirth!==formData.dateofBirth || false;
      var dateofEstebChanged = defaultValues.dateofEsteb!==formData.dateofEsteb || false;

      var dataToChange = {
          ...differences,
          ...(dateofBirthChanged && {dateofBirth: dateofBirth}), 
          ...(dateofEstebChanged && {dateofEsteb: dateofEsteb})
      }

      var resp = await patchCustomer(customerID, dataToChange, dealID, "Klantgegevens");
      if(resp==="SUCCESS") {
        var activityResp = await createActivity("Deal", "update", {dealID: dealID}, dataToChange, "Klantgegevens");
        setFormChanged(false);
        if(activityResp?.message==="SUCCESS") {
            onUpdate();
        }
      }
    };

    const changeHandler = (fieldName, fieldValue) => {
        if(fieldName==="dateofEsteb") {
            var age = calcAgeInMonths(dateToDutch(fieldValue));
            setDateOfEsteb(dateToDutch(fieldValue));
            if(age) setValue("age", ""+age);
        }
    }

    const cardTitle = title || (customerDetail.firstName+" "+customerDetail.lastName+" - "+customerDetail.companyName);

    return (
        <form onSubmit={handleSubmit(submitFormHandler)} onChange={() => setFormChanged(true)} className={`text-xs lg:text-base ${inModal ? 'mt-0' : 'mt-5'}`}>
            <Card title={cardTitle} showClose={inModal} onClose={onClose}>
                <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
                    <div className="grid col-span-2 gap-3 p-5">
                        {role.includes("Admin") &&
                        <Accordion open={true} summary="Bedrijfsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                            <Input3 name="companyName" register={register} label="Bedrijfsnaam" placeholder="Bedrijfsnaam" inputValue={watchAll.companyName} />
                            <Input3 name="chamberNumber" fieldToValidate="chamberNumber" register={register} label="KvK nummer" placeholder="KvK nummer" inputValue={watchAll.chamberNumber} errorMessage={errors?.chamberNumber?.message} />
                            <Input3 name="address" register={register} label="Adres" placeholder="Adres" inputValue={watchAll.address} />
                            <Input3 name="place" register={register} label="Plaats" placeholder="Plaats" inputValue={watchAll.place} />
                            <Input3 name="postalCode" fieldToValidate="postalCode" register={register} label="Postcode" placeholder="Postcode" inputValue={watchAll.postalCode} errorMessage={errors?.postalCode?.message} />
                            <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-x-5">
                                <div className="col-span-2">
                                {kvkData?.materieleRegistratie
                                ?
                                <>
                                <Input3 onBlur={changeHandler} type="date" readOnly={true} name="dateofEsteb" register={register} label="Datum oprichting" placeholder="Datum oprichting" inputValue={watchAll.dateofEsteb} />
                                </>
                                :
                                <>
                                <Input3 onBlur={changeHandler} type="date" name="dateofEsteb" register={register} label="Datum oprichting" placeholder="Datum oprichting" inputValue={watchAll.dateofEsteb} />
                                </>
                                }
                                </div>
                                {dateofEsteb  &&
                                <div className="flex flex-col text-sm">
                                    <div>{monthsToYearsAndMonths(calcAgeInMonths(dateofEsteb)).years} jaar</div>
                                    <div>{monthsToYearsAndMonths(calcAgeInMonths(dateofEsteb)).months} maanden</div>
                                </div>
                                }
                            </div>
                            </div>
                        </Accordion>}
                        <Accordion open={true} summary="Persoonsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <Select3 name="salutation" set="aanhef" register={register} label="Aanhef" placeholder="Aanhef" inputValue={watchAll.salutation} />
                                <Input3 name="firstName" register={register} label="Voornaam" placeholder="Voornaam" inputValue={watchAll.firstName} />
                                <Input3 name="lastName" register={register} label="Achternaam" placeholder="Achternaam" inputValue={watchAll.lastName} />
                                <Input3 name="phone" register={register} label="Telefoon" placeholder="Telefoon" inputValue={watchAll.phone} />
                                <Input3 name="email" fieldToValidate="email" register={register} label="E-mailadres" placeholder="E-mailadres" inputValue={watchAll.email} errorMessage={errors?.email?.message} />
                                <Input3 type="date" register={register} name="dateofBirth" label="Geboortedatum" placeholder="Geboortedatum" inputValue={watchAll.dateofBirth} />
                                <Input3 name="driverLicenseNumber" fieldToValidate="driverLicenseNumber" required={false} register={register} label="Rijbewijsnummer" placeholder="Rijbewijsnummer" inputValue={watchAll.driverLicenseNumber} errorMessage={errors?.driverLicenseNumber?.message} />
                                <Input3 upperCase={true} name="iban" fieldToValidate="iban" required={false} register={register} label="IBAN" placeholder="IBAN" inputValue={watchAll.iban} errorMessage={errors?.iban?.message} />
                            </div>
                        </Accordion>
                        <Accordion open={true} summary="Aanvullende gegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <Input3 name="rechtsvorm" register={register} label="Rechtsvorm" placeholder="Rechtsvorm" inputValue={watchAll.rechtsvorm} />
                                <div></div>
                                <div className="col-span-2 mt-3">
                                        <div className="grid grid-cols-4 p-1">
                                            <div>SBI code</div><div className="col-span-2">Omschrijving</div><div>Hoofdactiviteit</div>
                                        </div>
                                        <div>{watchAll.sbiCodes?.length>0 && JSON.parse(watchAll.sbiCodes).map((code, index) => {
                                            return (
                                                <div key={"key"+index} className="grid grid-cols-4  p-1">
                                               <div>{code.sbiCode}</div>
                                               <div className="col-span-2">{code.sbiOmschrijving}</div>
                                               <div>{code.indHoofdactiviteit}</div>
                                               </div>
                                            )
                                        }
                                        )}</div>
                                </div>
                                <div>{watchAll.latitude}</div>
                                <div>{watchAll.longitude}</div>
                            </div>
                        </Accordion>
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
                        <div>Datum aangemaakt {dateToDutch(customerDetail?.createdDate)}</div>
                        <div>Laatst bijgewerkt {getDateTimeFromDate(customerDetail?.updatedDate)}</div>
                        <div className="flex flex-col gap-y-3">
                            <Button className="bg-gradient" disabled={!formChanged}>Opslaan</Button>
                            {watchAll.chamberNumber && <Button onClick={() => getByKvk(watchAll.chamberNumber)} type="button" className="btn-secondary">KvK data invullen</Button>}
                        </div>
                    </div>
                </div>
            </Card>
        </form>
    )
}

export default CustomerForm;