import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import useAuth from "hooks/use-auth";
import { dateToDutch } from "lib/kvk";
import Pagination from "components/Navigation/Pagination";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";
import useDealerV2 from "hooks/dealer/use-dealer_v2";
import { calculateElapsedTime, getDateTimeFromDate } from "lib/helpers";

const DealerOverzicht = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { role } = useAuth();
  const navigate = useNavigate();
  const { getDealers, dealers } = useDealerV2();

  const { data, totalRecords, pageNumber, pageSize } = dealers;

  useEffect(() => {
    getDealers(searchParams, "searchParams");
  }, [searchParams]);
 
  return (
    <div className="text-xs lg:text-base max-w-8xl">
        {role.includes("Admin") && (
        <>
        <Zoekmachine searchFor="dealers" />
        <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
          </>
        )}

      {data?.length ? (
        <div>
            <div className="hidden xl:grid bg-sld-blue text-white rounded-xl p-3 mb-3 items-center grid-cols-7 gap-x-2">
              <div className="col-span-2">Bedrijfsnaam</div>
              <div className="col-span-3 hidden xl:block">Email / Laatst ingelogd</div>
              <div>Telefoon</div>
              <div>Status</div>
          </div>

            {data.map(dealer => {
                return (
                  <div onClick={() => navigate(""+dealer.dealerID)} key={dealer?.dealerID} id={dealer?.dealerID} className={`grid grid-cols-2 md:grid-cols-7 gap-x-2 gap-y-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer`+(dealer?.isArchive ? ' opacity-50' : '')}>
                   
                    <div className="col-span-2 md:col-span-7 border-b border-solid border-slate-200">
                    <div className="flex flex-row justify-between text-sm opacity-75 border-b-slate-50">
                      <div className="flex flex-row sm:flex-row gap-x-2">
                        <div><span>Dealer ID: </span><span>{dealer.dealerID}</span></div>
                        <span className="opacity-25">|</span>
                        <div><span>{dealer?.sourceOfDealer ? dealer?.sourceOfDealer : "SLD"}</span></div>
                      </div>
                      <div className="flex flex-col items-center sm:flex-row gap-x-2">
                        <span>{getDateTimeFromDate(dealer?.createdDate)}</span>
                      </div>
                    </div>
                  </div>
                  

                  <div className="flex flex-row items-center gap-x-3 col-span-2">
                    <div className={`flex flex-row justify-center rounded-full w-8 h-8 p-0 m-0 items-center ${dealer.isActive ? 'bg-[#008002]': 'bg-[#FF0000]'}`}>
                      <span className="text-white text-xs">{dealer.dealerID}</span>
                    </div>
                    <div className="flex flex-col py-3">
                      <div>{dealer.name}</div>
                      <div>KvK: {dealer.chamberNumber}</div>
                    </div>
                  </div>
                  <div className="hidden xl:flex flex-col col-span-3">
                  {[dealer?.dealerUserDetail, ...dealer?.subUserDetails?.filter(item => item?.lastLogin)]?.map((user, index) => {
                      return (
                        <div key={user?.email+index} className="flex flex-col"><span>{user?.email}</span>
                        {user?.lastLogin &&
                        <div className="grid grid-cols-2">
                          <span>{getDateTimeFromDate(user?.lastLogin)}</span>
                          <span>({calculateElapsedTime(new Date(user?.lastLogin.slice(0, 19)).getTime())})</span>
                        </div>
                        }
                        </div>
                      )
                    })}
                  </div>
                  <div className="">{dealer.phone}</div>
                  <div className="">{dealer.status!=="undefined" && dealer.status}</div>



                </div>
              )
            })}
        </div>
      ) : (
        <p>Er zijn nog geen dealers</p>
      )}  
      <Pagination page={pageNumber} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
    </div>
  );
};

export default DealerOverzicht;
