import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { 
    BtnBold,
    BtnItalic,
    BtnNumberedList,
    HtmlButton,
    Editor,
    EditorProvider,
    Toolbar
  } from 'react-simple-wysiwyg';

import Button from "components/ui/Button/Button";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";
import useTermsAndConditions from "hooks/termsandconditions/use-termsandconditions";
import useUser from "hooks/user/use-user";
import Select3 from 'components/ui/Select/Select3';

const TermsAndConditions = () => {
    const [activeTab, setActiveTab] = useState("sld");
    const tabClasses = "select-none cursor-pointer inline-block p-4 border-b-2 rounded-t-lg";
    const activeTabClasses = "font-semibold border-orange text-orange cursor-default";

    const { setTermAndConditions } = useUser();
    const { termsAndConditions, getTermsAndConditions, stateUpdateTermsAndConditions, createTermsAndConditions, updateTermsAndConditions } = useTermsAndConditions();

    const { register, watch, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            src: activeTab,
            agree: false
        }
    });

    const { agree, src, booleanValue } = watch();

    useEffect(() => {
        getTermsAndConditions(activeTab);
    }, [getTermsAndConditions, activeTab]);


    const submitHandler = (data) => {
        setTermAndConditions(data);
        reset();
    }

    const submitHandler2 = (evt) => {
        evt.preventDefault();
        if(!termsAndConditions?.id) {
            createTermsAndConditions(activeTab, termsAndConditions);
        } else {
            updateTermsAndConditions(termsAndConditions?.id, termsAndConditions);
        }
    }

     return (
        <>
        <h1>Algemene voorwaarden</h1>
        <div className="mb-4 border-b border-gray-200">
            <div className=" flex flex-wrap -mb-px text-center">
                <span onClick={() => setActiveTab("sld")} className={`${tabClasses} ${activeTab==="sld" && activeTabClasses}`}>SLD</span>
                <span onClick={() => setActiveTab("actua")} className={`${tabClasses} ${activeTab==="actua" && activeTabClasses}`}>Actua</span>
            </div>
        </div>


        <div className="grid grid-cols-3 gap-12">
            <div className="col-span-2">
                <form onSubmit={submitHandler2} className="flex flex-col gap-3 mt-5">
                    <EditorProvider className="overflow-x-scroll">
                    <Editor value={termsAndConditions?.termAndConditions} onChange={e => stateUpdateTermsAndConditions(e.target.value)} className="max-w-[219px]">
        <Toolbar>
          <BtnBold />
          <BtnItalic /> 
          <BtnNumberedList />
          <HtmlButton />
        </Toolbar>
      </Editor>
    </EditorProvider>

                    <Button type="submit" className="bg-gradient">Opslaan</Button>
                </form>               
            </div>
            <div>
                <h3 className="">Zichtbaarheid algemene voorwaarden</h3>
                <p>Pas de zichtbaarheid voor alle gebruikers aan.</p>
                <p>Dit kan zinvol zijn, wanneer de voorwaarden zijn aangepast.</p>
                <form onSubmit={handleSubmit(submitHandler)} className="flex flex-col gap-3 mt-5">
                    <Select3 name="src" register={register} fieldToValidate="Source is een verplicht veld" placeholder="Welke gebruikers?" label="Welke gebruikers?" inputValue={src} errorMessage={errors?.src?.message}>
                        <option value="SLD">SLD</option>
                        <option value="Actua">Actua</option>
                    </Select3>
                    <Select3 name="booleanValue" register={register} fieldToValidate="Dit is een verplicht veld" placeholder="Wat wil je doen?" label="Wat wil je doen?" inputValue={booleanValue} errorMessage={errors?.booleanValue?.message}>
                        <option value="true">Voorwaarden tonen</option>
                        <option value="false">Voorwaarden verbergen</option>
                    </Select3>
                    {booleanValue==="true" && <small className="text-[red]">Let op! Bij het aanpassen van de zichtbaarheid krijgen gebruikers die eerder de voorwaarden hebben bevestigd deze toch opnieuw te zien!</small>}
                    <InputCheckbox3 name="agree" register={register} fieldToValidate="Dit is een verplicht vinkje" inputValue={agree} label="Bevestigen" errorMessage={errors?.agree?.message} />
                    <Button>Aanpassing doorvoeren</Button>
                </form>
                {src==="Actua" && <small className="block mt-10">Voor Actua worden alleen bestaande gebruikers aangepast. Dat zijn gebruikers die eerder een deal hebben ingeschoten en dus bij ons geregistreerd zijn.</small>}

            </div>
        </div>



        </>
    )
}

export default TermsAndConditions;