import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getFriendlyDocName } from "components/Forms/ActivityForm/options";
import useAuth from "hooks/use-auth";

import { uiActions } from "store/ui-slice";
import { aesDecrypt, getDateTimeFromDate } from "lib/helpers";

export const useActivity = () => {
    const dispatch = useDispatch();

    const [activity, setActivity] = useState({});

    const [activities, setActivities] = useState([]);

    const { token : jwt, role, userDetails, userInfo } = useAuth();
    
    const sendDealerUpdate = useCallback(async (formObj, dealID) => {
        dispatch(uiActions.showBackdrop());
        try {
            var formData = new FormData();
            formData.append("dealID", dealID);
            formData.append("createAt", new Date());
            formData.append("name", formObj?.name);
            formData.append("description", formObj?.description);
            formData.append("offer", "0");
            formData.append("documentURLForm", "");
            formData.append("type", "Dealer update");
            formData.append("typeOfActivity", "adminActivity");
    
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActivitiesDeal", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer "+jwt,
                },
                body: formData
            });
            var respJson = await resp.json();
            if(respJson?.message==="Activity created") {
                dispatch(uiActions.hideBackdrop());
                dispatch(uiActions.setAlert({ message: "Dealer update succesvol verzonden", type: "success" }));
                return "SUCCESS";
            }
        } catch (error) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Fout bij het versturen van de dealer update!. Probeer opnieuw!", type: "danger" }));
            return "FAIL";
        }
    }, []);

    const createActivity = useCallback(async(table, actionType, data, changedData, category, stringify=true) => {       
        var formData = new FormData();
        var typeOfActivity = "", bodyType = "", createdBy = "", saleType = "", endpoint = ""; // typeOfActivity: systemActivity|userActivity|adminActivity
        
        if(/(Activity|Bank|Deal|Document)/.test(table)) {
            endpoint = "/api/ActivitiesDeal";
            var dealID = data?.dealID;
            if(!dealID) {
                console.log("dealID missing");
                return;
            }
            formData.append("dealID", dealID);
        }

        if(table==="Activity") bodyType = "Activiteit ";

        // table
        if(/(Deal|Bank)$/.test(table)) {
            bodyType = "Deal ";
            formData.append("name", ""+data?.phase);
        }

        if(table==="Dealer update") {
            bodyType = "Dealer update ";
            formData.append("name", ""+data?.subject);
        }

        if(table==="Dealer") {
            bodyType = "Autobedrijf ";
            endpoint = "/api/ActivitiesDealer";
            var dealerID = data?.data_object?.dealerID;
            if(!dealerID) {
                console.log("dealerID missing");
                return;
            }
            formData.append("dealerID", dealerID);
        }

        if(table==="Document") bodyType = "Document ";

        if(table==="Partner") {
            bodyType = "Partner ";
            console.log("Er zijn nog geen Partner activities mogelijk");
            return;
        }

        // category, for example Verkoopgegevens | Klantgegevens | Voertuiggegevens | Financieringsgegevens | Bankgegevens
        if(category) {
            if(/(Dutch Finance|Hiltermann Lease|Alphabet|Findio)/.test(category)) {
                bodyType=bodyType.replace(/^Deal\s/);
                bodyType = category + " ";
            } else {
                bodyType+="("+category+") ";
            }            
        }

        // action
        if(actionType==="create") bodyType+="aangemaakt ";
        if(actionType==="update") bodyType+="gewijzigd ";
        if(actionType==="delete") bodyType+="verwijderd ";
        if(actionType==="assign") bodyType+="toegewezen ";
        if(actionType==="archive") bodyType+="gearchiveerd ";
        if(actionType==="dearchive") bodyType+="gedearchiveerd ";
        if(actionType==="submit") bodyType+="ingediend ";
        if(actionType==="upload") bodyType+="geupload ";
        if(actionType==="sent") bodyType+="verzonden ";
        if(actionType==="move") bodyType+="verplaatst ";
        if(actionType==="reassess") bodyType+="herbeoordeling aangevraagd ";
        if(actionType==="use") bodyType+="voorstel gebruiken in offerte ";

        if(!endpoint || !actionType) {
            console.log("Geen endpoint of actionType");
            return;
        }

        if(table==="Bank") {
            var bank = changedData?.bank;
            if(bank) {
                bodyType+="bij "+bank+" ";
            }
        }

        // specify info
        if(table==="Activity") {
            var extraData = changedData?.type || "";
            if(extraData) {
                bodyType+="("+extraData+") ";
            }
        }

        if(table==="Document") {
            var extraData = changedData.documentName ? getFriendlyDocName(changedData.documentName) : changedData?.type || "";
            if(extraData) {
                bodyType+="("+extraData+") ";
            }
        }

        if(table==="Deal" && actionType==="create") {
            saleType = data?.saleType || "";
            if(saleType) {
                saleType = saleType.replace("FinancialLease", "Financial Lease").replace("BtwFinanciering", "BTW financiering");
                bodyType+="("+saleType+") ";
            }
        }

        if(table==="Dealer") {
            var extraInfo = data?.data_object?.name || "";
            if(extraInfo) {
                bodyType+="("+extraInfo+") ";
            }
        }
        
        if(table==="Partner") {
            var extraInfo = data?.data_object?.name || "";
            if(extraInfo) {
                bodyType+="("+extraInfo+") ";
            }
        }

        if(actionType==="assign") {
            var assignedUser = data?.firstName || data?.userName;
            if(assignedUser) bodyType+="aan "+assignedUser+" ";
        }
       
        // createdBy and typeOfActivity
        // auth->userDetails->firstName/lastName (sld)
        // auth->userInfo->email || auth->userInfo->name (bedrijf)


        if(role && role.includes("Admin")) {
            typeOfActivity = "adminActivity";
            if(userDetails?.firstName) createdBy = userDetails?.firstName || "";
            if(userDetails?.email) createdBy = userDetails?.email || "";
            if(createdBy) {
                bodyType+= "door "+createdBy;
            }
        } else if(userInfo?.name) {
            typeOfActivity = "userActivity";
            bodyType+="door "+userInfo?.name;
        } else {
            typeOfActivity = "systemActivity";
        }
        if(table==="Activity") typeOfActivity = "manualActivity"; // overwrite to empty string

        var description = "";

        if(stringify) {
            try {
                description = JSON.stringify(changedData);
            } catch (err) {};
        } else {
            description = changedData;
        }

        if(/Dealer update/.test(bodyType)) {
            // remove 'verzonden door Erik'
            bodyType="Dealer update"; 
        }

        formData.append("type", bodyType);
        formData.append("description", description);
        formData.append("createAt", new Date());
        formData.append("typeOfActivity", typeOfActivity); 
        formData.append("offer", "2"); // ?
        formData.append("documentUrlForm", null);

        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+endpoint, {
            method: "POST",
            headers: {
                "Authorization": "Bearer "+jwt,
            },
            body: formData
        });
        var respJson = await resp.json();
        if(respJson?.message==="Activity created") {
            setActivity(respJson?.data_object);
            return {
                message: "SUCCESS",
                data_object: respJson?.data_object
            }
        }
    },[role, userDetails, userInfo]);

    const getActivities = useCallback(async (query) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActivitiesDeal"+(query ? "?"+query : ""), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ jwt
            }
        });
        var respText = await resp.text();
        var responseIv = resp.headers.get("key");
        var respData = aesDecrypt(respText, responseIv);
        setActivities(respData);
        return respData;
    },[]);

    const getActivitiesByDealId = useCallback(async (dealID, query) => {
        if(!dealID) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActivitiesDeal/Deals/"+dealID+(query ? "?"+query : ""), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ jwt
            }
        });
        var respText = await resp.text();
        var responseIv = resp.headers.get("key");
        var respData = aesDecrypt(respText, responseIv);

        // var respJson = await resp.json();
        var sortedData = respData.reverse();
        var transformedData = sortedData.map(activity => {
            var createdDateTime = getDateTimeFromDate(activity.createdDate);
            return {
                ...activity,
                createdDateTime
            }
        });
        setActivities({data: transformedData});
        return transformedData;
    },[]);

    const deleteActivity = useCallback(async (activityID) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ActivitiesDeal/"+activityID, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ jwt
            }
        });
        var respJson = await resp.json();
        // setActivities(respJson);
        return "SUCCESS";
        // return respJson;
    },[]);

    const sendOffer = useCallback(async (data, dealDetail) => {
        dispatch(uiActions.showBackdrop());
        var file = new File([data], "attachment.pdf", { type: "application/pdf"});
        var formData = new FormData();
        formData.append("name", "" + dealDetail.phase);
        formData.append("description", "Verzonden via klik op button");
        formData.append("dealID", dealDetail.dealID);
        formData.append("documentUrlForm", file);
        formData.append("offer", "2");
        formData.append("type", "Offerte verzonden");
        formData.append("createAt", new Date());

        var typeOfActivity;
        if(role && role.includes("Admin")) {
            typeOfActivity = "adminActivity";
        } else if(role && role.includes("User") || role.includes("SubUser")) {
            typeOfActivity = "userActivity";
        } else {
            typeOfActivity = "systemActivity";
        }
        formData.append("typeOfActivity", typeOfActivity);
        
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal', {
                headers: {
                  "Authorization": "Bearer " + jwt
                },
                body: formData,
                method: "POST",
              });
              var respJson = await resp.json();
              if(respJson?.message==="Activity created") {
                  dispatch(uiActions.hideBackdrop());
                  dispatch(uiActions.setAlert({ message: "De offerte is succesvol verzonden", type: "success" }));
                  return "SUCCESS";
              }
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Fout bij het versturen van de offerte. Probeer opnieuw!", type: "danger" }));
            return "FAIL";
        }
    }, []);

    return {
        activity,
        activities,
        createActivity,
        getActivities,
        getActivitiesByDealId,
        deleteActivity,
        sendOffer,
        sendDealerUpdate
    }
}

export default useActivity;