import { sortDataByKey } from "lib/voertuig";

export const documentNames = () => {
    var options;
    var documentName = [
        { label: "Verkoopfactuur", value: "verkoopfactuur" },
        { label: "Voorkant rijbewijs", value: "rijbewijsvoorkant" },
        { label: "Achterkant rijbewijs", value: "rijbewijsachterkant" },
        { label: "Voorkant value/paspoort", value: "paspoort_id_voorkant" },
        { label: "Achterkant value/paspoort", value: "paspoort_id_achterkant" },
        { label: "(zakelijke) bankpas", value: "bankpaszakelijk" },
        { label: "Bankafschrift", value: "bankafschrift" },
        { label: "Getekend contract", value: "getekendcontract" },
        { label: "Tenaamstellingsverslag", value: "tenaamstellingsverslag" },
        { label: "UBO formulier", value: "uboformulier" },
        { label: "Verkoopovereenkomst", value: "verkoopovereenkomst"},
        { label: "Verblijfsvergunning", value: "verblijfsvergunning"},
        { label: "Offerte concurrent", value: "offerteconcurrent"},
        { label: "Mail", value: "mail"}
    ];
    var docYear = ["Jaarrekening", "Aangifte inkomstenbelasting", "Concept jaarrekening", "Concept aangifte inkomstenbelasting"];
    for(let year=2020;year<=(new Date().getFullYear());year++) {
        docYear.forEach(function(e) {
            var label = e+" "+year;
            var value = label.replace(/\s/g,"_").toLowerCase();
            documentName.push({label: e+" "+year, value: value});
        });
    }
    options = sortDataByKey(documentName, "label");
    return options;
} 

export const getFriendlyDocName = (optionValue) => {
    var docNames = documentNames();
    var docName = docNames.filter(item => item.value===optionValue).map(item => item.label);
     if(!docName.length) return optionValue;
    return docName;
}