import DealForm from "components/Forms/DealForm/DealForm";

const VerkoopDetail = () => {
  return (
    <>
      {/* <NavLink to=".."><Button type="button" className="btn-secondary">Terug naar verkopen</Button></NavLink> */}
      <DealForm />     
    </>
  );
};

export default VerkoopDetail;
