const Card = (props) => {
    return (
        <div className={`drop-shadow rounded-3xl ${props.className ? props.className : ""}`}>
            <div className={`${props.hideTitle ? "hidden": "flex"} flex-row justify-between bg-[#212853] text-white p-5 rounded-t-3xl items-center`}>
                <span>{props.title}</span>
                {props.titleEnd && <span>ID: {props.titleEnd}</span>}
                {props.showClose && <span onClick={props.onClose} className="cursor-pointer xp-5 fa fa-xmark text-xl"></span>}
            </div>
            {props.children}
        </div>
    )
}

export default Card;