import { useState } from "react";
import useAuth from "hooks/use-auth";
import { aesDecrypt } from "lib/helpers";

const useCustomerV2 = () => {
    const { token: jwt } = useAuth();

    const [customer, setCustomer] = useState({});
    const [customers, setCustomers] = useState([]);

    const getCustomers = async (query) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Customer/Filters"+(query ? "?"+query : ""), {
            headers: {
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        setCustomers(decryptedData);
        return decryptedData;
    }

    return {
        customer: customer,
        customers: customers,
        getCustomers
    }
}

export default useCustomerV2;