import useActivity from "hooks/activity/use-activity";
import useAuth from "hooks/use-auth";
import { aesDecrypt, aesEncrypt } from "lib/helpers";
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uiActions } from "store/ui-slice";

export const usePartner = () => {
    const { createActivity } = useActivity();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token: jwt } = useAuth();

    const [partner, setPartner] = useState({
        "name": "",
        "address": "",
        "place": "",
        "postalCode": 0,
        "website": "",
        "kvkNumber": 0,
        "ibanNumber": "",
        "phoneNumber1": "",
        "phoneNumber2": "",
        "phoneNumber3": "",
        "eMailAddress1": "",
        "eMailAddress2": "",
        "eMailAddress3": ""
    });

    const [partners, setPartners] = useState([]);

    const partnerChangeHandler = (evt) => {
        var fieldName = evt.target.name;
        var fieldValue = evt.target.value;
      
        setPartner(prevState => {
          return {
            ...prevState,
            [fieldName]: fieldValue
          }
        });
      }

    const createPartner = useCallback(async (partnerData) => {
      dispatch(uiActions.showBackdrop());
      try {
        // transform partnerData
        if(!/http/.test(partnerData.website)) partnerData.website = "https://"+partnerData.website;
        if(partnerData?.postalCode) partnerData.postalCode = partnerData.postalCode.replace(/\s/g, "").toUpperCase();

        var { bodyEncrypted, iv } = aesEncrypt(partnerData);
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner', {
          method: "POST",
          headers: {
            "Key": iv,
            'Authorization': 'Bearer ' + jwt
          },
          body: bodyEncrypted
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        if(decryptedData?.message==="Partner created") {
          dispatch(uiActions.hideBackdrop());
          dispatch(uiActions.setAlert({message: "Partner succesvol aangemaakt", type: "success"}));
          createActivity("Partner", "create", decryptedData);
          navigate("..");
        }
      } catch (err) {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet aangemaakt worden", type: "danger"}));
      }
    },[]);

    const getPartners = useCallback(async (query) => {
      try {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner'+(query ? '?'+query : ''), {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + jwt
          }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        setPartners(decryptedData);
        return decryptedData;
      } catch (err) {
        dispatch(uiActions.setAlert({message: "Kan lijst met partners niet ophalen", type: "danger"}));
      }
    },[]);

    const getPartnerById = useCallback(async (id) => {
      dispatch(uiActions.showBackdrop());
      try {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/Partner/"+id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        setPartner(decryptedData);
        dispatch(uiActions.hideBackdrop());
        return decryptedData;
      } catch (err) {
        dispatch(uiActions.setAlert({message: "Fout bij het ophalen van Partner", type: "danger"}));
        dispatch(uiActions.hideBackdrop());
      }
    },[]);

    const setPartnersState = useCallback(async (data) => {
        setPartners(data);
    }, []);

    const updatePartner = useCallback(async (partnerData) => {
      dispatch(uiActions.showBackdrop());
      try {
        const data = { ...partnerData };
        data.postalCode = data.postalCode.replace(/\s/g, "").toUpperCase();
        var { bodyEncrypted, iv } = aesEncrypt(data);
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+partnerData.partnerID, {
          method: "PUT",
          headers: {
            "Key": iv,
            'Authorization': 'Bearer ' + jwt
          },
          body: bodyEncrypted
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        if(decryptedData?.message==="Partner updated") {
          dispatch(uiActions.hideBackdrop());
          dispatch(uiActions.setAlert({message: "Partner succesvol gewijzigd", type: "success"}));
          createActivity("Partner", "update", decryptedData);
          setPartner(decryptedData?.data_object);
        }
      } catch (err) {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet worden gewijzigd", type: "danger"}));
      }
    },[]);

    const archivePartner = useCallback(async (partnerID, isArchive=true) => {
      var isArchiveText = (isArchive ? "gearchiveerd": "gedearchiveerd");
      dispatch(uiActions.showBackdrop());
      var data = { isArchive: isArchive };
      try {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+partnerID, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
          },
          body: JSON.stringify(data)
        });
        var respJson = await resp.json();
        if(respJson?.message==="Partner updated") {
          dispatch(uiActions.hideBackdrop());
          dispatch(uiActions.setAlert({message: `Partner succesvol ${isArchiveText}`, type: "success"}));

          var activityArchive = "archive";
          if(!isArchive) activityArchive = "dearchive";
          createActivity("Partner", activityArchive, respJson);
          setPartner(respJson?.data_object);
          navigate("../");
        }
      } catch (err) {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: `Partner kan niet worden ${isArchiveText}`, type: "danger"}));
      }
    },[]);

    const deArchivePartner = (partnerID) => {
      archivePartner(partnerID, false);
    }

    const deletePartner = useCallback(async (id)  => {
      dispatch(uiActions.showBackdrop());
      try {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+id, {
          method: "DELETE",
          headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
          }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        if(decryptedData?.message==="Partner deleted") {
          dispatch(uiActions.hideBackdrop());
          createActivity("Partner", "delete", decryptedData);
          dispatch(uiActions.setAlert({message: "Partner succesvol verwijderd", type: "success"}));
        }
        navigate("../");
      } catch (err) {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet verwijderd worden", type: "danger"}));
      }
    },[]);

    return {
        partner,
        partners,
        partnerChangeHandler,
        createPartner,
        updatePartner,
        deletePartner,
        archivePartner,
        deArchivePartner,
        getPartnerById,
        getPartners,
        setPartnersState
    }
}