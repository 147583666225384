import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { calcAgeInMonths } from "lib/calc";
import { fetchWithTimeout, kvkDateToISODate } from "lib/helpers";
import { dealActions } from "store/deal-slice";
import useAuth from "hooks/use-auth";

const useKvk = (historyChamberNumber, publicApi=false) => {
    const { token: jwt } = useAuth();
    const dispatch = useDispatch();

    const [kvkSelected, setKvkSelected] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState("");
    const [results, setResults] = useState([]);
    
    const fetchOptions = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+jwt
        }
    };

    useEffect(() => {
        if(!searchQuery) return;
        const timer = setTimeout(function() {
            getByFilter(searchQuery);
        }, 450);
        return () => clearTimeout(timer);       
      },[searchQuery]);

    useEffect(() => {
        if(!historyChamberNumber) return;
        getByKvk(historyChamberNumber);
    }, []);


    const clearErrorMessage = () => {
        setErrorMessage("");
        setResults([]);
    }

    const getByPublicKvk = async (query) => {
        var resp = await fetch("https://zoeken.kvk.nl/Address.ashx?site=handelsregister&q="+query);
        var { resultatenHR: respJson  } = await resp.json();
        var data = respJson.map(item => {
            return {
                ...item,
                kvkNummer: item.dossiernummer,
            }
        });
        var transformedData = {
            resultaten: data
        };
        setIsLoading(false);
        setResults(transformedData);
    }

    const getByKvk = async (chamberNumber, clearResults=true) => {
        if(!chamberNumber) return;
        if(publicApi) {
            var resultData = results?.resultaten?.filter(item => item.dossiernummer===chamberNumber).map(item => {
                return {
                    companyName: item?.handelsnaam,
                    place: item?.plaats,
                    address: item?.straat + item?.huisnummer,
                    chamberNumber: item?.dossiernummer,
                    postalCode: item?.postcode,
                    rechtsvorm: item?.rechtsvormbeschrijving
                }
            })[0];

            setData(resultData);
            dispatch(dealActions.extendCustomerWithKvK(resultData));
            if(clearResults) setResults([]);
            setIsLoading(false);
            setKvkSelected(true);
            return;
        }
        setIsLoading(true);
        try {
            var kvkData = fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/KVK/ZoekenGetByKVKNumber?KvkNummer='+chamberNumber, fetchOptions).then(resp => {
                if(resp?.status!==200) {
                    setErrorMessage("Fout bij het ophalen van de data");
                    throw new Error("KvK koppeling momenteel niet beschikbaar");
                }                
                return resp.json();
            });

            var profileData = fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber?KvkNummer=`+chamberNumber+`&GeoData=true`, fetchOptions).then(resp => {
                if(resp?.status!==200) {
                    setErrorMessage("Fout bij het ophalen van de data");
                    throw new Error("KvK koppeling momenteel niet beschikbaar");
                }                
                return resp.json();
            });
    
            const allData = Promise.all([kvkData, profileData]);

            allData.then(([kvk, profile]) => {

                if(!kvk?.pagina || !profile?.kvkNummer) {
                    setErrorMessage("Fout bij het ophalen van de data");
                    throw new Error("KvK koppeling momenteel niet beschikbaar");
                }

                const combinedData = {
                    ...kvk.resultaten[0],
                    ...profile
                }

                var materieleRegistratie = combinedData.materieleRegistratie?.datumAanvang || "";
                var formeleRegistratiedatum = combinedData.formeleRegistratiedatum || "";

                try {
                    var bezoekadres = combinedData?._embedded?.hoofdvestiging?.adressen?.filter(adres => adres.type==="bezoekadres")[0] || combinedData?._embedded?.eigenaar?.adressen?.filter(adres => adres.type==="bezoekadres")[0];
                    var { straatnaam, plaats, postcode, huisnummer, gpsLatitude: latitude, gpsLongitude: longitude, toevoegingAdres } = bezoekadres;
                    var { gpsLatitude: latitude, gpsLongitude: longitude } = bezoekadres.geoData;
                    var sbiStr = JSON.stringify(combinedData.sbiActiviteiten);
                } catch (err) {
                    var bezoekadres = "", straatnaam = "", plaats = "", postcode = "", huisnummer = "", latitude = "", longitude = "", toevoegingAdres = "";
                    var sbiStr = "";
                };
                
                try { var { rechtsvorm } = combinedData._embedded.eigenaar; } catch (err ) { var rechtsvorm = ""};
                
                var adres = straatnaam+" "+huisnummer;
                if(toevoegingAdres) adres+=" "+toevoegingAdres;        

                var dateofEsteb = kvkDateToISODate(materieleRegistratie) || "";
                var age = calcAgeInMonths(dateofEsteb) || "";

                const completeData = {
                    ...combinedData,
                    handelsnaam: combinedData?.naam,
                    rechtsvorm: rechtsvorm,
                    materieleRegistratie,
                    formeleRegistratiedatum,
                    latitude,
                    longitude,
                    visitingAddress: {
                        adres,
                        plaats,
                        straatnaam,
                        postcode,
                        huisnummer
                    },
                    sbiStr,
                    dateofEsteb: dateofEsteb,
                    age: age
                }
                setHasError(false);

                dispatch(dealActions.extendCustomerWithKvK({
                    companyName: completeData?.handelsnaam,
                    chamberNumber: completeData?.kvkNummer,
                    address: completeData?.visitingAddress?.adres,
                    place: completeData?.visitingAddress?.plaats,
                    postalCode: completeData?.visitingAddress?.postcode,
                    latitude: completeData?.latitude,
                    longitude: completeData?.longitude,
                    age: completeData?.age,
                    dateofEsteb: completeData?.dateofEsteb,
                    rechtsvorm: completeData?.rechtsvorm,
                    sbiCodes: completeData?.sbiStr
                }));

                setData(completeData);
                if(clearResults) setResults([]);
                setIsLoading(false);
            }).catch(err => {
                setHasError(true);
            });

        } catch (err) {
            setErrorMessage("Fout bij het ophalen van de data");
            setHasError(true);
            setIsLoading(false);
        }

        setIsLoading(false);
    }

    const getByFilter = useCallback(async (query) => {
        if(!query) return;
        if(publicApi) {
            setIsLoading(true);
            getByPublicKvk(query);
            return;
        }
        var searchField = "Naam";
        // 8 characters digits -> chamberNumber
        if(query.length===8 && /\d{8}/.test(query)) searchField = "KvkNummer";
        setIsLoading(true);
        fetchWithTimeout(process.env.REACT_APP_SLD_API_BASE_URL + "/api/KVK/ZoekenGetByFilter?"+searchField+"="+query, fetchOptions, 10000) // 10 seconds timeout
        .then(resp => {
            if(resp?.status!==200 && resp?.status!==404) throw new Error("KvK koppeling momenteel niet beschikbaar");
            return resp.json();
        })
        .then((data) => {
          // Handle the data from the API
          if(data.status===404) {
            throw new Error("Bedrijf is niet bekend bij de KvK. Probeer een andere zoekterm, of probeer het 8-cijferig KvK-nummer");
          } else {
            setErrorMessage("");
          }

         if(data?.status===400) {
            throw new Error("KvK koppeling momenteel niet beschikbaar");
         }
    
          setHasError(false);

          // add key handelsnaam, with value of item.naam
          var transformedResults = data?.resultaten?.map(item => ({...item, handelsnaam: item?.naam}));
          var finalResults = {
            ...data,
            resultaten: transformedResults
          }
          setResults(finalResults);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle the error, including timeout error
            setErrorMessage(error?.message);
            setIsLoading(false);
            setHasError(true);
        });
        
        // try {
        //     var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/KVK/ZoekenGetByFilter?"+searchField+"="+query, fetchOptions);
        //     if(resp.status!==200) throw new Error();
        //     var data = await resp.json();
        //     setHasError(false);
        //     setResults(data);
        //     setIsLoading(false);
        // } catch (err) {
        //     setIsLoading(false);
        //     setHasError(true);
        // }
    },[]);

    const getBasisProfile = async (chamberNumber, geoData=false) => {
        if(!chamberNumber) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber?KvkNummer=`+chamberNumber+`&GeoData=`+geoData, fetchOptions);
    }
    
    const clearKvkDataHandler = () => {
        dispatch(dealActions.clearCustomerKvK());
        setKvkSelected(false);
        setSearchQuery("");
    }

    return {
        isLoading,
        kvkData: data,
        kvkResults: results,
        kvkSelected,
        getByKvk,
        changeQuery: (query) => setSearchQuery(query),
        getByFilter,
        getBasisProfile,
        clearKvkData: clearKvkDataHandler,
        hasError,
        errorMessage,
        clearErrorMessage
    }
}

export default useKvk;