import AdminDealList from "components/Tables/AdminDealList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Inbox = () => {
    const [transformedPhaseRecords, setTransformedPhaseRecords] = useState([]);
    const { phaseRecords } = useSelector(state => state.counter);

    useEffect(() => {
        setTransformedPhaseRecords(Object.values(phaseRecords));
    },[phaseRecords]);

    return (
        <div>
            <details open>
                <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Nieuwe aanvragen</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Uw aanvraag is ontvangen").map(item => item.totalRecords)})</span></div></summary>
                <AdminDealList phase="Uw aanvraag is ontvangen" />
            </details>
            <details>
                <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Ingediende aanvragen</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Uw aanvraag wordt beoordeeld").map(item => item.totalRecords)})</span></div></summary>
                <AdminDealList phase="Uw aanvraag wordt beoordeeld" />
            </details>
            <details>
                <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Aanvullende informatie aangevraagd</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Klant dient extra informatie aan te leveren").map(item => item.totalRecords)})</span></div></summary>
                <AdminDealList phase="Klant dient extra informatie aan te leveren" />
            </details>
            <details>
                <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Gereed voor verzending</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Controleer en verzend offerte").map(item => item.totalRecords)})</span></div></summary>
                <AdminDealList phase="Controleer en verzend offerte" />
            </details>
            
        </div>
    )
}

export default Inbox;