import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "../use-auth";
import { uiActions } from "store/ui-slice";
import { aesDecrypt, getDateTimeFromDate } from "lib/helpers";
import useActivity from "hooks/activity/use-activity";

const useDocument = (init, initQuery=null, showBackdrop=true) => {
    const { createActivity } = useActivity();

    const dispatch = useDispatch();
    const { token: jwt, role } = useAuth();

    const [document, setDocument] = useState({});
    const [documents, setDocuments] = useState([]);

    const getDocuments = useCallback(async (dealID,dealerName) => {
        if(showBackdrop) dispatch(uiActions.showBackdrop());
        try {       
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/DocumentDeal/Deals?dealId="+dealID, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                } 
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            var transformedData = decryptedData.map((document) => {
                var createdByCompany;
                var createdDateTime = getDateTimeFromDate(document.createdDate);
                var updatedDateTime = getDateTimeFromDate(document.updatedDate);
                if(!role.includes("Admin") && document.createdBy) {
                    createdByCompany = (/@(scherpeleasedeal|sld)\.nl/.test(document.createdBy)) ? "SLD" : dealerName;
                }
                return {
                    ...document,
                    createdDateTime,
                    updatedDateTime,
                    createdByCompany
                };
            });
            setDocuments(transformedData);
            if(showBackdrop) dispatch(uiActions.hideBackdrop());
        } catch (err) {
            if(showBackdrop) dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Fout bij het ophalen van documenten", type: "danger"}));
        }

    },[dispatch, jwt]);
    
    const uploadDocument = async (id, documentName, documentFile, onUpdate) => {
        dispatch(uiActions.showBackdrop());
        const formData = new FormData();
        if(!documentFile) return;
        formData.append("dealID", id);
        formData.append("documentName", documentName);
        formData.append("description", "Beschrijving");
        formData.append("documentURLForm", documentFile);
        formData.append("type", "");
  
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/DocumentDeal", {
          method: "POST",
          body: formData,
          headers: {
              "Authorization": "Bearer "+jwt
          }
        });

        if(resp?.status===200) {
            onUpdate();
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } else {
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    } 

    const removeDocument = async (id, onUpdate, dealID, documentName) => {
        dispatch(uiActions.showBackdrop());
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/DocumentDeal/"+id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var respJson = await resp.json();
        if(respJson?.status==="Success") {
            createActivity("Document", "delete", {documentID: id, dealID: dealID, documentName: documentName}, {documentID: id, dealID: dealID, documentName: documentName?.id});
            onUpdate();
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } else {
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    }

    const getDocumentById = (documentID) => {
        var document = documents.filter(document => document.documentID===documentID)[0];
        setDocument(document);
    }   

    const clearDocumentId = () => {
        setDocument({});
        dispatch(uiActions.hideBackdrop());
    }
    
    useEffect(() => {
        if(init==="getDocuments") getDocuments(initQuery, showBackdrop);
    },[getDocuments]);

    return {
        document,
        documents,
        getDocuments,
        uploadDocument,
        removeDocument,
        getDocumentById,
        clearDocumentId
    }
}

export default useDocument;