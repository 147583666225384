import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { dealActions } from "store/deal-slice";
import { progressActions } from "store/progress-slice";
// import { dealActions } from "store";
// import { progressActions } from "store";



const useProgress = () => {
    const location = useLocation();
    const currentStep = useSelector(state => state.progress.currentStep);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(Storage) {
            // on refresh page, set the correct step
            var fixedStep;
            var thankyoupage = false;

            // FinancialLease
            if(location.pathname==="/financiering/financial-lease/voertuiggegevens" && currentStep!==2) fixedStep = 2;
            if(location.pathname==="/financiering/financial-lease/btw-marge" && currentStep!==3) fixedStep = 3;
            if(location.pathname==="/financiering/financial-lease/calculator" && currentStep!==4) fixedStep = 4;
            if(location.pathname==="/financiering/financial-lease/klantgegevens" && currentStep!==5) fixedStep = 5;
            if(location.pathname==="/financiering/financial-lease/bedankpagina" && currentStep!==6) {
                fixedStep = 6;
                thankyoupage = true;
            }

            // Verzekering
            if(location.pathname==="/verzekering/voertuiggegevens" && currentStep!==2) fixedStep = 2;
            if(location.pathname==="/verzekering/btw-marge" && currentStep!==3) fixedStep = 3;
            if(location.pathname==="/verzekering/klantgegevens" && currentStep!==5) fixedStep = 5;
            if(location.pathname==="/verzekering/bedankpagina" && currentStep!==6) {
                fixedStep = 6;
                thankyoupage = true;
            }

            if(fixedStep && !thankyoupage) dispatch(progressActions.changeStep(fixedStep));
        }
    }, []);
    
    const changeStepHandler = (navigateTo, clear=false, dataToPass=null) => {
        var step;
        if(navigateTo==="/") step = 1;
        if(/voertuiggegevens/.test(navigateTo)) step = 2;
        if(/\.\/btw-marge/.test(navigateTo)) step = 3;
        if(/\.\/calculator/.test(navigateTo)) step = 4;
        if(/\.\/klantgegevens/.test(navigateTo)) step = 5;
        if(/\.\/bedankpagina/.test(navigateTo)) step = 6;

        if(clear) {
            dispatch(dealActions.clearDeal());
            sessionStorage.removeItem("vehicleDetail");
            sessionStorage.removeItem("calcDetail");
            sessionStorage.removeItem("customerDetail");
        }
        dispatch(progressActions.changeStep(step));
        navigate(navigateTo, dataToPass);
    }

    return {
        currentStep,
        changeStepHandler
    }
}

export default useProgress;