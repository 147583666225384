import { useSelector } from "react-redux";
import useProgress from "hooks/progress/use-progress";
import useSession from "hooks/session/use-session";
import classes from "./ProgressBar.module.css";

export const ProgressBar = ({thankyoupage}) => {
    const { currentStep } = useProgress();
    const { currentCase } = useSelector(state => state.progress);
    useSession();

    const activeClass = `
    text-white
    bg-[#212853]
    before:left-0
    before:top-0
    before:absolute
    before:border-[24px]
    before:border-y-transparent
    before:border-r-transparent
    after:absolute
    after:border-[#212853]
    after:border-2
    after:left-full
    after:top-0
    after:border-[24px]
    after:border-y-transparent
    after:border-r-transparent
    `; 

    const upcomingClass = `
    before:-z-10
    before:border-[#9c9c9c]
    before:top-[0px]
    before:absolute
    before:border-[24px]
    before:right-[2px]
    before:border-y-transparent
    before:border-left-[#9c9c9c]
    before:border-r-0

    after:border-white
    after:absolute
    after:border-[24px]
    after:right-[3px]
    after:border-y-transparent
    after:border-left-[#9c9c9c]
    after:border-r-0
    after:-z-10
    after:top-[0px]
    `;


    const doneClass = `
    text-green
    !border-green
    before:z-10
    before:border-green
    before:top-[0px]
    before:absolute
    before:border-[24px]
    before:left-[3px]
    before:border-y-transparent
    before:border-r-0

    after:border-white
    after:absolute
    after:border-[24px]
    after:left-[2px]
    after:border-y-transparent
    after:border-r-0
    after:z-20
    after:top-[0px]

    `;

    return (
        <div className="container max-w-screen-xl mx-auto my-10">
            <div className="flex flex-row items-center justify-center xl:justify-start overflow-hidden text-browser font-medium">
                <div className={`${classes.ProgressStep} border-y ${currentStep===2 && activeClass} ${(currentStep>2 || thankyoupage) && doneClass + 'after:!content-none'} rounded-l-full before:!content-none`}>
                    <div className="flex flex-row gap-x-3 items-center">
                        <span className="fa fa-car-default"></span>
                        <span className="hidden xl:inline">Voertuiginformatie</span>
                    </div>
                </div>
                <div className={`${classes.ProgressStep} text-[#9c9c9c] border-y ${currentStep===3 ? activeClass : upcomingClass} ${(currentStep>3 || thankyoupage) && doneClass}`}>
                    <div className="flex flex-row gap-x-3">
                        <span className="fa fa-percent"></span>
                        <span className="hidden xl:inline-block">Btw/marge</span>
                    </div>
                </div>
                
                {currentCase==="FinancialLease" &&
                <div className={`${classes.ProgressStep} text-[#9c9c9c] border-y ${currentStep===4 ? activeClass : upcomingClass} ${(currentStep>4 || thankyoupage) && doneClass}`}>
                    <div className="flex flex-row gap-x-3">
                        <span className="fa fa-calculator"></span>
                        <span className="hidden xl:inline-block">Berekening</span>
                    </div>
                </div>
                }

                <div className={`${classes.ProgressStep} text-[#9c9c9c] border-y ${currentStep===5 ? activeClass : upcomingClass} ${(currentStep>5 || thankyoupage) && doneClass}`}>
                    <div className="flex flex-row gap-x-3">
                        <span className="fa fa-address-card"></span>
                        <span className="hidden xl:inline-block">Klantgegevens</span>
                    </div>
                </div>
                <div className={`${classes.ProgressStep} text-[#9c9c9c] border-y border-r rounded-r-full ${(currentStep===6 || thankyoupage) && activeClass} after:border-none`}>
                    <div className="flex flex-row gap-x-3">
                        <span className="fa fa-envelope-circle-check"></span>
                        <span className="hidden xl:inline-block">Bevestiging</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;