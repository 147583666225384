import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { dealerActions } from "store/dealer-slice";
import useDealer from "hooks/use-dealer";
import SmartSearch from "../SmartSearch/SmartSearch";
import useDealerV2 from "hooks/dealer/use-dealer_v2";

const SelectDealer = (props) => {
    const dispatch = useDispatch();
    const { getAllDealers, allDealers, dealerOptions } = useDealerV2();

    const dealerRef = useRef();
    const { dealerID } = useDealer();

    useEffect(() => {
        getAllDealers();
    }, [getAllDealers]);

    const changeDealerHandler = (obj) => {
       if(!obj) return;
       var selectedDealerID = obj.value || "";
       const selectedDealerData = allDealers.filter(dealer => dealer.dealerID===+selectedDealerID)[0];
       dispatch(dealerActions.setDealerData(selectedDealerData));
    }

    useEffect(() => {
        changeDealerHandler();
    },[]);

    return (
        <>
        <SmartSearch ref={dealerRef} value={dealerID} options={dealerOptions} label="Kies een bedrijf" placeholder="Kies een bedrijf" onChange={id => changeDealerHandler(id)} disabled={props.disabled} />
        </>
    )
};

export default SelectDealer;