import { useState } from "react";
import { useForm } from "react-hook-form";
import Card from "components/ui/Card/Card";
import useAuth from "hooks/use-auth";
import Button from "components/ui/Button/Button";
import { dateToDateFormat, localDate, toyyyymmdd } from "lib/calc";
import useDealV2 from "hooks/deal/use-deal_v2";
import Input3 from "components/ui/Input/Input3";
import TextArea3 from "components/ui/TextArea/TextArea3";
import useActivity from "hooks/activity/use-activity";
import { findObjectDifference } from "lib/helpers";

const AdditionalTermsForm = ({ title, inModal, dealDetail, onUpdate, onClose }) => {
  const [formChanged, setFormChanged] = useState(false);

  const { role } = useAuth();
  const { createActivity } = useActivity();
  const { patchDeal } = useDealV2();

  var descriptionMinusDate = "";
  var selectedDate = "";

  if(dealDetail?.description) {
    // add localDate from datepicker to description
    // get localDate to datepicker from description
    if(/^\d{2}\-\d{2}\-\d{4}/.test(dealDetail?.description)) selectedDate = dealDetail?.description?.replace(/(^\d{2}\-\d{2}\-\d{4}).*/,"$1");
    descriptionMinusDate = dealDetail?.description?.replace(/^\d{2}\-\d{2}\-\d+\s/, "");
    selectedDate = toyyyymmdd(dateToDateFormat(selectedDate));
  }

  const defaultValues = {
    description: descriptionMinusDate || "",
    date: selectedDate || ""
  };

  const { handleSubmit, register, formState: { errors }, watch } = useForm({
    mode: "onChange",
    defaultValues: defaultValues 
  });

  const submitFormHandler = async (formData) => {
    var differences = findObjectDifference(defaultValues, formData);

    var selectedDate = "";
    selectedDate = localDate(formData?.date);
    if(/NaN/.test(selectedDate)) selectedDate = "";


    var dataToChange = {
       description: selectedDate ? selectedDate+" "+formData.description : formData.description
    }

    var resp = await patchDeal(dealDetail.dealID, dataToChange);
    if(resp==="SUCCESS") {
      var activityResp = await createActivity("Deal", "update", dealDetail, dataToChange, "Aanvullende voorwaarden");
      setFormChanged(false);
      if(activityResp?.message==="SUCCESS") {
        onUpdate();
      }
    }
  };

  const watchAll = watch();

  if (!title) return <>Laden...</>;
  if(!role || !role.includes("Admin")) return <>Onvoldoende rechten</>;

  return (
    <form onSubmit={handleSubmit(submitFormHandler)} onChange={() => setFormChanged(true)} className={`text-xs lg:text-base ${inModal ? "mt-0" : "mt-5"}`}>
      <Card title={title} showClose={inModal} onClose={onClose}>
        <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
          <div className="grid col-span-2 gap-3 p-5">
            <div className="flex flex-col gap-3">
                <Input3 name="date" type="date" register={register} label="Datum" placeholder="Datum" inputValue={watchAll.date} />
                <TextArea3 rows="10" cols="40" register={register} name="description" label="Beschrijving" placeholder="Beschrijving" inputValue={watchAll.description} /> 
            </div>
          </div>
          <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
            <Button className="bg-gradient" disabled={!formChanged}>Opslaan</Button>
          </div>
        </div>
      </Card>
    </form>
  );
};
export default AdditionalTermsForm;