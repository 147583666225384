import { useState, useEffect } from "react";
import useAuth from "./use-auth";
import { fetchWithTimeout, kvkDateToISODate, setError } from "lib/helpers";
import { calcAgeInMonths } from "lib/calc";

const useKvk = (historyChamberNumber) => {
    const { token: jwt } = useAuth();

    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState("");
    const [results, setResults] = useState([]);
    
    const fetchOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt
        }
    };

    useEffect(() => {
        if(!searchQuery) return;
        const timer = setTimeout(function() {
            getByFilter(searchQuery);
        }, 450);
        return () => clearTimeout(timer);       
      },[searchQuery]);

    useEffect(() => {
        if(!historyChamberNumber) return;
        getByKvk(historyChamberNumber);
    }, []);


    const getByKvk = async (chamberNumber) => {
        if(!chamberNumber) return;
        setIsLoading(true);
        try {
            var kvkData = fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/KVK/ZoekenGetByKVKNumber?KvkNummer='+chamberNumber, fetchOptions).then(resp => resp.json());
            var profileData = fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber?KvkNummer=`+chamberNumber+`&GeoData=true`, fetchOptions).then(resp => resp.json());
            
            const allData = Promise.all([kvkData, profileData]);
            allData.then(([kvk, profile]) => {
                const combinedData = {
                    ...kvk.resultaten[0],
                    ...profile
                }

                var materieleRegistratie = combinedData.materieleRegistratie?.datumAanvang || "";
                var formeleRegistratiedatum = combinedData.formeleRegistratiedatum || "";

                try {
                    var bezoekadres = combinedData._embedded.hoofdvestiging.adressen.filter(adres => adres.type==="bezoekadres")[0];
                    var { straatnaam, plaats, postcode, huisnummer, gpsLatitude: latitude, gpsLongitude: longitude, toevoegingAdres } = bezoekadres;
                    var { gpsLatitude: latitude, gpsLongitude: longitude } = bezoekadres.geoData;
                    var sbiStr = JSON.stringify(combinedData.sbiActiviteiten);
                } catch (err) {
                    var bezoekadres = "", straatnaam = "", plaats = "", postcode = "", huisnummer = "", latitude = "", longitude = "", toevoegingAdres = "";
                    var sbiStr = "";
                };
                
                try { var { rechtsvorm } = combinedData._embedded.eigenaar; } catch (err ) { var rechtsvorm = ""};
                
                var adres = straatnaam+" "+huisnummer;
                if(toevoegingAdres) adres+=" "+toevoegingAdres;        

                var dateofEsteb = kvkDateToISODate(materieleRegistratie) || "";
                var age = calcAgeInMonths(dateofEsteb) || "";

                const completeData = {
                    ...combinedData,
                    handelsnaam: combinedData?.naam,
                    rechtsvorm: rechtsvorm,
                    materieleRegistratie,
                    formeleRegistratiedatum,
                    latitude,
                    longitude,
                    visitingAddress: {
                        adres,
                        plaats,
                        straatnaam,
                        postcode,
                        huisnummer
                    },
                    sbiStr,
                    dateofEsteb: dateofEsteb,
                    age: age
                }
                setData(completeData);
                setResults([]);
                setHasError(false);
                setIsLoading(false);
            }).catch(err => {
                setHasError(true);
            });

        } catch (err) {
            setHasError(true);
            setIsLoading(false);
            console.log("err", err);
        }
        setIsLoading(false);
    }

    const getByFilter = async (query) => {
        if(!query) return;
        var searchField = "Naam";
        // 8 characters digits -> chamberNumber
        if(query.length===8 && /\d{8}/.test(query)) searchField = "KvkNummer";
        setIsLoading(true);
        fetchWithTimeout(process.env.REACT_APP_SLD_API_BASE_URL + "/api/KVK/ZoekenGetByFilter?"+searchField+"="+query, fetchOptions, 10000) // 10 seconds timeout
        .then(resp => resp.json())
        .then((data) => {
          // Handle the data from the API
          if(data.status===404) {
            setErrorMessage("Bedrijf is niet bekend bij de KvK. Probeer een andere zoekterm, of probeer het 8-cijferig KvK-nummer");
          } else {
            setErrorMessage("");
          }
          setHasError(false);

            // add key handelsnaam, with value of item.naam
          var transformedResults = data?.resultaten?.map(item => ({...item, handelsnaam: item?.naam}));
          var finalResults = {
              ...data,
              resultaten: transformedResults
          }
            
          setResults(finalResults);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle the error, including timeout error
            setIsLoading(false);
            setHasError(true);
        });


        // try {
        //     var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/KVK/ZoekenGetByFilter?"+searchField+"="+query, fetchOptions);
        //     var data = await resp.json();
        //     setResults(data);
        //     setIsLoading(false);
        //     setHasError(false);
        // } catch (err) {
        //     setIsLoading(false);
        //     setHasError(true);
        // }
    }

    const getBasisProfile = async (chamberNumber, geoData=false) => {
        if(!chamberNumber) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber?KvkNummer=`+chamberNumber+`&GeoData=`+geoData, fetchOptions);
    }

    const getHeadQuarters = async (chamberNumber, geoData=false) => {
        if(!chamberNumber) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber/HeadQuarters?KvkNummer=`+chamberNumber+`&GeoData=`+geoData, fetchOptions);
    }

    const getLocations = async (chamberNumber) => {
        if(!chamberNumber) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + `/api/KVK/BasisProfileGetBykvkNumber/Locations?KvkNummer=`+chamberNumber, fetchOptions);
    }           

    const clearKvkDataHandler = () => {
        setData({chamberNumber: "", visitingAddress: {}});
        setSearchQuery("");
        setErrorMessage("");
    }

    return {
        isLoading,
        kvkData: data,
        kvkResults: results,
        getByKvk,
        changeQuery: (query) => setSearchQuery(query),
        getByFilter,
        getBasisProfile,
        clearKvkData: clearKvkDataHandler,
        hasError: hasError,
        errorMessage: errorMessage
    }
}

export default useKvk;