import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import useAuth from "hooks/use-auth";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import { serialize } from "lib/helpers";

const useTodo = () => {
    const dispatch = useDispatch();
    const { token: jwt, userDetails } = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const [playSoundCounter, setPlaySoundCounter] = useState(0);
    const [updateCounter, setUpdateCounter] = useState(0);

    let audio = useMemo(() => new Audio("/check.mp3"));

    const [todos, setTodos] = useState([]);

    useEffect(() => {
        if(!playSoundCounter) return;
        var audioTimer = setTimeout(function() {
            audio.play();
        }, 350);
        return () => {
            clearTimeout(audioTimer);
        }
    }, [playSoundCounter]);

    const [todo, setTodo] = useState({});


    const getTodos = async (userId=null, queryParams) => {
        if(queryParams) setSearchParams(serialize(queryParams));
        // var endpoint = "/api/TodoInfo/Filters?pageSize=9999&"+((queryParams) ? ""+serialize(queryParams) : ""+searchParams);
        var endpoint = "/api/TodoInfo/Filters?pageSize=9999&"+((queryParams) ? ""+serialize(queryParams) : ""+searchParams);
        if(userId) endpoint = "/api/TodoInfo/GetByUserId/"+userId+((queryParams) ? ""+serialize(queryParams) : ""+searchParams);

        const resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+endpoint, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });

        var data = await resp.json();
        setTodos(data);
    }

    const createTodo = async (todo) => {
        var dueDate = new Date();
        var priority = 5;
        var userId = userDetails?.id || null;
        if(/gisteren/i.test(todo)) {
            dueDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
            todo = todo.replace(/gisteren/i,"");
        }
        if(/morgen/i.test(todo)) {
            dueDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            todo = todo.replace(/morgen/i,"");
        }
        if(/p1/.test(todo)) {
            priority = 1;
            todo = todo.replace(/\bp1\b/i,"");
        }
        if(/p2/.test(todo)) {
            priority = 2;
            todo = todo.replace(/\bp2\b/i,"");
        }
        if(/p3/.test(todo)) {
            priority = 3;
            todo = todo.replace(/\bp3\b/i,"");
        }
        if(/p4/.test(todo)) {
            priority = 4;
            todo = todo.replace(/\bp4\b/i,"");
        }

        var body = {
            "title": todo,
            "description": "",
            "priority": priority,
            "dueDate": dueDate,
            "userId": userId
        };


        const resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TodoInfo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
            body: JSON.stringify(body)
        });

        var data = await resp.json();
        await getTodos();
        setUpdateCounter(prevState => prevState + 1);
        return true;
    }

    const toggleComplete = async (todo) => {
        const { todoId } = todo;

        var newCompletedState = !todo.isCompleted;
        const todoData = {
            ...todo,
            isCompleted: newCompletedState
        }

        const resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TodoInfo/?Id="+todoId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
            method: "PUT",
            body: JSON.stringify(todoData)
            
        }); 
        await getTodos();
        setTodo({});
        if(todoData.isCompleted===true) {
            setPlaySoundCounter(prevState => prevState + 1);
            dispatch(uiActions.setAlert({type: "success", message: "Goed gedaan! De taak is afgevinkt!"}));           
        }
        setUpdateCounter(prevState => prevState + 1);

    }

    const updateTodo = async (updatedTodo, updateToDo=true) => {
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TodoInfo/?Id="+updatedTodo?.todoId, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                method: "PUT",
                body: JSON.stringify(updatedTodo)
                
            });
            if(resp?.status!==200) throw new Error();
            if(updateToDo) setTodo(updatedTodo);
            await dispatch(uiActions.setAlert({type: "success", message: "Taak succesvol bijgewerkt!"}));           
            await getTodos();
            setUpdateCounter(prevState => prevState + 1);
        } catch (err) {
            dispatch(uiActions.setAlert({type: "danger", message: "Fout bij het bijwerken van de taak!"}));           
        }
    }

    const removeTodo = async (id) => {
        await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TodoInfo/?Id="+id, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
            method: "DELETE"
            
        });
        await getTodos();
        setUpdateCounter(prevState => prevState + 1);
    }

    const getById = async (id) => {
        const resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/TodoInfo/GetById/"+id, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var data = await resp.json();
        await setTodo(data);
        setUpdateCounter(prevState => prevState + 1);
    }
    
    const clearId = () => {
        setTodo({});
    }

    const prepareUpdate = (updatedTodo) => {
        setTodo(prevState => updatedTodo);
    }

    return {
        prepareUpdate,
        updateTodo,
        updateCounter,
        todo: todo,
        todos: todos.data,
        createTodo: createTodo,
        getTodos,
        toggleComplete,
        removeTodo,
        getById,
        clearId
    }
}

export default useTodo;