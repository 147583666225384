import Loader from "components/ui/Loader/Loader";
import useKvk from "hooks/kvk/use-kvk";
import { useEffect } from "react";

export const StatusPage = () => {
    const { getByFilter, getByKvk, kvkData, kvkResults } = useKvk();
    
    useEffect(() => {
        getByFilter("Meiland");
        getByKvk("28035928", false);
    }, [])

    return (
        <>
        <h1>STATUS</h1>
        <div className="flex flex-col">
            <div className="grid grid-cols-4">
                <div className="flex flex-row gap-x-3">
                    <div className="w-4">{!kvkResults?.totaal ? <Loader /> : <span className="fa fa-check text-[green]"></span>}</div>
                    <span>KVK/ZoekenGetByFilter</span>
                </div>
            </div>
            <div className="grid grid-cols-4">
                <div className="flex flex-row gap-x-3">
                    <div className="w-4">{!kvkData?.kvkNummer ? <Loader /> : <span className="fa fa-check text-[green]"></span>}</div>
                    <span>KVK/ZoekenGetByKVKNumber</span>
                    {/* <div>{JSON.stringify(kvkData)}</div> */}

                    {/* KVK/BasisProfileGetBykvkNumber */}
                </div>
            </div>
        </div>
        </>

    )
}

export default StatusPage;