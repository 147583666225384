import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { dateToDutch } from "lib/kvk";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import { calcAgeInMonths, dateToISO, localDate, toyyyymmdd } from "lib/calc";

import useCustomer from "hooks/customer/use-customer";
import useAuth from "hooks/use-auth";

import { kvkDateToYYYYMMDD, monthsToYearsAndMonths } from "lib/helpers";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import useKvk from "hooks/kvk/use-kvk";

const CustomerFormPage = (props) => {
    const { role } = useAuth();
    const params = useParams();

    const { customerData, changeCustomerObj, changeCustomerData, getCustomerById, updateCustomer, patchCustomer } = useCustomer();   

    const { register, formState: { errors}, watch, handleSubmit, setValue } = useForm({
        mode: "onChange",
        defaultValues: async () => {
            var respJson = await getCustomerById(params.customerID, true);
            return {
                companyName: respJson?.companyName || "",
                chamberNumber: respJson?.chamberNumber || "",
                address: respJson?.address || "",
                place: respJson?.place || "",
                postalCode: respJson?.postalCode || "",
                dateofEsteb: respJson?.dateofEsteb ? toyyyymmdd(respJson?.dateofEsteb) : "",
                salutation: respJson?.salutation || "",
                firstName: respJson?.firstName || "",
                lastName: respJson?.lastName || "",
                phone: respJson?.phone || "",
                email: respJson?.email || "",
                dateofBirth: respJson?.dateofBirth ? toyyyymmdd(respJson?.dateofBirth) : "",
                driverLicenseNumber: respJson?.driverLicenseNumber || "",
                iban: respJson?.iban || "",
                rechtsvorm: respJson?.rechtsvorm || "",
                sbiCodes: "",
                latitude: "",
                longitude: "",

            };
        }
    });

    const watchAll = watch();

    const { kvkData, getByKvk } = useKvk();
    const { kvkNummer } = kvkData;
  
    const customerID = params.customerID || props.customerID;


    const dateofEstebRef = useRef();
    const dateofBirthRef = useRef();
    
    useEffect(() => {
        if(!kvkNummer) return;
        const { rechtsvorm, sbiStr, longitude, latitude, materieleRegistratie, handelsnaam } = kvkData;
        const { adres, plaats, postcode } = kvkData.visitingAddress;
        setValue("companyName", handelsnaam);
        setValue("place", plaats);
        setValue("address", adres);
        setValue("postalCode", postcode);
        setValue("sbiCodes", sbiStr);
        setValue("rechtsvorm", rechtsvorm);
        setValue("latitude", ""+latitude);
        setValue("longitude", ""+longitude);
        setValue("dateofEsteb", ""+kvkDateToYYYYMMDD(materieleRegistratie));
        // console.log("kvk set: "+kvkDateToYYYYMMDD(materieleRegistratie));
    }, [kvkNummer]);


    const submitFormHandler = async (formData) => {
      // remove spaces, and uppercase like 2324VT
      var postalCode = formData.postalCode;
      postalCode = postalCode.replace(/\s/g, "").toUpperCase();   
      var updatedCustomer = {
        ...formData,
        ...(dateofBirthRef && {"dateofBirth": dateToISO(localDate(formData?.dateofBirth))}),
        ...(dateofEstebRef && {"dateofEsteb": dateToISO(localDate(formData?.dateofEsteb))}),
        postalCode,
      }
      var resp = await patchCustomer(customerID, updatedCustomer);
      if(resp==="SUCCESS" && props.inModal) props.onUpdate();  
    };

    const cardTitle = props.title || (customerData.firstName+" "+customerData.lastName+" - "+customerData.companyName);
    if(!customerData?.customerID) return <>Laden...</>;

    return (
        <form onSubmit={handleSubmit(submitFormHandler)} className={`text-xs lg:text-base ${props.inModal ? 'mt-0' : 'mt-5'}`}>
            <Card title={cardTitle} showClose={props.inModal} onClose={props.onClose}>
                <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
                    <div className="grid col-span-2 gap-3 p-5">
                        {role.includes("Admin") &&
                        <Accordion open={true} summary="Bedrijfsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <Input3 name="companyName" register={register} label="Bedrijfsnaam" placeholder="Bedrijfsnaam" inputValue={watchAll?.companyName} fieldToValidate="Bedrijfsnaam is een verplicht veld" errorMessage={errors?.companyName?.message} />
                                <Input3 name="chamberNumber" register={register} label="KvK nummer" placeholder="KvK nummer" inputValue={watchAll?.chamberNumber} fieldToValidate="chamberNumber" errorMessage={errors?.chamberNumber?.message} />
                                <Input3 name="address" register={register} label="Adres" placeholder="Adres" inputValue={watchAll?.address} fieldToValidate="Adres is een verplicht veld" errorMessage={errors?.address?.message} />
                                <Input3 name="place" register={register} label="Plaats" placeholder="Plaats" inputValue={watchAll?.place} fieldToValidate="Plaats is een verplicht veld" errorMessage={errors?.place?.message} />
                                <Input3 name="postalCode" register={register} label="Postcode" placeholder="Postcode" inputValue={watchAll?.postalCode} fieldToValidate="postalCode" errorMessage={errors?.postalCode?.message} />
                            <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-x-5">
                                <div className="col-span-2">
                                <Input3 type="date" name="dateofEsteb" register={register} label="Datum oprichting" placeholder="Datum oprichting" inputValue={watchAll?.dateofEsteb} fieldToValidate="dateofEsteb" errorMessage={errors?.dateofEsteb?.message} />
                                {/* {kvkData?.materieleRegistratie
                                ?
                                <Input readOnly={true} onChange={() => {}} ref={dateofEstebRef} name="dateofEsteb" placeholder="Datum oprichting" value={localDate(kvkDateToISODate(kvkData.materieleRegistratie))} />
                                :
                                } */}
                                </div>
                                {watchAll?.dateofEsteb &&
                                <div className="flex flex-col text-sm">
                                    <div>{monthsToYearsAndMonths(calcAgeInMonths(localDate(watchAll.dateofEsteb))).years} jaar</div>
                                    <div>{monthsToYearsAndMonths(calcAgeInMonths(localDate(watchAll.dateofEsteb))).months} maanden</div>
                                </div>
                                }
                            </div>
                            </div>
                        </Accordion>}
                        <Accordion open={true} summary="Persoonsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <Select3 set="aanhef" name="salutation" register={register} placeholder="Aanhef" label="Aanhef" fieldToValidate="Aanhef is een verplicht veld" inputValue={watchAll?.salutation} errorMessage={errors?.salutation?.message} />
                                <Input3 name="firstName" register={register} placeholder="Voornaam" label="Voornaam" fieldToValidate="firstName" inputValue={watchAll?.firstName} errorMessage={errors?.firstName?.message} />
                                <Input3 name="lastName" register={register} placeholder="Achternaam" label="Achternaam" fieldToValidate="lastName" inputValue={watchAll?.lastName} errorMessage={errors?.lastName?.message} />
                                <Input3 name="phone" register={register} placeholder="Telefoon" label="Telefoon" fieldToValidate="phone" inputValue={watchAll?.phone} errorMessage={errors?.phone?.message} />
                                <Input3 name="email" register={register} placeholder="E-mailadres" label="E-mailadres" fieldToValidate="email" inputValue={watchAll?.email} errorMessage={errors?.email?.message} />
                                <Input3 name="driverLicenseNumber" required={false} register={register} placeholder="Rijbewijsnummer" label="Rijbewijsnummer" fieldToValidate="driverLicenseNumber" inputValue={watchAll?.driverLicenseNumber} errorMessage={errors?.driverLicenseNumber?.message} />
                                <Input3 name="iban" register={register} required={false} placeholder="IBAN" label="IBAN" fieldToValidate="iban" inputValue={watchAll?.iban} errorMessage={errors?.iban?.message} />
                                <Input3 type="date" name="dateofBirth" register={register} label="Geboortedatum" placeholder="Geboortedatum" inputValue={watchAll?.dateofBirth} fieldToValidate="dateofBirth" errorMessage={errors?.dateofBirth?.message} />
                            </div>
                        </Accordion>
                        <Accordion open={true} summary="Aanvullende gegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <Input3 required={false} name="rechtsvorm" register={register} placeholder="Rechtsvorm" label="Rechtsvorm" fieldToValidate="rechtsvorm" inputValue={watchAll?.rechtsvorm} errorMessage={errors?.rechtsvorm?.message} />

                                <div></div>
                                <div className="col-span-2 mt-3">
                                        <div className="grid grid-cols-4 p-1">
                                            <div>SBI code</div><div className="col-span-2">Omschrijving</div><div>Hoofdactiviteit</div>
                                        </div>
                                        <div>{customerData.sbiCodes?.length>0 && JSON.parse(customerData.sbiCodes).map((code, index) => {
                                            return (
                                                <div key={code+index} className="grid grid-cols-4  p-1">
                                               <div>{code.sbiCode}</div>
                                               <div className="col-span-2">{code.sbiOmschrijving}</div>
                                               <div>{code.indHoofdactiviteit}</div>
                                               </div>
                                            )
                                        }
                                        )}</div>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
                        <div>Datum aangemaakt {dateToDutch(customerData.createdDate)}</div>
                        <div>Laatst bijgewerkt {dateToDutch(customerData.updatedDate)}</div>
                        <div className="flex flex-col gap-y-3">
                            <Button className="bg-gradient">Opslaan</Button>
                            {watchAll.chamberNumber && <Button onClick={() => getByKvk(watchAll.chamberNumber)} type="button" className="btn-secondary">KvK data invullen</Button>}
                        </div>
                    </div>
                </div>
            </Card>
        </form>
    )
}

export default CustomerFormPage;
