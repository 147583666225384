import { useRef } from "react";
import { useForm } from "react-hook-form";

import Button from "components/ui/Button/Button";
import DropArea from "components/ui/DropArea/DropArea";

import useAuth from "hooks/use-auth";
import { dateToISO, localDate, toyyyymmdd } from "lib/calc";
import Select3 from "components/ui/Select/Select3";
import Input3 from "components/ui/Input/Input3";
import useAuthenticate from "hooks/authenticate/use-authenticate";

const MyProfilePage = () => {
    const imageRef = useRef();
    const { updateProfile } = useAuthenticate();
    const { userDetails } = useAuth();

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm({
      mode: "onChange",
      defaultValues: {
        salutation: userDetails?.salutation || "",
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        dateOfBirth: toyyyymmdd(userDetails?.dateOfBirth) || "",
        profileImage: userDetails?.profileImage || "",
        phoneNumber: userDetails?.phoneNumber || "",
      }
    });

    const watchAll = watch();
       
    const submitHandler = (formData) => {
      var imageFile = imageRef?.current?.files[0] || watchAll?.profileImage || "";   
      console.log({
        1: imageRef?.current?.files[0],
        2: watchAll?.profileImage,
        3: imageFile
      });

      var dateOfBirth = dateToISO(localDate(formData?.dateOfBirth)) || ""; // 1986-03-28 to dateISO
      var profileObj = {
        ...formData,
        dateOfBirth: dateOfBirth
      }

      console.log("profileObj", profileObj);
      updateProfile(profileObj, imageFile);    
    };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-col md:flex-row justify-between items-center gap-5 mb-5">
            <h3>Mijn profiel</h3>
            <div className="flex flex-row gap-x-3">
                <Button type="submit" className="bg-gradient max-h-fit">Opslaan</Button>
            </div>
        </div>
        <div className="text-[#1a2a52] relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 items-start py-10 px-5 lg:py-5 lg:px-10 gap-y-0 md:gap-y-5 gap-x-10 bg-white shadow-xl rounded-xl mb-5">
          <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-3 mb-3 md:mb-0">
            <div className="font-semibold">Aanhef</div>
            <Select3 set="aanhef" name="salutation" register={register} placeholder="Aanhef" label="Aanhef" fieldToValidate="Aanhef is een verplicht veld" inputValue={watchAll?.salutation} errorMessage={errors?.salutation?.message} />
            <div className="font-semibold">Voornaam</div>
            <Input3 name="firstName" register={register} placeholder="Voornaam" label="Voornaam" fieldToValidate="firstName" inputValue={watchAll?.firstName} errorMessage={errors?.firstName?.message} />
            <div className="font-semibold">Achternaam</div>
            <Input3 name="lastName" register={register} placeholder="Achternaam" label="Achternaam" fieldToValidate="lastName" inputValue={watchAll?.lastName} errorMessage={errors?.lastName?.message} />
            <div className="font-semibold">Telefoon (zakelijk)</div>
            <Input3 name="phoneNumber" register={register} placeholder="Telefoon (zakelijk)" label="Telefoon (zakelijk)" fieldToValidate="phone" inputValue={watchAll?.phoneNumber} errorMessage={errors?.phoneNumber?.message} />
            <div className="font-semibold">Geboortedatum</div>
            <Input3 id="dateOfBirth" register={register} type="date" name="dateOfBirth" placeholder="Geboortedatum" label="Geboortedatum" inputValue="Geboortedatum is een verplicht veld" fieldToValidate="dateOfBirth" errorMessage={errors?.dateOfBirth?.message}/>
          </div>
          <div className="col-span-3">
            {watchAll?.profileImage
            ?
            <div className="flex flex-row justify-center">
              <div className="flex flex-col text-center relative">
                <span onClick={() => setValue("profileImage", "")} className="fa fa-square-xmark absolute right-2 top-0 text-orange hover:opacity-50 text-3xl cursor-pointer"></span>
                <img alt="Profielfoto" loading="lazy" className="w-56 h-56 rounded-full" src={watchAll?.profileImage} />
              </div>
            </div>
            :
            <DropArea onSetFileName={() => {}} accept="image/*" ref={imageRef} onDelete={() => {}} uploadText="Upload" name="file" extensions="png|jpg|jpeg|bmp|gif" />
            }
            <div>{watchAll?.profileImage}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyProfilePage;
