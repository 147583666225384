import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "components/ui/Button/Button";
import { uiActions } from "store/ui-slice";

const DropArea = React.forwardRef((props,ref) => {
    const dispatch = useDispatch();

    const [previewImage, setPreviewImage] = useState(props.value);

    const [file, setFile] = useState("");

    const validateFileType = (fileName) => {
        var fileArr = fileName.split(".");
        var lastIndex = fileArr.length - 1;
        var ext = fileArr[lastIndex];
        var possible_extensions = props.extensions || "png";
        var extensionCheck = new RegExp(possible_extensions+"$","i");
        if(!extensionCheck.test(ext)) {
            dispatch(uiActions.setAlert({message: "Je kunt alleen "+possible_extensions+" bestanden uploaden.", visible: true, type: "danger"}));
            return false;
        }
        return true;
    }

    function handleChange(evt) {
        evt.preventDefault();
        if(evt.target?.files?.length) {
           var fileTypeIsValid = validateFileType(evt.target.files[0].name);
           if(!fileTypeIsValid) return;
           setFile(evt.target.files[0]);

           if(typeof props.onSetFileName==="function") props.onSetFileName(evt.target.files[0].name);


           setPreviewImage(URL.createObjectURL(evt.target.files[0]));
           if(typeof props.onAdd==="function") props.onAdd();
        }
        if(!file) {
            // file = ref.current?.value;
        }
        if(props.onChange) {
            props.onChange({name: props.name, value: file});
        }
    }

    const clickHandler = () => {
        ref.current.click();
    }

    const dropHandler = (evt) => {
        evt.preventDefault();
        var fileTypeIsValid = validateFileType(evt.dataTransfer.files[0].name);
        // console.log(evt.dataTransfer.files[0].name);

        if(!fileTypeIsValid) return;
        setFile(evt.dataTransfer.files[0]);
    }

    const allowDrop = (evt) => {
        evt.preventDefault();
    }

    const removeFileHandler = () => {
        setFile("");
        setPreviewImage("");
        if(typeof props.onDelete==="function") props.onDelete();
        ref.current.value = "";
    }

    return (
        <div className="border p-5 border-dashed border-slate-300 rounded-xl cursor-grabbing" onDrop={(evt) => dropHandler(evt)} onDragOver={(evt) => allowDrop(evt)}>
            <div className="grid grid-cols-3 gap-3">
                <div className="flex flex-col col-span-2 gap-3">
                    <div>{file.name}</div>
                    <Button type="button" className="btn-secondary" onClick={clickHandler}>{props.uploadText ? props.uploadText : 'Upload'}</Button>
                    <input ref={ref} name={props.name} accept={props.accept ? props.accept : "image/png"} style={{display: "none"}} type="file" onChange={(evt) => handleChange(evt)} />
                    <span>Drag &amp; drop het bestand binnen het kader</span>
                    {/* <Input placeholder="URL" onChange={(evt) => handleChange(evt)} /> */}
                </div>
                {previewImage && (
                <div className="bg-slate-200 rounded-xl relative flex flex-row justify-center items-center h-full">
                    <div className="fa fa-square-xmark absolute right-2 top-0 text-orange hover:opacity-50 text-3xl cursor-pointer" onClick={removeFileHandler}></div>
                    <div className="p-3">
                        {/png|svg|jpg|jpe|jpeg|bmp|gif|webp/i.test(file.name) ? <img alt="" className="object-contain max-w-[140px]" style={{height: "120px"}} src={previewImage} /> : file.name }
                    </div>
                </div>
                )
                }
            </div>
        </div>
    )
});

export default DropArea;