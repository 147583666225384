import useActivities from "hooks/use-activities";
import { useEffect } from "react";
import ActivitySummary2 from "./ActivitySummary2";
import Modal from "components/hoc/Modal";

const ActivityForm2 = ({id, updated, activityParams, activityType, tab, onUpdate}) => {
  const { activity, activities, getActivityById, getActivities, clearActivityId } = useActivities();

  const autoAssignedType = (/Deal\s(aangemaakt|bijgewerkt|gearchiveerd)/.test(activity.type));
  const editable = activityType==="dealerActivity" || /(Werkblok|Belblok|Mailing|Uitslag)/.test(activity.type) || activity.type === "";
 
  useEffect(() => {
    getActivities(id, activityType, activityParams, false);
  },[updated,activityType, activityParams]);
 
  return (
    <div>
      <div className={`hidden md:grid ${tab==='log' ? 'grid-cols-4' : 'grid-cols-3'} font-semibold bg-sld-blue text-white rounded-xl shadow-xl gap-x-10 items-center py-10 px-5 lg:py-5 lg:px-10 mb-5`}>
        <div>Type</div>
        <div>Onderwerp</div>
        <div>Beschrijving</div>
        <div></div>
      </div>
      {activities?.length>0 && activities.map((activity, index) => {
                return (
                  <div key={"activity"+index} onClick={() => getActivityById(activity.activityID, activityType)} className={`grid ${tab==='log' ? 'flex flex-col lg:grid-cols-4' : 'flex-col md:grid-cols-3'} cursor-pointer hover:bg-[#FF931E] hover:text-white font-semibold bg-white rounded-xl shadow-xl gap-x-10 items-center py-10 px-5 lg:py-5 lg:px-10 mb-5`}>
                        <div className="flex flex-col">
                          <div>{activity.type}</div>
                          <div className="">{activity.createdDateTime}</div>
                          <div className="">{activity.createdBy}</div>
                        </div>
                        <div className="">{activity.name}</div>
                        <div className={`${tab==='log' && 'lg:col-span-2'} break-words`}>
                          {autoAssignedType && 'Bekijk eerdere berekening'}
                          {activity.type==="Dealer update" && 'Bekijk bericht'}
                          {(activity.type!=="Dealer update" && !autoAssignedType) && (
                            <span>
                            {activity.description.slice(0, 100)}
                            {activity.description.length>100 && '...'}
                            </span>
                          )}
                        </div>
                    </div>
                )
            })}
            {!activities.length && <p className="p-3">Er zijn nog geen activiteiten</p>}
           
            {activity.activityID &&
            <Modal show={activity.activityID} title={activity.type+" - "+activity.name} onClose={clearActivityId}>
              <ActivitySummary2 dealerID={id} activityType={activityType} onClose={clearActivityId} onUpdate={onUpdate} editable={editable} autoAssignedType={autoAssignedType} activity={activity} />
            </Modal>
            }
    </div>
  );
};

export default ActivityForm2;
