import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Button from "components/ui/Button/Button";
import Container from "components/hoc/Container";
import { uiActions } from "store/ui-slice";
import image from "components/0x0-Model3_20.jpg";

import InputKvk3 from "components/ui/InputKvk/InputKvk3";
import Input3 from "components/ui/Input/Input3";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";

const Introductie = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { customerDetail } = useSelector(state => state.deal);
    const { register, handleSubmit, formState: { errors }, watch, setFocus, getValues, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            companyName: ""
        }
    });

    const { kvkSearchQuery, firstName, lastName, email, phone, demo } = watch();

    const kvkErrorHandler = () => {};

     const submitHandler = async (formData) => {
        dispatch(uiActions.showBackdrop());

        try {
            const body = {
                "subject": "Demo Request",
                "companyname": customerDetail?.companyName,
                "firstName": formData?.firstName,
                "lastName": formData?.lastName,
                "email": formData?.email,
                "phonenumber": formData?.phone,
                "demo": demo,
                "url": "/autobedrijf-aanmelden"
            }
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/RequestDemo', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
    
            var respJson = await resp.json();
            if(respJson.status==="Success") {
                navigate("bedankpagina", { state: {...body, phone: body?.phone} });
                dispatch(uiActions.hideBackdrop());
            }
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
        }
    }

    return (
        <>
        <div className="container mx-auto px-5 h-full my-5">
        <div className="flex flex-row h-full items-center justify-center md:justify-start">
        <div className="fixed top-0 left-0 w-full h-full bg-no-repeat bg-center lg:bg-left-top lg:bg-cover opacity -z-10 scale-x-flip xscale-y-flip" style={{backgroundImage:`url(${image})`}}></div>
        <Container>
        <form onSubmit={handleSubmit(submitHandler)} className="border-slate-100 border justify-start gap-y-5 flex flex-col max-w-2xl p-10 shadow-xl rounded-xl bg-white-transparent">
        <h1 className="text-2xl mb-0 flex flex-row justify-start items-center whitespace-nowrap">
                <span className="fa fa-user-group pr-4"></span>
                <span>Autobedrijf aanmelden</span>
            </h1>
            <div className="">
                Interesse? Meld uw bedrijf aan!
            </div>
            <div className="flex flex-col gap-y-3">
                <InputKvk3 publicApi={true} onError={kvkErrorHandler} setFocus={setFocus} onComplete={() => {}} className="lg:col-span-3" historyChamberNumber={getValues("chamberNumber")} register={register} errors={errors} getValues={getValues} setValue={setValue} inputValue={kvkSearchQuery} errorMessage="Veld is verplicht" />    
                <Input3 name="firstName" register={register} fieldToValidate="Voornaam is een verplicht veld" placeholder="Voornaam" label="Voornaam" inputValue={firstName} errorMessage={errors?.firstName?.message} />
                <Input3 name="lastName" register={register} fieldToValidate="Achternaam is een verplicht veld" placeholder="Achternaam" label="Achternaam" inputValue={lastName} errorMessage={errors?.lastName?.message} />
                <Input3 name="email" register={register} fieldToValidate="email" placeholder="E-mailadres" label="E-mailadres" inputValue={email} errorMessage={errors?.email?.message} />
                <Input3 name="phone" register={register} fieldToValidate="phone" placeholder="Telefoonnummer" label="Telefoonnummer" inputValue={phone} errorMessage={errors?.phone?.message} />

                <div className="px-3">
                    <InputCheckbox3 name="demo" register={register} inputValue={demo} label="Ja, ik wil een demo van het systeem" errorMessage={errors?.demo?.message} />
                </div>

                <div className="mt-5">
                    <Button>Aanmelding versturen</Button>
                </div> 
            </div>
        </form>
        </Container>
        </div>
        </div>
        </>
    )
}

export default Introductie;