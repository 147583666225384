import { useCallback } from "react";
import ReactGA from "react-ga4";

const useAnalytics = () => {
    
    const sendPageView = useCallback((eventOptions) => {
        if(process.env.REACT_APP_SLD_API_BASE_URL==="https://api-sld-staging.trailfive.com") {
            // only log on staging
            console.log("sendPageView", eventOptions);
        } else {
            // live
            ReactGA.event({
                category: eventOptions.category || "",
                action: eventOptions.action || "",
                label: eventOptions.label || ""
            });
        }
    },[]);

    return {
        sendPageView
    }
}

export default useAnalytics;