import { useState, useRef, Suspense } from "react";
import DocumentForm2 from "components/Forms/DocumentForm/DocumentForm2";
import Button from "components/ui/Button/Button";
import Input from "components/ui/Input/Input";
import useEmail from "hooks/email/use-email";
import Activities from "routes/crm/activiteiten";
import TextArea from "components/ui/TextArea/TextArea";
import { createPortal } from "react-dom";
import Modal from "components/hoc/Modal";
import NewActivityForm2 from "components/Forms/ActivityForm/NewActivityForm2";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import useActivity from "hooks/activity/use-activity";
import { useForm } from "react-hook-form";
import Input3 from "components/ui/Input/Input3";
import TextArea3 from "components/ui/TextArea/TextArea3";
import ActivityForm2 from "components/Forms/ActivityForm/ActivityForm2";
import EmailComposer from "components/EmailComposer/EmailComposer";

export const DocAndActivityBlock2 = ({ source, dealID, dealDetail, dealerID, dealerDetail, updateCounter, onUpdate, sourceOfComponent }) => {

    const { register, formState: { errors }, watch, handleSubmit,  } = useForm({
        mode: "onChange",
        defaultValues: {
            description: "",
            name: `Statusupdate van uw aanvraag ${dealID}, ${dealDetail?.dealerDetail?.name}.`
        }
    });
    const { sendDealerUpdate, createActivity } = useActivity();
    const dispatch = useDispatch();
    const iframeRef = useRef();

    const closeHandler = (update=false) => {
        if(update) {
            onUpdate();
        }
        setShowPopup(false);
        dispatch(uiActions.hideBackdrop());
    };

    const addHandler = (typeOfActivity) => {
        setTypeOfActivity(typeOfActivity);
        setShowPopup("document");
    }

    const [showPopup, setShowPopup] = useState(false);
    const [typeOfActivity, setTypeOfActivity] = useState("");

    const [referenceId, setReferenceId] = useState("Dealer update");

    const { emails } = useEmail("getEmails");

    const sendDealerUpdateHandler = async (formData) => {
        var resp = await sendDealerUpdate(formData, dealID);
        if(resp==="SUCCESS") {
            var activityResp = await createActivity("Dealer update", "sent", {dealID: dealID}, {
                documentName: formData?.name || "",
                type: "Dealer update",
                description: formData?.description || ""
            });
        }
    }

    const showMailHandler = () => {
        setShowPopup("email");
        setReferenceId("2"); // 2 is dealer update
    }

    const [activeTab, setActiveTab] = useState("activities"); // activities|documents|mailing|log

    const tabClasses = "select-none cursor-pointer inline-block p-4 border-b-2 rounded-t-lg";
    const activeTabClasses = "font-semibold border-orange text-orange cursor-default";

    const watchAll = watch();

    return (
    <div className="text-[#1a2a52] relative py-10 px-5 lg:py-5 lg:px-10 gap-y-0 md:gap-y-5 gap-x-10 bg-white shadow-xl rounded-xl mb-5 max-w-7xl">
        <div className="mb-4 border-b border-gray-200">
            <div className=" flex flex-wrap -mb-px text-center">
                    <span onClick={() => setActiveTab("activities")} className={`${tabClasses} ${activeTab==="activities" && activeTabClasses}`}>Activiteiten</span>
                    {sourceOfComponent==="deal" && <span onClick={() => setActiveTab("documents")} className={`${tabClasses} ${activeTab==="documents" && activeTabClasses}`}>Documenten</span>}
                    <span onClick={showMailHandler} className={`${tabClasses} ${activeTab==="mailing" && activeTabClasses}`}>Mailing</span>
                    <span className={`${tabClasses} group relative ${(activeTab==="log" || activeTab==="logemail") && activeTabClasses}`}>
                        <div onClick={() => setActiveTab("log")} className="flex flex-row items-center gap-x-3"><span>Logboek</span><span className="fa fa-chevron-down text-xs"></span></div>
                        <div className="hidden flex-col group-hover:flex absolute text-left font-body text-body text-black font-normal -left-[4px] top-full border border-black rounded-b-xl xw-full z-20 bg-white">
                            {sourceOfComponent==="deal" && <span onClick={() => setActiveTab("log")} className={`${activeTab==="log" && "text-orange font-semibold"} px-5 py-2 border-b border-slate-300 hover:bg-slate-300 border-t border-t-black`}>Activiteiten</span>}
                            <span onClick={() => setActiveTab("logemail")} className={`${activeTab==="logemail" && "text-orange font-semibold"} px-5 py-2 hover:bg-slate-300 rounded-b-xl`}>E-mail</span>
                        </div>
                    </span>
                    {sourceOfComponent==="deal" && <span onClick={() => setActiveTab("log_old")} className={`${tabClasses} ${activeTab==="log_old" && activeTabClasses}`}>Logboek (oud)</span>}
            </div>
        </div>

        {activeTab==="activities" && 
        <div>
            <div className="flex flex-row justify-start">
                <Button onClick={() => addHandler("activity")}>Nieuwe activiteit</Button>
            </div>
            <div className="mt-10">
                <ActivityForm2 activityType={sourceOfComponent==="dealer" ? "dealerActivity" : null} id={sourceOfComponent==="dealer" ? dealerID : dealDetail?.dealID} updated={updateCounter} onUpdate={onUpdate} updateCounter={updateCounter} activityParams={'Dealer%20update|Werkblok|Belblok|Mailing|Uitslag'} />
            </div>
        </div>
        }
        {activeTab==="documents" && 
        <div>
            <div className="flex flex-row justify-start mb-10">
                <Button onClick={() => addHandler("document")}>Nieuwe document</Button>
            </div>
            <DocumentForm2 updated={updateCounter} updateCounter={updateCounter} id={dealID} onUpdate={onUpdate} dealerName={dealDetail?.dealerDetail?.name ? dealDetail.dealerDetail.name : ""} />
        </div>
        }

        {showPopup==="email" && <div>
        {createPortal(
        <Suspense fallback={<div>Loading ...</div>}>
            <Modal category="calc" title="E-mail versturen" show={true} onClose={closeHandler}>
                <EmailComposer sourceOfComponent={sourceOfComponent} source={source} dealId={dealID} dealerId={dealDetail?.dealerDetail?.dealerID || dealerID} customerId={dealDetail?.customerDetail?.customerID} deal={dealDetail} toWho="toDealer" referenceId={source==="Dealer-aanvraag" ? "2" : ""} emailTemplates={emails} />
            </Modal>
        </Suspense>, document.body)}
        </div>
        }

        {(activeTab==="log" || activeTab==="logemail") && 
        <div>   
            <Activities whatToShow={activeTab} onUpdate={onUpdate} updateCounter={updateCounter} dealID={dealID} widget={true} widgetSearchQuery={"dealID="+dealID+"&pageSize=999&typeOfActivity=userActivity|adminActivity|manualActivity"} />
        </div>
        }
        {activeTab==="log_old" && 
        <div>   
            <ActivityForm2 id={dealID} updated={updateCounter} onUpdate={onUpdate} tab="log" activityParams="Deal%20aangemaakt|Deal%20bijgewerkt|Deal%20gearchiveerd|Deal%20gedearchiveerd" />
        </div>
        }

        <div className="flex flex-row col-span-2 items-center justify-end">
        {showPopup==="document" && createPortal(
        <Suspense fallback={<div>Loading ...</div>}>
            <Modal childrenOnly={true} show={true} onClose={closeHandler}>
            <NewActivityForm2 onUpdate={() => {
                onUpdate();
                setShowPopup(false);
            }} source={dealDetail?.source} typeOfActivity={sourceOfComponent==="dealer" ? "dealerActivity": typeOfActivity} saleType={dealDetail?.saleType ? dealDetail?.saleType : ""} id={dealID || dealerID} companyName={dealDetail?.dealerDetail?.name} title="Activiteit of Document" onClose={closeHandler} inModal={true} />
        </Modal>

        </Suspense>, document.body)}
        </div>

    </div>
    )
}

export default DocAndActivityBlock2;