import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";

import Container from "components/hoc/Container";
import { dateToDutch } from "lib/kvk";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import InputRadio from "components/ui/InputRadio/InputRadio";

import { uiActions } from "store/ui-slice";

const TokenDetail = (props) => {
    const { data } = props;
    const [tokenData, setTokenData] = useState(data);
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        setTokenData(data);
    },[data]);

    useEffect(() => {
        setPermissions({
            "hasAccessToCreateDealWithDetailsAndKey": tokenData.hasAccessToCreateDealWithDetailsAndKey,
            "hasAccessToDelete": tokenData.hasAccessToDelete,
            "hasAccessToFilters": tokenData.hasAccessToFilters,
            "hasAccessToFiltersWithoutData": tokenData.hasAccessToFiltersWithoutData,
            "hasAccessToGetAll": tokenData.hasAccessToGetAll,
            "hasAccessToGetById": tokenData.hasAccessToGetById,
            "hasAccessToGetByMake": tokenData.hasAccessToGetByMake,
            "hasAccessToGetExternalVehicleDataOnly": tokenData.hasAccessToGetExternalVehicleDataOnly,
            "hasAccessToInsert": tokenData.hasAccessToInsert,        
            "hasAccessToUpdate": tokenData.hasAccessToUpdate,
            "hasAccessToUpdateMonthlyAmount": tokenData.hasAccessToUpdateMonthlyAmount,
            "hasAccessToGetAllCsv": tokenData.hasAccessToGetAllCsv,
            "hasAccessToGetAllXml": tokenData.hasAccessToGetAllXml,
            "hasAccessToGetUniqueMakeModel": tokenData.hasAccessToGetUniqueMakeModel,
            "hasAccessToKVKEndpoints": tokenData.hasAccessToKVKEndpoints,
            "hasAccesstoCreateDealWithKeyFromActua": tokenData?.hasAccesstoCreateDealWithKeyFromActua,
            "hasAccessToGetActuaDealers": tokenData?.hasAccessToGetActuaDealers,
            "hasAccessToUpdateDealerValidationToken": tokenData?.hasAccessToUpdateDealerValidationToken,
            "hasAccessToGetAllDealsByToken": tokenData?.hasAccessToGetAllDealsByToken,
            "hasAccessToGetActuaDealsById": tokenData?.hasAccessToGetActuaDealsById,
            "hasAccessToCreateActuaActivitiesDeals": tokenData?.hasAccessToCreateActuaActivitiesDeals,
            "hasAccessToGetAllActuaActivities": tokenData?.hasAccessToGetAllActuaActivities,
            "hasAccessToGetActuaActivitiesById": tokenData?.hasAccessToGetActuaActivitiesById,
            "hasAccessToVerifyActuaToken": tokenData?.hasAccessToVerifyActuaToken,
            "hasAccessToGetActuaDealersById": tokenData?.hasAccessToGetActuaDealersById,
            "hasAccessToPatchActuaDeals": tokenData?.hasAccessToPatchActuaDeals,
            "hasAccessToUpdateActuaDealsWithoutDealer": tokenData?.hasAccessToUpdateActuaDealsWithoutDealer,
            "hasAccessToPatchActuaUsers": tokenData?.hasAccessToPatchActuaUsers
        });
    },[tokenData]);

    const dispatch = useDispatch();
    const { token: jwt } = useAuth();
   
    const submitFormHandler = (evt) => {
        evt.preventDefault();
        dispatch(uiActions.showBackdrop());
        const updatedData = {
            token: data.token,
            email: data.email,
            ...permissions
        }
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Authenticate/UpdateExternalVehicleToken', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            },
            method: "POST",
            body: JSON.stringify(updatedData)
        }).then(resp => resp.json())
        .then(() => {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Token succesvol bijgewerkt", type: "success"}));
            props.onUpdate();
        }).catch(() => {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Token kan niet bijgewerkt worden", type: "danger"}));
            props.onUpdate();
        });
    };

    const changeFilterHandler = (evt) => {
        const { name: checkName, checked } = evt.target;
        setPermissions(prevState => {
            return {
                ...prevState,
                [checkName]: checked
            }
        });
    };

    if(!permissions || !props.data.id) return <></>;

    return (
        <Container>

        <form onSubmit={submitFormHandler} className="text-xs lg:text-base mt-5">
                <div className="bg-white grid lg:grid-cols-3 items-start">
                    <div className="grid col-span-2 gap-3 p-5">
                        <Accordion open={true} summary="API gegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                                <span>Token</span>
                                <span className="break-words col-span-2 bg-slate-100 xborder xp-1 xborder-slate-300 text-sm">{tokenData.token}</span>
                                <span>E-mail</span><span>{tokenData.email}</span>
                            </div>
                        </Accordion>
                        <Accordion open={true} summary="Rechten">
                            <div className="grid grid-cols-2 gap-3 py-5">
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToInsert} name="hasAccessToInsert" type="checkbox" options={[{label: "Insert"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToUpdate} name="hasAccessToUpdate" type="checkbox" options={[{label: "Update"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToDelete} name="hasAccessToDelete" type="checkbox" options={[{label: "Delete"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToCreateDealWithDetailsAndKey} name="hasAccessToCreateDealWithDetailsAndKey" type="checkbox" options={[{label: "CreateDealWithDetailsAndKey"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToFilters} name="hasAccessToFilters" type="checkbox" options={[{label: "Filters"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToFiltersWithoutData} name="hasAccessToFiltersWithoutData" type="checkbox" options={[{label: "FiltersWithoutData"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetAll} name="hasAccessToGetAll" type="checkbox" options={[{label: "GetAll"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetById} name="hasAccessToGetById" type="checkbox" options={[{label: "GetById"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetByMake} name="hasAccessToGetByMake" type="checkbox" options={[{label: "GetByMake"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetExternalVehicleDataOnly} name="hasAccessToGetExternalVehicleDataOnly" type="checkbox" options={[{label: "ExternalVehicleDataOnly"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToUpdateMonthlyAmount} name="hasAccessToUpdateMonthlyAmount" type="checkbox" options={[{label: "UpdateMonthlyAmount"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetAllCsv} name="hasAccessToGetAllCsv" type="checkbox" options={[{label: "GetAllCsv"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetAllXml} name="hasAccessToGetAllXml" type="checkbox" options={[{label: "GetAllXml"}]} />
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetUniqueMakeModel} name="hasAccessToGetUniqueMakeModel" type="checkbox" options={[{label: "GetUniqueMakeModel"}]} />                          
                            <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToKVKEndpoints} name="hasAccessToKVKEndpoints" type="checkbox" options={[{label: "KVK-Endpoints"}]} />       


                            <details className="col-span-2">
                                <summary className="select-none cursor-pointer">Actua rechten</summary>
                                <div>
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccesstoCreateDealWithKeyFromActua} name="hasAccesstoCreateDealWithKeyFromActua" type="checkbox" options={[{label: "CreateDealWithKeyFromActua"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetActuaDealers} name="hasAccessToGetActuaDealers" type="checkbox" options={[{label: "GetActuaDealers"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToUpdateDealerValidationToken} name="hasAccessToUpdateDealerValidationToken" type="checkbox" options={[{label: "UpdateDealerValidationToken"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetAllDealsByToken} name="hasAccessToGetAllDealsByToken" type="checkbox" options={[{label: "GetAllDealsByToken"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetActuaDealsById} name="hasAccessToGetActuaDealsById" type="checkbox" options={[{label: "GetActuaDealsById"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToCreateActuaActivitiesDeals} name="hasAccessToCreateActuaActivitiesDeals" type="checkbox" options={[{label: "CreateActuaActivitiesDeals"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetAllActuaActivities} name="hasAccessToGetAllActuaActivities" type="checkbox" options={[{label: "GetAllActuaActivities"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetActuaActivitiesById} name="hasAccessToGetActuaActivitiesById" type="checkbox" options={[{label: "GetActuaActivitiesById"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToVerifyActuaToken} name="hasAccessToVerifyActuaToken" type="checkbox" options={[{label: "VerifyActuaToken"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToGetActuaDealersById} name="hasAccessToGetActuaDealersById" type="checkbox" options={[{label: "GetActuaDealersById"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToPatchActuaDeals} name="hasAccessToPatchActuaDeals" type="checkbox" options={[{label: "PatchActuaDeals"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToUpdateActuaDealsWithoutDealer} name="hasAccessToUpdateActuaDealsWithoutDealer" type="checkbox" options={[{label: "UpdateActuaDealsWithoutDealer"}]} />       
                                    <InputRadio onChange={changeFilterHandler} checked={permissions.hasAccessToPatchActuaUsers} name="hasAccessToPatchActuaUsers" type="checkbox" options={[{label: "PatchActuaUsers"}]} />       
                                </div>
                            </details>

                                             
                            </div>
                        </Accordion>
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full">
                        <div>Datum aangemaakt {dateToDutch(tokenData.createdDate)}</div>
                        <div>Laatst bijgewerkt {dateToDutch(tokenData.updatedDate)}</div>
                        <Button className="bg-gradient">Opslaan</Button>
                    </div>
                </div>
        </form>

        </Container>
    )
}

export default TokenDetail;