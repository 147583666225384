import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import useAuth from "hooks/use-auth";

export const useChangeLog = (init, initQuery=null) => {
    const { token: jwt } = useAuth();
    const dispatch = useDispatch();
    const [changeLog, setChangeLog] = useState("");
    const [changeLogs, setChangeLogs] = useState([]);

    const createChangeLog = useCallback(async (data) => {
        dispatch(uiActions.showBackdrop());        
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ChangeLog", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(data)
            });
            dispatch(uiActions.hideBackdrop());        
            dispatch(uiActions.setAlert({message: "Changelog succesvol toegevoegd!", type: "success" }));
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.hideBackdrop());        
            dispatch(uiActions.setAlert({message: "Fout bij het toevoegen van de changelog!", type: "danger" }));
        }
    },[]);

    const getChangeLog = useCallback(async (src) => {
        if(!src) return;
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ChangeLog?src="+src, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var respJson = await resp.json();
            setChangeLogs(respJson);
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            setChangeLogs("");
            dispatch(uiActions.hideBackdrop());
        }       
    },[]);

    const getChangeLogById = useCallback(async (id) => {
        if(!id) return;
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ChangeLog/"+id, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var respJson = await resp.json();
            setChangeLog(respJson);
            dispatch(uiActions.hideBackdrop());
            return respJson;
        } catch (err) {
            setChangeLog("");
            dispatch(uiActions.hideBackdrop());
        }       
    },[]);
   

    const stateUpdateChangeLog = (html) => {
        setChangeLog(prevState => ({...prevState, description: html}));
    }

    const stateClearChangeLog = () => setChangeLog("");

    const updateChangeLog = useCallback(async (id, body) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ChangeLog/Id?Id="+id, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(body)
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());    
            dispatch(uiActions.setAlert({message: "Changelog succesvol gewijzigd!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());    
            dispatch(uiActions.setAlert({message: "Fout bij het wijzigen van de changelog!", type: "danger" }));
        }    
    },[]);

    const deleteChangeLogById = useCallback(async (id, body) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/ChangeLog/Id?Id="+id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());    
            setChangeLogs(prevState => prevState.filter(item => item.changeLogId!==id));
            setChangeLog("");
            dispatch(uiActions.setAlert({message: "Changelog succesvol verwijderd!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());    
            dispatch(uiActions.setAlert({message: "Fout bij het verwijderen van de changelog!", type: "danger" }));
        }    
    },[]);

    return {
        changeLog,
        changeLogs,
        getChangeLog,
        getChangeLogById,
        stateUpdateChangeLog,
        stateClearChangeLog,
        updateChangeLog,
        createChangeLog,
        deleteChangeLogById
    }
}

export default useChangeLog;