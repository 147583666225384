import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useDealV2 from "hooks/deal/use-deal_v2";
import useActivity from "hooks/activity/use-activity";
import useHead from "hooks/use-head";
import useAnalytics from "hooks/use-analytics";

import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import Button from "components/ui/Button/Button";
import InputKvk3 from "components/ui/InputKvk/InputKvk3";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import InputRange3 from "components/ui/InputRange/InputRange3";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";

import { dealActions } from "store/deal-slice";
import { getExludedEmailDomains } from "lib/helpers";

const BtwFinanciering = () => {
    const navigate = useNavigate();
    const { createDeal } = useDealV2();
    const { createActivity } = useActivity();

    // in case kvk is offline
    const [planB, setPlanB] = useState(false);

    const { vehicleDetail, calcDetail, customerDetail } = useSelector(state => state.deal);

    var storedCustomerDetail;

    if(Storage) {
        try {
            storedCustomerDetail = JSON.parse(sessionStorage.getItem("customerDetail"));
        } catch {};
    }

    const { register, formState: { errors }, watch, getValues, setFocus, setValue, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: {
            salutation: customerDetail?.salutation || storedCustomerDetail?.salutation || "",
            firstName: customerDetail?.firstName || storedCustomerDetail?.firstName || "",
            lastName: customerDetail?.lastName || storedCustomerDetail?.lastName || "",
            email: customerDetail?.email || storedCustomerDetail?.email || "",
            phone: customerDetail?.phone || storedCustomerDetail?.phone || "",
            address: customerDetail?.address || storedCustomerDetail?.address || "",
            place: customerDetail?.place || storedCustomerDetail?.place || "",
            postalCode: customerDetail?.postalCode || storedCustomerDetail?.postalCode || "",
            chamberNumber: customerDetail?.chamberNumber || storedCustomerDetail?.chamberNumber || "",
            companyName: customerDetail?.companyName || storedCustomerDetail?.companyName || "",
            financeAmount: storedCustomerDetail?.financeAmount || "0", // post to leaseAmount field
            agree: false
        }
    });
    
    const changeHandler = (fieldName, fieldValue, objName=false) => {
        var customer = JSON.stringify(getValues());
        if(Storage) sessionStorage.setItem("customerDetail", customer);
        if(objName) {
            dispatch(dealActions.setDateForCustomer({objName, fieldName, fieldValue}));
            return;
        }
        dispatch(dealActions.setCustomerData({[fieldName]: fieldValue}));
    }

    const [pageViewSent, setPageViewSent] = useState(false);
    const { sendPageView } = useAnalytics();
    const dispatch = useDispatch();

    useHead({title: "BTW-financiering"});

    const { dealer: dealerDetail } = useSelector(state => state.dealer);
    var { email: domainToExclude } = dealerDetail;
    if(domainToExclude) domainToExclude = getExludedEmailDomains(domainToExclude);
    

    const submitHandler = async (formData) => {
        if(!formData?.chamberNumber) return;

        sendPageView({category: "btwfinanciering", action: "Klik", label: "Versturen"});

        var deal = {
            calcDetail: calcDetail,
            vehicleDetail: vehicleDetail,
            customerDetail: {
                ...customerDetail,
                ...formData
            },
            dealerDetail: dealerDetail,
            financeAmount: formData?.financeAmount,
        };
        
        var resp = await createDeal(deal, "BtwFinanciering");
        if(resp.message==="Deal created") {
            var activityResp = await createActivity("Deal", "create", {dealID: resp.data_object.dealID, saleType: "BtwFinanciering"}, {...deal});
            if(activityResp?.message==="SUCCESS") {
                navigate("/financiering/btw-financiering/bedankpagina", { state: resp.data_object});
            }
        }
    }

    const kvkErrorHandler = (message) => {
        if(!message) {
            return;
        }
        if(!/Probeer/.test(message)) setPlanB(true);
    }

    const { financeAmount, kvkSearchQuery, name, chamberNumber, postalCode, address, place, email, phone, salutation, firstName, lastName, agree } = watch();

    useEffect(() => {
        if(financeAmount==="0" || pageViewSent) return;
        sendPageView({category: "btwfinanciering", action: "Gebruik slider", label: "Grootte van de financiering"});
        setPageViewSent(true);
    }, [financeAmount, sendPageView]);

    const kvkCompleteHandler = () => {
        if(customerDetail?.chamberNumber && (!customerDetail?.postalCode || !customerDetail?.address || !customerDetail?.place)) {
            setValue("name", customerDetail?.companyName ? customerDetail?.companyName : "");
            setPlanB(true);
        }
    }

    return (
        <>
            <Container type="fullscreen">
                <Content title="BTW financiering" description="" />
            </Container>
            <Container>
                <form onSubmit={handleSubmit(submitHandler)}>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
                    <div className="text-xl font-bold lg:col-span-3">Grootte van de financiering</div>
                    {/* <InputRange forwardOnChange={true} prefix={"\u20AC "} thousandNotation={true} label="Grootte van de financiering" inputValue={leaseAmount} min="0" step="1000" max="25000" name="n" /> */}
                    <InputRange3 name="financeAmount" register={register} fieldToValidate="financeAmount" label="Grootte van de financiering" prefix={"\u20AC "} thousandNotation={true} min="0" max="25000" step="1000" inputValue={financeAmount} errorMessage={errors?.financeAmount?.message} />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
                        <div className="text-xl font-bold lg:col-span-3">Bedrijfsnaam</div>
                        {!planB
                        ?
                        <InputKvk3 fieldToValidate="Bedrijfsnaam of KvK-nummer is een verplicht veld" onError={kvkErrorHandler} onComplete={kvkCompleteHandler} onBlur={changeHandler} setFocus={setFocus} className="lg:col-span-2" historyChamberNumber={getValues("chamberNumber")} register={register} errors={errors} getValues={getValues} setValue={setValue} inputValue={kvkSearchQuery} errorMessage={errors?.kvkSearchQuery?.message} />    
                        :
                        <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-3 gap-5">
                            <div className="border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] lg:col-span-3 text-sm px-3 py-2 w-fit rounded-xl">Bedrijfsgegevens kunnen momenteel niet (volledig) opgehaald worden bij de Kvk. Gelieve de gegevens handmatig in te voeren.</div>
                            <Input3 name="name" onBlur={changeHandler} register={register} fieldToValidate="Bedrijfsnaam is een verplicht veld" placeholder="Bedrijfsnaam" label="Bedrijfsnaam" inputValue={name} errorMessage={errors?.name?.message} />
                            <Input3 name="chamberNumber" onBlur={changeHandler} register={register} fieldToValidate="chamberNumber" placeholder="KvK-nummer" label="KvK-nummer" inputValue={chamberNumber} errorMessage={errors?.chamberNumber?.message} />
                            <Input3 name="postalCode" onBlur={changeHandler} register={register} fieldToValidate="postalCode" placeholder="Postcode" label="Postcode" inputValue={postalCode} errorMessage={errors?.postalCode?.message} />
                            <Input3 name="address" onBlur={changeHandler} register={register} fieldToValidate="Adres is een verplicht veld" placeholder="Adres" label="Adres" inputValue={address} errorMessage={errors?.address?.message} />
                            <Input3 name="place" onBlur={changeHandler} register={register} fieldToValidate="Plaats is een verplicht veld" placeholder="Plaats" label="Plaats" inputValue={place} errorMessage={errors?.place?.message} />
                        </div>
                        }

                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                        <div className="text-xl font-bold lg:col-span-3">Klantgegevens</div>
                        <Select3 onChange={changeHandler} name="salutation" inputValue={salutation} register={register} fieldToValidate="Aanhef is een verplicht veld" placeholder="Aanhef" label="Aanhef" errorMessage={errors?.salutation?.message}>
                            <option value="Dhr">Dhr</option>
                            <option value="Mevr">Mevr</option>
                        </Select3>
                        <Input3 name="firstName" onBlur={changeHandler} register={register} fieldToValidate="Voornaam is een verplicht veld" placeholder="Voornaam" label="Voornaam" inputValue={firstName} errorMessage={errors?.firstName?.message} />
                        <Input3 name="lastName" onBlur={changeHandler} register={register} fieldToValidate="Achternaam is een verplicht veld" placeholder="Achternaam" label="Achternaam" inputValue={lastName} errorMessage={errors?.lastName?.message} />
                        <Input3 name="email" onBlur={changeHandler} register={register} fieldToValidate="email" exclude={domainToExclude} placeholder="E-mailadres" label="E-mailadres" inputValue={email} errorMessage={errors?.email?.message} />
                        <Input3 name="phone" onBlur={changeHandler} register={register} fieldToValidate="phone" placeholder="Telefoonnummer" label="Telefoonnummer" inputValue={phone} errorMessage={errors?.phone?.message} />
                     </div>
                     <InputCheckbox3 onChange={changeHandler} name="agree" fieldToValidate="Akkoord is verplicht" inputValue={agree} register={register} label={["Ja, ik ga akkoord met de ", <object className="inline-flex"><a href="https://sld.nl/wp-content/themes/sld/assets/docs/av-sld-eindklant-def.pdf" target="_blank" rel="noreferrer" className="underline">algemene voorwaarden</a></object>]} errorMessage={errors?.agree?.message} />
                    <div className="flex flex-row justify-end mb-10">
                        <Button className="btn-primary" type="submit">Versturen</Button>
                    </div>
                </form>
            </Container>
            </>
    )
}

export default BtwFinanciering;