import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";

import useEmail from "hooks/email/use-email";
import Button from "components/ui/Button/Button";
import useDocument from "hooks/document/use-document";
import { getFriendlyDocName } from "components/Forms/ActivityForm/options";
import { useForm } from "react-hook-form";
import NewActivityForm2 from "components/Forms/ActivityForm/NewActivityForm2";
import { uiActions } from "store/ui-slice";
import useTrengo from "hooks/trengo/use-trengo";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import Modal from "components/hoc/Modal";
import TextArea3 from "components/ui/TextArea/TextArea3";
import useActivity from "hooks/activity/use-activity";

export const EmailComposer = ({sourceOfComponent, dealId, dealerId, customerId, deal, referenceId, emailTemplates, toWho, source }) => {
    const { activity, createActivity } = useActivity();
    
    const [progress, setProgress] = useState({
        step: 0,
        message: "",
        type: "success"
    })

  const categorizedItems = {};

  var defaultEmailId = "";
  var refIdOfStatusUpdate = 2;

  // remove Emailtemplate 'Statusupdate' from Website-aanvraag
  if(source==="Website-aanvraag") emailTemplates = emailTemplates.filter(item => item.referenceId!==refIdOfStatusUpdate);
  if(source==="Dealer-aanvraag" || sourceOfComponent==="dealer") defaultEmailId = emailTemplates.filter(item => item.referenceId===refIdOfStatusUpdate).map(item => item.emailId)[0] || "";

  if(sourceOfComponent==="dealer") emailTemplates = emailTemplates?.filter(item => item.category==="Direct_Dealer");

  // Group items by category, only with referenceId
  emailTemplates.filter(item => item.referenceId && item.isActive).forEach(item => {
    const category = item.category || 'Overige templates';
    if (!categorizedItems[category]) {
      categorizedItems[category] = [];
    }
    categorizedItems[category].push(item);
  });

  const sortedCategories = Object.keys(categorizedItems).sort((a, b) => {
    if (a === 'Overige templates') return 1; // "Overige templates" goes last
    if (b === 'Overige templates') return -1; // "Overige templates" goes last
    return a.localeCompare(b); // Sort other categories alphabetically
  });

    const [formBtnDisabled, setFormBtnDisabled] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(referenceId);
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);

    const dispatch = useDispatch();
    const [updateCounter, setUpdateCounter] = useState(0);

    const [trengoAttachIds, setTrengoAttachIds] = useState([]);

    const { register, formState: { errors }, setValue, reset, handleSubmit, clearErrors, watch, setError, setFocus } = useForm({
        mode: "onChange",
        defaultValues: {
            contact_id: "",
            emailId: defaultEmailId,
            email_referenceId: referenceId,
            emailTemplate: referenceId,
            ticket_id: "",
            channel_id: "",
            subject: "",
            attachment_ids: "",
            attachment_ids_trengo: trengoAttachIds,
            signature_id: "",
            signature_referenceId: "",
            to: "",
            toWho: toWho || "",
            description: ""
        }
    });
    const iframeRef = useRef();
    const signatureRef = useRef();

    const { email, getEmailByReferenceId } = useEmail("getEmailByReferenceId", selectedTemplate);

    useEffect(() => {
        setFocus("description");
    },[]);

    useEffect(() => {
        // if(selectedTemplate!==referenceId) {
            getEmailByReferenceId(selectedTemplate);
        // }
    }, [selectedTemplate, getEmailByReferenceId]);

    const { signature, signatures, getEmailSignatureById, sendEmail, clearSignatureFromState } = useEmail("getEmailSignatures");
    const { documents, getDocuments } = useDocument((sourceOfComponent==="deal" ? "getDocuments" : ""), dealId, false);

    const { createTicket, createDocument, documents: trengoDocuments } = useTrengo();

    const watchAll = watch();

    const { subject } = email;

    useEffect(() => {
        setValue("subject", mailVarsToValues(subject));
        if(!subject) return;
    }, [subject]);

    useEffect(() => {
        if(!updateCounter) return;
        if(dealId) getDocuments(dealId, false);
        dispatch(uiActions.showBackdrop());
    },[getDocuments, updateCounter]);
    
    const [iframeHeightTemplate, setIframeHeightTemplate] = useState("");
    const [iframeHeightSignature, setIframeHeightSignature] = useState("");

    const [selectedSignature, setSelectedSignature] = useState("");
    const [selectedAttachments, setSelectedAttachments] = useState([]);

    const onIframeLoad = (src) => {
       if(src==="template") {
        var contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight + 50;
        setIframeHeightTemplate(contentHeight);
       }
       if(src==="signature") {
        var contentHeight = signatureRef.current.contentWindow.document.body.scrollHeight + 50;
        setIframeHeightSignature(contentHeight);
    }
   }

   const getEmailIdFromReferenceId = (refId) => {
    var emailId = emailTemplates.filter(item => item.referenceId===+refId)?.map(item => item.emailId)[0] || "";;
    return emailId;
   }

   const toggleAttachmentHandler = (documentID, docName, documentURL) => {
    setSelectedAttachments(prevState => {
        var newValue = prevState.map(item => item.documentID).includes(documentID) ? prevState.filter(item => item.documentID!==documentID) : [...prevState, {documentID, docName, friendlyDocName: getFriendlyDocName(docName)[0], documentURL: documentURL}];
        if(newValue?.length) {
            var ids = newValue.map(item => item.documentID);
            setValue("attachment_ids", ids);
            clearErrors("attachment_ids");
        } else {
            setValue("attachment_ids", "");
            setValue("contact_id", "");
        }
        return newValue;
    });
   }

   const toggleSignatureHandler = (id, refId, from) => {
    getEmailSignatureById(id);
    setValue("channel_id", from);
    setSelectedSignature(prevState => {
        var newValue = prevState===refId ? "" : refId;
        setValue("signature_id", id);
        setValue("signature_referenceId", newValue);
        (newValue) ? clearErrors("signature_referenceId") : setError("signature_referenceId", { message: "Handtekening is verplicht"});
        return newValue;
    });
   }

   const submitHandler = async (formData) => {
        setFormBtnDisabled(true);
        var mailResp = "";
        var ticketResp = "";
        var activityId;

        // createActivity in case mailTemplates startWith Statusupdate
        if(parseInt(formData.email_referenceId)===refIdOfStatusUpdate) {
            setProgress(prevState => ({...prevState, step: 25, message: "Activiteit aanmaken", type: "success" }));
            var activityResp = await createActivity("Dealer update v2", "sent", { dealID: dealId, subject: formData?.subject}, formData?.description, null, false);
            if(activityResp.message==="SUCCESS") {
                activityId = activityResp?.data_object?.activityID;
            } else {
                setProgress(prevState => ({...prevState, message: "Fout bij het aanmaken van een activiteit", step: 0, type: "danger" }));
                return;
            }
        }

        // createTicket (only in case there are attachments)
        if(selectedAttachments?.length>0) {
            setProgress(prevState => ({...prevState, step: 25, message: "Ticket aanmaken in Trengo", type: "success" }));
            ticketResp = await createTicket({channel_id: formData?.channel_id, contact_id: formData?.contact_id, subject: formData?.subject});
            var documentResp = "";
    
            if(ticketResp==="FAIL") {
                setProgress(prevState => ({...prevState, message: "Fout bij het aanmaken van een ticket in Trengo", step: 0, type: "danger" }));
                return;
            }
            if(ticketResp?.data?.id) {
            // upload and create documents in trengo
                var trengoAttachments = [];

                const uploadDocuments = async () => {
                    try {
                        for(const attachment of selectedAttachments?.reverse()) {
                            var nameOfDoc = attachment.friendlyDocName || "Document";
                            setProgress(prevState => ({...prevState, message: nameOfDoc+" uploaden naar Trengo", step: prevState.step + 5 }));     
                            var individualDocResp = await createDocument(attachment.documentURL);
                            setTrengoAttachIds(prevState => [...prevState, individualDocResp.id]);
                            trengoAttachments.push(individualDocResp.id);
                            // console.log("upload succesfully");
                        }
                    } catch (err) {
                        // console.log("Fout bij uploaden document");
                        return "FAIL";
                    }
                    return "SUCCESS";
                }
        
                documentResp = await uploadDocuments();
        
                if(documentResp==="FAIL") setProgress(prevState => ({...prevState, message: "Fout bij het uploaden van document naar Trengo", step: 0, type: "danger"}));
                if(documentResp==="SUCCESS") {
                    setProgress(prevState => ({...prevState, message: "Mail aan het versturen", step: 75, type: "success" }));
                    // dealId, emailId, signatureId, attachmentIds
                    mailResp = await sendEmail(sourceOfComponent, watchAll.toWho, dealId, dealerId, customerId, activityId, formData.emailId, formData.signature_id, trengoAttachments, ticketResp?.data?.id);
                    if(mailResp==="FAIL") {
                        setProgress(prevState => ({...prevState, message: "Fout bij het versturen van mail via Trengo", step: 0, type: "danger" }));
                        setFormBtnDisabled(false);
                    }
                    if(mailResp==="SUCCESS") {
                        setProgress(prevState => ({ ...prevState, message: "Mail is succesvol verstuurd!", step: 100, type: "success" }));
                        resetFormHandler();
                    }
                }
            }
        } else {
            // sendMail (without attachments)
            // console.log("send mail without attachments!");
            mailResp = await sendEmail(sourceOfComponent, watchAll.toWho, dealId, dealerId, customerId, activityId, formData.emailId, formData.signature_id);
            if(mailResp==="SUCCESS") {
                setProgress(prevState => ({ ...prevState, message: "Mail is succesvol verstuurd!", step: 100, type: "success" }));
                resetFormHandler();
            }
        }
   }

   const changeHandler = (fieldName, fieldValue) => {
    if(fieldName==="emailTemplate") {
        setSelectedTemplate(fieldValue);
        setValue("email_referenceId", fieldValue);
        var emailId = getEmailIdFromReferenceId(fieldValue);
        setValue("emailId", ""+emailId);
        var toWho = emailTemplates?.filter(item => item.emailId===emailId).map(item => {
            if(item.toDealer) return "toDealer";
            if(item.toCustomer) return "toCustomer";
            if(item.toEmail) return "toEmail";
            return "toFail";
          })[0];
          setValue("toWho", toWho);
          var to = emailTemplates?.filter(item => item.emailId===emailId).map(item => item.to)[0];
          setValue("to", to);

    }
   }

   const mailVarsToValues = (inputHtml, description=null) => {
    if(!dealId && !dealerId) return;
    // }}.00 will replace .00 from FE
    var htmlWithValues = inputHtml?.replace(/}}\.00/g,"}}").replace(/{{((ActivitiesDeal|deal|vehicle|customer|dealer)?.?\w+)}}/g, (match, captureGroup) => {
        // The captured group is the number within the $1 placeholder
        if(captureGroup?.startsWith("dealer")) {
            var fieldName = captureGroup?.replace("dealer.", "");
            return deal?.dealerDetail[fieldName] || deal?.dealerDetail[fieldName?.toLowerCase()] || match;
        }
        if(captureGroup?.startsWith("deal")) {
            var fieldName = captureGroup?.replace("deal.", "");
            var result = deal[fieldName];
            if(!result && result!==0) result = deal[fieldName?.toLowerCase()] || match;
            return result;
        }
        if(captureGroup?.startsWith("vehicle")) {
            var fieldName = captureGroup?.replace("vehicle.", "");
            return deal?.vehicleDetail[fieldName] || deal?.vehicleDetail[fieldName?.toLowerCase()] || match;
        }
        if(captureGroup?.startsWith("customer")) {
            var fieldName = captureGroup?.replace("customer.", "");
            return deal?.customerDetail[fieldName] || deal?.customerDetail[fieldName?.toLowerCase()] || match;
        }
        if(captureGroup?.startsWith("ActivitiesDeal")) {
            var fieldName = captureGroup?.replace("ActivitiesDeal.description", description);
            return fieldName || match;
        }
        return deal[captureGroup] || match;
    });
    return htmlWithValues;
   }

   const resetFormHandler = () => {
        setTrengoAttachIds([]);
        setSelectedAttachments([]);
        setSelectedSignature("");
        setFormBtnDisabled(false);
        setValue("attachment_ids", "");
        setValue("contact_id", "");
        setValue("signature_id", "");
        setValue("signature_referenceId", "");
        setValue("description", "");
        clearSignatureFromState();
   }

    return (
        <>
        {!showAttachmentPopup &&
        <>
        {1>2 &&
        <table>
            <tbody>
                <tr><td>ToWho</td><td>{watchAll.toWho}</td></tr> 
                <tr><td>To</td><td>{watchAll.to}</td></tr>
                <tr><td>Deal ID</td><td>{dealId}</td></tr>
                <tr><td>Dealer ID</td><td>{dealerId}</td></tr>
                <tr><td>Customer ID</td><td>{customerId}</td></tr>
                <tr><td>Activity ID</td><td>{activity?.activityID}</td></tr>

                <tr><td>Channel ID</td><td>{watchAll.channel_id}</td></tr> 
                <tr><td>Subject</td><td>{watchAll.subject}</td></tr>
                <tr><td>Contact ID</td><td>{watchAll.contact_id}</td></tr>
                <tr><td>Email ID / refID</td><td>{watchAll.emailId} / {watchAll.email_referenceId}</td></tr>
                <tr><td>Signature ID / refID</td><td>{watchAll.signature_id} / {watchAll.signature_referenceId}</td></tr>
                <tr><td>Trengo Attachment IDs</td><td>{JSON.stringify(trengoAttachIds)}</td></tr>
                <tr><td>Attachment IDs</td><td>{JSON.stringify(watchAll.attachment_ids)}</td></tr>
            </tbody>
        </table>
        }
        <form onSubmit={handleSubmit(submitHandler)} className="w-screen container">
            <div className="grid grid-cols-4 gap-x-16">
                <div className="flex flex-col bg-slate-100 p-5">
                    <div className="flex flex-col">
                        <div className="font-semibold">Selecteer handtekening</div>
                        {signatures?.data?.map((item, index) => {
                            return (
                                <div key={item.templateName+index} onClick={() => toggleSignatureHandler(item.id, item.referenceId, item.from)} className={`flex flex-row gap-x-3 items-center select-none text-sm !min-w-full p-1 border-b border-slate-300 cursor-pointer  ${selectedSignature===item.referenceId ? "bg-green text-white": "hover:bg-slate-200"}`}>
                                    {selectedSignature===item.referenceId && <span className="fa !text-lg fa-circle-check text-[white]"></span>}
                                    <span>{item.templateName} {item.id} ({item.referenceId})</span>
                                </div>
                            )
                        })}
                       {errors?.signature_referenceId?.message && <span className="text-xs text-[red] xabsolute -bottom-4 left-2 whitespace-nowrap">{errors?.signature_referenceId?.message}</span>}

                    </div>
                    {sourceOfComponent==="deal" &&
                    <div className="flex flex-col mt-10">
                        <div className="font-semibold">Selecteer bijlage(s)</div>
                        {documents?.map((item, index) => {
                            return (
                                <div key={item.documentName+index} className={`flex flex-row gap-x-3 items-center select-none text-sm !min-w-full p-1 border-b border-slate-300 cursor-pointer  ${selectedAttachments?.map(item => item.documentID).includes(item.documentID) ? "bg-green text-white" : "hover:bg-slate-200"}`} onClick={() => toggleAttachmentHandler(item.documentID, item.documentName, item.documentURL)}>
                                    {selectedAttachments?.map(item => item.documentID).includes(item.documentID) && <span className="fa !text-lg fa fa-paperclip-vertical text-[white]"></span>}
                                    {/* fa-circle-check */}
                                    <span>{getFriendlyDocName(item.documentName)}</span>
                                </div>
                            )
                        })}
                        
                        <Button className="secondary flex flex-row gap-x-2 mt-3 text-sm" type="button" onClick={() => setShowAttachmentPopup(true)}><span className="fa fa-paperclip-vertical"></span><span>Bijlage</span></Button>

                        {/* {documents?.data?.map((item, index) => {
                            return (
                                <div key={item.templateName+index} onClick={() => getEmailSignatureById(item.id)} className="select-none text-sm !min-w-full p-3 border-b border-slate-300 cursor-pointer hover:bg-slate-200">{item.templateName}</div>
                            )
                        })} */}
                    </div>
                    }   
                </div>
                <div className="flex flex-col col-span-2">
                        <div className="flex flex-col gap-y-1">
                        {/* options={emailTemplates?.map(item => ({value: item.emailId, label: mailVarsToValues(item.subject)}))} */}
                        {selectedAttachments?.length > 0 && 
                        <Select3 name="contact_id" register={register} placeholder="Contact ID" label="Contact ID" inputValue={watchAll.contact_id} fieldToValidate="Contact ID is verplicht" errorMessage={errors?.contact_id?.message}>
                            <option value="449487007">uitbetalingen@hiltermannlease.nl</option>
                            <option value="449885754">contract.financieren@vwpfs.nl</option>   
                            <option value="452129992">sven@sld.nl</option>
                        </Select3>
                        }

                        <Select3 name="emailTemplate" onChange={changeHandler} placeholder="Mail Template" inputValue={watchAll.emailTemplate} label="Mail Template" register={register} fieldToValidate="Mail template is verplicht" errorMessage={errors?.emailTemplate?.message}>
                        {sortedCategories.map(category => (
                            <optgroup key={category} label={category}>
                            {categorizedItems[category].map(item => (
                                <option key={category+item.emailId} value={item.referenceId} data-id={item.emailId}>{item.templateName} {item.emailId}/{item.referenceId}</option>
                            ))}
                            </optgroup>
                        ))}
                        </Select3>

                        {/* <Select3 name="emailTemplate" onChange={changeHandler} placeholder="Mail Template" inputValue={watchAll.emailTemplate} label="Mail Template" register={register} fieldToValidate="Mail template is verplicht" errorMessage={errors?.emailTemplate?.message}>
                            <optgroup label="Klant templates">
                                {emailTemplates?.filter(item => item.toCustomer && item.referenceId).map((item,index) => (
                                    <option key={"customer"+item+index} value={item.referenceId} data-id={item.emailId}>{mailVarsToValues(item.subject)}</option>
                                ))}
                            </optgroup>
                            <optgroup label="Autobedrijf templates">
                                {emailTemplates?.filter(item => item.toDealer  && item.referenceId).map((item, index) => (
                                    <option key={"dealer"+item+index} value={item.referenceId} data-id={item.emailId}>{mailVarsToValues(item.subject)}</option>
                                ))}
                            </optgroup>
                            <optgroup label="Overige templates">
                                {emailTemplates?.filter(item => !item.toDealer && !item.toCustomer && item.referenceId).map((item, index) => (
                                    <option key={"rest"+item+index} value={item.referenceId} data-id={item.emailId}>{mailVarsToValues(item.subject)}</option>
                                ))}
                            </optgroup>
                        </Select3> */}

                        <Input3 name="signature_referenceId" type="hidden" register={register} inputValue={watchAll?.signature_referenceId} placeholder="Handtekening" label="Handtekening" fieldToValidate="Handtekening is verplicht" errorMessage={errors?.signature_referenceId?.message} />
                        <Input3 type="hidden" name="attachment_ids" register={register} inputValue={watchAll.attachment_ids} placeholder="Bijlage" label="Bijlage" />
                        
                        {(!email.toEmail && sourceOfComponent==="deal") &&
                        <Select3 disableFirst={true} name="toWho" register={register} inputValue={watchAll.toWho} placeholder="Aan" label="Aan" fieldToValidate="Ontvanger is verplicht" errorMessage={errors?.toWho?.message}>
                            {email?.toDealer && 
                            <optgroup label="Autobedrijf">
                                <option value="toDealer">{deal?.dealerDetail?.name} &lt;{deal?.dealerDetail?.email}&gt;</option>
                            </optgroup>
                            }
                            {email?.toCustomer && 
                            <optgroup label="Klant">
                                <option value="toCustomer">{deal?.customerDetail?.companyName} {deal?.customerDetail?.lastName} &lt;{deal?.customerDetail?.email}&gt;</option>
                            </optgroup>}
                        </Select3>
                        }
                        {watchAll.toWho==="toEmail" && (
                            <Input3 name="to" register={register} inputValue={watchAll.to} placeholder="Aan" label="Aan" fieldToValidate="email" errorMessage={errors?.to?.message} />
                            )}
                        <Input3 name="subject" register={register} inputValue={watchAll.subject} placeholder="Onderwerp" label="Onderwerp" fieldToValidate="Onderwerp is verplicht" errorMessage={errors?.subject?.message} />
                        {selectedTemplate==="2" && <TextArea3 name="description" register={register} label="Omschrijving" placeholder="Omschrijving" inputValue={watchAll.description} fieldToValidate="Omschrijving is een verplicht veld" errorMessage={errors?.description?.message}></TextArea3>}
                    </div>

                    <div className="flex flex-col border border-slate-300 rounded-xl mt-1">            
                        <iframe className="rounded-t-xl" ref={iframeRef} srcDoc={mailVarsToValues(email.htmlBody, watchAll.description)} width="100%" height={iframeHeightTemplate} onLoad={() => onIframeLoad("template")}></iframe>
                        <iframe className="rounded-b-xl" ref={signatureRef} srcDoc={signature.htmlBody} width="100%" height={iframeHeightSignature} onLoad={() => onIframeLoad("signature")}></iframe>
                    </div>
                </div>


                <div className="flex flex-col bg-slate-100 p-5 gap-y-10">
                    <Button className="bg-gradient select-none" disabled={formBtnDisabled}>Sturen</Button>
                    <div className="flex flex-col">
                        {(progress.step>0 || progress.type==="danger") && 
                        <div className="w-full bg-slate-300 rounded-full mb-1">
                            <div className="bg-[green] font-medium text-center p-1 leading-none rounded-full text-white select-none" style={{width: progress.step+"%"}}>{progress.step+"%"}</div>
                        </div>
                        }
                        {(progress.step>0 || progress.type==="danger") && 
                        <div className="flex flex-col text-sm">
                            <div><span className={`${progress?.type==="success" ? "" : "text-[red]"} select-none`}>{progress.message}</span></div>

                            {/* {progressStep===50 && <div><span>Document(en) uploaden naar Trengo</span></div>}
                            {progressStep===75 && <div><span>Mail aan het versturen</span></div>
                            {progressStep==100 && <div><span>Mail is succesvol verstuurd!</span></div>} */}
                            {/* <span className="text-[red]">Fout bij het versturen van de mail!</span> */}                            
                        </div>
                        }
                    </div>
                </div>
            </div>
        </form>
        </>
        }
        {showAttachmentPopup && 
        <>
         {createPortal(<>
            <Modal category="calc" title="Document uploaden" show={true} onClose={() => setShowAttachmentPopup(false)}>
                {/* <div className="fixed top-0 left-0 right-0 bg-black w-screen h-screen z-50"></div> */}
               <NewActivityForm2 inModal={true} hideTitle={true} id={dealId} widget={true} typeOfActivity="document" onUpdate={() => {setUpdateCounter(prevState => prevState + 1); setShowAttachmentPopup(false)}} />
               </Modal>
       </>, document.body)}
       </>}

            </>
    )
}

export default EmailComposer;