import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { 
    BtnBold,
    BtnItalic,
    BtnNumberedList,
    HtmlButton,
    Editor,
    EditorProvider,
    Toolbar
  } from 'react-simple-wysiwyg';

import Button from "components/ui/Button/Button";
import Input3 from "components/ui/Input/Input3";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";
import useUser from "hooks/user/use-user";
import Select3 from 'components/ui/Select/Select3';
import useChangeLog from 'hooks/changelog/use-changelog';
import { getDateTimeFromDate } from 'lib/helpers';
import { toyyyymmdd } from 'lib/calc';


const ChangeLog = () => {
    const [changeCounter, setChangeCounter] = useState(0);

    const [errorMessage, setErrorMessage] = useState("");

    const [activeTab, setActiveTab] = useState("sld");
    const tabClasses = "select-none cursor-pointer inline-block p-4 border-b-2 rounded-t-lg";
    const activeTabClasses = "font-semibold border-orange text-orange cursor-default";

    const { setChangeLog } = useUser();
    const { changeLog, changeLogs, getChangeLog, getChangeLogById, stateUpdateChangeLog, createChangeLog, updateChangeLog, stateClearChangeLog, deleteChangeLogById } = useChangeLog();
   
    const { register, watch, formState: { errors }, handleSubmit, reset, setValue, clearErrors } = useForm({
        mode: "onChange",
        defaultValues: {
            version: "",
            selectedVersion: "",
            deploymentDate: toyyyymmdd(new Date()).toString()+"T09:00" //localDate(new Date()).toString()
        }
    });

    const { register: register2, watch: watch2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm({
        mode: "onChange",
        defaultValues: {
            agree: false,
        }
    });


    const watchAll = watch();
    const watchAll2 = watch2();

    useEffect(() => {
        stateClearChangeLog();
        reset();
        clearErrors();
        getChangeLog(activeTab);
    }, [getChangeLog, activeTab, changeCounter]);

    const submitHandler = async (formData) => {
        var body = {
            "description": changeLog?.description,
            "version": formData?.version,
            "src": activeTab,
            "deploymentDate": formData?.deploymentDate
        }
        if(!changeLog?.changeLogId) {
            if(!changeLog?.description || changeLog?.description==="<br>") {
                setErrorMessage("Beschrijving is een verplicht veld");
                return;
            }
           var resp = await createChangeLog(body);
           if(resp==="SUCCESS") {
            stateClearChangeLog();
            reset();
            clearErrors();
            setChangeCounter(prevState => prevState + 1);
           }
        } else {
            updateChangeLog(changeLog?.changeLogId, body);
        }
    }

    const submitHandler2 = (data) => {
        setChangeLog(data);
        reset();
    }



    const changeHandler = async (fieldName, fieldValue) => {
        if(fieldName==="selectedVersion") {
            clearErrors();

            if(fieldValue) {
                const resp = await getChangeLogById(fieldValue);
                setValue("version", resp.version);
                setValue("deploymentDate", resp?.deploymentDate?.slice(0,16));
            }  else {
                stateClearChangeLog();
                reset();
            }

        }
    }

     return (
        <>
        <h1>Changelog</h1>
        <div className="mb-4 border-b border-gray-200">
            <div className=" flex flex-wrap -mb-px text-center">
                <span onClick={() => setActiveTab("sld")} className={`${tabClasses} ${activeTab==="sld" && activeTabClasses}`}>SLD</span>
                <span onClick={() => setActiveTab("actua")} className={`${tabClasses} ${activeTab==="actua" && activeTabClasses}`}>Actua</span>
            </div>
        </div>

        <div className="grid grid-cols-3 gap-12">
            <div className="col-span-2">
            <form onSubmit={handleSubmit(submitHandler)} className="flex flex-col gap-3 mt-5">
                <Select3 name="selectedVersion" inputValue={watchAll?.selectedVersion} label="Versie" placeholder="Selecteer versie om te wijzigen" onChange={changeHandler} register={register} options={changeLogs?.map(item => ({value: item.changeLogId, label: item.version+" - "+getDateTimeFromDate(item?.deploymentDate)}))}></Select3>
                <hr className="my-10" />
                <div className="grid grid-cols-2 justify-between gap-x-10">
                    <Input3 name="version" pattern="\d{1,3}.\d{1,3}.\d{1,3}" fieldToValidate="Versie (x.x.x) is een verplicht veld" register={register} inputValue={watchAll.version} label="Versie" placeholder="Versie (x.x.x)" errorMessage={errors?.version?.message} />
                    <Input3 type="datetime-local" name="deploymentDate" fieldToValidate="Datum is een verplicht veld" register={register} inputValue={watchAll.deploymentDate} label="Releasedatum" placeholder="Releasedatum" errorMessage={errors?.version?.message} />
                </div>               
                    <EditorProvider className="overflow-x-scroll">
                    <Editor value={changeLog?.description} onChange={e => {stateUpdateChangeLog(e.target.value); e.target.value && setErrorMessage("")}} className="max-w-[219px]">
        <Toolbar>
          <BtnBold />
          <BtnItalic /> 
          <BtnNumberedList />
          <HtmlButton />
        </Toolbar>
      </Editor>
    </EditorProvider>
    {errorMessage && <span className="text-xs text-[red] xabsolute -bottom-4 left-2 whitespace-nowrap">{errorMessage}</span>}

                    <div className="flex flex-row justify-between items-center">
                    {changeLog?.changeLogId ?
                        <details className="text-sm cursor-pointer ">
                            <summary>Verwijderen</summary>
                            <span className="text-[red]  hover:font-bold relative top-10" onClick={() => deleteChangeLogById(changeLog?.changeLogId)}>Bevestigen</span>
                        </details>
                    : <>&nbsp;</>}
                        <Button type="submit" className="bg-gradient">{changeLog?.changeLogId ? "Wijzigen" : "Toevoegen"}</Button>
                    </div>
                </form>
                
                            
            </div>
            <div>
                <h3 className="">Zichtbaarheid changelog</h3>
                <p>Pas de zichtbaarheid voor alle gebruikers aan.</p>
                <p>Dit kan zinvol zijn, wanneer de changelog is aangepast.</p>
                <form onSubmit={handleSubmit2(submitHandler2)} className="flex flex-col gap-3 mt-5">
                    <Select3 name="src" register={register2} fieldToValidate="Source is een verplicht veld" placeholder="Welke gebruikers?" label="Welke gebruikers?" inputValue={activeTab} errorMessage={errors2?.src?.message}>
                        <option value="SLD">SLD</option>
                        <option value="Actua">Actua</option>
                    </Select3>
                    <Select3 name="booleanValue" register={register2} fieldToValidate="Dit is een verplicht veld" placeholder="Wat wil je doen?" label="Wat wil je doen?" inputValue={watchAll2.booleanValue} errorMessage={errors2?.booleanValue?.message}>
                        <option value="true">Changelog tonen</option>
                        <option value="false">Changelog verbergen</option>
                    </Select3>
                    {watchAll2.booleanValue==="true" && <small className="text-[red]">Let op! Bij het aanpassen van de zichtbaarheid krijgen gebruikers die eerder de changelog hebben bevestigd deze toch opnieuw te zien!</small>}
                    <InputCheckbox3 name="agree" register={register2} fieldToValidate="Dit is een verplicht vinkje" inputValue={watchAll2.agree} label="Bevestigen" errorMessage={errors2?.agree?.message} />
                    <Button>Aanpassing doorvoeren</Button>
                </form>
                {activeTab==="Actua" && <small className="block mt-10">Voor Actua worden alleen bestaande gebruikers aangepast. Dat zijn gebruikers die eerder een deal hebben ingeschoten en dus bij ons geregistreerd zijn.</small>}

            </div>
        </div>



        </>
    )
}

export default ChangeLog;