import { createSlice } from "@reduxjs/toolkit";
import { localDateStrToDateObj, rdwDateStrToDateObj, removeStorageItem, transformToValidVehicleType } from "lib/helpers";

const initVehicleDetail = {
    age: "",
    carrosserie: "",
    engineCapacity: "",
    firstRegistrationDate: {
        day: "",
        month: "",
        year: ""
    },
    firstRegistrationDateStr: "",
    fuelType: "",
    grossBPM: "",
    image: "",
    licensePlate: "",
    listPrice: "",
    make: "",
    marginVAT: "",
    mileage: "",
    model: "",
    occasionNumber: 0,
    positionJudgement: "",
    restBPM: "",
    type: "",
    vehicleType: "",
    massEmpty: 0
}

const initCustomerDetail = {
    addition: "",
    address: "",
    age: "",
    chamberNumber: "",
    companyName: "",
    dateofBirth: "",
    dateofEsteb: "",
    driverLicenseNumber: "",
    email: "",
    firstName: "",
    iban: "",
    lastName: "",
    latitude: "",
    longitude: "",
    phone: "",
    place: "",
    postalCode: "",
    rechtsvorm: "",
    salutation: "",
    sbiCodes: "",
    type: ""
};

const initCalcDetail = {
    administrationCost: "",
    amountpurchase: "",
    amountpurchaseExclVAT: "",
    chosenBank: "",
    commissionAmountDealer: "",
    commissionAmountSLD: "",
    downpayment: "",
    duration: "",
    finalpayment: "",
    interest: "",
    leaseAmount: "",
    durationMax: "",
    monthlyamount: "",
    provisiePercentage: "",
    repayment: "",
    totalToPay: "",
    tradeInAmount: "",
    typeOfDeal: "",
    vat: "",
    kentekenInLossing: "",
    bankInLossing: "",
    contractInLossing: ""
};

const initialDealState = {
    changeCounter: 0,
    changed: false,
    dealId: null,
    pageNumber: 1,
    pageSize: "",
    deals: [],
    dealDetail: {},
    dealFilters: {},
    calcDetail: initCalcDetail,
    customerDetail: initCustomerDetail,
    dealerDetail: {
        address: "",
        buttonTextColor: "",
        chamberNumber: "",
        contactFirstName: "",
        contactLastName: "",
        dealerID: "",
        email: "",
        iban: "",
        logoImageUrl: "",
        name: "",
        phone: "",
        place: "",
        postalCode: "",
        primaryColor: "",
        telephone: "",
        vaTnumber: "",
        website: "",
    },
    vehicleDetail: initVehicleDetail
};


export const dealSlice = createSlice({
    name: 'deal',
    initialState: initialDealState,
    reducers: {
        clearDeal: () => initialDealState,
        extendCustomerWithKvK: (state, actions) => {
            state.customerDetail = {
                ...state.customerDetail,
                companyName: actions?.payload?.companyName || "",
                chamberNumber:  actions?.payload?.chamberNumber || "",
                address: actions?.payload?.address || "",
                sbiCodes: actions?.payload?.sbiCodes || "",
                latitude: actions?.payload?.latitude || "",
                longitude: actions?.payload?.longitude || "",
                rechtsvorm: actions?.payload?.rechtsvorm || "",
                dateofEsteb: actions?.payload?.dateofEsteb || "",
                age: actions?.payload?.age || "",
                place: actions?.payload?.place || "",
                postalCode: actions?.payload?.postalCode || "",
            }
        },
        clearCustomerKvK: (state, actions) => {
            state.customerDetail = {
                ...state.customerDetail,
                companyName: "",
                chamberNumber: "",
                address: "",
                sbiCodes: "",
                latitude: "",
                longitude: "",
                rechtsvorm: "",
                dateofEsteb: "",
                age: "",
                place: "",
                postalCode: ""
            }
        },
        setCalcDetail: (state, actions) => {
            state.calcDetail = {
                ...state.calcDetail,
                ...actions.payload
            }
        },
        setDealerDetail: (state, actions) => {
            state.dealerDetail = {...actions.payload};
        },
        setDealDetail(state, actions) {
            state.dealDetail = actions.payload;
        },
        setDateForCustomer: (state, actions) => {
            const { objName, fieldName, fieldValue } = actions.payload;
            state.customerDetail = {
                ...state.customerDetail,
                [objName]: {
                    ...state.customerDetail[objName],
                    [fieldName]: fieldValue
                }
            }
        },
        setCustomerData: (state, actions) => {
            state.customerDetail = {
                ...state.customerDetail,
                ...actions.payload
            }
        },
        setTypeOfDeal: (state, actions) => {
            state.calcDetail.typeOfDeal = actions.payload;
        },
        setDealFilters(state, actions) {
            state.dealFilters = actions.payload;
        },
        setDeals(state, actions) {
            state.deals = actions.payload.data;
        },
        archiveDeal(state, actions) {
            state.changeCounter = state.changeCounter+1;
        },
        updateCounter(state) {
            state.changeCounter = state.changeCounter + 1;
        },
        updateDeal(state, actions) {
            if(actions.payload.objectName) {
                const { objectName, dataName, dataValue } = actions.payload;
                if(objectName==="vehicleDetail") {
                    state.dealDetail.vehicleDetail = {
                        ...state.dealDetail.vehicleDetail,
                        [dataName]: dataValue
                    }
                }
            } else {
                state.dealDetail = {
                    ...state.dealDetail,
                    ...actions.payload
                };
            }          
            state.changeCounter = state.changeCounter+1;
        },
        storeDeal(state, actions) {
            state.changed = true;
            state.deals = [...state.deals, actions.payload];
        },
        removeDeal(state, actions) {
            state.deals = state.deals.filter(deal => deal.dealID!==actions.payload.dealID);
            state.changed = true;
        },
        setRestBPM: (state,actions) => {
            state.calcDetail.restBPM = actions.payload;
            state.vehicleDetail.restBPM = actions.payload;

            if(Storage) {
                sessionStorage.setItem("vehicleDetail", JSON.stringify(state.vehicleDetail));
                sessionStorage.setItem("calcDetail", JSON.stringify(state.calcDetail));
            }
        },
        setVehicleField(state, actions) {
            const { dataName, dataValue } = actions.payload;
            state.vehicleDetail = {
                ...state.vehicleDetail,
                [dataName]: dataValue
            }
        },
        setDateForVehicle: (state, actions) => {
            const { objName, fieldName, fieldValue } = actions.payload;
            state.vehicleDetail = {
                ...state.vehicleDetail,
                [objName]: {
                    ...state.vehicleDetail[objName],
                    [fieldName]: fieldValue
                }
            }
        },
        setVehicleDetailByRDW(state,actions) {
            var { duration, durationMax, age, kenteken, restBPM, grossBPM, datum_eerste_toelating_dt, brandstof, catalogusprijs, merk, handelsbenaming, voertuigsoort, tellerstandoordeel, inrichting, cilinderinhoud, massEmpty } = actions.payload;

            state.vehicleDetail = {
                ...state.vehicleDetail,
                ...(kenteken && {licensePlate: kenteken}),
                ...(grossBPM && {grossBPM: grossBPM}),
                ...(age && {age: age}),
                ...(restBPM && {restBPM: restBPM}),
                ...(brandstof && {fuelType: brandstof}),
                ...(catalogusprijs && {listPrice: catalogusprijs}),
                ...(merk && {make: merk}),
                ...(handelsbenaming && {model: handelsbenaming}),
                ...(voertuigsoort && {vehicleType: voertuigsoort}),
                ...(tellerstandoordeel && {positionJudgement: tellerstandoordeel}),
                ...(inrichting && {carrosserie: inrichting}),
                ...(cilinderinhoud && {engineCapacity: cilinderinhoud}),
                ...(massEmpty && {massEmpty: massEmpty}),
                ...(datum_eerste_toelating_dt && {
                    firstRegistrationDate: rdwDateStrToDateObj(datum_eerste_toelating_dt),
                    firstRegistrationDateStr: datum_eerste_toelating_dt
                }),
            }

            state.calcDetail = {
                ...state.calcDetail,
                ...(age && {age : age}),
                ...(duration && {duration : duration}),
                ...(durationMax && {durationMax : durationMax}),
                ...(restBPM && {restBPM : restBPM})
            }

            if(Storage) {
                sessionStorage.setItem("vehicleDetail", JSON.stringify(state.vehicleDetail));
                sessionStorage.setItem("calcDetail", JSON.stringify(state.calcDetail));
            }


            // setStorageItem("voertuigstate", state.voertuig);
        },
        setRepaymentDetail: (state, actions) => {
            state.calcDetail = {
                ...state.calcDetail,
                ...actions.payload
            }
        },  
        setVehicleDetailByInventory(state, actions) {
            var { amountpurchase, duration, durationMax, finalpayment, leaseAmount, amountpurchaseExclVAT, vat, typeOfDeal, restBPM, image, vehicleType, licensePlate, make, model, mileage, engineCapacity, fuelType, carrosserie, positionJudgement, firstRegistrationDate, age, listPrice, brutoBPM, type, occasionNumber, massEmpty } = actions.payload;

            state.vehicleDetail = {
                ...state.vehicleDetail,
                restBPM: restBPM || "",
                vehicleType: transformToValidVehicleType(vehicleType) || "",
                image: image || "",
                licensePlate: licensePlate || "",
                make: make || "",
                model: model || "",
                mileage: mileage || "",
                engineCapacity: engineCapacity || "",
                fuelType: fuelType || "",
                carrosserie: carrosserie || "",
                positionJudgement: positionJudgement || "",
                firstRegistrationDate: localDateStrToDateObj(firstRegistrationDate),
                age: age || "",
                marginVAT: 0,
                listPrice: listPrice || "",
                grossBPM: brutoBPM || "",
                type: type || "",
                occasionNumber: occasionNumber || 0,
                massEmpty: massEmpty ? massEmpty : 0
            }

            state.calcDetail = {
                amountpurchase: amountpurchase || "",
                amountpurchaseExclVAT: amountpurchaseExclVAT || "",
                vat: vat || "",
                typeOfDeal: typeOfDeal || "",
                restBPM: restBPM || "",
                duration: duration,
                durationMax: durationMax,
                leaseAmount: leaseAmount,
                finalpayment: finalpayment
            }
            if(Storage) {
                sessionStorage.setItem("vehicleDetail", JSON.stringify(state.vehicleDetail));
                sessionStorage.setItem("calcDetail", JSON.stringify(state.calcDetail));
            }
        }
    }
})

export const resetDeal = () => {
    return async (dispatch) => {
        try {
            await removeStorageItem(['calcDetail', 'vehicleDetail', 'customerDetail']);   
            dispatch(dealActions.clearDeal());
        } catch (err) {
            // consoleLog(err.message, true);
        }

    }
}

export const dealActions = dealSlice.actions;