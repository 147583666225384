import { useCallback } from "react";
import { formatThousandNotation, formattedPMT_v2 } from "lib/calc";
import { friendlyBankStatus } from "lib/voertuig";

const BankSelector = ({onClick, prefix, dealDetail, title, status, size}) => {

    const transformStatus = useCallback((status) => {
        if(!status || status==="!" || status==="?" || status==="v"|| status==="x") return status;
        var transformStatus = "";
        switch(true) {
            case /^(Aanvraag|PENDING)/i.test(status):
                transformStatus = "?";
                break;
            case /^(Bij|De door|Er is|Het door u|Het opgegeven|In de aanvraag|In de door|Op uw aanvraag|Op dit moment|Uw gebruikersn|ADDITIONAL_INFO_REQUIRED)/i.test(status):
                transformStatus = "!";
                break;
            case /^(De aanvraag.*geannu|Uw aanvraag is afgewezen|REJECTED|CANCELLED)/i.test(status):
                transformStatus = "x";
                break;
            case /^(De aanvraag.*uitbetaald|Uw aanvraag is|APPROVED|APPROVED_UNDER_CONDITIONS)/i.test(status):
                transformStatus = "v";
                break;
            default: 
                transformStatus = "";
        }
        return transformStatus;
    },[status]);

    var transformedStatus = transformStatus(status);

    const circleClasses = `text-white rounded-full items-center flex flex-row justify-center ${size==="xs" ? "text-sm w-6 h-6": "text-2xl w-10 h-10"}`;

    return (
        <div onClick={onClick} className={`${size==="xs" ? "py-0 gap-y-1" : "bg-white shadow-xl py-8 gap-y-3"} px-1 md:px-3 group relative rounded-xl w-full max-w-[220px] flex flex-col items-center cursor-pointer hover:scale-105 hover:z-20`}>
            <span className="text-sm text-[#1A2A52] font-semibold select-none">{title ? title : "Geen prop -> title"}</span>

            {!transformedStatus && <span className={`fa fa-question bg-sld-blue opacity-50 ${circleClasses}`}></span>}
            {transformedStatus==="v" && <span className={`fa fa-check bg-[green] ${circleClasses}`}></span>}
            {transformedStatus==="x" && <span className={`fa fa-xmark bg-[red] ${circleClasses}`}></span>}
            {transformedStatus==="?" && <span className={`fa fa-question bg-sld-blue ${circleClasses}`}></span>} 
            {transformedStatus==="!" && <span className={`fa fa-exclamation bg-[orange] ${circleClasses}`}></span>}
        	
            <div className={`${size==="xs" ? "bg-white text-black right-0" : "bg-slate-200 left-0"} rounded-xl shadow-xl hidden md:group-hover:block p-3 text-xs absolute bottom-full w-[400px] leading-none`}>
                <div className="lg:col-span-2 grid grid-cols-2 gap-y-1 mb-3 md:mb-0">
                    {dealDetail[prefix+'Status'] && <><div className="font-semibold">Status</div><div className="mb-3">{friendlyBankStatus(dealDetail[prefix+'Status'])}</div></>}
                    <div className="font-semibold">Aanschaf{/btw/i.test(dealDetail.typeOfDeal) && ' Incl.'}</div><div className="">&euro; {formatThousandNotation(dealDetail[prefix+'AmountPurchase'])}</div>
                    <div className="font-semibold">BTW</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail[prefix+'Vat']) : "0"}</div>
                    <div className="font-semibold">Aanschaf Excl.</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail[prefix+'AmountPurchaseExclVat']) : formatThousandNotation(dealDetail[prefix+'AmountPurchase'])}</div>
                    <div className="font-semibold">Aanbetaling</div><div>&euro; {formatThousandNotation(dealDetail[prefix+'DownPayment'])}</div>
                    <div className="font-semibold">Inruil</div><div>&euro; {dealDetail.tradeInAmount ? formatThousandNotation(dealDetail[prefix+'TradeInAmount']) : "0"}</div>
                    <div className="font-semibold">Inlossing</div><div>&euro; {dealDetail.repayment ? formatThousandNotation(dealDetail[prefix+'Repayment']) : "0"}</div>
                    <div className="font-semibold">Leasebedrag</div><div>&euro; {formatThousandNotation(dealDetail[prefix+'LeaseAmount'])}</div>
                    <div className="font-semibold">Slottermijn</div><div>&euro; {formatThousandNotation(dealDetail[prefix+'FinalPayment'])}</div>
                    <div className="font-semibold">Looptijd</div><div>{dealDetail[prefix+'Duration']} maanden</div>
                    <div className="font-semibold">Maandbedrag</div><div>&euro; {formattedPMT_v2(dealDetail[prefix+'MonthlyAmount'])}</div>
                    <div className="font-semibold">Rente</div><div>{dealDetail[prefix+'Interest']}%</div>
                    <div className="font-semibold">Provisiepercentage</div><div>{dealDetail[prefix+'ProvisiePercentage']}%</div>

                </div>
            </div>
        </div>
    )
}

export default BankSelector;