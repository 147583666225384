import { createSlice } from "@reduxjs/toolkit";
import { consoleLog } from "lib/helpers";
import { userLogin, userLogout, authLoginWithUserPwdCode } from "./auth-actions";

const initialState = {
    userIsLoggedIn: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    backToLogin: 0,
    username: "",
    extraCheck: false,
    requestPwd: false,
    userInfo: false,
    expiration: '',
    role: [],
    dealers: [],
    dealerDetail: {},
    userDetails: {}
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        register: (state, actions) => {
            consoleLog("register!");
        },
        set_dealers: (state, actions) => {
            state.dealers = actions.payload.dealers;
        },
        signin: (state, actions) => {
            const { token, expiration, role, dealer } = actions.payload;
            state.userInfo = dealer || {};
            state.token = token;
            state.expiration = expiration;
            state.role = role;
            state.userIsLoggedIn = true;
        },
        updateProfile: (state, actions) => {
            state.userDetails = actions.payload.data;
        },
        get_user_info: (state, actions) => {
            const { expiration, role, dealer } = actions.payload;
            state.expiration = expiration;
            state.role = role;
            state.userInfo = dealer || {};
            state.userIsLoggedIn = true;
            // state.userDetails = userDetails ? userDetails: {};
            state.userDetails = actions?.payload?.userDetails || {};
        },
        setUserDetails: (state, actions) => {
            state.userDetails = actions.payload.userDetails;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(authLoginWithUserPwdCode.rejected, (state, { payload }) => {
            // state.username = "";
            // state.requestPwd = false;
            // state.extraCheck = false;
            // state.backToLogin = state.backToLogin + 1;
        })
        .addCase(authLoginWithUserPwdCode.fulfilled, (state, { payload }) => {
            const { token, expiration, role } = payload;
            state.userInfo = payload.dealer || {};
            state.token = token;
            state.expiration = expiration;
            state.role = role;
            state.userIsLoggedIn = true;
        })
        .addCase(userLogin.pending, (state, { payload }) => {
        })
        .addCase(userLogin.fulfilled, (state, { payload }) => {
            // state.requestPwd = true;
            state.extraCheck = true;
        })
        .addCase(userLogin.rejected, (state, { payload} ) => {
            // console.log("REJECTED");
            // const { username } = payload;
            // state.requestPwd = false;
            // state.username = username;
            // state.userIsLoggedIn = false;
            // state.extraCheck = true;
        })
        .addCase(userLogout.pending, (state, { payload }) => {
        })
        .addCase(userLogout.fulfilled, (state, {payload}) => {
            state.token = null;
            state.username = "";
            state.extraCheck = false;
            state.requestPwd = false;
            state.userIsLoggedIn = false;
            state.role = [];
            state.applicationUser = "";
        })
    },
});

export const authActions = authSlice.actions;
