import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getSalutation } from "lib/voertuig";
import { resetDossier } from "store/dossier-actions";
import CalculatorV3 from "components/Calculator/Calculator_v3";

export const CrmPage = () => {
    const dispatch = useDispatch();
    const { firstName } = useSelector(state => state.auth.userDetails);
    const clearPreviousRequestHandler = () => {
        dispatch(resetDossier());
    }

    return (
        <div>
            <div className="text-xl lg:text-3xl mb-5 lg:mb-20">{getSalutation(new Date().getHours())}{firstName ? " "+firstName : ""}!</div>
            <div className="text-xl lg:text-3xl mb-5">Wil je een nieuwe aanvraag indienen?</div>
            <div className="grid lg:grid-cols-3 max-w-5xl mb-5 lg:mb-20 gap-3 lg:p-5 lg:gap-10">
                <NavLink onClick={clearPreviousRequestHandler} to="/financiering/financial-lease" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-full md:w-32 md:h-32 lg:w-64 lg:h-64 hover:shadow-2xl">Financial Lease aanvraag</NavLink>
                <NavLink onClick={clearPreviousRequestHandler} to="/verzekering" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-full md:w-32 md:h-32 lg:w-64 lg:h-64 hover:shadow-2xl">Verzekering aanvragen</NavLink>
                <NavLink onClick={clearPreviousRequestHandler} to="/financiering/btw-financiering" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-full md:w-32 md:h-32 lg:w-64 lg:h-64 hover:shadow-2xl">BTW-financiering aanvragen</NavLink>
            </div>
            <div className="text-xl lg:text-3xl mb-5">Snelle calculatie</div>
            {/* <Calculator2 disableTitle={true} /> */}
            <CalculatorV3 quickCalc={true} disableTitle={true} hidePrevNext={true} typeOfDeal="Marge" />

        </div>
    )
}

export default CrmPage;