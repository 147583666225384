import { useState, useEffect } from "react";
import { formatThousandNotation, intTo2DecStr, formattedPMT_v2 } from "lib/calc";
import TextArea from "components/ui/TextArea/TextArea";
import Button from "components/ui/Button/Button";
import { dateToDutch } from "lib/kvk";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import useActivities from "hooks/use-activities";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import { getDateTimeFromDate } from "lib/helpers";
import useActivity from "hooks/activity/use-activity";

const ActivitySummary2 = (props) => {
    const { createActivity } = useActivity();
    const dispatch = useDispatch();
    const { autoAssignedType, editable } = props;

    const { updateActivity } = useActivities();

    const [activity, setActivity] = useState(props.activity);
    const [activityData, setActivityData] = useState(activity.description);
    const [inlossingsDetails, setInlossingsDetails] = useState(false);

    useEffect(() => {
        if(!activityData) return;
        if(autoAssignedType) {
            try {
                var activityJson = JSON.parse(activity.description);
                setActivityData(activityJson);
                try {
                    if("inlossing_details" in activityJson && activityJson.inlossing_details) {
                        var inlossing = JSON.parse(activityJson.inlossing_details) || "";
                        setInlossingsDetails(inlossing);
                    }
                } catch (err) {
                    setInlossingsDetails(activityJson.inlossing_details);
                }

            } catch (err) {
                setActivityData(activity.description);
            }
        }
    },[]);

    const updateHandler = async (evt) => {
        evt.preventDefault();
        const formData = new FormData();

        switch(true) {
            case props.activityType==="dealerActivity":
                formData.append("dealerID", props.dealerID);
                formData.append("activityID", activity.activityID);
                // formData.append("createAt", new Date());
                formData.append("name", activity.name);
                formData.append("description", activity.description);
                formData.append("offer", "0");
                formData.append("documentURLForm", "");
                formData.append("type", activity.type);
                break;
            default: 
                formData.append("activityID", activity.activityID);
                formData.append("createAt", activity.createAt);
                formData.append("name", activity.name);
                formData.append("description", activity.description);
                formData.append("offer", "0");
                formData.append("documentURLForm", "");
                formData.append("dealID", activity.dealID);
                formData.append("type", activity.type);
        }

        const resp = await updateActivity(activity.activityID, formData, props.activityType);
        if(resp==="SUCCESS") {
            var dataToChange = {...activity};
            createActivity("Activity", "update", { dealID: activity.dealID}, dataToChange);
            props.onUpdate();
            props.onClose();
        }
    };

    return (
<>
{autoAssignedType ?
    <div className={`grid lg:grid-cols-4 grid-cols-2 text-xs leading-4 gap-x-1`}>
    <div>Dealer provisie</div><div>&euro; {intTo2DecStr(activityData.commissionAmountDealer)}</div>
    <div>SLD provisie</div><div>&euro; {intTo2DecStr(activityData.commissionAmountSLD)}</div> 
    <div>Aanschaf Incl.</div><div>&euro; {formatThousandNotation(activityData.amountpurchase)}</div>
    <div>BTW</div><div>&euro; {formatThousandNotation(activityData.vat)}</div>
    <div>Aanschaf Excl.</div><div>&euro; {formatThousandNotation(activityData.amountpurchaseExclVAT)}</div>
    <div>Aanbetaling</div><div>&euro; {formatThousandNotation(activityData.downpayment)}</div>
    <div>Inruil</div><div>&euro; {formatThousandNotation(activityData.tradeInAmount)}</div>
    <div>Te financieren</div><div>&euro; {formatThousandNotation(activityData.leaseAmount)}</div>
    <div>Slottermijn</div><div>&euro; {formatThousandNotation(activityData.finalpayment)}</div>
    <div>Maandbedrag</div><div>&euro; {formattedPMT_v2(activityData.monthlyamount)}</div>
    <div>Looptijd</div><div>{activityData.duration} maanden</div>
    <div>Rente</div><div>{activityData.interest}%</div>
    <div>Handelingsfee</div><div>&euro; {activityData.administrationCost===0 ? "0": intTo2DecStr(activityData.administrationCost)}</div>
    {inlossingsDetails && (
        <>
        <div className="col-span-4 text-lg my-3">Klant heeft lopend contract</div>
        <div>Kenteken</div><div>{inlossingsDetails.kenteken || ""}</div>
        <div>Contractnummer</div><div>{inlossingsDetails.contractnummer || ""}</div>
        <div>Bank</div><div>{inlossingsDetails.bank || ""}</div>
        <div>Inlossing openstaand saldo</div><div>&euro; {inlossingsDetails.saldo || ""}</div>
        </>
        )
    }
</div>
:
<form onSubmit={updateHandler} className="flex flex-col gap-3">
<div className="flex flex-row justify-between px-3">
    <div className="flex flex-row">
        <span className="mr-1 text-slate-500">Geregistreerd door:</span>
        <span>{activity.createdBy}</span>
    </div>
    <div className="flex flex-row">
        <span className="mr-1 text-slate-500">Datum:</span>
        <span>{activity.createdDate ? getDateTimeFromDate(activity.createdDate) : ""}</span>
    </div>
</div>

{!editable && (
<div className="flex flex-col justify-between px-3">
<div className="flex flex-row">
    <span className="mr-1 text-slate-500">Type:</span><span>{activity.type}</span>
</div>
<div className="flex flex-row">
    <span className="mr-1 text-slate-500">Onderwerp:</span><span>{activity.name}</span>
</div>
<div className="flex flex-row">
    <span className="mr-1 text-slate-500">Source:</span><span>{autoAssignedType && JSON.parse(activity.description).source}</span>
</div>
{activity?.type==="Dealer update" && (
<div className="flex flex-col mt-5">
    <span className="mr-1 text-slate-500">Bericht:</span>
    <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: activity.description}}></div>
</div>
)}
</div>
)}

{editable && (
<>
<Select value={activity.type} name="type" set="activityType" placeholder="Type" label="Type" onChange={evt => setActivity(prevState => ({...prevState, type: evt.target.value}))} />
<Input value={activity.name} name="name" placeholder="Onderwerp" onChange={evt => setActivity(prevState => ({...prevState, name: evt.target.value}))} />
</>
)}

{editable && (
<TextArea value={activity.description} name="description" placeholder="Omschrijving" onChange={evt => setActivity(prevState => ({...prevState, description: evt.target.value}))} />
)}

{editable &&
<span className="col-span-2">
<Button className="btn-secondary">Activiteit bijwerken</Button>
</span>
}
</form>
}
</>
)

}

export default ActivitySummary2;

