import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Container from "components/hoc/Container";
import Button from "components/ui/Button/Button";
import ProgressBar from "components/ProgressBar/ProgressBar";

import useActivity from "hooks/activity/use-activity";
import InputKvk3 from "components/ui/InputKvk/InputKvk3";
import Select3 from "components/ui/Select/Select3";
import Input3 from "components/ui/Input/Input3";
import DateSelects from "components/ui/DateSelects/DateSelects";
import { useForm } from "react-hook-form";
import useDealV2 from "hooks/deal/use-deal_v2";
import InputCheckbox3 from "components/ui/InputCheckbox/InputCheckbox3";
import { getExludedEmailDomains } from "lib/helpers";

const VerzekerenForm = () => {
    const { createDeal } = useDealV2();

    const { vehicleDetail, calcDetail, customerDetail } = useSelector(state => state.deal);
    const { dealer: dealerDetail } = useSelector(state => state.dealer);
    var { email: domainToExclude } = dealerDetail;
    if(domainToExclude) domainToExclude = getExludedEmailDomains(domainToExclude);

    var storedCustomerDetail;

    if(Storage) {
        try {
            storedCustomerDetail = JSON.parse(sessionStorage.getItem("customerDetail"));
        } catch {};
    }
    
    const { register, formState: { errors }, watch, handleSubmit, getValues, setValue, setFocus } = useForm({
            mode: "onChange",
            delayError: 750,
            defaultValues: {
                amountpurchase: calcDetail?.amountpurchase || storedCustomerDetail?.amountpurchase || "",
                companyName: customerDetail?.companyName || storedCustomerDetail?.companyName || "",
                chamberNumber: customerDetail?.chamberNumber || storedCustomerDetail?.chamberNumber || "",
                salutation: customerDetail?.salutation || storedCustomerDetail?.salutation || "",
                firstName: customerDetail?.firstName || storedCustomerDetail?.firstName || "",
                lastName: customerDetail?.lastName || storedCustomerDetail?.lastName || "",
                email: customerDetail?.email || storedCustomerDetail?.email || "",
                phone: customerDetail?.phone || storedCustomerDetail?.phone || "",
                dateofBirth: {
                    day: customerDetail?.dateofBirth?.day || storedCustomerDetail?.dateofBirth?.day || "",
                    month: customerDetail?.dateofBirth?.month || storedCustomerDetail?.dateofBirth?.month || "",
                    year: customerDetail?.dateofBirth?.year || storedCustomerDetail?.dateofBirth?.year || "",
                },
                driverLicenseNumber: customerDetail?.driverLicenseNumber || storedCustomerDetail?.driverLicenseNumber || "",
                iban: customerDetail?.iban || storedCustomerDetail?.iban || "",
                salesDescription: customerDetail?.salesDescription || storedCustomerDetail?.salesDescription || "",
                agree: customerDetail?.agree || storedCustomerDetail?.agree || false,
                requestedInsurance: true,
                damageHistoryInsurance: customerDetail?.damageHistoryInsurance || storedCustomerDetail?.damageHistoryInsurance || "",
                damageFreeYearsInsurance: customerDetail?.damageFreeYearsInsurance || storedCustomerDetail?.damageFreeYearsInsurance || "",
                kmPerYear: customerDetail?.kmPerYear || storedCustomerDetail?.kmPerYear || "",
                typeOfInsurance: customerDetail?.typeOfInsurance || storedCustomerDetail?.typeOfInsurance || "All Risk / Casco" || "",
        }
    });

    const { createActivity } = useActivity();

    const navigate = useNavigate();

    // in case kvk is offline
    const [planB, setPlanB] = useState(false);
    
    const submitHandler = async (formData) => {
        if(!formData?.chamberNumber) return;

        var deal = {
            calcDetail: calcDetail,
            vehicleDetail: vehicleDetail,
            customerDetail: {
                ...customerDetail,
                ...formData
            },
            dealerDetail: dealerDetail,
            amountpurchase: formData?.amountpurchase,
            damageHistoryInsurance: formData?.damageHistoryInsurance,
            damageFreeYearsInsurance: formData?.damageFreeYearsInsurance,
            kmPerYear: formData?.kmPerYear,
            typeOfInsurance: formData?.typeOfInsurance
        };

        var resp = await createDeal(deal, "Verzekering");
        if(resp.message==="Deal created") {
            createActivity("Deal", "create", {dealID: resp.data_object.dealID, saleType: "Verzekering"}, {...deal});
            navigate("/verzekering/bedankpagina", { state: resp.data_object});
        }
    }

    const changeHandler = (evt) => {
        var customer = JSON.stringify(getValues());
        if(Storage) sessionStorage.setItem("customerDetail", customer);
    }
    
    const kvkErrorHandler = (message) => {
        if(!message) {
            return;
        }
        if(!/Probeer/.test(message)) setPlanB(true);
    }

    const { kvkSearchQuery, name, chamberNumber, postalCode, address, place, email, phone, salutation, firstName, lastName, dateofBirth, amountpurchase, damageHistoryInsurance, damageFreeYearsInsurance, kmPerYear, typeOfInsurance, agree } = watch();

    const kvkCompleteHandler = () => {
        if(customerDetail?.chamberNumber && (!customerDetail?.postalCode || !customerDetail?.address || !customerDetail?.place)) {
            setValue("name", customerDetail?.companyName ? customerDetail?.companyName : "");
            setPlanB(true);
        }
    }
    
    return (
        <>
            <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
                <ProgressBar />
            </div>
            <Container>
            <form onSubmit={handleSubmit(submitHandler)}>           
            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-5 mb-5">
                    <div className="text-xl font-bold lg:col-span-3">Bedrijfsnaam</div>
                    {!planB
                        ?
                        <div className="col-span-3">
                            <InputKvk3 fieldToValidate="Bedrijfsnaam of KvK-nummer is een verplicht veld" onError={kvkErrorHandler} onComplete={kvkCompleteHandler} onBlur={changeHandler} setFocus={setFocus} historyChamberNumber={getValues("chamberNumber")} register={register} errors={errors} getValues={getValues} setValue={setValue} inputValue={kvkSearchQuery} errorMessage={errors?.kvkSearchQuery?.message} />    
                        </div>
                        :
                        <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-3 gap-5">
                            <div className="border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] lg:col-span-3 text-sm px-3 py-2 w-fit rounded-xl">Bedrijfsgegevens kunnen momenteel niet (volledig) opgehaald worden bij de Kvk. Gelieve de gegevens handmatig in te voeren.</div>
                            <Input3 name="name" onBlur={changeHandler} register={register} fieldToValidate="name" placeholder="Bedrijfsnaam" label="Bedrijfsnaam" inputValue={name} errorMessage={errors?.name?.message} />
                            <Input3 name="chamberNumber" onBlur={changeHandler} register={register} fieldToValidate="chamberNumber" placeholder="KvK-nummer" label="KvK-nummer" inputValue={chamberNumber} errorMessage={errors?.chamberNumber?.message} />
                            <Input3 name="postalCode" onBlur={changeHandler} register={register} fieldToValidate="postalCode" placeholder="Postcode" label="Postcode" inputValue={postalCode} errorMessage={errors?.postalCode?.message} />
                            <Input3 name="address" onBlur={changeHandler} register={register} fieldToValidate="address" placeholder="Adres" label="Adres" inputValue={address} errorMessage={errors?.address?.message} />
                            <Input3 name="place" onBlur={changeHandler} register={register} fieldToValidate="place" placeholder="Plaats" label="Plaats" inputValue={place} errorMessage={errors?.place?.message} />
                        </div>
                    }
                        <Input3 name="email" onBlur={changeHandler} register={register} fieldToValidate="email" exclude={domainToExclude} placeholder="E-mailadres" label="E-mailadres" inputValue={email} errorMessage={errors?.email?.message} />
                        <Input3 name="phone" onBlur={changeHandler} register={register} fieldToValidate="phone" placeholder="Telefoonnummer" label="Telefoonnummer" inputValue={phone} errorMessage={errors?.phone?.message} />
                </div>

                <div className="grid gap-5 mb-5">
                    <div className="text-xl font-bold lg:col-span-2">Klantgegevens</div>
                        <Select3 onChange={changeHandler} name="salutation" inputValue={salutation} register={register} fieldToValidate="Aanhef is een verplicht veld" placeholder="Aanhef" label="Aanhef" errorMessage={errors?.salutation?.message}>
                            <option value="Dhr">Dhr</option>
                            <option value="Mevr">Mevr</option>
                        </Select3>
                        <Input3 name="firstName" onBlur={changeHandler} register={register} fieldToValidate="Voornaam is een verplicht veld" placeholder="Voornaam" label="Voornaam" inputValue={firstName} errorMessage={errors?.firstName?.message} />
                        <Input3 name="lastName" onBlur={changeHandler} register={register} fieldToValidate="Achternaam is een verplicht veld" placeholder="Achternaam" label="Achternaam" inputValue={lastName} errorMessage={errors?.lastName?.message} />
                        <DateSelects maxYear={(new Date().getFullYear() - 18)} onChange={changeHandler} name="dateofBirth" register={register} getValues={getValues} errors={errors} fieldToValidate="Geboortedatum is een verplicht veld" placeholder="Geboortedatum" label="Geboortedatum" inputValue={dateofBirth} errorMessage={errors?.dateofBirth?.message} />                      
                    </div>
                
                <div className="grid gap-5">
                    <div className="text-xl font-bold lg:col-span-3">Verzekeringsgegevens</div>
                    <Input3 valuta="euro" name="amountpurchase" onBlur={changeHandler} register={register} fieldToValidate="Verkoopprijs incl. BTW is een verplicht veld" placeholder="Verkoopprijs incl. BTW" label="Verkoopprijs incl. BTW" inputValue={amountpurchase} errorMessage={errors?.amountpurchase?.message} />
                    <Input3 name="damageHistoryInsurance" onBlur={changeHandler} register={register} fieldToValidate="Schadeverleden afgelopen 5 jaar is een verplicht veld" placeholder="Schadeverleden (auto) afgelopen 5 jaar" label="Schadeverleden (auto) afgelopen 5 jaar" inputValue={damageHistoryInsurance} errorMessage={errors?.damageHistoryInsurance?.message} />
                    <Input3 name="damageFreeYearsInsurance" onBlur={changeHandler} register={register} fieldToValidate="Geregistreerde schadevrije jaren is een verplicht veld" placeholder="Aantal geregistreerde schadevrije jaren" label="Aantal geregistreerde schadevrije jaren" inputValue={damageFreeYearsInsurance} errorMessage={errors?.damageFreeYearsInsurance?.message} />
                    <Select3 set="kmperjaar" name="kmPerYear" onBlur={changeHandler} register={register} fieldToValidate="Kilometers per jaar is een verplicht veld" placeholder="Hoeveel kilometers per jaar" label="Hoeveel kilometers per jaar" inputValue={kmPerYear} errorMessage={errors?.kmPerYear?.message} />
                    <Select3 set="typeOfInsurance" name="typeOfInsurance" onBlur={changeHandler} inputValue={typeOfInsurance} register={register} fieldToValidate="Type verzekering is een verplicht veld" placeholder="Type verzekering" label="Type verzekering" errorMessage={errors?.typeOfInsurance?.message}></Select3>
                </div>

                 <div className="mb-5">
                    <Input3 name="requestedInsurance" type="hidden" register={register} />
                    <InputCheckbox3 onChange={changeHandler} name="agree" fieldToValidate="Akkoord is verplicht" inputValue={agree} register={register} label={["Ja, ik ga akkoord met de ", <object className="inline-flex"><a href="https://sld.nl/wp-content/themes/sld/assets/docs/av-sld-eindklant-def.pdf" target="_blank" rel="noreferrer" className="underline">algemene voorwaarden</a></object>]} errorMessage={errors?.agree?.message} />
                 </div>

                <div className="flex flex-row justify-between">
                    <Button type="button" secondary={true} onClick={() => navigate("/verzekering/btw-marge")} className="flex flex-row disabled:scale-100 hover:scale-105 items-center">
                        <div className="fa fa-chevron-left mr-2"></div><span>Vorige</span>
                    </Button>
                    <Button type="submit">Versturen</Button>
                 </div>
            </form>
        </Container>
        </>
    )
}

export default VerzekerenForm;