import { useEffect } from "react";
import { useSelector } from "react-redux";

import useHead from "hooks/use-head";
import Auth from "components/Auth/Auth";

export const PageHome = () => {  
    useHead({title: "Inloggen"});
    const auth = useSelector(state => state.auth);
    const { userIsLoggedIn, role } = auth;
    

    useEffect(() => {
        if(userIsLoggedIn && role && (role.includes("Admin") || role.includes("SuperAdmin")) ) {
            // navigate("crm");
        } else if(userIsLoggedIn) {
            // navigate("financiering/financial-lease");
        } else {}
    },[auth, role, userIsLoggedIn]);
  
    return (
        <>
            <Auth case="login" />
        </>
    )
}

export default PageHome;