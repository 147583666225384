import { validateFormField } from "lib/validation";
import { getSelectOptions } from "lib/voertuig";

const Select3 = ({ objKey, children, options, set, register, name, inputValue, fieldToValidate, required, placeholder, placeholdersm, errorMessage, disableFirst, selectClasses, onChange }) => {
    var focussedOrWithValue = !!inputValue;
    var options = options || null;
    if(set) options = getSelectOptions(set);

    return (
        <div className="flex flex-col relative mb-3">
            <select {...register(objKey ? objKey+"."+name : name, { onChange: (e) => onChange ? onChange(name, e.target.value, objKey) : null, validate: (value) => validateFormField(fieldToValidate, value, required) })} className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                ${selectClasses ? selectClasses : ""}
                `}>
                {!disableFirst && <option value=""></option>}
                {(options?.length) && options.map(option => {

                return <option data-key={option.value ? option.value : option.label} key={option.value ? option.value : option.label} data-extra={option.extra && option.extra} value={option.value}>{option.label ? option.label : option.value}</option>
                })}
                {children}
            </select>
            {placeholder && <label className={`
            ease-in-out
            duration-200
            transition-all
            peer-focus:text-slate-600
            peer-focus:text-xs
            peer-focus:top-1
            ${focussedOrWithValue ? "top-1 text-xs" : "top-3"}
            select-none absolute left-3 text-slate-500 pointer-events-none
            `}>{placeholder}</label>}
            {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-2">{errorMessage}</span>}

        </div>
    )
}

export default Select3;