import { useForm } from "react-hook-form";

import { usePartner } from "hooks/partner/use-partner";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import { useParams } from "react-router-dom";
import { Input3 } from "components/ui/FormFields/FormFields";

const PartnerForm = () => {
  const { createPartner, getPartnerById, updatePartner, archivePartner, deArchivePartner, deletePartner } = usePartner();
  const { partnerID } = useParams();

  const { watch, register, formState: { errors }, handleSubmit } = useForm({
    mode: "onChange",
    delayError: 750,
    defaultValues: async () => {
      var respData;
      if(partnerID) respData = await getPartnerById(partnerID);
      return {
      ...(partnerID && {"partnerID": partnerID}),
      "isArchive": respData?.isArchive || false,
      "name": respData?.name || "",
      "address": respData?.address || "",
      "place": respData?.place || "",
      "postalCode": respData?.postalCode || "",
      "website": respData?.website || "",
      "kvkNumber": respData?.kvkNumber || "",
      "ibanNumber": respData?.ibanNumber || "",
      "phoneNumber1": respData?.phoneNumber1 || "",
      "phoneNumber2": respData?.phoneNumber2 || "",
      "phoneNumber3": respData?.phoneNumber3 || "",
      "eMailAddress1": respData?.eMailAddress1 || "",
      "eMailAddress2": respData?.eMailAddress2 || "",
      "eMailAddress3": respData?.eMailAddress3 || "",
    }
    }
  });

  const submitHandler = (formValues) => {
    partnerID ? updatePartner(formValues) : createPartner(formValues);     
  };

  const { isArchive, name,address,postalCode,kvkNumber,ibanNumber,phoneNumber1,phoneNumber2,phoneNumber3,eMailAddress1,eMailAddress2,eMailAddress3 } = watch();

  return (
        <form onSubmit={handleSubmit(submitHandler)}>
          <Card title={"Partner aanmaken"}>
          <div className="bg-white grid grid-cols-3 items-start group">
          <div className="grid col-span-2  p-5">
          <h3>Contactgegevens partner</h3>
            <div className="grid grid-cols-2 gap-3">
                <Input3 register={register} inputValue={name} fieldToValidate="Naam is een verplicht veld" name="name" placeholder="Naam" errorMessage={errors?.name?.message} />
                <Input3 register={register} inputValue={address} name="address" placeholder="Adres" errorMessage={errors?.address?.message} />
                <Input3 register={register} inputValue={postalCode} fieldToValidate="postalCode" required={false} name="postalCode" placeholder="Postcode" errorMessage={errors?.postalCode?.message} />
                <Input3 register={register} inputValue={kvkNumber} fieldToValidate="chamberNumber" required={false} name="kvkNumber" placeholder="KvK Nummer" errorMessage={errors?.kvkNumber?.message} />
                <Input3 register={register} inputValue={ibanNumber} fieldToValidate="iban" required={false} name="iban" upperCase={true} placeholder="IBAN" errorMessage={errors?.iban?.message} />
                <Input3 register={register} inputValue={phoneNumber1} fieldToValidate="phone" required={false} name="phoneNumber1" placeholder="Telefoon 1" errorMessage={errors?.phoneNumber1?.message} />
                <Input3 register={register} inputValue={phoneNumber2} fieldToValidate="phone" required={false} name="phoneNumber2" placeholder="Telefoon 2" errorMessage={errors?.phoneNumber2?.message} />
                <Input3 register={register} inputValue={phoneNumber3} fieldToValidate="phone" required={false} name="phoneNumber3" placeholder="Telefoon 3" errorMessage={errors?.phoneNumber3?.message} />
                <Input3 register={register} inputValue={eMailAddress1} fieldToValidate="email" required={false} name="eMailAddress1" placeholder="E-mailadres 1" errorMessage={errors?.eMailAddress1?.message} />
                <Input3 register={register} inputValue={eMailAddress2} fieldToValidate="email" required={false} name="eMailAddress2" placeholder="E-mailadres 2" errorMessage={errors?.eMailAddress2?.message} />
                <Input3 register={register} inputValue={eMailAddress3} fieldToValidate="email" required={false} name="eMailAddress3" placeholder="E-mailadres 3" errorMessage={errors?.eMailAddress3?.message} />
        </div>
        </div>
        <div className="flex flex-col gap-5 p-5 bg-slate-50 h-full justify-between">
                {partnerID
                ?
                <div className="flex flex-col h-full justify-between">
                  <Button className="bg-gradient">Opslaan</Button>
                  <div className="flex flex-row justify-between">
                  {!isArchive && <Button type="button" className="btn-secondary" onClick={() => archivePartner(partnerID)}>Archiveren</Button>}
                  {isArchive && <Button type="button" className="btn-secondary" onClick={() => deArchivePartner(partnerID)}>Herstellen uit archief</Button>}
                  <Button type="button" className="!bg-[red]" onClick={() => deletePartner(partnerID)}>Verwijderen</Button>
                  </div>
                </div>
                :
                <Button type="submit" className="bg-gradient">Registreren</Button>
                }

              </div>
        </div>
        </Card>
      </form>
    )
}

export default PartnerForm;
