import { useState, useEffect } from "react";
import DealItem from "./DealItem";
import useDealV2 from "hooks/deal/use-deal_v2";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import Button from "components/ui/Button/Button";
import Search from "./Search";

const DealList = (props) => {
    const [searchParams] = useSearchParams();
    const [isArchive, setIsArchive] = useState(searchParams.get("isArchive")==="true" ? true : false);
    var { deals, getDeals } = useDealV2();
    const [params, setParams] = useState("isArchive="+isArchive);
    
    useEffect(() => {
        getDeals(params);
    }, [getDeals, isArchive, params]);

    if(!deals?.data) return <>Verkopen worden ingeladen...</>;
   
    return (
        <>
        <div className="container mx-auto max-w-screen-xl">
            <Search archive={isArchive} onUpdateDeals={p => setParams(p)} />
            {deals?.data?.map(deal => {
                return <DealItem key={deal.dealID} deal={deal} />
            })}
        </div>
        </>
    )
}

export default DealList;