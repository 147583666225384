import Button from "components/ui/Button/Button";
import { calcRealCommissionPerc } from "./helpers";
import useAuth from "hooks/use-auth";

export const EarlierCalculated = ({ currentCalculation, calculatorGoal, typeOfDeal, commissionAmountDealer, leaseAmount, reAssessment, calcFeedback, onUpdateFinance, onClose }) => {
    const { role } = useAuth();
    
    return (
        <>
        {(currentCalculation && !calculatorGoal)
            &&
            <div className="h-full flex flex-col flex-end justify-between items-end">
                <details className="flex flex-col bg-slate-100 p-5 text-sm w-full">
                    <summary className="font-semibold cursor-pointer">Eerdere berekening</summary>
                    {Object.keys(currentCalculation).map((item, index) => {
                        if(/marge/i.test(typeOfDeal) && /(btw|excl)/i.test(item)) return;
                        // if(!role.includes("Admin") && /(typeOfDeal|provisiepercentage)/.test(item)) return;
                        let value = currentCalculation[item];
                        if(item==="Provisiepercentage") value = calcRealCommissionPerc(commissionAmountDealer, leaseAmount)+"%";

                        return (
                            <div key={'key'+index} className="flex flex-row justify-between">
                                <div className="">{item}</div>
                                <div className="">{value}</div>
                            </div>
                        )
                    })}
                    <Button onClick={onClose}>Deze berekening behouden</Button> 
                </details>
                <div className="flex flex-col gap-5 mt-5">
                    {(reAssessment && calcFeedback.formIsValid) && <Button onClick={() => onUpdateFinance(true)} className="btn-primary hover:scale-105 !bg-[red] !text-white">Herbeoordeling aanvragen</Button>}
                    {/* Admin can change in case reassessment */}
                    {((!reAssessment || role.includes("Admin")) && calcFeedback.formIsValid) && <Button onClick={() => onUpdateFinance()} className="!bg-[green] !text-white btn-primary hover:scale-105">Financiering aanpassen</Button>}           
                </div>
            </div>
        }</>
    )
}

export default EarlierCalculated;