import { validateFormField } from "lib/validation";

const InputCheckbox3 = ({register, name, type, inputValue, fieldToValidate, required, label, placeholdersm, errorMessage, onChange, infoText}) => {
    var focussedOrWithValue = !!inputValue;

    return (
        <>
        <div className={`flex flex-row gap-x-2 relative mb-3 items-baseline`}>
            <input id={`checkbox-${name}`} {...register(name, { onChange: (e) => onChange ? onChange(name, e.target.checked) : null, validate: (value) => validateFormField(fieldToValidate, value, required) })} className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                `}
                type="checkbox"
                spellCheck={false}
                autoComplete="off"
                />
            <label htmlFor={`checkbox-${name}`} className="select-none">{label}</label>
            {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-5">{errorMessage}</span>}
            {infoText && 
            <div>
            <span className="peer fa fa-square-info text-md cursor-pointer px-5 py-2]"></span>
            <div className="peer-hover:block hidden shadow-xl border border-slate-500 w-3/4 p-3 absolute bottom-14 right-0 bg-slate-100 rounded-xl select-none">{infoText}</div>
            </div>}
        </div>
        </>
    )
}

export default InputCheckbox3;