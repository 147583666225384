import { configureStore } from "@reduxjs/toolkit";
import { uiSlice } from "./ui-slice";
import { authSlice } from "./auth-slice";
import { dossierSlice } from "./dossier-slice";
import { dealerSlice } from "./dealer-slice";
import { occSlice } from "./occ-slice";
import { progressSlice } from "./progress-slice";
import { dealSlice } from "./deal-slice";
import { customerSlice } from "./customer-slice";
import { counterSlice } from "./counter-slice";
import { vehicleSlice } from "./vehicle-slice";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        auth: authSlice.reducer,
        dossier: dossierSlice.reducer,
        dealer: dealerSlice.reducer,
        occ: occSlice.reducer,
        progress: progressSlice.reducer,
        deal: dealSlice.reducer,
        vehicle: vehicleSlice.reducer,
        customer: customerSlice.reducer,
        counter: counterSlice.reducer
    }
});

export default store;