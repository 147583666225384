import { createSlice } from "@reduxjs/toolkit";
export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: {
        pageNumber: 1,
        totalRecords: "",
        vehicle: {},
        list: []
    },
    reducers: {
        setVehicles(state, actions) {
            state.list = actions.payload.data;
            state.totalRecords = actions.payload.totalRecords;
            state.pageNumber = actions.payload.pageNumber;
        },
        setSingleVehicle(state, actions) {
            state.vehicle = actions.payload;
        },
        setVehicleData(state, actions) {
            state.vehicle = {
                ...state.vehicle,
                ...actions.payload
            };
        },
        removeVehicle(state, actions) {
            state.list = state.list.filter(item => item.vehicleID !== actions.payload.id);
        }
    }
});
export const vehicleActions = vehicleSlice.actions;
