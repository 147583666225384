import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { dealActions } from "store";

// hook for sending sessionStorage to state
export const useSession = () => {
    const dispatch = useDispatch();

    const tryToSetStorageToStore = useCallback((itemName) => {
        if(Storage) {
            var storageFieldDetail;
            try {
                storageFieldDetail = JSON.parse(sessionStorage.getItem(itemName));
                switch(itemName) {
                    // case "vehicleDetail": dispatch(dealActions.setVehicleData(storageFieldDetail)); break;
                    // case "calcDetail": dispatch(dealActions.setCalcDetail(storageFieldDetail)); break;
                    // case "repaymentDetail": dispatch(dealActions.setRepaymentDetail(storageFieldDetail)); break;
                    // case "customerDetail": dispatch(dealActions.setCustomerData(storageFieldDetail)); break;
                    default: break;
                }
            } catch (err) {
                return null;
            }
            return storageFieldDetail;
        }
    },[]);

    useEffect(() => {
        tryToSetStorageToStore('vehicleDetail');
        tryToSetStorageToStore('calcDetail');
        tryToSetStorageToStore('repaymentDetail');
        tryToSetStorageToStore('customerDetail');
    }, [tryToSetStorageToStore]);
}

export default useSession;
