import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { getUserName } from "lib/helpers";
// import { updateDeal } from "store/deal-actions";

import useAuth from "hooks/use-auth";
import useUser from "hooks/use-user";
import useDealV2 from "hooks/deal/use-deal_v2";
import useActivity from "hooks/activity/use-activity";

const AssignUser = ({ dealDetail, onUpdate }) => {
    const { createActivity } = useActivity();
    const { patchDeal } = useDealV2();

    const userSelectorRef = useRef(null);
    const { getUser, adminList, isRequested: adminListRequested } = useUser();

    const [assignedUser, setAssignedUser] = useState(dealDetail.adminDetails);
    const [showUserList, setShowUserList] = useState(false);
  
    const handleClickOutside = (event) => {
        if (userSelectorRef.current && !userSelectorRef.current.contains(event.target)) {
            setShowUserList(false);
        }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);
  
    const assignUserHandler = async (admin) => {
      setAssignedUser(admin);
      var userId = admin?.userrId || null;
      var dataToChange = {userId: userId};
      var resp = await patchDeal(dealDetail.dealID, dataToChange);
      if(resp==="SUCCESS") {
        var activityResp = await createActivity("Deal", "assign", {...dealDetail, ...admin}, dataToChange);
        if(activityResp?.message==="SUCCESS") {
          onUpdate();
        }

      }
      setShowUserList(false);
    }
  
    const showUserListHandler = () => {
      setShowUserList(true);
      if(adminListRequested) return;
      getUser('admin');
    }

    return (
        <div ref={userSelectorRef} className="absolute right-0 top-0 p-5 cursor-pointer scrollbar-vertical">
        <div className="flex flex-col rounded-md">
          <div className="flex flex-row justify-end gap-x-3" onClick={showUserListHandler}>
            <div className="flex flex-row items-center gap-x-2 hover:outline outline-offset-2 outline-[#212853] rounded-md">
              {(assignedUser?.userrId || assignedUser?.id)
              ?
              <>{assignedUser.profileImage && <img src={assignedUser.profileImage} className="rounded-full w-6 h-6" />}<span className="">{getUserName(assignedUser)}</span></>
              :
              <><span className="fa fa-user bg-slate-300 rounded-full w-6 h-6 text-center flex items-center justify-center"></span><span>Niet toegewezen</span></>}
            </div>
            <span className="hidden"></span>
          </div>
          {showUserList &&
          <div className="flex flex-col z-10 bg-slate-100 text-sm max-h-64 overflow-y-auto leading-relaxed rounded-bl-xl shadow-xl">
            <div onClick={() => assignUserHandler(null)} className="hover:bg-slate-200 border-b border-solid border-b-slate-400 p-2">Niet toegewezen</div>
            {adminList.map(admin => {
              return <div key={"user"+admin?.userrId} onClick={() => assignUserHandler(admin)} className="hover:bg-slate-200 border-b border-solid border-b-slate-400 p-2">{getUserName(admin)}</div>
            })} 
          </div>
          }
        </div>
      </div>
    )
}

export default AssignUser;