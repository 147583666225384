import Table, { Thead, Th, Td, Tr } from "components/ui/Table/Table";
import useUser from "hooks/user/use-user";

const GebruikerOverzicht = () => {
    const { users } = useUser("getUsers");

    return (
        <div className="max-w-6xl">
            {users.length ?
                <Table>
                    <Thead>
                        <Tr thead="true">
                        <Th>ID</Th>
                        <Th>Gebruikersnaam</Th>
                        <Th>E-mailadres</Th>
                        <Th>Rollen</Th>
                        <Th>Actie</Th>
                        </Tr>
                    </Thead>
                    <tbody>
                        {users.map((user) => {
                            return (<Tr key={user.userrId} className="cursor-pointer">
                                <Td>{user.userrId}</Td>
                                <Td>{user.userName}</Td>
                                <Td>{user.email}</Td>
                                <Td>{user.role}</Td>
                                <Td>
                                    {/* <NavLink to={""+user.userrId} className="fa fa-pencil hover:text-black"></NavLink> */}
                                    </Td>
                            </Tr>)
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                        </tr>
                    </tfoot>
                </Table>
                :
                <p>Er zijn nog geen gebruikers</p>
            }
        </div>
    )
}

export default GebruikerOverzicht;