import { useState } from "react";
import { localDate } from "lib/calc";

const TaskDate = ({todo, id, onDeadlineChange}) => {
    const [dueDate, setDueDate] = useState(todo.dueDate);

    const dueDateChangeHandler = (id, date) => {
        setDueDate(date);      
        onDeadlineChange(id, date);
    }

    return (
        <>datum hier..</>
        // <DatePicker
        // className="max-w-full"
        // value={localDate(dueDate)}
        // autoComplete="off"
        // locale="nl"
        // dateFormat="dd-MM-yyyy"
        // onChange={(date) => dueDateChangeHandler(id, date)}
        // />
    )
}

export default TaskDate;