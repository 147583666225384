import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useHuisstijl from "hooks/use-huisstijl";
import { formatThousandNotation } from "lib/calc";
import Input from "../Input/Input";
import useAuth from "hooks/use-auth";

const StyledInputRange = styled.input`
height: 34px;
-webkit-appearance: none;
width: 100%;
background-color: transparent;
&:focus {
    outline: none;
}
&::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    border-radius: 1px;
    border: 0px solid #010101;
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'}
}
&::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}

&:focus::-webkit-slider-runnable-track {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'}
}
&::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
    border-radius: 1px;
    border: 0px solid #010101;
}
&::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    /* background: #FFFFFF; */
    cursor: pointer;
}
&::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
&::-ms-fill-lower,
&::-ms-fill-upper {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
}
&::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    cursor: pointer;
}
&:focus::-ms-fill-lower,
&:focus::-ms-fill-upper {
    background-color: ${props => props.$primaryColor ? props.$primaryColor : 'black'};
}
`;

const InputRange = React.forwardRef((props, ref) => {
    const { role } = useAuth();
    const { primaryColor } = useHuisstijl();

    const [init, setInit] = useState(true);
    const [manualInput, setManualInput] = useState(false || (props?.value<12 || props?.value>72 || props?.value%props?.step!==0));
    const [inputValue, setInputValue] = useState(props?.value);

    useEffect(() => {
        if(init) return;
        props.onChange({target: { name: props.name, value: inputValue}});
    }, [inputValue]);

    useEffect(() => {
        setInit(false);
    }, []);

    return (
        <>
        
            {role?.includes("Admin") && props?.enableManualInput && manualInput ?
            <div className="flex flex-row justify-between gap-x-3 items-center">
                <Input ref={ref} className="grow" suffix="maanden" type="text" min="12" max="120" step="12" label="Looptijd" placeholder="Looptijd (handmatig)" value={inputValue} onChange={props.forwardOnChange ? props.onChange : evt => setInputValue(evt.target.value)} />
                <span title="Looptijd slider" className="fa fa-slider text-xl cursor-pointer hover:text-slate-500" onClick={() => setManualInput(false)}></span>           
            </div>
            :
            <div className="border border-slate-300 rounded-xl bg-white pt-1 pb- px-3 relative flex flex-col">
                <label className="text-xs xabsolute top-0">{props.label}</label>
                <StyledInputRange $primaryColor={primaryColor} type="range" name={props.name} ref={ref} value={props.forwardOnChange ? props.value : inputValue} min={props.min} max={props.max} step={props.step} onChange={props.forwardOnChange ? props.onChange : evt => setInputValue(evt.target.value)} />          
                <div className="flex flex-row justify-between">
                    <span>{props.prefix}{props.thousandNotation ? formatThousandNotation(props.value) : inputValue}{props.suffix}</span>
                    {role?.includes("Admin") && props?.enableManualInput && <span title="Handmatig invoer" className="fa fa-input-numeric cursor-pointer hover:text-slate-500" onClick={() => setManualInput(true)}></span>}     
                </div>
            </div>
            }
        </>
    )
});

export default InputRange;