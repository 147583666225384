import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Content from "components/Content/Content";
import Button from "components/ui/Button/Button";
import useAnalytics from "hooks/use-analytics";
import useDealV2 from "hooks/deal/use-deal_v2";

const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));

const StapBedankt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { sendPageView } = useAnalytics();
  const [downloadPdf, setDownloadPdf] = useState(false);

  const dealID = state?.dealID;
  const { deal, getDealById, clearDealFromStore } = useDealV2();

  useEffect(() => {
    sendPageView({category: "financiallease", action: "View", label: "Financial lease succesvol (bedankpagina)"});
    getDealById(dealID);
    clearDealFromStore(false);
  },[sendPageView, getDealById, dealID]);

  if(!deal?.dealID) return <>Laden...</>;

  return (
    <>
      <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
          <ProgressBar thankyoupage={true} />
      </div>
      <Container>
      <Content
          title="Bedankt voor uw aanvraag!"
          description={["Wij hebben uw aanvraag in goede orde ontvangen. Doorgaans is uw aanvraag op werkdagen binnen 24 uur beoordeeld.", <><br /><br /></>, "Onderstaand kunt u de offerte onder voorbehoud goedkeuring downloaden voor uw klant.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen op het nummer 0180 23 41 41"]}
          contentAlign="left"
          />
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-5">
            <Button onClick={() => navigate("/")} className="btn-secondary mb-5">Home</Button>
            <Button onClick={() => setDownloadPdf(true)} className="btn-primary mb-5">Download offerte (onder voorbehoud)</Button>
          </div>
        </div>
      </Container>
      {downloadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 chosenBank={deal?.chosenBank} hostname={"https://"+window.location.hostname} ondervoorbehoud={true} onDone={() => setDownloadPdf(false)} dealDetail={deal} /></Suspense>}
    </>
  );
};

export default StapBedankt;
