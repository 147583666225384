import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import useCars from "hooks/use-cars";
import useHuisstijl from "hooks/use-huisstijl";

import Result from "./Result";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import useAnalytics from "hooks/use-analytics";
import { dealActions } from "store/deal-slice";
import { escapeSpecialChars } from "lib/voertuig";
import { calcExPrice, calcFinalPaymentDefault, calcLeaseAmount, calcRestBpm, calcVat } from "components/Calculator/helpers";
import { calcAgeInMonths, calcDefaultDuration, calcMaxDuration } from "lib/calc";

const VoorraadModule = (props) => {
    const navigate = useNavigate();
    const { currentCase } = useSelector(state => state.progress);

    const { sendPageView } = useAnalytics();

    const [query, setQuery] = useState("");
    const { data: vehicles }  = useCars({query});

    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const keywordRef = useRef();

    const { primaryColor, buttonTextColor } = useHuisstijl();  

    const setDataHandler = (data) => {   
        sendPageView({category: data.typeOfDeal, action: "Klik", label: "Selecteer voertuig (Stap voertuig uit voorraad)"})
        dispatch(dealActions.setVehicleDetailByInventory(data));

        // when there is no typeOfDeal in the inventory car, go to the btw-marge step first
        if(currentCase==="Verzekering") {
            (!data.typeOfDeal) ? navigate("/verzekering/btw-marge") : navigate("/verzekering/klantgegevens");
        }
        if(currentCase==="FinancialLease") {
            (!data.typeOfDeal) ? navigate("/financiering/financial-lease/btw-marge") : navigate("/financiering/financial-lease/calculator", { state: { amountTouched: (data.vehicleType==="AUTO" && /btw/i.test(data.typeOfDeal)) }});
        }
    }

    const searchHandler = (evt) => {
        evt.preventDefault();
        if(!keywordRef.current.value) return;
        setKeyword(keywordRef.current.value);
        setQuery("&keyword="+keywordRef.current.value);
     }

     const showAllHandler = () => {
        setQuery("");
        setKeyword("");
     }
    
    return (
        <>
            <h1 className="">Selecteer een voertuig uit uw voorraad</h1>
            <form onSubmit={(evt) => evt.preventDefault()} className="flex flex-row gap-3 lg:gap-5 border-b border-slate-300 py-5 lg:p-10 lg:my-10 max-w-xl">
                <Input className="w-[140px] md:w-full" ref={keywordRef} name="keyword" placeholder="Zoek op trefwoord" value={keyword} onChange={(evt) => setKeyword(evt.target.value)}/>
                <Button type="submit" className="btn-primary" onClick={searchHandler}><span className="hidden lg:block">Zoeken</span><span className="lg:hidden fa fa-magnifying-glass"></span></Button>
                <Button className="btn-secondary" onClick={showAllHandler}><span className="hidden lg:block">Bekijk alle voorraad</span><span className="lg:hidden fa fa-trash"></span></Button>
            </form>
            <div className="max-w-7xl mr-auto">
            {vehicles && vehicles.map(vehicle => {

                var vehicleType = vehicle?.vehicleType;
                var typeOfDeal = vehicle?.marginVAT.replace(/^m.*/i, "Marge").replace(/^b.*/i, "BTW");
                
                var datePart1 = vehicle?.datePart1 || "01-01-"+vehicle?.constructionYear; // // age (of vehicle) calculation, firstRegistrationDate (datum_deel_1)
                var datePart1b = vehicle?.datePart1b || "01-01-"+vehicle?.constructionYear; // restBPM calculation (datum_deel_1b)

                var age = calcAgeInMonths(datePart1);
                
                var grossBPM = vehicle?.brutoBPM;
                var restBPM = calcRestBpm(vehicle?.vehicleType, age, grossBPM);
                var durationMax = calcMaxDuration(age, 6);
                var duration = calcDefaultDuration(durationMax);
                var amountpurchase = vehicle?.price;
                var amountpurchaseExclVAT = calcExPrice(typeOfDeal, amountpurchase, restBPM, vehicleType);
                var finalpayment = calcFinalPaymentDefault(amountpurchaseExclVAT, duration);
                var leaseAmount = calcLeaseAmount(typeOfDeal, amountpurchase, amountpurchaseExclVAT);
                var vat = calcVat(amountpurchase, amountpurchaseExclVAT);

                var massEmpty = vehicle?.mass || 0;

                var vehicleDetail = {
                    'leaseAmount': leaseAmount,
                    'vat': vat,
                    'age': age,
                    'finalpayment': finalpayment,
                    'duration': duration,
                    'durationMax': durationMax,
                    'make': vehicle?.make ? escapeSpecialChars(vehicle?.make) : "",
                    'model': vehicle?.model ? escapeSpecialChars(vehicle?.model) : "",
                    'type': vehicle?.model ? escapeSpecialChars(vehicle?.type) : "",
                    'licensePlate': vehicle?.licensePlate,
                    'fuelType': vehicle?.fuelType,
                    'constructionYear': vehicle?.constructionYear,
                    'datePart1': datePart1, 
                    'datePart1b': datePart1b, 
                    'firstRegistrationDate': datePart1,
                    "cylinderCapacity": vehicle?.cylinderCapacity,
                    'mileage': vehicle?.mileage,
                    'typeOfDeal': typeOfDeal,
                    'vehicleType': vehicleType,
                    'amountpurchase': vehicle?.price,
                    'brutoBPM': grossBPM,
                    'restBPM': restBPM,
                    "image": vehicle?.vehicleImages[0]?.uploadURL || "",
                    'carrosserie': vehicle?.carrosserie,
                    'listPrice': vehicle?.listPrice,
                    'tellerstandoordeel': /j/i.test(vehicle?.napWebLabel) && 'Logisch',
                    'massEmpty': massEmpty
                };              
                return (
                    <Result key={vehicle.vehicleID} primaryColor={primaryColor} buttonTextColor={buttonTextColor} data={vehicle} view='list' onClick={() => setDataHandler(vehicleDetail)} />
                )
            })}
            {(!vehicles.length && keyword) && (<div className="flex flex-row items-center mt-5 mb-40 gap-x-3">
                <div>Er zijn geen voertuigen gevonden met het zoekwoord</div>
                    <div onClick={showAllHandler} className="flex flex-row gap-x-3 items-center justify-between bg-slate-500 hover:bg-slate-400 text-white py-1 px-3 cursor-pointer rounded-lg">
                        <span>{keyword}</span><span className="fa fa-xmark"></span>
                    </div>
                </div>)}
            </div>
            </>
    )
}

export default VoorraadModule;