import Modal from "components/hoc/Modal";
import useAuth from "hooks/use-auth";
import useDocument from "hooks/document/use-document";
import { useState, useEffect } from "react";
import { getFriendlyDocName } from "../ActivityForm/options";
import Button from "components/ui/Button/Button";
import useActivity from "hooks/activity/use-activity";

const DocumentForm2 = ({ id, dealerName, onUpdate, updated } ) => {
    const { createActivity } = useActivity();
    const { role }  = useAuth();
    const { document, documents, getDocuments, removeDocument, getDocumentById, clearDocumentId } = useDocument();

    const [confirmDelete,setConfirmDelete] = useState(false);

    useEffect(() => {
        getDocuments(id, dealerName);
    }, [updated]);

    const removeHandler = async (docId, docName, createdBy, createdDateTime) => {
       var resp = await removeDocument(docId, onUpdate);
       if(resp==="SUCCESS") {
            createActivity("Document", "delete", {dealID: id}, {documentID: docId, documentName: docName, createdBy: createdBy, createdDateTime: createdDateTime});
        }
       setConfirmDelete(false);
    }

    return (
        <div>
            <div className="hidden font-semibold bg-sld-blue text-white rounded-xl shadow-xl md:grid grid-cols-3 gap-x-10 items-center py-10 px-5 lg:py-5 lg:px-10 mb-5">
                <div className="flex flex-row">
                    <div>Type</div>
                </div>
                <div>Omschrijving</div>
                {/* <div>Door</div>
                <div>Datum</div> */}
                <div></div>
            </div>
            {documents?.length>0 && documents.map((document, index) => {
                var documentName = document.documentName;
                if(documentName) documentName = documentName.replace("undefined", "uboformulier");
                var createdBy = /^ey/.test(document.createdBy) ? (document?.deal?.dealer?.name ? document?.deal?.dealer?.name : "") : document.createdBy;

                return (
                    <div key={"document"+index} className="font-semibold bg-white rounded-xl shadow-xl grid md:grid-cols-3 gap-x-10 items-center py-10 px-5 lg:py-5 lg:px-10 mb-5">
                        <div className="flex flex-col">
                            {/* <div className="w-12">{document.documentID}</div> */}
                            <div className="">{getFriendlyDocName(documentName)}</div>
                            <div>{document.createdDateTime}</div>
                            <div>{createdBy}</div>
                        </div>
                        <div className="">{document.description}</div>
     
                        <div className="flex flex-row gap-x-10 justify-end">
                            {(!confirmDelete || confirmDelete!==document.documentID) &&
                            <>
                            {(role.includes("Admin") || (document.createdByCompany!=="SLD")) && <div className="text-center hover:scale-105"><span className="text-xl fa fa-magnifying-glass cursor-pointer" onClick={() => getDocumentById(document.documentID)}></span></div>}
                            {(role.includes("Admin") || (document.createdByCompany!=="SLD")) && <div className="text-center hover:scale-105"><a href={document.documentURL} target="_blank" rel="noreferrer" className=""><span className="text-xl fa fa-download cursor-pointer"></span></a></div>}
                            </>}
                            {(role.includes("Admin") || (document.createdByCompany!=="SLD")) && (
                            <div className="text-center cursor-default">
                                {confirmDelete===document.documentID  
                                ?
                                <div className="flex flex-row gap-x-3 items-center">
                                    <div className="text-xs cursor-pointer hover:scale-105" onClick={() => setConfirmDelete(false)}>Annuleren</div>
                                    <div className="text-xs"><Button type="button" className="text-xs !bg-[red]" onClick={() => removeHandler(document.documentID, documentName, createdBy, document.createdDateTime)}>Verwijderen bevestigen</Button></div>
                                </div>
                                :
                                <span className="relative text-xl fa fa-trash-can cursor-pointer hover:scale-105" onClick={() => setConfirmDelete(document.documentID)}></span>
                                }
                                </div>)
                            }
                        </div>
                    </div>
                )
            })}
            {document.documentID && <Modal xl={true} onClose={() => clearDocumentId()} show={true} title={document.documentName}><iframe title="Document bekijken" className="w-full h-full" src={document.documentURL} border="0" /></Modal>}
        </div>
    )
}

export default DocumentForm2;