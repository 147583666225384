import { useState } from "react";
import { useDispatch } from "react-redux";

import Accordion from "components/ui/Accordion/Accordion";
import InputColor from "components/ui/InputColor/InputColor";
import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";
import { uiActions } from "store/ui-slice";

const ProfielForm = () => {
    const dispatch = useDispatch();
    const { token: jwt, userInfo } = useAuth();

    const [profile, setProfile] = useState({
        dealerID: userInfo.dealerID,
        logoImageUrl: userInfo.logoImageUrl,
        buttonTextColor: userInfo.buttonTextColor,
        primaryColor: userInfo.primaryColor
    });

    const valueChangeHandler = (evt) => {
        const fieldName = evt.target?.name;
        const fieldValue = evt.target?.value;

        setProfile(prevState => {
            return {
                ...prevState,
                [fieldName]: fieldValue
            }
        });
    }

    const changeProfileHandler = (evt) => {
        evt.preventDefault();
        if(!profile.dealerID) return;

        const formData = new FormData();
        formData.append("buttonTextColor", userInfo.buttonTextColor);
        formData.append("primaryColor", userInfo.primaryColor);
        dispatch(uiActions.showBackdrop());

        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Dealer/'+profile.dealerID, {
            method: "PUT",
            headers: {
                "Authentication": "Bearer "+jwt
            },
            body: formData
        }).then(() => {
            dispatch(uiActions.hideBackdrop());
        }).catch(err => {
            dispatch(uiActions.hideBackdrop());
        });
    }

    return (
        <>
        <form onSubmit={changeProfileHandler}>
            <div>{JSON.stringify(profile)}</div>
        <Accordion summary="Vormgeving">
        <div className="grid grid-cols-2 gap-3 py-5">
            <div className="col-span-2 gap-3 flex flex-col">
                {/* <DropArea ref={logoImageUrlFormRef} value={dealer.logoImageUrl} uploadText="Upload logo" name="logoImageUrlForm" uploadText="Upload logo (.PNG)" /> */}
                <InputColor placeholder="Primaire kleur" name="primaryColor" onChange={valueChangeHandler} value={profile.primaryColor} />
                <InputColor placeholder="Button tekstkleur" name="buttonTextColor" onChange={valueChangeHandler} value={profile.buttonTextColor} />
            </div>
        </div>
        </Accordion>
        <Button className="btn-primary">Profiel opslaan</Button>
        </form>
        </>
    )
}

export default ProfielForm;