import { useEffect, useState, useRef, useCallback } from "react";
import Input from "components/ui/Input/Input";

import useAuth from "hooks/use-auth";
import Button from "components/ui/Button/Button";
import Card from "components/ui/Card/Card";
import DropArea from "components/ui/DropArea/DropArea";
import Select from "components/ui/Select/Select";
import TextArea from "components/ui/TextArea/TextArea";
import useActivities from "hooks/use-activities";
import { documentNames } from "./options";
import { getSelectOptions } from "lib/voertuig";
import useActivity from "hooks/activity/use-activity";

const NewActivityForm2 = (props) => {
    const { createActivity: createNewActivity } = useActivity();
    const [typeOfActivity, setTypeOfActivity] = useState(props.typeOfActivity);
    
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [fileName, setFileName] = useState("");
    const { role } = useAuth();
    const { createActivity } = useActivities();
    const docRef = useRef();

    const initialObjState = {
        name: "",
        documentName: "",
        type: "",
        description: "",
        descriptionTouched: false,
        documentNameTouched: false,
        typeTouched: false
    }

    const initialDocState = {
        subject: "",
        documentName: "",
        description: "",
        type: "",
        manualDocumentName: "",
        originalName: "",
        descriptionTouched: false,
        subjectTouched: false
    }

    const [obj, setObj] = useState(initialObjState);
    const descriptionError = !obj.description && obj.descriptionTouched;
    const documentNameError = !obj.documentName && obj.documentNameTouched; 
    const typeError = !obj.type && obj.typeTouched;
    
    const [docObj, setDocObj] = useState(initialDocState);
    const docDescriptionError = !docObj.description && docObj.descriptionTouched;
    const docSubjectError = !docObj.subject && docObj.subjectTouched;

    const detectFileType = useCallback((fileName) => {
        var description, docYear;
        switch(true) {
            case /bankaf/i.test(fileName):
                description = "bankafschrift";
                break;
            case /(bankp|pinp|card)/i.test(fileName):
                description = "bankpaszakelijk";
                break;
            case /ubo/i.test(fileName):
                description = "uboformulier";
                break;
            case /(verkoopf|factu)/i.test(fileName):
                description = "verkoopfactuur";
                break;
            case /tenaam/i.test(fileName):
                description = "tenaamstellingsverslag";
                break;
            case /contract/i.test(fileName):
                description = "getekendcontract";
                break;
            case /(jaarrekening|aangifte|income|inkomsten)/i.test(fileName):
                if(/jaarrekening/i.test(fileName)) description = "jaarrekening";
                if(/(aangifte|income|inkomsten)/i.test(fileName)) description = "aangifte_inkomstenbelasting";
                description = "jaarrekening";
                var yearArr = [];
                for(var year=2020;year<=new Date().getFullYear();year++) {
                    yearArr.push(year);
                };
                var yearStr = yearArr.join("|");
                var regExp = new RegExp(".*("+yearStr+").*");
                docYear = fileName.replace(regExp, "$1");
                description = (docYear) ? description+"_"+docYear : "";
                if(/concept/i.test(fileName)) description="concept_"+description;
                 break;
            case /(rijbe|pass|pasp|identi|drivers?\s*li[cs]ense)/i.test(fileName):
                if(/rijbe/i.test(fileName)) description = "rijbewijs";
                if(/(pass|pasp|identi)/i.test(fileName)) description = "paspoort_id_";
                description = (/achter/i.test(fileName)) ? description+="achterkant" : description+="voorkant";
                break;
            default:
                break;
        }

        if(description) setDocObj(prevState => ({...prevState, description: description, descriptionTouched: true}));
    },[]);

    useEffect(() => {
        if(!fileName) return;
        detectFileType(fileName);    
    }, [fileName, detectFileType])

    const validateForm = () => {
        setObj(prevState => ({...prevState, documentNameTouched: true, descriptionTouched: true, typeTouched: true}));
        setDocObj(prevState => ({...prevState, subjectTouched: true, descriptionTouched: true}));
    }

    const submitFormHandler = async (evt) => {
        evt.preventDefault();
        setSubmitBtnDisabled(true);

        if(!typeOfActivity) {
            setSubmitBtnDisabled(false);
            return;
        }
        validateForm();

        const formData = new FormData();

        switch(true) {
            case props.typeOfActivity==="dealerActivity":
                formData.append("dealerID", props.id);
                formData.append("createAt", new Date());
                formData.append("name", obj.documentName);
                formData.append("description", obj.description);
                formData.append("offer", "0");
                formData.append("documentURLForm", "");
                formData.append("type", obj.type);
                break;
            case typeOfActivity==="document":
                if(!docObj.subject || !docObj.description) {
                    setSubmitBtnDisabled(false);
                    return;
                }
                formData.append("dealID", props.id);
                formData.append("documentName", docObj.description);
                formData.append("description", docObj.subject);
                formData.append("documentURLForm", docRef.current?.files[0]);
                formData.append("type", "");
                break;
            case /^(activity|dealer\supdate)$/i.test(typeOfActivity):
                if(!obj.documentName || !obj.description || !obj.type) {
                    setSubmitBtnDisabled(false);
                    return;
                };
                formData.append("dealID", props.id);
                formData.append("createAt", new Date());
                formData.append("name", obj.documentName);
                formData.append("description", obj.description);
                formData.append("offer", "0");
                formData.append("documentURLForm", "");
                formData.append("type", obj.type);
                break;
            default: 
                break;
        }
        var resp = await createActivity(formData, typeOfActivity, props.typeOfActivity);
        if(resp==="SUCCESS") {
            if(typeOfActivity==="document") {
                var addedData = {documentName: docObj.description, description: docObj.subject  };
                createNewActivity("Document", "upload", {dealID: props.id}, addedData);
            }
            var addedData = {...obj};
            if(/^(activity)$/i.test(typeOfActivity)) {
                createNewActivity("Activity", "create", {dealID: props.id}, {
                    documentName: addedData?.documentName || "",
                    type: addedData?.type || "",
                    description: addedData?.description || ""
                });
            } else if(/^(dealer\supdate)$/i.test(typeOfActivity)) {
                createNewActivity("Dealer update", "sent", {dealID: props.id}, {
                    documentName: addedData?.documentName || "",
                    type: addedData?.type || "",
                    description: addedData?.description || ""
                });
            }
            props.onUpdate();           
        } else {
            console.log("Er is iets fout gegaan bij opslaan");
        } 
        (resp==="FAIL") && props.onUpdate();
        setSubmitBtnDisabled(false);
    }

    const fileNameHandler = (name) => {
        setFileName(name);
        setDocObj(prevState => ({...prevState, subject: name, subjectTouched: true }));
    }

    useEffect(() => {
        if(typeOfActivity==="" || typeOfActivity==="dealerActivity") return;
        if(typeOfActivity==="Dealer update") {
            setObj(prevState => ({...prevState, type: "Dealer update", typeTouched: true, documentNameTouched: true, documentName: `Statusupdate van uw aanvraag ${props.id}, ${props.companyName}`, manualDocumentName: true}));
        } else {
            setObj(initialObjState);
            setDocObj(initialDocState);
        }
    }, [typeOfActivity]);

    useEffect(() => {
        // automatically set soort to "activity" when dealerActivity
        if(props.typeOfActivity==="dealerActivity") setTypeOfActivity("activity");
    }, []);

    var submitBtnText = "Opslaan";
    if(typeOfActivity==="Dealer update") submitBtnText = "Update versturen";
    if(typeOfActivity==="document") submitBtnText = "Document opslaan";
    if(typeOfActivity==="activity") submitBtnText = "Activiteit aanmaken";

    var activityOptions = [{label: "Dealer update", value: "Dealer update"}, {label: "Activiteit", value: "activity"}, {label: "Document", value: "document"}];
    if(props.typeOfActivity==="dealerActivity") activityOptions = activityOptions.filter(item => !/(Dealer update|document)/.test(item.value));
    if(props.source==="Website-aanvraag") activityOptions = activityOptions.filter(item => item.value!=="Dealer update");
    var activityTypeOptions = getSelectOptions("activityType");
    if(props.typeOfActivity==="dealerActivity") activityTypeOptions = activityTypeOptions.filter(item => item.label!=="Uitslag");

return (
<form onSubmit={submitFormHandler} className={`text-xs lg:text-base ${props.inModal ? 'mt-0' : 'mt-5'}`}>
            <Card title={typeOfActivity==="document" ? "Document uploaden" : "Activiteit aanmaken"} hideTitle={props.hideTitle} showClose={props.inModal} onClose={props.onClose}>
                <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
                    <div className={`${props.widget ? "flex flex-col col-span-3" : "grid col-span-2"} gap-3 p-5`}>
                        {role.includes("Admin") &&
                        <div open={true} summary="Bedrijfsgegevens">
                            <div className="flex flex-col gap-3 py-5">
                            <Select className={typeOfActivity ? "hidden": ""} value={typeOfActivity} onChange={evt => setTypeOfActivity(evt.target.value)} label="Soort" options={activityOptions}></Select>

                            {(typeOfActivity==="Dealer update") &&
                            <>
                            <div className="flex flex-col">
                                <Input name="description" placeholder="Onderwerp" label="Onderwerp" value={obj.documentName} onChange={evt => setObj(prevState => ({...prevState, documentName: evt.target.value, documentNameTouched: true}))} />
                                {documentNameError && <div className="text-sm p-1 text-red-500">Onderwerp is een verplicht veld</div>}
                            </div>
                            </>
                            }                            

                            {(typeOfActivity==="activity") &&
                            <>
                            <Select onChange={evt => setObj(prevState => ({...prevState, type: evt.target.value, typeTouched: true}))} value={obj.type} options={activityTypeOptions} label="Type"></Select>
                            {typeError && <div className="text-sm p-1 text-red-500">Type is een verplicht veld</div>}
                            <Input name="documentName" placeholder="Onderwerp" label="Onderwerp" value={obj.documentName} onChange={evt => setObj(prevState => ({...prevState, documentName: evt.target.value, documentNameTouched: true}))} />
                            {documentNameError && <div className="text-sm p-1 text-red-500">Onderwerp is een verplicht veld</div>}
                            </>
                            }    

                            {typeOfActivity==="document" &&
                            <>
                            <div className="col-span-2">
                            <DropArea onSetFileName={fileNameHandler} accept="image/*,.jpe,.eml,.msg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={docRef} onDelete={() => setDocObj(initialDocState)} uploadText="Upload" name="file" extensions="pdf|doc|docx|png|jpg|jpe|jpeg|bmp|gif"/>
                            </div>
                            <div className="flex flex-col">
                                <Input name="description" placeholder="Onderwerp" label="Onderwerp" value={docObj.subject} onChange={evt => setDocObj(prevState => ({...prevState, subject: evt.target.value, subjectTouched: true}))} />
                                {docSubjectError && <div className="text-sm p-1 text-red-500">Onderwerp is een verplicht veld</div>}
                            </div>
                            </>
                            }       

                            {(typeOfActivity==="document") &&
                            <div className="flex flex-col relative">
                                <div className={`flex ${props.widget ? "flex-col" : "flex-row"} gap-x-3`}>
                                    {(!docObj.manualDocumentName) ? 
                                    <Select label="Type" options={documentNames()} onChange={evt => setDocObj(prevState => ({...prevState, description: evt.target.value, descriptionTouched: true}))} value={docObj.description}></Select>
                                    :
                                    <Input name="documentName" placeholder="Type" label="Type" value={docObj.description} onChange={evt => setDocObj(prevState => ({...prevState, description: evt.target.value, descriptionTouched: true}))} />
                                    }
                                    <div className="flex flex-row gap-x-2 p-2 items-center cursor-pointer hover:text-slate-600" onClick={() => setDocObj(prevState => ({...prevState, manualDocumentName: !prevState.manualDocumentName, documentName: "", description: ""}))}>
                                        <span className={`fa ${docObj.manualDocumentName ? "fa-list" : "fa-pen-to-square"}`}></span>
                                        <span>{docObj.manualDocumentName ? "Kies uit lijst" : "Handmatig invoeren"}</span>
                                    </div>
                                </div>
                                {docDescriptionError && <div className="text-sm p-1 text-red-500">Type is een verplicht veld</div>}
                            </div>
                            }    
                            
                            {(/^(activity|dealer\supdate)$/i.test(typeOfActivity)) &&
                                <>
                                <TextArea name="description" placeholder="Omschrijving" value={obj.description} onChange={evt => setObj(prevState => ({...prevState, description: evt.target.value, descriptionTouched: true}))} />
                                {descriptionError && <div className="text-sm p-1 text-red-500">Omschrijving is een verplicht veld</div>}
                                </>
                            }
                            </div>
                        </div>}
                                          </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
                        <Button disabled={submitBtnDisabled} className="bg-gradient">{submitBtnText}</Button>
                    </div>
                </div>
            </Card>
        </form>
    )
}

export default NewActivityForm2;