import useAuth from "hooks/use-auth";
import { useState, useCallback } from "react";

export const useTrengo = () => {
    const { token: jwt } = useAuth();

    const [ticket, setTicket] = useState("");
    const [documents, setDocuments] = useState([]);

    const createTicket = useCallback(async (ticketData) => {
        // console.log("createTicket", ticketData);
        // return "SUCCESS";

        // TESTING: Wait for 2000 milliseconds (2 seconds) inline
        // var returnValue = await new Promise(resolve => setTimeout(function() { resolve("SUCCESS")}, 2000));
        // return returnValue;

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/CreateTicket", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(ticketData)
            });
            var respJson = await resp.json();
            console.log("ticketResp", respJson);
            setTicket(respJson);
            return {
                message: "SUCCESS",
                data: respJson
            }
        } catch (err) {
            return "FAIL";
        }
    }, []);

    const createDocumentWithUrl = useCallback(async (filePath) => {
        // console.log("createDocumentWithUrl", filePath);

        // Wait for 2000 milliseconds (2 seconds) inline
        // var returnValue = await new Promise(resolve => setTimeout(function() { resolve("SUCCESS")}, 3000));
        // return returnValue;

        // response:
        // {"id":330788331,"size":30581,"name":"638435244495473285.pdf","mime":"application\/pdf"}

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/Email/CreateDocumentWithUrl?filePath=${filePath}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var respJson = await resp.json();
            console.log("createDocumentWithUrl response", respJson);
            return {
                message: "SUCCESS",
                id: respJson?.id
            }
        } catch (err) {
            // alert("ERR ON DOC");
            return { message: "FAIL"}
        }
    }, []);

    const createDocument = useCallback(async (input) => {
        if(/^http/.test(input)) {
            var resp = await createDocumentWithUrl(input);
            return resp;
        }        

        // console.log("createDocument");
        // return;
        // Wait for 2000 milliseconds (2 seconds) inline
        // var returnValue = await new Promise(resolve => setTimeout(function() { resolve("SUCCESS")}, 3000));
        // return returnValue;

        try {
            const formData = new FormData();
            formData.append("formFile", input)
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/apix/Email/CreateDocument", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: formData
            });
            var respJson = await resp.json();
            setDocuments(prevState => [...prevState, ...respJson]);
        } catch (err) {
            // alert("ERX");
            return "FAIL";
        }
    }, []);

    return {
        ticket,
        documents,
        createTicket,
        createDocument
    }
}

export default useTrengo;