const TodoPrio = ({todo, onChange}) => {
    return (
        <div className="flex flex-col group relative cursor-pointer min-w-fit max-w-fit">
        <div className="flex flex-row items-center gap-x-2">
            <span className={`fa fa-star ${todo.priority===1 && 'text-[#E50823]'} ${todo.priority===2 && 'text-[orange]'} ${todo.priority===3 && 'text-[blue]'}`}></span>
            <span>Prio {todo.priority}</span>
        </div>
        <div className="hidden group-hover:block absolute right-0 top-full z-20 w-[100px] text-left bg-white rounded-xl shadow-xl">
            <div className="hover:bg-slate-200 px-3 py-1 border-b border-slate-100 border-solid" onClick={() => onChange(todo.todoId, 1)}>Prio 1</div>
            <div className="hover:bg-slate-200 px-3 p-1 border-b border-slate-100 border-solid" onClick={() => onChange(todo.todoId, 2)}>Prio 2</div>
            <div className="hover:bg-slate-200 px-3 p-1 border-b border-slate-100 border-solid" onClick={() => onChange(todo.todoId, 3)}>Prio 3</div>
            <div className="hover:bg-slate-200 px-3 p-1 border-b border-slate-100 border-solid" onClick={() => onChange(todo.todoId, 4)}>Prio 4</div>
            <div className="hover:bg-slate-200 px-3 p-1 border-b border-slate-100 border-solid" onClick={() => onChange(todo.todoId, 5)}>Prio 5</div>
        </div>
    </div>
    )
}

export default TodoPrio;