import { NavLink } from "react-router-dom";
import DealerForm from "components/Forms/DealerForm/DealerForm";
import Button from "components/ui/Button/Button";

const DealerDetail = () => {

  return (
    <>
      <NavLink to={-1}><Button type="button" className="btn-secondary">Terug</Button></NavLink>
      <DealerForm detail={true} />     
    </>
  );
};

export default DealerDetail;
