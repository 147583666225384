import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";
import { uiActions } from "store/ui-slice";
import { aesDecrypt } from "lib/helpers";

export const useAuthenticate = (init, initQuery=null) => {
    const [tokens, setTokens] = useState([]);
    const { token: jwt, role } = useAuth();

    const dispatch = useDispatch();

    const registerAdmin = useCallback(async (formData, sourceOfUser) => {
        if(!role.includes("SuperAdmin")) return;
        if(!sourceOfUser) return;
        dispatch(uiActions.showBackdrop());
        var endpoint;
        if(sourceOfUser==="sld") endpoint = "/api/Authenticate/register-admin";
        if(sourceOfUser==="actua") endpoint = "/api/Authenticate/register-Actua-admin";
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+endpoint, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer "+jwt
                },
                body: formData
            });
            var respJson = await resp.json();
            if(respJson.status==="Error") throw new Error(respJson.message);
            dispatch(uiActions.setAlert({message: "Beheer succesvol aangemaakt!", type: "success"}))
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch(err) {
            var message = err.message;
            dispatch(uiActions.setAlert({message: message, type: "danger"}))
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    },[]);

    const createToken = useCallback(async (formData) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/TokenGeneration", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(formData)
            });
            var respText = await resp.text();
            var responseIv = resp.headers.get("key");
            var respData = aesDecrypt(respText, responseIv);
            if(respData?.status==="Error") throw new Error(respData?.message);
            dispatch(uiActions.setAlert({message: "Token succesvol aangemaakt!", type: "success"}));
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch(err) {
            var message = err.message;
            dispatch(uiActions.setAlert({message: message, type: "danger"}));
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    }, []);


    const getTokens = useCallback(async () => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Authenticate/GetTokens', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        setTokens(decryptedData);        
    },[]);

    const updateProfile = useCallback(async (obj, imageFile) => {
        dispatch(uiActions.showBackdrop());

        const { firstName, lastName, phoneNumber, profileImage, salutation, dateOfBirth } = obj;
        const formData = new FormData();
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("phoneNumber", phoneNumber);
        formData.append("profileImage", profileImage);
        formData.append("salutation", salutation);
        if(imageFile) formData.append("profileImageForm", imageFile);
        formData.append("dateOfBirth", dateOfBirth);

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/update-User", {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer "+jwt
                },
                body: formData
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            // dispatch(authActions.updateProfile({data: obj}));
            dispatch(uiActions.setAlert({ message: "Je profiel is succesvol bijgewerkt!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Je profiel kan niet bijgewerkt worden!", type: "danger" }));
        }
    },[]);
    
    const resendEmailVerification = useCallback(async (toWho, id, userName, touchBackdrop=true) => {
        if(!toWho || !id) return;
        var endpoint;

        if(touchBackdrop) dispatch(uiActions.showBackdrop());
        if(toWho==="dealer") endpoint = "/api/Authenticate/resendEmailVerificationToDealerV2?dealerId="+id;
        if(toWho==="subUser") endpoint = "/api/Authenticate/resendEmailVerificationToSubUserV2?userId="+id;

        if(/\-Actua$/.test(userName)) endpoint = endpoint.replace("resend", "resendActua");
   
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var respJson = await resp.json();
            dispatch(uiActions.setAlert({message: "Activatiemail succesvol verstuurd!", type: "success"}))
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            return {
                message: "SUCCESS",
                data: respJson
            }
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het versturen van de activatiemail", type: "danger"}))
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            return {
                message: "FAIL"
            }
        }

    },[]);

    const sendForgotPassword = useCallback(async (email, userName, touchBackdrop=true) => {
        if(!email) return;
        var endpoint;

        if(touchBackdrop) dispatch(uiActions.showBackdrop());
        if(/\-Actua$/.test(userName)) {
            endpoint = "/api/Authenticate/forgot-password-actua";
        } else {
            endpoint = "/api/Authenticate/forgot-password";
        }
        
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify({"email": email})
            });
            var respJson = await resp.json();
            dispatch(uiActions.setAlert({message: "Mail succesvol verstuurd!", type: "success"}))
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            return {
                message: "SUCCESS",
                data: respJson
            }
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het versturen van de mail", type: "danger"}))
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            return {
                message: "FAIL"
            }
        }

    },[]);

    const updateEmail = useCallback(async (oldEmail, newEmail, touchBackdrop=true) => {
        if(!oldEmail || !newEmail) return;
        if(touchBackdrop) dispatch(uiActions.showBackdrop());

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/UpdateEmail?oldEmail='+oldEmail+'&newEmail='+newEmail, {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData?.status==="Success") {
                // createActivity("Dealer", "update", decryptedData);
                dispatch(uiActions.setAlert({ message: "E-mailadres succesvol bijgewerkt!", type: "success"}));
                if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            }
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.setAlert({ message: "E-mailadres kan niet bijgewerkt worden", type: "danger"}));
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    },[]);

    useEffect(() => {
        if(init==="getTokens") getTokens();
    }, [getTokens]);
    
    return {
        registerAdmin,
        tokens,
        getTokens,
        createToken,
        updateProfile,
        resendEmailVerification,
        sendForgotPassword,
        updateEmail
    }
}

export default useAuthenticate;