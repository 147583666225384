import { useEffect, useState } from "react";
import Input from "components/ui/Input/Input";
import { aesDecrypt } from "lib/helpers";

const Selectie = () => {
    const [key, setKey] = useState("");
    const [encryptedData, setEncryptedData] = useState("");
    const [decryptedData, setDecryptedData] = useState("");

    useEffect(() => {
        if(!encryptedData || !key) return;
        var data = JSON.stringify(aesDecrypt(encryptedData, key));
        if(data) {
            setDecryptedData(data);
        } else {
            setDecryptedData("Er is iets fout gegaan bij het decrypten van de data");
        }
    }, [encryptedData, key]);

    return (
        <>
        <h1>Selectie</h1>
        <div className="mb-3 ">
            <Input label="Key" placeholder="Response headers (key)" value={key} onChange={evt => setKey(evt.target.value)} />
        </div>
        <div className="grid grid-cols-2 gap-x-3">
            <div className="flex flex-col">
                <label>Encrypted data</label>
                <textarea placeholder="Response body" rows="20" className="border border-slate-300 rounded-xl p-2" value={encryptedData} onChange={evt => setEncryptedData(evt.target.value)} />
            </div>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <label>JSON data</label>
                    <span className="cursor-pointer" onClick={() => navigator.clipboard.writeText(decryptedData)}>Kopieren</span>
                </div>
                <textarea rows="20" className="border border-slate-300 rounded-xl p-2" value={decryptedData} />
            </div>
        </div>
        </>
    )
}

export default Selectie;