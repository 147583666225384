import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Table, { Thead, Th, Tr, Td } from "components/ui/Table/Table";
import useSearch from "hooks/use-search";
import SearchForm from "components/Forms/SearchForm/SearchForm";
import InputRadio from "components/ui/InputRadio/InputRadio";
import { serialize } from "lib/helpers";
import Button from "components/ui/Button/Button";
import { usePartner } from "hooks/partner/use-partner";

const PartnerOverzicht = () => {
  const navigate = useNavigate();
  const { partners, getPartners } = usePartner();
  const [query, setQuery] = useState('');
  const { resetHandler, searchHandler, isTouched: searchIsTouched, isResetted: searchIsResetted } = useSearch(partners?.data);
  const archiveRef = useRef();

  useEffect(() => {
    if(searchIsResetted===false) return;
      getPartners(query);
  }, [query, searchIsResetted, getPartners]);

  const changeFilterHandler = () => {
    var query = {};
    var isArchive = archiveRef.current.checked;     
    if(isArchive) query['isArchive'] = true;
    var serializedQuery = serialize(query);
    setQuery(serializedQuery);
  }

  return (
    <div className="text-xs lg:text-base max-w-6xl">

  <div className="flex flex-row justify-between items-center mb-5">
  <div className={`${(searchIsTouched) ? "invisible" : ""}`}>
              <SearchForm onReset={resetHandler} onSearch={searchHandler} isTouched={searchIsTouched} />  
          </div>
        
          <InputRadio className="hidden lg:block" ref={archiveRef} name="isArchive" type="checkbox" options={[{label: "Archief"}]} onChange={changeFilterHandler} />
          <Button className={`btn-secondary ${(query || searchIsTouched) ? "" : "invisible"}`} onClick={resetHandler}>Reset</Button>
  </div>
    
      {partners?.data?.length ? (
        <Table>
          <Thead>
            <Tr thead="true">
              <Th>ID</Th>
              <Th>Naam</Th>
              <Th>Telefoonnummer</Th>
              <Th>E-mail</Th>
              <Th>Adres</Th>
            </Tr>
          </Thead>
          <tbody>
            {partners?.data?.map(partner => {
              return (
                <Tr key={'k'+partner.partnerID} className={(partner.isArchive ? 'opacity-25 ' : '')+" cursor-pointer"} onClick={() => navigate(""+partner.partnerID)}>
                  <Td>{partner.partnerID}</Td>
                  <Td>{partner.name}</Td>
                  <Td>{partner.phoneNumber1}</Td>
                  <Td>{partner.eMailAddress1}</Td>
                  <Td>{partner.address} {partner.postalCode} {partner.place}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>Er zijn nog geen partners</p>
      )}
    </div>
  );
};

export default PartnerOverzicht;
