import { useCallback, useState } from "react";
import useAuth from "hooks/use-auth";
import useActivity from "hooks/activity/use-activity";
import { getFullBankNameFromPrefix } from "lib/voertuig";

const useBankApi = () => {
    const { createActivity } = useActivity();
    const [responseData, setResponseData] = useState({
        status: "",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    
    const { token: jwt } = useAuth();

    const postBankData = useCallback((bank, dealId, herbeoordeling) => {
        if( (bank==="df" || /^df_/.test(bank)) && herbeoordeling) {
            window.open("https://www.vwpfsintegration.nl/AanvraagServicePortaal/Login", "_blank", "noreferrer");
            return;
        }
        setIsRequested(true);
        setIsLoading(true);
        if(!dealId || !bank) return;
        var baseUrl = process.env.REACT_APP_SLD_API_BASE_URL;
        var apiPath;
        if(bank==="df" || /^df_/.test(bank)) apiPath = "/api/DutchFinanceBank/DutchFinanceRequest";
        if(/^hi/.test(bank)) apiPath = "/api/HiltermannBank/SendHilterManRequest";
        if(!bank) return;
        var queryParams = "?dealId="+dealId;
        var fetchUrl = baseUrl+apiPath+queryParams;
        fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        })
        .then(data => {
            var status, message;
            if(bank==="df" || /^df_/.test(bank)) status = data.status || "";
            if(/^hi/.test(bank)) status = data.statusCode || "";
            message = data.message || "";
            setResponseData({
                status: status,
                message: message
            });
             setIsLoading(false);
             createActivity("Bank", "submit", {dealID: dealId}, {bank: getFullBankNameFromPrefix(bank), bankPrefix: bank}, "");

        })
        .catch(err => {
            setResponseData({
                status: "Fout",
                message: "Fout na het indienen bij de bank."
            });
            setIsLoading(false);
            setIsRequested(false);
        });  
    },[jwt]);

    const getBankStatus = useCallback((bank, dealId) => {
        setIsLoading(true);
        if(!dealId || !bank) return;
        var baseUrl = process.env.REACT_APP_SLD_API_BASE_URL;
        var apiPath;
        if(bank==="df" || /^df_/.test(bank)) apiPath = "/api/DutchFinanceBank/GetDutchFinanceRequestStatus";
        if(/^hi/.test(bank)) apiPath = "/api/HiltermannBank/GetApplicationStatus";
        if(!bank) return;
        var queryParams = "?dealId="+dealId;
        var fetchUrl = baseUrl+apiPath+queryParams;
        fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        })
        .then(resp => resp.json())
        .then(data => {
            var response="",status="", message="", reason=[];
            var contractNumber = "";
            var rejectReason = "";
            var creditAppStatus = {};

            if(bank==="df" || /^df_/.test(bank)) {
                message = data.status || "";
                if(message==="Success") message = "";
                status = JSON.parse(data.message).Response.description || "";
                try { contractNumber = JSON.parse(data.message).Data.Status.Application.ContractNumber; } catch (err) {}
                try { rejectReason = JSON.parse(data.message).Data?.Status?.RejectOrDeviate_Set?.RejectOrDeviate?.Reason; } catch (err) {}

                try {
                    creditAppStatus = {
                        ...JSON.parse(data.message).Data?.Status?.CreditApplicationStatus_Set?.CreditApplicationStatus
                    };
                } catch (err) {}

            }         
            if(/^hi/.test(bank)) {
                try {
                    response = JSON.parse(data.message);
                    status = response.hlStatus;
                    var nlResponse = JSON.parse(response.hlResponseDutch);
                    var enResponse = JSON.parse(response.hlResponse);
                    var reason = [...nlResponse, ...enResponse];
                    message = response.hlMessage || "Er is geen bericht";
                } catch (err) {
                    message = data.message || "";
                    status = data.status || "";
                }

            }
            setResponseData({
                status: status,
                message: message,
                reason: reason,
                contractNumber: contractNumber,
                rejectReason: rejectReason,
                creditAppStatus: creditAppStatus
            });
            setIsLoading(false);
        })
        .catch(err => {
            // console.log("err", err);
            setIsLoading(false);
        });
    },[jwt]);

    return {
        isLoading,
        responseData,
        getBankStatus,
        postBankData,
        isRequested
    }
}

export default useBankApi;