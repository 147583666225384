import Button from "components/ui/Button/Button";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import useActivity from "hooks/activity/use-activity";
import { getSpecialDay, toyyyymmdd } from "lib/calc";
import {
  calculateElapsedTime,
  getDateTimeFromDate,
  replaceKeys,
  replaceValues,
  serialize,
} from "lib/helpers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { documentNames } from "components/Forms/ActivityForm/options";
import { getSelectOptions } from "lib/voertuig";
import useEmail from "hooks/email/use-email";
import EmailPreview from "components/EmailComposer/EmailPreview";
import useDealerV2 from "hooks/dealer/use-dealer_v2";

export const Activities = ({ dealID, widget, widgetSearchQuery, getByDealId, updateCounter, whatToShow }) => {
  const { dealers, getDealers } = useDealerV2();

  const [currentDate, setCurrentDate] = useState(new Date().getTime());
  const navigate = useNavigate();
  const { emailLogs, getEmailLogs } = useEmail(); 
  const [showEmailPreview, setShowEmailPreview] = useState("");

  useEffect(() => {
    getEmailLogs(dealID ? "dealId="+dealID : "");
  }, [updateCounter]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date().getTime());
    }, [60 * 1000]);
    return () => clearInterval(timer);
  }, []);

  const [detailsOpen, setDetailsOpen] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(
    widgetSearchQuery ? widgetSearchQuery : searchParams
  );

  const { register, watch, formState: { isSubmitted }, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      keyword: "",
      dealID: "",
      typeOfActivity: "",
      createdBy: "",
      name: "",
      pageSize: "25",
      dateFrom: toyyyymmdd(getSpecialDay().firstDayOfYear),
      dateTo: toyyyymmdd(getSpecialDay().today),
    },
  });

  
  const { activities, getActivities, getActivitiesByDealId } = useActivity();

  useEffect(() => {
    if (getByDealId) {
      getActivitiesByDealId(dealID, searchQuery);
    } else {
      getActivities(searchQuery);
    }
  }, [dealID, getByDealId, getActivities, getActivitiesByDealId, searchQuery, updateCounter]);

  const submitHandler = (formData) => {
    const query = serialize(formData);
    setSearchParams(query);
    setSearchQuery(query);
  };

  const watchAll = watch();

  const resetHandler = () => {
    setSearchQuery("");
    setSearchParams("");
    reset();
  }

  const documentTypes = documentNames();
  const activityTypes = getSelectOptions("activityType");
  const selectFields = [...activityTypes, {label: "Dealer update", value: "Dealer update"}, ...documentTypes];

  const [activeTab, setActiveTab] = useState("activities"); // activities|emailLogs

  useEffect(() => {
    if(activeTab==="lastLogin") getDealers("?order=desc&orderBy=lastLogin");
  }, [getDealers, activeTab]);

  const tabClasses = "select-none cursor-pointer inline-block p-4 border-b-2 rounded-t-lg";
  const activeTabClasses = "font-semibold border-orange text-orange cursor-default";

  return (
    <div>
      {!widget && (
        <>
          <form onSubmit={handleSubmit(submitHandler)} className={`${activeTab!=="activities" && "invisible"}`}>

            <div className="grid grid-cols-4 gap-3">
            <Input3 name="dealID" register={register} label="Verkoop ID" placeholder="Verkoop ID" inputValue={watchAll.dealID} />
            <Select3 name="typeOfActivity" register={register} label="Type activiteit" placeholder="Type activiteit" inputValue={watchAll.typeOfActivity}>
              <option value="">Alles</option>
              {/* <option value="systemActivity">Systeem activiteit</option> */}
              <option value="userActivity">Dealer activiteit</option>
              <option value="adminActivity">SLD activiteit</option>
            </Select3>
            <Input3 name="createdBy" register={register} label="Door (email)" placeholder="Door (email)" inputValue={watchAll.createdBy} />
            <span></span>

            <Select3 options={selectFields} name="keyword" register={register} label="Soort" placeholder="Soort" inputValue={watchAll.keyword} />
            <Input3 name="dateFrom" type="date" register={register} label="Startdatum" placeholder="Startdatum" inputValue={watchAll.dateFrom} />
            <Input3 name="dateTo" type="date" register={register} label="Einddatum" placeholder="Einddatum" inputValue={watchAll.dateTo} />
              <Select3 disableFirst={true} name="pageSize" register={register} label="Aantal resultaten" placeholder="Aantal resultaten" inputValue={watchAll.pageSize}>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
              </Select3>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-3 items-center">
                <Button type="submit" className="btn-secondary !w-full !max-w-full">Zoeken</Button>
                {isSubmitted && 
                <Button type="button" className="cursor-pointer ml-2 !bg-[#D20B00]" onClick={resetHandler}><span className="fa fa-xmark"></span></Button>
                }
              </div>
          </form>
        </>
      )}

{!whatToShow && 
      <div className="mt-10 mb-5 border-b border-gray-200">
         <div className=" flex flex-wrap -mb-px text-center">
          <span onClick={() => setActiveTab("activities")} className={`${tabClasses} ${activeTab==="activities" && activeTabClasses}`}>Activiteiten</span>
          <span onClick={() => setActiveTab("emailLogs")} className={`${tabClasses} ${activeTab==="emailLogs" && activeTabClasses}`}>E-mail logboek</span>
          <span onClick={() => setActiveTab("lastLogin")} className={`${tabClasses} ${activeTab==="lastLogin" && activeTabClasses}`}>Laatst ingelogd</span>
        </div>
      </div>
}
{(activeTab==="lastLogin") && (
  <div>
    {dealers?.data?.map((dealer, index) => {
      return <div key={"lastLogin"+dealer?.dealerID+index} onClick={() => navigate("/crm/dealers/"+dealer?.dealerID)} className="grid grid-cols-5 hover:bg-slate-100 border-b border-slate-200 cursor-pointer text-sm">
                  <div>{dealer?.dealerID}</div>
                  <div>{dealer?.name}</div>
                  {[dealer?.dealerUserDetail, ...dealer?.subUserDetails?.filter(item => item?.lastLogin)]?.map((user, index) => {
                      return (
                        <div key={user?.email+index} className="col-span-3 grid grid-cols-4">
                        <span>{user?.email}</span>
                        {user?.lastLogin &&
                        <>
                        <span>{getDateTimeFromDate(user?.lastLogin)}</span>
                        <span>{calculateElapsedTime(new Date(user?.lastLogin.slice(0, 19)).getTime())}</span>
                        </>
                        }
                        </div>
                      )
                    })}
      </div>
    })}
  </div>
)}

{(activeTab==="emailLogs" || whatToShow==="logemail") && (
  <div className="flex flex-col">
    {emailLogs?.data?.map(log => {
      return (
        <div key={"id"+log?.emailLogId} className="flex flex-row text-sm cursor-pointer hover:bg-slate-100 border-b border-slate-200">
          <div className="flex flex-row gap-x-3 justify-start w-[72px]">
            <span className={`fa ${log?.isEmailSentSuccessful ? "fa-check text-[green]" : "fa-xmark text-[red]"} flex flex-row w-[16px] justify-center`}>{log?.isEmailSentSuccessful}</span>
            {!widget && log?.dealId
            ?
            <div className="w-[60px] select-none" onClick={() => navigate("/crm/verkopen/" + log?.dealId)}>{log?.dealId}</div>
            :
            <div className="w-[60px] select-none">{log?.emailLogId}</div>
            }
            </div>

          <div className="grid grid-cols-7 w-full select-none">
            <div className="col-span-3 flex flex-col">
              <span onClick={() => setShowEmailPreview(log?.emailLogId)}>{log?.subject}</span>
              {log?.sendFailureReason && <span className="text-[red]">{log?.sendFailureReason}</span>}
            </div>
            <span className="col-span-2">{log?.to}</span>
            <span>{getDateTimeFromDate(log?.createdDate)}</span>
            <span>{calculateElapsedTime(new Date(log.createdDate.slice(0, 19)).getTime(), currentDate)}</span> 
          </div>
        </div>
      )
    })}
  </div>
)}

{showEmailPreview && <EmailPreview emailLogId={showEmailPreview} onClose={() => setShowEmailPreview(false)} />}

{(activeTab==="activities" && whatToShow!=="logemail") && (
      <div className="">
        
        {activities?.data?.map((activity, index) => {

          var activityCategory;
          if (/verwijderd/.test(activity.type)) activityCategory = "delete";
          if (/upload/.test(activity.type)) activityCategory = "upload";
          if (/Deal.*aangemaakt/.test(activity.type))
            activityCategory = "create";
          if (/gewijzigd/.test(activity.type)) activityCategory = "update";
          if (/ingediend/.test(activity.type)) activityCategory = "submit";
          if (/toegewezen/.test(activity.type)) activityCategory = "assign";
          if (/verzonden/.test(activity.type)) activityCategory = "sent";
          if (/gearchi/.test(activity.type)) activityCategory = "archive";
          if (/gedearchi/.test(activity.type)) activityCategory = "dearchive";
          if (/Activiteit/.test(activity.type)) activityCategory = "activity";
          if (/voorstel gebruiken/.test(activity.type)) activityCategory = "use";

          return (
              <div key={"activities"+activity.activityID+index}
                className={`
                    ${activityCategory === "create" && "font-bold"}
                    ${activityCategory === "submit" && "text-[tomato] font-bold"}
                    ${activityCategory === "upload" && "text-[blue]"} 
                    ${activityCategory === "delete" && "text-[red]"}
                    ${activityCategory === "create" && "text-[forestgreen]"}
                    ${activityCategory === "sent" && "text-orange font-bold"}

                    ${activityCategory === "archive" && "text-gray"}
                    ${activityCategory === "dearchive" && "text-gray"}
                    ${activityCategory === "use" && "text-[hotpink]"}

                    

                    text-sm flex flex-col xselect-none cursor-pointer hover:bg-slate-100 border-b border-slate-200`}
              >
                              {/* <div type="button" onClick={() => deleteActivity(activity.activityID)}>DELETE</div> */}

                <div className="flex flex-row">
                  {!widget && (
                    <div className="w-[50px]">
                       {activityCategory === "use" && (
                        <span className="fa fa-hand-pointer !text-base"></span>
                      )}
                      {activityCategory === "delete" && (
                        <span className="fa fa-trash-can !text-base"></span>
                      )}
                      {activityCategory === "upload" && (
                        <span className="fa fa-upload !text-base"></span>
                      )}
                      {activityCategory === "create" && (
                        <span className="fa fa-face-smile-plus text-[forestgreen] !text-base"></span>
                      )}
                      {activityCategory === "update" && (
                        <span className="fa fa-pen-to-square !text-base"></span>
                      )}
                      {activityCategory === "submit" && (
                        <span className="fa fa-paper-plane !text-base"></span>
                      )}
                      {activityCategory === "assign" && (
                        <span className="fa fa-user-pen !text-base"></span>
                      )}
                      {activityCategory === "sent" && (
                        <span className="fa fa-envelope !text-base"></span>
                      )}
                      {activityCategory === "archive" && (
                        <span className="fa fa-box-archive !text-base"></span>
                      )}
                      {activityCategory === "dearchive" && (
                        <span className="fa fa-folder-open !text-base"></span>
                      )}
                      {activityCategory === "activity" && (
                        <span className="fa fa-comment !text-base"></span>
                      )}
                    </div>
                  )}

<div className="w-[60px] select-none" onClick={() => navigate("/crm/verkopen/" + activity.dealID)}>{widget ? activity.activityID : activity.dealID}</div>
                 
                  <div
                    className={`grid grid-cols-6 gap-x-3 w-full select-none`}
                    onClick={() =>
                      setDetailsOpen((prevState) => {
                        if (prevState.includes(activity.activityID)) {
                          return prevState.filter(
                            (item) => item !== activity.activityID
                          );
                        } else {
                          return [...prevState, activity.activityID];
                        }
                      })
                    }
                  >
                    <div className="flex flex-col col-span-3 relative">
                      <span>{activity.type}</span>
                    </div>
                    <div>{activity.createdBy?.replace(/ey.*/, "Actua dealer")}</div>
                    <div className="flex flex-row gap-x-3">
                      {/* <div>{calculateElapsedTime(activity.createdDate)}</div> */}
                      <div>{getDateTimeFromDate(activity.createdDate)}</div>
                    </div>
                    <div>
                      {calculateElapsedTime(
                        new Date(activity.createdDate.slice(0, 19)).getTime(),
                        currentDate
                      )}
                    </div>
                    {/* {!widget && <div>{activity.typeOfActivity ? activity.typeOfActivity : ""}</div>} */}
                  </div>
                </div>
                <div
                  className={`text-black font-normal ${
                    detailsOpen.includes(activity.activityID)
                      ? "flex flex-row"
                      : "hidden"
                  }`}
                >
                  {/* <pre>{activity.description}</pre> */}
                  <div className="flex flex-row pl-3 py-3">
                    {activity?.description &&
                      /^{.*}$/.test(activity?.description) && (
                        <div className="flex flex-col border-r">
                          {Object.keys(JSON.parse(activity?.description)).map(
                            (head) => (
                              <span key={"a"+head} className="border-b px-3">
                                {typeof head === "object" ? "" : replaceKeys(head)}
                              </span>
                            )
                          )}
                        </div>
                      )}
                    {activity?.description &&
                      /^{.*}$/.test(activity?.description) && (
                        <div className="flex flex-col">
                          {Object.values(JSON.parse(activity?.description)).map(
                            (body, index) => (
                              <span key={"b"+body+index} className="border-b px-3">
                                {typeof body === "object" &&
                                body?.hasOwnProperty ? (
                                  <div>
                                    <div className="flex flex-col">
                                      {Object.keys(body).map((y, index) => (
                                        <div key={"c"+y+index} className="grid grid-cols-2">
                                        <span>{replaceKeys(y)}</span>
                                        <span>{typeof body==="object" && <span>{replaceValues((body[y]?.toString()))}</span>}</span>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="flex flex-col">
                                      <span></span>
                                    </div>
                                  </div>
                                ) : (body||body===false) ? (
                                  <>{replaceValues(body)}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            )
                          )}
                        </div>
                      )}
                  </div>
                  {/* <span className="text-xs text-gray">{activity?.description}</span> */}
                </div>
              </div>
          );
        })}
      </div>)}
    </div>
  );
};

export default Activities;
