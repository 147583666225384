import { NavLink } from "react-router-dom";
import PartnerOverzicht from "components/Tables/PartnerOverzicht";

const Partners = () => {
    return (
            <div>   
                {/* <h1>Partners</h1> */}
                <div className="flex flex-row justify-between mb-5">
                <NavLink
                    to="nieuwe-partner"
                    className="hover:scale-105 bg-[#212853] w-[50px] h-[50px] rounded-full justify-center text-center flex flex-col text-white"
                    >
                    <span className="fa fa-plus"></span>
                </NavLink>
                </div>
                <PartnerOverzicht />
            </div>
    )
}

export default Partners;