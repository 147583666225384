import { useState } from "react";
// import useAuth from "../../hooks/use-auth";
import { useDispatch } from "react-redux";
// import { uiActions } from "store";

const useBankCalculator = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    // const { token: jwt } = useAuth();
    const jwt = 0;
    
    const getCalculation = async () => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/BankCalculator", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        })
        var respData = await resp.json();
        await setData(respData);
        return respData;
    }

    const saveCalculation = async (calcObj) => {
        // dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/BankCalculator", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(calcObj)
            })
            await resp.json();
            setData(calcObj);
            // dispatch(uiActions.setAlert({type: "success", message: "Berekening is succesvol opgeslagen!"}));
            // dispatch(uiActions.hideBackdrop());
        } catch (err) {
            // dispatch(uiActions.setAlert({type: "danger", message: "Berekening kan niet opgeslagen worden!"}));
            // dispatch(uiActions.hideBackdrop());
        }
    }

    return {
        saveCalculation: saveCalculation,
        getCalculation: getCalculation,
        data: data
    }
    
}

export default useBankCalculator;