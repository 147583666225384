import { useState, useCallback, useEffect } from "react";
import useAuth from "hooks/use-auth";
import { aesDecrypt } from "lib/helpers";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

export const useEmail = (init, initQuery=null) => {
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();

    const [signature, setSignature] = useState("");
    const [signatures, setSignatures] = useState([]);

    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState([]);

    const [emailLog, setEmailLog] = useState("");
    const [emailLogs, setEmailLogs] = useState([]);
   
    const getEmails = useCallback(async () => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });

        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var respData = aesDecrypt(respText, iv);
        setEmails(respData);
        return "SUCCESS";
    }, [jwt]);


    const stateClearEmail = () => setEmail("");

    const getEmailById = useCallback(async (emailId) => {
        if(!emailId) return;
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/"+emailId, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var respData = aesDecrypt(respText, iv);
            setEmail(respData);
            return {
                message: "SUCCESS",
                data: respData
            }
        } catch (err) {
            return {
                message: "FAIL"
            }
        }
    }, [jwt]);

    const getEmailByReferenceId = useCallback(async (referenceId) => {       
        if(!referenceId) return;
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/Email/GetByReferenceId/${referenceId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var respData = aesDecrypt(respText, iv);
            setEmail(respData);
            return {
                message: "SUCCESS",
                data: respData
            }
        } catch (err) {
            return {
                message: "FAIL"
            }
        }
    }, [jwt]);

    const createEmail = useCallback(async (emailBody) => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(emailBody)
            });
    
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template succesvol aangemaakt", type: "success"}));
            return {
                message: "SUCCESS",
                data: respJson
            }
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template kan niet aangemaakt worden", type: "danger"}));
            return {
                message: "FAIL"
            }
        }
    }, [jwt]);

    const updateEmail = useCallback(async (emailId, emailBody) => {
        if(!emailId) return;
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/"+emailId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(emailBody)
            });
    
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template succesvol bijgewerkt", type: "success"}));
            return {
                message: "SUCCESS",
                data: respJson
            }
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template kan niet bijgewerkt worden", type: "danger"}));
            return {
                message: "FAIL"
            }
        }
    }, [jwt]);

    const deleteEmail = useCallback(async (emailId) => {
        if(!emailId) return;
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/?id="+emailId, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
            });
    
            // if(respJson?.message==="Record Deleted") {

            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template succesvol verwijderd", type: "success"}));
            return {
                message: "SUCCESS"
            }
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Template kan niet verwijderd worden", type: "danger"}));
            return {
                message: "FAIL"
            }
        }
    }, [jwt]);

    const getEmailSignatureById = useCallback(async (id) => {
        if(!id) return;
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/GetEmailSignature/"+id, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var respData = aesDecrypt(respText, iv);
        setSignature(respData);
        return "SUCCESS";
    }, [jwt]);

    const getEmailSignatures = useCallback(async () => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/getEmailSignatures", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var respData = aesDecrypt(respText, iv);
        setSignatures(respData);
        return "SUCCESS";
    }, [jwt]);

    const getEmailLogById = useCallback(async (id) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/EmailLogs/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var respData = aesDecrypt(respText, iv);
        setEmailLog(respData);
        return "SUCCESS";
    }, [jwt]);

    const getEmailLogs = useCallback(async (query) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/EmailLogs${query ? "?"+query : ""}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var respText = await resp.text();
        var responseIv = resp.headers.get("key");
        var respData = aesDecrypt(respText, responseIv);
        setEmailLogs(respData);
        return "SUCCESS";
    }, [jwt]);

    const sendEmailWithAttachment = useCallback(async (toWho, dealId, dealerId, customerId, activityId, emailId, signatureId, attachmentIds, ticketId) => {
        // {
        //     "emailId": 0,
        //     "ticket_id": 0,
        //     "attachment_ids": [
        //       0
        //     ]
        //   }

        var body = {
            "emailId": +emailId,
            "ticket_id": +ticketId,
            "attachment_ids": attachmentIds
        }

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/SendEmailWithAttachment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt,
                    "dealID": dealId,
                    "signatureId": signatureId,
                    ...(activityId && {"ActivityId": activityId})

                },
                body: JSON.stringify(body)
            });
            return "SUCCESS";
        } catch (err) {
            return "FAIL";
        }

    }, [jwt]);

    const sendEmail = useCallback(async (sourceOfComponent="deal", toWho, dealId, dealerId, customerId, activityId, emailId, signatureId, attachmentIds, ticketId) => {
        // console.log(toWho, dealId, dealerId, customerId, activityId, emailId, signatureId, attachmentIds);
        // Wait for 2000 milliseconds (2 seconds) inline
        // var returnValue = await new Promise(resolve => setTimeout(function() { resolve("SUCCESS")}, 2000));
        // return returnValue;
        
        if(attachmentIds?.length) {
            var resp = await sendEmailWithAttachment(toWho, dealId, dealerId, customerId, activityId, emailId, signatureId, attachmentIds, ticketId);
            return resp;
        }
        // if(!emailId) return;
        // {
        //     "emailId": 0,
        //     "ticket_id": 0,
        //     "attachment_ids": [
        //       0
        //     ]
        //   }

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Email/SendMail?EmailId="+emailId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt,
                    ...(dealId && {"DealID": dealId}),
                    ...( (dealerId && sourceOfComponent==="dealer") && {"DealerID": dealerId}),
                    "SignatureId": signatureId,
                    ...(activityId && {"ActivityId": activityId})
                }
            });
            var respJson = await resp.text();
            return "SUCCESS";
        } catch (err) {
            return "FAIL";
        }
    }, [jwt]);
   
    useEffect(() => {
        if(init==="getEmails") getEmails();
        if(init==="getEmailByReferenceId") getEmailByReferenceId(initQuery);
        if(init==="getEmailById") getEmailById(initQuery);
        if(init==="getEmailSignatures") getEmailSignatures();
        if(init==="getEmailLogs") getEmailLogs(initQuery);
        if(init==="getEmailLogById") getEmailLogById(initQuery);
    },[getEmails,getEmailByReferenceId,getEmailById,getEmailSignatures,getEmailLogs, getEmailLogById]);

    return {
        email,
        emails,
        getEmails,
        getEmailById,
        getEmailByReferenceId,
        createEmail,
        updateEmail,
        deleteEmail,
        sendEmail,
        signature, 
        signatures,
        getEmailSignatures,
        getEmailSignatureById,
        emailLog,
        emailLogs,
        getEmailLogs,
        getEmailLogById,
        clearSignatureFromState: () => setSignature(""),
        stateClearEmail
    }
}

export default useEmail;