import { uiActions } from "./ui-slice";
import { dossierActions } from "./dossier-slice";
import { calcAgeInMonths, calcMaxDuration, localDate } from "lib/calc";
import { consoleLog, monthLabels, removeStorageItem } from "lib/helpers";
import { progressActions } from "./progress-slice";
import { dealActions } from "./deal-slice";
import { calcRestBpm } from "components/Calculator/helpers";

export const getCarByLicensePlate = (kenteken) => {
    return async dispatch => {
        try {
            const voertuigData = fetch("https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken="+kenteken).then((res) => res.json()).catch(err => {});
            const brandstofData = fetch("https://opendata.rdw.nl/resource/8ys7-d773.json?kenteken="+kenteken).then((res) => res.json()).catch(err => {});            
            const allData = Promise.all([voertuigData, brandstofData]);

            // ...(voertuig && {...voertuig[0]}),
            // ...(brandstof && {...brandstof[0]}),

            allData.then(([voertuig, brandstof]) => {

                var error = {
                    show: false,
                    title: "",
                    message: ""
                }

                const extendedVoertuigData = {
                    ...voertuig[0],
                    ...brandstof[0],
                    brandstof: (brandstof[0]?.brandstof_omschrijving ? brandstof[0]?.brandstof_omschrijving : '')
                }

                if (!allData) {
                    error.show = true;
                    error.title = "Ongeldig kenteken";
                    error.message = "Het kenteken dat je hebt opgegeven is onbekend";
                }

                var monthLabel = (new Date().getMonth()+1);
                var yearLabel = new Date().getFullYear()-11;
                
                if(monthLabel===12) {
                    yearLabel = yearLabel + 1;
                    monthLabel = 0;
                }

                var maxPossibleYearAndMonth = monthLabels[monthLabel].toLowerCase() + " " + (yearLabel);
                var age = calcAgeInMonths(extendedVoertuigData?.datum_eerste_toelating_dt);
                if( (age>=132)) {
                    // OUD VOERTUIG: 1-KXR-73
                    error.show = true;
                    error.title = `Het voertuig is te oud`;
                    error.message = `De minimale leeftijd van een voertuig is ${maxPossibleYearAndMonth} en het opgegeven voertuig is van ${localDate(extendedVoertuigData?.datum_eerste_toelating_dt)}.`;
                } 

                if(!/(Personenauto|Bedrijfsauto)/.test(extendedVoertuigData?.voertuigsoort)) {
                    // BROMFIETS: FGG-88-Z
                    // MOTOR: 71-MT-KN
                    error.show = true;
                    error.title = `Voertuigsoort ${extendedVoertuigData?.voertuigsoort} niet mogelijk`;
                    error.message = `We waarderen je interesse in financial lease voor je ${extendedVoertuigData?.voertuigsoort}.\nOp dit moment kunnen we echter geen aanvraag goedkeuren voor financiële ondersteuning van voertuigsoort ${extendedVoertuigData?.voertuigsoort}.\nWe staan klaar om je te helpen met eventuele andere vragen of financieringsbehoeften die je hebt.\nBedankt voor je begrip en neem gerust contact met ons op.`;
                }
                if(extendedVoertuigData?.export_indicator==="Ja") {
                    // EXPORT INDICATOR: NZ-703-T
                    error.show = true;
                    error.title = "Exportvoertuig niet mogelijk"
                    error.message = "Onze excuses, maar we kunnen geen financiering verstrekken voor exportvoertuigen.\nNeem contact met ons op als je vragen hebt over andere financieringsopties.\nBedankt voor je begrip.";
                }
                
                if(JSON.stringify(extendedVoertuigData).length<5 || !extendedVoertuigData?.voertuigsoort) {
                    // 123456
                    error.show = true;
                    error.title = "Kenteken onbekend bij de RDW";
                    error.message = "Onze excuses, maar het opgegeven kenteken is niet bekend bij de RDW (Rijksdienst voor het Wegverkeer).\nControleer het kenteken en probeer het opnieuw.";
                }

                if(error?.show) {
                    dispatch(uiActions.setNotification({
                        title: error.title,
                        visible: true,
                        message: error.message
                    }));
                    return;
                }

                extendedVoertuigData.age = age;
                extendedVoertuigData.grossBPM = extendedVoertuigData.bruto_bpm;
                extendedVoertuigData.restBPM = calcRestBpm(extendedVoertuigData?.voertuigsoort, age, extendedVoertuigData.grossBPM);
                extendedVoertuigData.massEmpty = extendedVoertuigData?.massa_ledig_voertuig ? parseInt(extendedVoertuigData?.massa_ledig_voertuig) : 0;

                var durationMax = calcMaxDuration(age);
                dispatch(dealActions.setCalcDetail({durationMax: durationMax}));
                dispatch(dossierActions.setCarDataByRDW(extendedVoertuigData));
                dispatch(dealActions.setVehicleDetailByRDW(extendedVoertuigData));
            });
      } catch(err) {
        dispatch(uiActions.setNotification({
            title: 'Ongeldig kenteken',
            message: 'Het kenteken dat je hebt opgegeven is onbekend.',
            visible: true
        }));
      };
    }
}

export const resetDossier = () => {
    return async (dispatch) => {
        try {
            await removeStorageItem(['berekeningstate', 'kvkDatastate', 'aanvullendegegevensstate', 'voertuigstate', 'inlossingstate']);   
            dispatch(progressActions.reset());
            dispatch(dossierActions.clearAdditionalFieldData());
            dispatch(dossierActions.clearKvKData());

        } catch (err) {
            consoleLog(err.message, true);
        }

    }
}