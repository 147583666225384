import useAuth from "hooks/use-auth";
import { mapToIdAndLabel } from "lib/helpers";
import { useState, useCallback } from "react";

const useExternalVehicle = () => {
    const { token: jwt } = useAuth();

    const [make, setMake] = useState("");
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);

    const getUniqueMakeModel = useCallback(async (make) => {
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/ExternalVehicle/UniqueMakeModel${make ? "?make="+make : ""}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ jwt 
                }
            });
            var respJson = await resp.json();
            var transformedMakes = mapToIdAndLabel(respJson.make);
            var transformedModels = mapToIdAndLabel(respJson.model);
            setMakes(transformedMakes);
            setModels(transformedModels);
            return "SUCCESS";
        } catch (err) {
            console.log("Fout bij het ophalen van UniqueMakeModel");
            return "FAIL";
        }
    },[]);

    return {
        make,
        makes,
        models,
        getUniqueMakeModel
    };
}

export default useExternalVehicle;