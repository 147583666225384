import AdminDealList from "components/Tables/AdminDealList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ContractAfronding = () => {
    const [transformedPhaseRecords, setTransformedPhaseRecords] = useState([]);
    const { phaseRecords } = useSelector(state => state.counter);

    useEffect(() => {
        setTransformedPhaseRecords(Object.values(phaseRecords));
    },[phaseRecords]);

    return (
        <>
        <details open>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Contract opgevraagd</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Contract wordt opgesteld door de bank").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="Contract wordt opgesteld door de bank" />
        </details>
        <details>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Contract bij de klant</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Contract ter ondertekening bij de klant").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="Contract ter ondertekening bij de klant" />
        </details>
        <details>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Contract bij de dealer</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Contract ter ondertekening bij u").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="Contract ter ondertekening bij u" />
        </details>
        <details>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Wachtend op tenaamstelling</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="U dient het voertuig op naam van de klant te registreren").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="U dient het voertuig op naam van de klant te registreren" />
        </details>
        <details>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Uitbetaling aangevraagd</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="In afwachting van uitbetaling").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="In afwachting van uitbetaling" />
        </details>
        <details>
            <summary className="tracking-tighter lg:tracking-normal text-sm md:text-base lg:text-xl cursor-pointer mb-5 select-none"><div className="inline-flex flex-row items-center gap-x-2"><span>Bevestiging van uitbetaling</span><span className="">({transformedPhaseRecords.filter(item => item.phase==="Bevestiging van uitbetaling").map(item => item.totalRecords)})</span></div></summary>
            <AdminDealList phase="Bevestiging van uitbetaling" />
        </details>
        </>
    )
}

export default ContractAfronding;