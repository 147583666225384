import { useCallback, useEffect, useState } from "react";

export const useRdw = (init, initQuery=null) => {
    const [rdwData, setRdwData] = useState(null);

    const getByLicensePlate = useCallback(async (licensePlate) => {
        if(!licensePlate) return;
        var licensePlate = licensePlate?.replace(/\-/g, "").toUpperCase();
        var resp = await fetch("https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken="+licensePlate);
        var respJson = await resp.json();
        setRdwData(respJson[0]);
        return respJson;
    }, []);

    useEffect(() => {
        if(init==="getByLicensePlate") getByLicensePlate(initQuery);
    }, []);
    
    return {
        rdwData,
        getByLicensePlate
    }

}

export default useRdw;