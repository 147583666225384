import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Content from "components/Content/Content";
import Container from "components/hoc/Container";
import Button from "components/ui/Button/Button";

import useAnalytics from "hooks/use-analytics";
import useDealV2 from "hooks/deal/use-deal_v2";

const BtwFinancieringBedankt = () => {
  const navigate = useNavigate();
  const { clearDealFromStore } = useDealV2();
  const { sendPageView } = useAnalytics();


  useEffect(() => {
    sendPageView({category: "financiallease", action: "View", label: "Financial lease succesvol (bedankpagina)"});
    clearDealFromStore(false);
  },[sendPageView]);
 
  return (
    <>
      <Container>
        <Content
          title="Bedankt voor uw aanvraag!"
          description={["Wij hebben uw aanvraag in goede orde ontvangen.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen"]}
          contentAlign="left"
        />
        <div className="flex flex-row gap-5">
          <Button onClick={() => navigate("/")} className="btn-secondary mb-5">Home</Button>
        </div>
      </Container>
    </>
  );
};

export default BtwFinancieringBedankt;
