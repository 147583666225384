import { formattedPMT_v2 } from "lib/calc";
import { noDotAndInteger } from "lib/helpers";
import { BANK_SETTINGS } from "./banksettings";

export const getSelectOptions = (field) => {
  if(!field) return;
  var options = [];
  switch(field) {
      case "bankStatus":
          options = [{label: "Aanvraag ingediend ter beoordeling", value: "?"}, {label: "Aanvullende informatie benodigd", value: "!"}, {label: "Aanvraag goedgekeurd", value: "v"}, {label: "Aanvraag afgewezen", value: "x"}];
          break;
      default:
          break;
  }
  return options;
}

export const valuesToNoDot = (obj, transformInt0ToEmptyStr=true) => {
  var resp = {};
  Object.keys(obj).forEach((fieldName, index) => {
    if(/(provisiePercentage|clientInterestMax|basicInterest|interest)/.test(fieldName)) {
      // console.log(fieldName, obj[fieldName]);
      resp[fieldName] = +obj[fieldName];
    } else {
      resp[fieldName] = noDotAndInteger(obj[fieldName]);
    }
    if(transformInt0ToEmptyStr && resp[fieldName]===0) resp[fieldName] = "";

  });
  return resp;
}

export const valuesToStr = (obj) => {
  var resp = {};
  Object.keys(obj).forEach((fieldName, index) => {
      resp[fieldName] = ""+obj[fieldName];
  });
  return resp;
}

export const calcFinalPaymentMax = (amountpurchaseExclVAT, duration) => {

    // 0 t/m 24 months 55% over amountpurchaseExclVAT
    // 25 t/m 36 months 45% over amountpurchaseExclVAT
    // 37 t/m 48 months 35% over amountpurchaseExclVAT
    // 49 t/m 60 months 25% over amountpurchaseExclVAT
    // 61 t/m 72 months 15% over amountpurchaseExclVAT

    // Possibility to increased finalpayment with 5%
    if(!amountpurchaseExclVAT || !duration) return;
    amountpurchaseExclVAT = +amountpurchaseExclVAT;

    var finalpaymentMax;
    switch(true) {
        case +duration<=24: finalpaymentMax = amountpurchaseExclVAT*.55; break;
        case +duration<=36: finalpaymentMax = amountpurchaseExclVAT*.45; break;
        case +duration<=48: finalpaymentMax = amountpurchaseExclVAT*.35; break;        
        case +duration<=60: finalpaymentMax = amountpurchaseExclVAT*.25; break;    
        case +duration<=72: finalpaymentMax = amountpurchaseExclVAT*.15; break;      
        default: 
          finalpaymentMax = amountpurchaseExclVAT*.15;    
    }
    return Math.floor(finalpaymentMax);
}

export const calcFinalPaymentDefault = (amountpurchaseExclVAT, duration) => {
    var finalpayment = 0;
    switch(true) {
        case +duration<=24: finalpayment=amountpurchaseExclVAT*.55; break;
        case +duration<=36: finalpayment=amountpurchaseExclVAT*.45; break;
        case +duration<=48: finalpayment=amountpurchaseExclVAT*.35; break;
        case +duration<=60: finalpayment=amountpurchaseExclVAT*.25; break;
        case +duration<=72: finalpayment=amountpurchaseExclVAT*.10; break;
        default: finalpayment = 0;
    }
    return Math.floor(finalpayment);
}

export const calcVat2 = (inputField, inputAmountpurchase, inputAmountpurchaseExclVAT, vehicleType) => {
  var vat = 0, amountpurchase = inputAmountpurchase, amountpurchaseExclVAT = inputAmountpurchaseExclVAT;
  if(inputField==="amountpurchase") {
    amountpurchaseExclVAT = (inputAmountpurchase/1.21).toFixed(0);
  }
  if(inputField==="amountpurchaseExclVAT" && vehicleType!=="AUTO") {
    amountpurchase = (inputAmountpurchaseExclVAT*1.21).toFixed(0);
  }
  vat = amountpurchase - amountpurchaseExclVAT;
  vat = vat.toFixed(0);
  return {
      amountpurchase: amountpurchase,
      amountpurchaseExclVAT: amountpurchaseExclVAT,
      vat: vat
  }
}

export const calcVat = (amountpurchase, amountpurchaseExclVAT) => {
  if(!amountpurchase || !amountpurchaseExclVAT) return 0;
  var vat = amountpurchase - amountpurchaseExclVAT;
  return vat;
}

export const calcLeaseAmount = (typeOfDeal, amountpurchase, amountpurchaseExclVAT, downpayment=0, tradeInAmount=0, repayment=0) => {
    amountpurchase=noDotAndInteger(amountpurchase);
    amountpurchaseExclVAT=noDotAndInteger(amountpurchaseExclVAT);
    downpayment=noDotAndInteger(downpayment);
    tradeInAmount=noDotAndInteger(tradeInAmount);
    repayment=noDotAndInteger(repayment);
    return (/btw/i.test(typeOfDeal)) ? (amountpurchaseExclVAT-downpayment-tradeInAmount)+repayment : (amountpurchase-downpayment-tradeInAmount)+repayment;
}

export const calcTotalToPay = (typeOfDeal, downpayment, vat) => {
    vat = +vat;
    downpayment = +downpayment;
    if (!/btw/i.test(typeOfDeal)) vat = 0;
    return vat+downpayment;
}

export const calcExPrice = (typeOfDeal, amountpurchase, restBPM=0, vehicleType="AUTO") => {
    // formule: ((amountpurchase-restBPM)/1.21)+restBPM;
    if(!amountpurchase || !typeOfDeal) return;
    if(/marge/i.test(typeOfDeal)) return amountpurchase;
    if(/bedrijf/i.test(vehicleType)) restBPM = 0;
    var amountpurchaseExclVAT = ((amountpurchase-restBPM)/1.21)+restBPM;
    amountpurchaseExclVAT = parseFloat(amountpurchaseExclVAT);
    amountpurchaseExclVAT = amountpurchaseExclVAT.toFixed(0);
    return amountpurchaseExclVAT;
}

export const calcRestBpm = (vehicleType, vehicleAge, grossBPM) => {
    // formule: (grossBPM-(grossBPM*percentage))
    // console.log({vehicleType, vehicleAge, grossBPM});


    if(/BEDRIJF/i.test(vehicleType) || !vehicleAge) return 0;

    // when datePart1 is in the future, the age is negative. So set vehicleAge to 0
    if(vehicleAge<0) vehicleAge = 0;

    grossBPM = noDotAndInteger(grossBPM);

    const bpmSettings = [
        [0,0,12],  
        [1,12,4],
        [3,20,3.5],
        [5,27,1.5],
        [9,33,1],
        [18,42,0.75],
        [30,51,0.5],
        [42,57,0.42],
        [54,62,0.42],
        [66,67,0.42],
        [78,72,0.25],
        [90,75,0.25],
        [102,78,0.25],
        [114,81,0.19]
    ].reverse();

    var rekenParameter = false;
    var rekenIndex;
    bpmSettings.forEach(function(row, index) {
        if(!rekenParameter && row[0]<=vehicleAge) {
            rekenParameter = row[0];  
            rekenIndex = index;
        }
    });
    var resterendeMaanden = vehicleAge - rekenParameter;

    var percentage1 = bpmSettings[rekenIndex][1];
    var percentage2 = bpmSettings[rekenIndex][2] * resterendeMaanden;

    var rekenPercentage = percentage1 + percentage2;
    var restBPM = Math.ceil(grossBPM-(grossBPM*rekenPercentage/100));
    return restBPM;
}

export const calcProvisieAmount = (sourceOfDealer="SLD", chosenBank, duration, monthlyamount, finalpayment, leaseAmount, provisiepercentage, dealerCategory, downpayment=0, tradeInAmount=0, repayment=0) => {
  // console.log(chosenBank, duration, monthlyamount, finalpayment, leaseAmount, provisiepercentage, dealerCategory, downpayment=0, tradeInAmount=0, repayment=0);
  if(!dealerCategory) dealerCategory = "B";
  var commissionTotal, commissionAmountDealer, commissionAmountSLD;
  duration = +duration;
  monthlyamount = +monthlyamount;
  finalpayment = +finalpayment;
  leaseAmount = +leaseAmount;
  if(downpayment) downpayment = +downpayment;
  if(tradeInAmount) tradeInAmount = +tradeInAmount;
  var typeCalculation = 1;
  if(chosenBank==="df" || /^df_/.test(chosenBank) || /^hi/.test(chosenBank)) typeCalculation = 2;
  if(/^al/.test(chosenBank)) typeCalculation = 3;
  
  // console.log({typeCalculation, duration, leaseAmount, monthlyamount, finalpayment, leaseAmount, provisiepercentage});
  if(typeCalculation===1) commissionTotal = leaseAmount*(provisiepercentage/100);
  if(typeCalculation===2) commissionTotal = ((duration * monthlyamount + finalpayment) - leaseAmount) * (provisiepercentage / 100);
  if(typeCalculation===3) commissionTotal = Math.abs((leaseAmount-repayment))*(provisiepercentage/100);
 
  if(dealerCategory==="D") dealerCategory = 1;
  if(dealerCategory==="C") dealerCategory = 2;
  if(dealerCategory==="B") dealerCategory = 3;
  if(dealerCategory==="A") dealerCategory = 4;
  if(dealerCategory==="E") dealerCategory = 5;

  var provisietarieven = {
      1: [.7, .3], // 70/30
      2: [.6, .4], // 60/40
      3: [.5, .5], // 50/50
      4: [.4, .6], // 40/60,
      5: [.3, .7]  // 30/70
  }

  commissionAmountDealer = commissionTotal*provisietarieven[dealerCategory][1];
  commissionAmountSLD = commissionTotal*provisietarieven[dealerCategory][0];

  if(dealerCategory===2 && sourceOfDealer==="Actua") {
    commissionAmountDealer = commissionAmountDealer+50
    commissionTotal = commissionTotal+50;
  };

  commissionTotal = commissionTotal.toFixed(2);
  commissionAmountDealer = commissionAmountDealer.toFixed(2);
  commissionAmountSLD = commissionAmountSLD.toFixed(2);

  return {commissionTotal, commissionAmountSLD, commissionAmountDealer};
}

// https://gist.github.com/kucukharf/677d8f21660efaa33f72
export const rate = (periods, payment, present, future, type, guess)  => {
    guess = (guess === undefined) ? 0.01 : guess;
    future = (future === undefined) ? 0 : future;
    type = (type === undefined) ? 0 : type;
  
    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10;
  
    // Set maximum number of iterations
    var iterMax = 10;
  
    // Implement Newton's method
    var y, y0, y1, x0, x1 = 0,
      f = 0,
      i = 0;
    var rate = guess;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = present + payment * periods + future;
    y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
    i = x0 = 0;
    x1 = rate;
    while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);
      x0 = x1;
      x1 = rate;
        if (Math.abs(rate) < epsMax) {
          y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
        } else {
          f = Math.exp(periods * Math.log(1 + rate));
          y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
      y0 = y1;
      y1 = y;
      ++i;
    }
    return rate;
};

export const PMTx = (ir, np, pv, fv, type) => {
    var interest = +ir;
    if(ir) ir = +ir;
    if(np) np = +np;
    if(pv) pv = -pv;
    if(fv) fv = +fv;

    var pmt, pvif;
    fv || (fv = 0);
    type || (type = 0);
    ir = ir / 100 / 12;
    if (ir === 0) return -(pv + fv) / np;
    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);
    if (type === 1) pmt /= 1 + ir;
    pmt = pmt.toFixed(2);
    // console.log({interest, np, pv, fv, type, pmt});

    return pmt;
};

function evaluateCondition(condition, leaseAmount) {
  // Split the condition into parts
  const parts = condition.split(/([<>!=]=?)/);

  // Extract the variable name and operator
  const variable = parts[0].trim();
  const operator = parts[1].trim();
  const value = parseFloat(parts[2].trim());

  // Evaluate the condition
  switch (operator) {
    case "<":
      return leaseAmount < value;
    case "<=":
      return leaseAmount <= value;
    case ">":
      return leaseAmount > value;
    case ">=":
      return leaseAmount >= value;
    case "==":
    case "=":
      return leaseAmount === value;
    case "!=":
      return leaseAmount !== value;
    default:
      throw new Error("Invalid operator in condition: " + operator);
  }
}

export const getInterestIndexFromConditions = (leaseAmount, conditions) => {
  if(!leaseAmount || !conditions) return 0;
    // Evaluate each condition
    for (let i = 1; i < conditions.length; i++) {
      if (evaluateCondition(conditions[i], leaseAmount)) {
        return i; // Return the index of the first condition that is satisfied
      }
    }
    // If none of the conditions are true, return -1
    return 1;
}


export const getInterestIndex = (chosenBank, leaseAmount) => {
  var interestIndex = 0;
  if(/^al/.test(chosenBank)) {
      if(leaseAmount>=15000 && leaseAmount<25000) interestIndex = 1;
      if(leaseAmount>=25000) interestIndex = 2;
  }
  if(chosenBank==="fi") {
    if(leaseAmount>=5000&&leaseAmount<=7499) interestIndex = 1;
    if(leaseAmount>=7500&&leaseAmount<=14999) interestIndex = 2;
    if(leaseAmount>=15000&&leaseAmount<250000) interestIndex = 3;
  }
  if(chosenBank==="dfrr") {
    if(leaseAmount<=12499) interestIndex = 1;
    if(leaseAmount>=12500&&leaseAmount<=24999) interestIndex = 2;
    if(leaseAmount>=25000) interestIndex = 3;
  }
  return interestIndex;
}

export const calcNominalInterest = function(rate, periods=12) {
  var interest = rate/100;
  // https://cdn.jsdelivr.net/gh/formulajs/formulajs@2.9.3/dist/formula.js
  periods = parseInt(periods, 10);
  // Return nominal annual interest rate
  var nominalInterest = ((Math.pow(interest + 1, 1 / periods) - 1) * periods)*100;
  return nominalInterest;
};

export const calcRealCommissionPerc = (commissionAmountDealer,leaseAmount) => {
  commissionAmountDealer = +commissionAmountDealer;
  leaseAmount = +leaseAmount;
  // console.log(commissionAmountDealer, leaseAmount);
  var commissionPerc = (commissionAmountDealer/leaseAmount*100).toFixed(2);
  if(commissionPerc.length===1) {
    commissionPerc = commissionPerc + ".00";
  }
  
  return formattedPMT_v2(commissionPerc);
}

export const getBankSelectOptions = (chosenBank, calculatorGoal) => {
  var options = BANK_SETTINGS?.filter(item => !item.disabled).map(item => ({
      label: item.name,
      value: item.shortcode 
  }));

  // get all disabled Banks
  var disabledBanks = BANK_SETTINGS.filter(item => item.disabled).map(item => item.shortcode);

  if(disabledBanks.includes(chosenBank) && calculatorGoal!=="bank") {
      // when chosenBank is disabled. Add bank to options
      options = [
          ...BANK_SETTINGS.filter(item => item.shortcode===chosenBank).map(item => ({
              label: item.name,
              value: item.shortcode 
          })),
          ... options
      ];
  };
  return options;
}