import { useForm } from "react-hook-form";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";
import useAuthenticate from "hooks/authenticate/use-authenticate";

const NewAdminForm = () => {
    const { registerAdmin } = useAuthenticate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            password: "",
            email: "",
            sourceOfUser: "sld"
        }
    });

    const { email, password, sourceOfUser } = watch();

    const submitHandler = (data) => {
      var formData = new FormData();
      formData.append("userName", data.email);
      formData.append("email", data.email);
      formData.append("password", data.password);
      registerAdmin(formData, data.sourceOfUser);
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Card title="Nieuwe beheerder">
                <div className="bg-white grid grid-cols-3 items-start group">
             <div className="grid col-span-2 gap-3 p-5">
                  {/* <Input3 name="userName" placeholder="Gebruikersnaam" register={register} fieldToValidate="Gebruikersnaam is een verplicht veld" inputValue={userName} errorMessage={errors?.userName?.message} /> */}
                  <Input3 name="email" placeholder="E-mailadres" register={register} fieldToValidate="email" inputValue={email} errorMessage={errors?.email?.message} />
                  <Input3 name="password" placeholder="Wachtwoord" register={register} fieldToValidate="Wachtwoord is een verplicht veld" inputValue={password} errorMessage={errors?.password?.message} type="password" />
                  <Select3 disableFirst={true} name="sourceOfUser" register={register} fieldToValidate="Verplicht veld" placeholder="Waar wil je de beheerder toevoegen?" inputValue={sourceOfUser} errorMessage={errors?.sourceOfUser?.message} >
                    <option value="sld">SLD CRM</option>
                    <option value="actua">Actua CRM</option>
                  </Select3>
                </div>
                <div className="flex flex-col gap-5 p-5 bg-slate-50 h-full">

              <Button className="btn-primary">Registreren</Button>
            </div>
                </div>
            </Card>

{/* {
  "username": "string",
  "email": "user@example.com",
  "password": "string"
} */}

        </form>
    )
}

export default NewAdminForm;