import Button from "components/ui/Button/Button";
import { useRef } from "react";

const AddTask = (props) => {
    const todoRef = useRef();

    const submitHandler = (evt) => {
        evt.preventDefault();       
        props.onAdd(todoRef.current?.value);
        todoRef.current.value = "";
    }

    return (
        <form onSubmit={submitHandler} className="flex flex-row w-3/4">
            <input spellCheck={false} ref={todoRef} className="w-full p-3 border rounded-xl border-slate-300 !rounded-r-none" type="text" placeholder="To-do toevoegen" />
            <Button className="btn-primary !rounded-l-none hover:!scale-100 text-2xl hover:!opacity-80">+</Button>
        </form>
    )
}

export default AddTask;