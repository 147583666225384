import { useState } from "react";
import Button from "components/ui/Button/Button";
import useCustomerV2 from "hooks/customer/use-customer_v2";
import useDealV2 from "hooks/deal/use-deal_v2";
import useDealerV2 from "hooks/dealer/use-dealer_v2";
import { usePartner } from "hooks/partner/use-partner";
import useActivity from "hooks/activity/use-activity";
import { getFormattedLicensePlate } from "lib/helpers";

const Export = () => {
    const [downloadOpen, setDownloadOpen] = useState(false);
    const { getCustomers } = useCustomerV2();
    const { getDeals, getArchivedDeals } = useDealV2();
    const { getDealers } = useDealerV2();
    const { getPartners } = usePartner();
    const { getActivities } = useActivity();

    const mapDeal = (dealData) => {
        // add formattedLicensePlate
        var transformedData = dealData?.map(item => {
            return {
                ...item,
                vehicleDetail: {
                    ...item.vehicleDetail,
                    licensePlate: getFormattedLicensePlate(item?.vehicleDetail?.licensePlate)
                }
            }
        });
        return transformedData;
    }

    const handleExport = async (whatToExport) => {
        var jsonData;
        switch(whatToExport) {
            case "administrationCost":
                jsonData = await getDeals("pageSize=999999&phase=Lopend");
                jsonData.data = mapDeal(jsonData.data);
                break;
            case "customers":
                jsonData = await getCustomers("pageSize=999999");
                break;
            case "deals":
                jsonData = await getDeals("pageSize=999999&isArchive=false");
                jsonData.data = mapDeal(jsonData.data);
                break;
            case "archivedDeals":
                jsonData = await getArchivedDeals("pageSize=999999");
                jsonData.data = mapDeal(jsonData.data);
                break;
            case "dealers":
                jsonData = await getDealers("?pageSize=9999999")
                break;
            case "partners":
                jsonData = await getPartners("pageSize=999999");
                break;
            case "activities":
                jsonData = await getActivities("?pageSize=999999");
                break;
            default: 
                break;
        }
        if(!jsonData?.data) return;
        
        const jsonStr = JSON.stringify(jsonData.data, null, 2);
        const blob = new Blob([jsonStr], { type: 'application/json' });
    
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = whatToExport+"-"+new Date().toLocaleDateString("nl-NL")+" "+new Date().toLocaleTimeString("nl-NL");
        link.download = `${fileName}.json`;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const downloadBtnClasses = "flex flex-row-reverse justify-between items-center gap-x-2 border-b bg-white py-1 px-5 border-slate-500 cursor-pointer hover:bg-blue hover:text-white";

    return (
        <div className="flex flex-col gap-x-10">
            <div className="group">
                <Button onClick={() => setDownloadOpen(prevState => !prevState)} className={`flex flex-row gap-x-2`}>
                    <span>Kies export</span>
                    <span className="fa fa-arrow-down"></span>
                </Button>
                {downloadOpen &&
                <div className="flex flex-col max-w-[240px] border">
                    <div className={downloadBtnClasses} onClick={() => handleExport('administrationCost')}><span className="fa fa-download"></span><span>Administratiekosten</span></div>
                    <div className={downloadBtnClasses} onClick={() => handleExport('deals')}><span className="fa fa-download"></span><span>Verkopen</span></div>
                    <div className={downloadBtnClasses} onClick={() => handleExport('archivedDeals')}><span className="fa fa-download"></span><span>Verkopen (archief)</span></div>
                    <div className={downloadBtnClasses} onClick={() => handleExport('customers')}><span className="fa fa-download"></span><span>Klanten</span></div>
                    <div className={downloadBtnClasses} onClick={() => handleExport('dealers')}><span className="fa fa-download"></span><span>Autobedrijven</span></div>
                    <div className={downloadBtnClasses} onClick={() => handleExport('activities')}><span className="fa fa-download"></span><span>Activiteiten</span></div>
                    <div className={`${downloadBtnClasses} border-b-0`} onClick={() => handleExport('partners')} ><span className="fa fa-download"></span><span>Partners</span></div>
                    
                </div>
                }
            </div>

        </div>
    )
}

export default Export;