import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "./use-auth";
import { uiActions } from "store/ui-slice";
import { aesDecrypt, getDateTimeFromDate } from "lib/helpers";

const useActivities = () => {
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();

    const [activity, setActivity] = useState({});
    const [activities, setActivities] = useState([]);

    const getActivities = useCallback(async (id, activityType, activityParams, showBackdrop=true) => {
      var endpoint = "ActivitiesDeal/Deals";
      if(activityType==="dealerActivity") endpoint = "ActivitiesDealer/GetByDealerId";
    
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/'+endpoint+'/'+id+(activityParams ? '?type='+activityParams : ''), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
          });

          var respData;
          if(activityType==="dealerActivity") {
            respData = await resp.json();
          } else {
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            respData = aesDecrypt(respText, iv);
          }

          var sortedData = respData.reverse();
          var transformedData = sortedData.map(activity => {
            var createdDateTime = getDateTimeFromDate(activity.createdDate);
            return {
                ...activity,
                createdDateTime
            }
          });
          setActivities(transformedData); 
          dispatch(uiActions.hideBackdrop());
          return transformedData;
        },[dispatch, jwt]);

        const getActivityById = useCallback(async (activityID, activityType) => {
          var endpoint = "ActivitiesDeal";
          if(activityType==="dealerActivity") endpoint = "ActivitiesDealer";

          var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/'+endpoint+'/'+activityID, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + jwt
              }
          });

          var respData;
          if(activityType==="dealerActivity") {
            respData = await resp.json();
          } else {
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            respData = aesDecrypt(respText, iv);
          }

          setActivity(respData);
          dispatch(uiActions.hideBackdrop());
          },[dispatch, jwt]);

        const createActivity = useCallback( async (formData, activityType, activitySubType) => {
          var endpoint = "ActivitiesDeal";
          if(activityType==="document") endpoint = "DocumentDeal";
          if(activitySubType==="dealerActivity") endpoint = "ActivitiesDealer";

          try {
          var data = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/'+endpoint, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt
            },
            body: formData
         });
          if (data.status!==200) throw new Error();
          var message = (activityType==="document") ? "Document succesvol geupload" : "Activiteit succesvol aangemaakt";
          dispatch(uiActions.setAlert({ message: message, type: "success" }));
          dispatch(uiActions.hideBackdrop());
          return "SUCCESS";
         } catch (err) {
          var message = (activityType==="document") ? "Fout bij het uploaden van het document" : "Fout bij het aanmaken van de activiteit";
          dispatch(uiActions.setAlert({ message: message, type: "danger" }));
          dispatch(uiActions.hideBackdrop());
          return "FAIL";
         }
        },[dispatch, jwt]);

        const updateActivity = useCallback(async (activityID, formData, activityType) => {
          var endpoint = "ActivitiesDeal/";
          if(activityType==="dealerActivity") endpoint = "ActivitiesDealer?Id=";

          try {
            const resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/'+endpoint+activityID, {
              body: formData,
              method: "PUT",
              headers: {
                  'Authorization': 'Bearer ' + jwt
              }
            })
            var data = resp.json();
            setActivity(data);
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
          } catch (err) {
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
          }

        },[dispatch, jwt]);

        const clearActivityId = useCallback(() => {
          setActivity({});
          dispatch(uiActions.hideBackdrop());
        },[]);

        return {
            activity,
            activities,
            getActivities,
            getActivityById,
            createActivity,
            updateActivity,
            clearActivityId
        }
}

export default useActivities;