import { useForm } from "react-hook-form";
import Button from "components/ui/Button/Button";
import Input3 from "components/ui/Input/Input3";
import { serialize } from "lib/helpers";
import { useSearchParams } from "react-router-dom";

export const Search = ({archive, onUpdateDeals}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const { watch, register, handleSubmit, reset, setValue } = useForm({
        mode: "onChange",
        delayError: 750,
        defaultValues: {
            "dealID": "",
            "licensePlate": "",
            "companyName": "",
            "phoneNumber": "",
            "isArchive": archive
        }
    });

    const submitHandler = (data) => {
        var serialized = serialize(data);
        setSearchParams(serialized);
        onUpdateDeals(serialized);
    }

    const resetHandler = () => {
        reset();
        setSearchParams("");
        onUpdateDeals("isArchive="+isArchive);
    }
    const { dealID, licensePlate, companyName, phoneNumber, isArchive } = watch();

    const toggleArchiveHandler = () => {
        setValue("isArchive", !isArchive);
        handleSubmit(submitHandler)();
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)} className="flex flex-col">
        <div className=" max-w-md">
        <div className="grid grid-cols-2 gap-x-3">
            <Input3 name="dealID" register={register} inputValue={dealID} placeholder="Verkoop ID" />
            <Input3 name="licensePlate" register={register} inputValue={licensePlate} placeholder="Kenteken" />
            <Input3 name="companyName" register={register} inputValue={companyName} placeholder="Bedrijfsnaam" />
            <Input3 name="phoneNumber" register={register} inputValue={phoneNumber} placeholder="Telefoonnummer" />
        </div>
        <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-x-1 items-center cursor-pointer select-none" onClick={resetHandler}>
                <span className="fa fa-square-xmark"></span>
                <span className="text-xs">Reset</span>
            </div>
            <Button type="submit">Zoeken</Button>
        </div>
        </div>
        <div className="flex flex-row justify-between items-center my-10">
                <h3 className="font-semibold text-xl select-none">{!isArchive ? "Openstaande aanvragen" : "Gearchiveerd"}</h3>
                <Button type="button" className="btn-secondary" onClick={toggleArchiveHandler}>{isArchive ? "Actueel": "Archief"}</Button>
    
            </div>
    </form>
    )
}

export default Search;