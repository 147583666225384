import { aesDecrypt, aesEncrypt, consoleLog } from "lib/helpers";

// https://gist.github.com/kucukharf/677d8f21660efaa33f72
export const rate = (periods, payment, present, future, type, guess)  => {
    // console.log("RATE"+JSON.stringify({periods, payment, present, future, type, guess}));

    guess = (guess === undefined) ? 0.01 : guess;
    future = (future === undefined) ? 0 : future;
    type = (type === undefined) ? 0 : type;
  
    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10;
  
    // Set maximum number of iterations
    var iterMax = 10;
  
    // Implement Newton's method
    var y, y0, y1, x0, x1 = 0,
      f = 0,
      i = 0;
    var rate = guess;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = present + payment * periods + future;
    y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
    i = x0 = 0;
    x1 = rate;
    while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);
      x0 = x1;
      x1 = rate;
        if (Math.abs(rate) < epsMax) {
          y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
        } else {
          f = Math.exp(periods * Math.log(1 + rate));
          y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
      y0 = y1;
      y1 = y;
      ++i;
    }
    return rate;
};

export const dotAndInteger = (inputValue) => {
    if(!inputValue) return;
    inputValue = inputValue.replace(/[^\d]/g,"");
    if(inputValue==="0") return inputValue;
    return +inputValue;
}

export const calcMaxDuration = (voertuigleeftijd, divider=3) => {
    if(!voertuigleeftijd) return;
    var max_age = 12 * 12; // 12 jaar x 12 maanden = 144 maanden
    var resterende_maanden = max_age-voertuigleeftijd;
    var max_looptijd;
    if(resterende_maanden>=72) {
        max_looptijd = 72;
    } else if (resterende_maanden % divider === 0) {
        max_looptijd = resterende_maanden;
    } else {
        var restmaanden = resterende_maanden % divider;
        max_looptijd = resterende_maanden-restmaanden;
    }   
    return max_looptijd;
}

export const calcNominaleRente = function(rate, periods=12) {
    var rente = rate/100;
    // https://cdn.jsdelivr.net/gh/formulajs/formulajs@2.9.3/dist/formula.js
    periods = parseInt(periods, 10);
    // Return nominal annual interest rate
    var nomimale_rente = ((Math.pow(rente + 1, 1 / periods) - 1) * periods)*100;
    return nomimale_rente;
  };


export const calcDefaultDuration = (max_looptijd) => {
    var default_looptijd = max_looptijd-(max_looptijd%12);
    if(default_looptijd===72) default_looptijd = 60;
    return default_looptijd;    
}

export const calcMaxLastAmount = (leasebedrag, looptijd) => {
    // 0 t/m 24 maanden 55% over het Leasebedrag
    // 25 t/m 36 maanden 45% over het Leasebedrag
    // 37 t/m 48 maanden 35% over het leasebedrag
    // 49 t/m 60 maanden 25% over het leasebedrag
    // 61 t/m 72 maanden 15% over het leasebedrag

    // Mogelijkheid om slottermijn met 5% te verhogen.
    if(!leasebedrag || !looptijd) return;
    leasebedrag = +leasebedrag;

    var max_slottermijn;
    switch(true) {
        case +looptijd<=24: max_slottermijn = leasebedrag*.55; break;
        case +looptijd<=36: max_slottermijn = leasebedrag*.45; break;
        case +looptijd<=48: max_slottermijn = leasebedrag*.35; break;        
        case +looptijd<=60: max_slottermijn = leasebedrag*.25; break;    
        case +looptijd<=72: max_slottermijn = leasebedrag*.15; break;      
        default: break;    
    }
    return Math.floor(max_slottermijn);
}

export const calcDefaultLastAmount = (leasebedrag, looptijd) => {
    var slottermijn = 0;
    switch(true) {
        case +looptijd<=24: slottermijn=leasebedrag*.55; break;
        case +looptijd<=36: slottermijn=leasebedrag*.45; break;
        case +looptijd<=48: slottermijn=leasebedrag*.35; break;
        case +looptijd<=60: slottermijn=leasebedrag*.25; break;
        case +looptijd<=72: slottermijn=leasebedrag*.10; break;
        default: slottermijn = 0;
    }
    return Math.floor(slottermijn);
}

export const sendRequest = async (requestConfig, sendType="", encryption=false) => {
    var body = requestConfig.body ? JSON.stringify(requestConfig.body) : null;
    var headers = requestConfig.headers ? requestConfig.headers : {};

    var iv;
    if(encryption) {
        var encryption = aesEncrypt(body).bodyEncrypted;
        body = encryption.bodyEncrypted;
        iv = encryption.iv;
        headers = {
            ...requestConfig.headers,
            "key": iv
        }
       
    }
    if(sendType==="formData") {
        body = requestConfig.body;
    }    
    const response = await fetch(requestConfig.url, {
        'method': requestConfig.method || 'GET',
        'headers': headers,
        'body': body
    });
   
    var responseData;
    if(encryption) {
        var responseText = await response.text().then(text => {
            return aesDecrypt(text, iv);
        });
        responseData = JSON.parse(responseText);
    } else {
        responseData = await response.json();
    }
    return responseData;
}

export const showDebug = (props) => {
    const {leasebedrag, aanbetaling, slottermijn, rente, looptijd, aanschafwaarde } = props;
    consoleLog("AANSCHAFWAARDE="+aanschafwaarde);
    consoleLog("LEASEBEDRAG="+leasebedrag);
    consoleLog("AANBETALING="+aanbetaling);
    consoleLog("SLOTTERMIJN="+slottermijn);
    consoleLog("RENTE="+rente);
    consoleLog("LOOPTIJD="+looptijd);
}

export const calcTotaleAanbetaling = (btw_marge='marge', aanbetaling=0, btw_bedrag=0) => { 
    var totale_aanbetaling;  
    aanbetaling = +aanbetaling;
    if (/marge/i.test(btw_marge)) btw_bedrag = 0;
    totale_aanbetaling=(btw_bedrag+aanbetaling);
    return totale_aanbetaling;
}

export const calcsrc_p = {
    fi: [1,1.5,2,2.5,3,3.5,4,4.5,5],
    fi_e: [1,1.5,2,2.5,3,3.5,4,4.5,5],
    df: [10,12.5,15,17.5,20,25],
    dfrr: [1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7],
    al: [1,2,3,4],
    hi: [2,2,3,3,5.5,8,9,10,10,10,12,13,15.5,18,19,20.5,22,24,26,28,28,30]
}

export const calcProvisieBedrag = (calcsrc, looptijd, leasetermijn, slottermijn, leasebedrag, provisiepercentage, dealerCategory, aanbetaling=0, inruil=0, inlossing=0) => {
    // if(!dealerCategory) dealerCategory = "B";
    // var provisietotaal, provisiebedrag_dealer, provisiebedrag_rest;
    // looptijd = +looptijd;
    // leasetermijn = +leasetermijn;
    // slottermijn = +slottermijn;
    // leasebedrag = +leasebedrag;
    // if(aanbetaling) aanbetaling = +aanbetaling;
    // if(inruil) inruil = +inruil;
    // // if(inlossing) inlossing = +inlossing;

    // /* typeBerekening
    //    1: leasebedrag*(provisiepercentage/100)
    //    2: ((looptijd*leasetermijn+slottermijn)-leasebedrag)*provisiepercentage
    // */
    // // console.log("calcProvisieBedrag", {calcsrc, looptijd, leasetermijn, slottermijn, leasebedrag, provisiepercentage, dealerCategory});

    // var typeBerekening = 1;
    // if(calcsrc==="df" || calcsrc === "hi") typeBerekening = 2;
    // if(calcsrc==="al") typeBerekening = 3;

    // if(typeBerekening===1) provisietotaal = leasebedrag*(provisiepercentage/100);
    // if(typeBerekening===2) provisietotaal = ((looptijd * leasetermijn +slottermijn) - leasebedrag) * (provisiepercentage / 100);
    // if(typeBerekening===3) provisietotaal = Math.abs((leasebedrag-inlossing))*(provisiepercentage/100);

    // // var provisiebedrag = Math.floor(((looptijd * leasetermijn +slottermijn) - leasebedrag) * (provisiepercentage / 100) * (provisietarieven[dealerClassificatie][1]));
    // // var provisiebedrag_sld = Math.floor(((looptijd * leasetermijn +slottermijn) - leasebedrag) * (provisiepercentage / 100) * (provisietarieven[dealerClassificatie][0]));
    
    // if(dealerCategory==="D") dealerCategory = 1;
    // if(dealerCategory==="C") dealerCategory = 2;
    // if(dealerCategory==="B") dealerCategory = 3;
    // if(dealerCategory==="A") dealerCategory = 4;

    // var provisietarieven = {
    //     1: [.7, .3], // 70/30
    //     2: [.6, .4], // 60/40
    //     3: [.5, .5], // 50/50
    //     4: [.4, .6]  // 40/60
    // }

    // provisiebedrag_dealer = provisietotaal*provisietarieven[dealerCategory][1];
    // provisiebedrag_rest = provisietotaal*provisietarieven[dealerCategory][0];

    // provisietotaal = provisietotaal.toFixed(2);
    // provisiebedrag_dealer = provisiebedrag_dealer.toFixed(2);
    // provisiebedrag_rest = provisiebedrag_rest.toFixed(2);

    // // console.log({provisietotaal, provisiebedrag_rest, provisiebedrag_dealer});
    // return {provisietotaal, provisiebedrag_rest, provisiebedrag_dealer};
}

export const calcExPrice = (prijs_incl, rest_bpm=0, decimalen=false) => {
    // formule: ((prijs_incl-rest_bpm)/1.21)+rest_bpm;
    if(!prijs_incl) {
        consoleLog("prijs_incl onbreekt!");
        return;
    };
    var prijs_excl = ((prijs_incl-rest_bpm)/1.21)+rest_bpm;
    if(decimalen) {
        prijs_excl = prijs_excl.toFixed(decimalen);
    }
    consoleLog(prijs_excl);
    return prijs_excl;
}

export const calcBtwBedrag = (prijs_incl, prijs_excl=0) => {
    // formule: prijs_incl - prijs_excl;
    if(!prijs_incl || !prijs_excl) {
        consoleLog("prijs_incl of prijs_excl ontbreekt!");
        return;
    }
    var btw_bedrag = prijs_incl - prijs_excl;
    consoleLog(btw_bedrag);
    return btw_bedrag;
}

export const dateWithoutToWithToZero = (inputDate) => {
    if(!inputDate) return;
    var dateArr = inputDate.split("-");
    var dag = +dateArr[0];
    var maand = +dateArr[1];
    var jaar = dateArr[2];
    if(dag<=9) dag="0"+dag;
    if(maand<=9) maand="0"+maand;
    var datum = ""+dag+"-"+maand+"-"+jaar;
    return datum;
}

export const dateToISO = (inputDate) => {
    if(!inputDate) return inputDate;
    var dateArr = inputDate.split("-");
    var day = parseInt(dateArr[0]);
    var month = parseInt(dateArr[1] - 1);
    var year = parseInt(dateArr[2]);
    var dateISO = new Date(year,month,day).toISOString();
    return dateISO;
}

export const getSpecialDay = () => {
    const today = new Date();
    var currentMonth = today.getMonth()+1;
    var currentYear = today.getFullYear();
    var firstDayOfMonth = new Date(currentMonth+"/1/"+currentYear);
    var firstDayOfYear = new Date("1/1/"+currentYear);
    var startDate = new Date("9/1/2023");

    return {
        firstDayOfYear,
        firstDayOfMonth,
        today,
        startDate
    }
}
export const localDate = (inputDateISO,disableInputNull=false) => {
    // var dateWithZ = inputDateISO;
    if(/\d{2}-\d{2}-\d{4}/.test(inputDateISO)) return inputDateISO;
    if(!inputDateISO&&disableInputNull) return "";
    var date = new Date(inputDateISO);
    var localDateStr = new Date(date).toLocaleDateString('nl-NL');
    var transformedDate = dateWithoutToWithToZero(localDateStr);
    return transformedDate; 
}

export const tommddyyyy = (inputDateISO) => {
    var nlDate = localDate(inputDateISO);
    var day = nlDate.slice(0,2);
    var month = nlDate.slice(3,5);
    var year = nlDate.slice(6,10);
    return month+"-"+day+"-"+year;
}

export const toyyyymmdd = (inputDate) => {
    var date = new Date(inputDate && inputDate);
    var yyyy = date.getFullYear();
    var mm = parseInt(date.getMonth()) + 1;
    if(mm<=9) mm = "0"+mm;
    var dd = parseInt(date.getDate());
    if(dd<=9) dd = "0"+dd;
    var result = yyyy+"-"+mm+"-"+dd;
    return result;
}

export const dateToDateFormat = (dutchDate) => {
    if(!dutchDate) return;
    dutchDate = dutchDate.replace(/T.*/,"");
    var splittedDate = dutchDate.split(/-|\//);
    var dateDay = +splittedDate[0];
    if(dateDay<=9) dateDay="0"+dateDay;
    var dateMonth = +splittedDate[1];
    if(dateMonth<=9) dateMonth="0"+dateMonth;
    var dateYear = splittedDate[2];
    var dateStr = dateYear+"/"+dateMonth+"/"+dateDay;
    var formattedDate = new Date(dateStr);
    return formattedDate;
}

export const calcAgeInMonths = (inputDate) => {
    if(!inputDate) return;
    var formattedInputDate;
    if(inputDate.length<=10) {
        formattedInputDate = dateToDateFormat(inputDate);
    } else {
        formattedInputDate = new Date(inputDate); 
    }
    var today = new Date();

    // consoleLog()
    var aantalmaanden = today.getMonth() - formattedInputDate.getMonth() + (12 * (today.getFullYear() - formattedInputDate.getFullYear())) + 1;
    var todayDay = today.getDate();
    var dateDay = formattedInputDate.getDate();
    if(todayDay < dateDay) aantalmaanden=aantalmaanden-1;
    return aantalmaanden;
}

export const formatThousandToNumber = (inputStr) => {
    if(!inputStr) return;
    var number = parseInt(inputStr.replace(/\./g,""))
    return number;
}

export const formatThousandNotation = (inputValue, returnNaN=true) => {
    if(!inputValue) return;
    inputValue = "" + inputValue;
    inputValue = inputValue.replace(/\./g,"");
    var f = /[,.](\d+)$/.exec(inputValue);
    inputValue = inputValue.replace(/[,.]\d+$/, "");
    var e = /^-/.test(inputValue);
    inputValue = inputValue.replace(/^-/, "");
    var d = inputValue.split("").reverse();
    inputValue = [];
    for (var a = 0; a < d.length; a++) {
        if (a && !(a % 3)) {
            inputValue.push(".")
        }
        inputValue.push(d[a])
    }
    var c = (e ? "-" : "") + inputValue.reverse().join("") + (f ? "," + f[1] : "");
    if(!returnNaN && isNaN(c)) return "";
    return c
}

export const PMTx = (ir, np, pv, fv, type) => {

    if(ir) ir = +ir;
    if(np) np = +np;
    if(pv) pv = -pv;
    if(fv) fv = +fv;
    // pv = -pv;
    // fv = +fv;

    // console.log({
    //     ir, np, pv, fv, type  
    // });

    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    ir = ir / 100 / 12;
    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;
    pmt = pmt.toFixed(2) //; .replace("-", "").replace(".", ",");
    // console.log("result: " + pmt);
    return pmt;
};

export const formattedPMT = (maandbedrag) => {
    if(!maandbedrag) return;
    var maandbedragStr = ""+maandbedrag;
    var maandbedragArr = maandbedragStr.split(".");

    var float = maandbedragArr[0];
    var last2decimals = maandbedragArr[1];
    if(last2decimals) last2decimals = last2decimals.toString().slice(0,2);

    var formattedFloat = formatThousandNotation(float);
    var formattedMaandbedrag = formattedFloat+","+last2decimals;
    return formattedMaandbedrag;
}

export const formattedPMT_v2 = (maandbedrag, returnNaN=true) => {
    if(!maandbedrag) return;
    var maandbedragStr = ""+maandbedrag;
    var maandbedragArr = maandbedragStr.split(".");

    var float = maandbedragArr[0];
    var last2decimals = maandbedragArr[1];
    if(last2decimals<=9) last2decimals=""+last2decimals+"0";
    if(!last2decimals) last2decimals = "00";
    if(last2decimals.toString().length>=3) last2decimals = last2decimals.toString().slice(0,2);
    var formattedFloat = formatThousandNotation(float, returnNaN);
    var formattedMaandbedrag = formattedFloat+","+last2decimals;
    return formattedMaandbedrag;
}

export const valueTo2DecInt = (input) => {
    if(!input || input==="0") return 0.00; 
    if(typeof input==="string") {
      // replace all dots
      input = input.replace(/\./g,"");
      
      // transform comma into dot
      input = input.replace(/,/,".");
  
      // transform typeof input to number
      input = +input;
    }
    // console.log("transformed input=", input);
    return input;
  }
  

export const intTo2DecStr = (input) => {
    if(!input || input===0) return "0";
    if(typeof input==="string") return input;
    if(typeof input==="number") {
        var input = ""+input;
        var inputArr = input.split(".");

        var float = inputArr[0];
        var last2decimals = inputArr[1];
        if(last2decimals<=9) last2decimals=""+last2decimals+"0";
        if(!last2decimals) last2decimals = "00";
        if(last2decimals.toString().length>=3) last2decimals = last2decimals.toString().slice(0,2);

        var formattedFloat = formatThousandNotation(float);
        var formattedInput = formattedFloat+","+last2decimals;
        return formattedInput;
    }
    return "0";
} 