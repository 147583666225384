import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "hooks/use-auth";
import Pagination from "components/Navigation/Pagination";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";
import useCustomerV2 from "hooks/customer/use-customer_v2";

const KlantOverzicht = (props) => {
  const [searchParams] = useSearchParams();
  const { customers, getCustomers } = useCustomerV2();

  useEffect(() => {
    getCustomers(searchParams);
  }, [searchParams]);

  const { role } = useAuth();

  const navigate = useNavigate();
  const { pageNumber, pageSize, totalRecords, data } = customers;
  
  return (
    <div className="text-xs lg:text-base max-w-6xl">
    {(role.includes("Admin") && !props.phase) && (
          <>
          <Zoekmachine disableArchive={true} searchFor="customers" />
          <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
            </>
          )}

      {data?.length ? (
        <div>
            <div className="hidden bg-sld-blue text-white rounded-xl p-3 mb-3 items-center xl:grid grid-cols-2 md:grid-cols-12 gap-x-2">
              <div className="w-[50px]">ID</div>
              <div className="col-span-3">Bedrijfsnaam</div>
              <div className="col-span-2">Naam</div>
              <div className="col-span-4">E-mail</div>
              <div className="col-span-2">Telefoon</div>
            </div>
            {data.map((customer) => {
                return (
                  <React.Fragment key={'k'+customer.customerID}>
                  <div className={"hidden xl:grid grid-cols-2 md:grid-cols-12 gap-x-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer"+(customer.isArchive ? ' opacity-50' : '')} onClick={() => navigate(""+customer.customerID)}>
                    <div className="w-[50px]">{customer.customerID}</div>
                    <div className="col-span-3">{customer.companyName}</div>
                    <div className="col-span-2">{customer.firstName} {customer.lastName}</div>
                    <div className="col-span-4">{customer.email}</div>
                    <div className="col-span-2">{customer.phone}</div>
                  </div>
                                  {/* mobile */}
                <div onClick={() => navigate(""+customer.customerID)} className={`xl:hidden flex flex-row gap-x-3 p-3 bg-white shadow-xl rounded-xl cursor-pointer mb-5 hover:bg-[#F6901E] hover:text-white ${customer.isArchive ? ' opacity-50' : ''}`}>
                <div className="flex flex-row items-center w-[40px]">
                  <div className={`flex flex-row justify-center rounded-full w-8 h-8 p-0 m-0 items-center`}>
                    <span className="text-xs">{customer.customerID}</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full">
                  <span>{customer.companyName}</span>
                  <span>{customer.firstName} {customer.lastName}</span>
                  <span>{customer.email}</span>
                  <span className="">{customer.phone}</span>
                </div>
              </div>
              </React.Fragment>
                )
            })}
        </div>
      ) : (
        <p>Er zijn nog geen klanten</p>
      )}
      <Pagination page={pageNumber} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
    </div>
  );
};

export default KlantOverzicht;
