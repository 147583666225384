import React, { useState, useEffect, Suspense, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate, NavLink } from "react-router-dom";
import { createPortal } from "react-dom";

import Select from "components/ui/Select/Select";
import Button from "components/ui/Button/Button";
import Modal from "components/hoc/Modal";
import Popup2 from "components/ui/Popup/Popup2";
import BankSelector from "components/BankSelector/BankSelector";
import DocAndActivityBlock2 from "components/DocAndActivityBlock/DocAndActivityBlock2";
import LicensePlate2 from "components/ui/LicensePlate/LicensePlate2";
import InsuranceForm from "../InsuranceForm/InsuranceForm";

import { getDateTimeFromDate, getErrorsFromResponse, getFormattedLicensePlate, getWebsiteUrl, isUnderCondition, rdwDateStrToDateObj, renderTableRows } from "lib/helpers";
import { calcMaxDuration, formattedPMT_v2, formatThousandNotation, intTo2DecStr, localDate } from "lib/calc";
import { escapeSpecialChars, getFriendlyDealerCategory, getFriendlyKmPerYear } from "lib/voertuig";

import { uiActions } from "store/ui-slice";
import { dossierActions } from "store/dossier-slice";
import { dealActions } from "store/deal-slice";

import AssignUser from "./AssignUser";

import useAuth from "hooks/use-auth";
import useDealV2 from "hooks/deal/use-deal_v2";
import useDealerV2 from "hooks/dealer/use-dealer_v2";
import useDocument from "hooks/document/use-document";
import useActivity from "hooks/activity/use-activity";
import RequestInsuranceByActuaForm from "../InsuranceForm/RequestInsuranceByActuaForm";
import AdditionalTermsForm from "../AdditionalTermsForm/AdditionalTermsForm";
import useActua from "hooks/actua/use-actua";
import Table, { Th, Thead, Tr } from "components/ui/Table/Table";

const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));
const CalculatorV3 = React.lazy(() => import("components/Calculator/Calculator_v3"))  ;

const CustomerForm = React.lazy(() => import("components/Forms/CustomerForm/CustomerForm.js"))  ;
const VehicleForm = React.lazy(() => import("components/Forms/VehicleForm/VehicleForm"));
const SalesForm = React.lazy(() => import("components/Forms/SalesForm/SalesForm"));

const DealForm = (props) => {
  const { role, userDetails } = useAuth();  
  const { checkHealth, healthResponse, getLatestStatus, latestStatus, getRequestStatus, requestStatus, submitInsuranceRequest, submitResponse, clearAllStatus } = useActua();
  const { createActivity, sendOffer } = useActivity();
  const { deal: dealDetail, getDealById, archiveDeal, patchDeal } = useDealV2();

  const dispatch = useDispatch();
  const params = useParams();
  
  const [dealChangeCounter, setDealChangeCounter] = useState(0);

  const { dealerOptions: dealers, getAllDealers } = useDealerV2();

  useEffect(() => {
    getAllDealers(2);
  }, []);

  var { dealID } = params;

  useEffect(() => {
    getDealById(dealID);
  }, [getDealById, dealChangeCounter]);

  const location = useLocation();
  const [pathName, setPathName] = useState(false);

  const [offerIsSent, setOfferIsSent] = useState(false);

  useEffect(() => {
    setPathName(window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : ""));
  }, [location]);

  const navigate = useNavigate();
  const phaseRef = useRef();

  const [dealPopup, setDealPopup] = useState(false); // vehicle|dealer|customer|sales|insurance|requestInsuranceByActua

  // calculator related 
  const [showCalculator, setShowCalculator] = useState(false);
  const [calculatorGoal, setCalculatorGoal] = useState(""); // empty string: calculatie verkoopgegevens, bank: calculatie banken

  // bank related
  const [bankDetail, setBankDetail] = useState({
    bankName: "",
    calcsrc: "",
    prefix: "",
    status: "",
    message: "",
    response: "",
    responseDutch: "",
    requestNumber: "",
    amountpurchase: "",
    amountpurchaseExclVAT: "",
    contractNumber: "",
    downpayment: "",
    leaseAmount: "",
    vat: "",
    tradeInAmount: "",
    interest: "",
    finalpayment: "",
    duration: "",
    monthlyAmount: "",
    repayment: "",
    provisiePercentage: "",
    contractInLossing: "",
    kentekenInLossing: "",
    bankInLossing: "",
  });

  var calcDataToPass = (!calculatorGoal) ? {
    ...dealDetail,
    calcsrc: dealDetail.chosenBank
  } : bankDetail;

  const [pdfMethod, setPdfMethod] = useState("download");
  const [loadPdf, setLoadPdf] = useState(false);
  const [showPhaseChoice, setShowPhaseChoice] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const archiveDealHandler = async (obj, reset) => {
    var isArchive = (reset ? false : true);
    const updatedDeal = {
    ...obj,
      isArchive: isArchive
    }
  var chosenPhase = phaseRef.current.value;
  if(!chosenPhase) return;
  var dataToChange = { isArchive: isArchive, phase: chosenPhase };
  var resp = await archiveDeal(dealDetail.dealID, dataToChange);
  if(resp==="SUCCESS") {
    var activityType;
    if(isArchive) activityType = "archive";
    if(!isArchive) activityType = "dearchive";
    createActivity("Deal", activityType, updatedDeal, dataToChange);
    setDealChangeCounter(prevState => prevState + 1);
  }
  setShowPhaseChoice(false);
}

const otherVehicleHandler = async () => {
  dispatch(dealActions.setDealerDetail(dealDetail.dealerDetail));
  dispatch(dealActions.setCustomerData({
    ...dealDetail.customerDetail,
    ...(dealDetail?.customerDetail?.dateofBirth && {dateofBirth: rdwDateStrToDateObj(dealDetail.customerDetail.dateofBirth)})
  }));
  var dataToChange = {
    isArchive: true,
    phase: "Ander object"

  };
  var resp = await archiveDeal(dealID, dataToChange);
  if(resp==="SUCCESS") {
    dispatch(dossierActions.reCalculate({
      dealID: dealDetail.relatedID,
      hlContractNumber: dealDetail.hlContractNumber,
      dfContractNumber: dealDetail.dfContractNumber,
      caContractNumber: dealDetail.caContractNumber,
      alContractNumber: dealDetail.alContractNumber
    }));
    createActivity("Deal", "archive", { dealID: dealID }, dataToChange);
    navigate("/financiering/financial-lease");
  }
}

const moveDealToDealerHandler = async (evt) => {
  var resp = await patchDeal(dealDetail.dealID, {dealerID: evt.target.value});
  if(resp==="SUCCESS") {
    setDealChangeCounter(prevState => prevState + 1);
    createActivity("Deal", "move", { dealID: dealID }, {dealerID: evt.target.value});
    setDealPopup(false);
  }
}

  const reCalculateHandler = () => {
    setShowCalculator(true);
    setShowModal(true);
  }

  const popupSubmitHandler = () => {
    setPdfMethod("base64");
    setLoadPdf(true);
  }

  const downloadPDFHandler = () => {
    setPdfMethod("download");
    setLoadPdf(true)
  }

  const submitSendOffer = async (data) => {
    if (offerIsSent) return;
    var resp = await sendOffer(data, dealDetail);
    (resp==="SUCCESS") ? setOfferIsSent(true) : setOfferIsSent(false);
    setDealChangeCounter(prevState => prevState + 1);
    setLoadPdf(false);
  }

  var ondervoorbehoud = isUnderCondition(dealDetail.phase);

  const closeModalHandler = (modalType) => {
    dispatch(uiActions.hideBackdrop());
    setDealPopup("");
  }

  const showCalcHandler = (calcsrc) => {
    if(!calcsrc || /!(al|df|fi|hi)/.test(calcsrc)) return;
    setCalculatorGoal("bank");
    var fieldPrefix, bankName;
    switch(true) {
      case /^al/.test(calcsrc): fieldPrefix = "al"; bankName = "Alphabet"; break;
      case /^df/.test(calcsrc): fieldPrefix = "df"; bankName = "Dutch Finance"; break;
      case /^fi/.test(calcsrc): fieldPrefix = "ca"; bankName = "Findio"; break;
      case /^hi/.test(calcsrc): fieldPrefix = "hl"; bankName = "Hiltermann Lease"; break;
    }
    if(!fieldPrefix || !bankName) return;
    setBankDetail(prevState => {
      return {
        ...prevState,
        calcsrc: calcsrc,
        prefix: fieldPrefix,
        bankName: bankName,
        status: dealDetail[fieldPrefix+'Status'],
        message: dealDetail[fieldPrefix+'Message'],
        response: dealDetail[fieldPrefix+'Response'],
        responseDutch: dealDetail[fieldPrefix+'ResponseDutch'],
        requestNumber: dealDetail[fieldPrefix+'RequestNumber'],
        amountpurchase: dealDetail[fieldPrefix+'AmountPurchase'],
        amountpurchaseExclVAT: dealDetail[fieldPrefix+'AmountPurchaseExclVat'],
        contractNumber: dealDetail[fieldPrefix+'ContractNumber'],
        downpayment: dealDetail[fieldPrefix+'DownPayment'],
        leaseAmount: dealDetail[fieldPrefix+'LeaseAmount'],
        vat: dealDetail[fieldPrefix+'Vat'],
        tradeInAmount: dealDetail[fieldPrefix+'TradeInAmount'],
        interest: dealDetail[fieldPrefix+'Interest'],
        finalpayment: dealDetail[fieldPrefix+'FinalPayment'],
        duration: dealDetail[fieldPrefix+'Duration'],
        monthlyAmount: dealDetail[fieldPrefix+'MonthlyAmount'],
        repayment: dealDetail[fieldPrefix+'Repayment'],
        provisiePercentage: dealDetail[fieldPrefix+'ProvisiePercentage']
      }
    });
    setShowModal(true);
    setShowCalculator(true);
  }

  const hideCalcHandler = () => {
    setCalculatorGoal("");
    setShowCalculator(false);
    setShowModal(false);
    setBankDetail({});
    dispatch(uiActions.hideBackdrop());
  }

  const { documents, getDocuments } = useDocument();

  useEffect(() => {
    if(!dealID) return;
    getDocuments(dealID);
  }, [getDocuments]);

  const updateHandler = () => {
    setDealPopup("");
    setDealChangeCounter(prevState => prevState + 1);
  }

  const getActuaStatusHandler = () => {
    clearAllStatus();
    checkHealth();
    getRequestStatus(dealDetail?.dealID);
    // getLatestStatus(dealDetail?.dealID);
    setDealPopup("statusScreen");
  }

  const submitActuaRequestHandler = async () => {
    clearAllStatus();
    checkHealth();
    var resp = await submitInsuranceRequest(dealDetail.dealID, dealDetail);
    setDealPopup("statusScreen");

    if(resp==="SUCCESS") {
      setDealChangeCounter(prevState => prevState + 1);
      setDealPopup("");
    }
  }

  var websiteUrl = getWebsiteUrl(dealDetail);  

  if (!dealDetail.customerDetail || !pathName) return <>Laden...</>;
  if(!role || !role.includes("Admin")) return <>Geen rechten</>; 

  const blokWrapperClass = "text-[#1a2a52] relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 items-start py-10 px-5 lg:py-5 lg:px-10 gap-y-0 md:gap-y-5 gap-x-10 bg-white shadow-xl rounded-xl mb-5";
  
  const VoertuigGegevens = (
    <div className={blokWrapperClass}>
      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Merk & model</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.make)} {escapeSpecialChars(dealDetail.vehicleDetail.model)}</div>
        {dealDetail.vehicleDetail.type && <><div className="font-semibold">Uitvoering</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.type)}</div></>}
        <div className="font-semibold">Kenteken</div><div className="scale-50 relative -left-1/4 h-[24px] bottom-1"><LicensePlate2 value={getFormattedLicensePlate(dealDetail.vehicleDetail.licensePlate)} /></div>
        <div className="font-semibold">Voertuigsoort</div><div>{dealDetail.vehicleDetail.vehicleType}</div>
        <div className="font-semibold">Eerste registratie</div><div>{localDate(dealDetail.vehicleDetail.firstRegistrationDate)}</div>
      </div>
      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Kilometerstand</div><div>{formatThousandNotation(dealDetail.vehicleDetail.mileage)} km</div>
        <div className="font-semibold">BTW/Marge</div><div>{dealDetail.typeOfDeal}</div>
        {dealDetail?.ottcode && <><div className="font-semibold">OTT-code</div><div>{dealDetail?.ottcode}</div></>}
        {dealDetail.vehicleDetail.positionJudgement && <><div className="font-semibold">Tellerstandoordeel</div><div>{dealDetail.vehicleDetail.positionJudgement}</div></>}
      </div>
      {websiteUrl && 
      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <a href={websiteUrl} target="_blank" rel="noreferrer" className="flex flex-row md:col-span-2 gap-x-1 cursor-pointer">
        <span className="fa fa-browser"></span>
        <span className="underline">Bekijk op website</span>
        </a>
      </div>
      }
      {( (dealDetail?.sourceOfRequest==="Actua") && (/(Controleer en verzend offerte|Offerte verzonden|Klant is akkoord met de offerte)/.test(dealDetail.phase)) && (dealDetail.saleType==="FinancialLease") ) &&
      <div className="flex flex-row"><Button size="xs" className="btn-secondary" type="button" onClick={() => setDealPopup("requestInsuranceByActua")}>Verzekering aanvragen</Button></div>
      }

      <span className="absolute right-0 bottom-0 fa fa-pen-to-square p-5 cursor-pointer text-xl hover:scale-105" onClick={() => setDealPopup("vehicle")}></span>
    </div>
  );

  var dealerCategory = dealDetail?.dealerCategory;
  if(dealDetail?.sourceOfRequest==="Actua") dealerCategory = "C";

  return (
<>
<form className="flex flex-col text-xs max-w-7xl lg:text-base mt-5">
<div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
  <Button type="button" onClick={() => navigate(-1)}>Terug naar verkopen</Button>
  <div className="flex flex-row gap-x-3">
  {!dealDetail.isArchive && <Button type="button" className="btn-secondary" onClick={() => setShowPhaseChoice(true)}>Archiveren</Button>}
  {dealDetail.isArchive && <Button type="button" className="btn-secondary" onClick={() => setShowPhaseChoice(true)}>Herstellen uit archief</Button>}
  </div>
</div>


<div className={blokWrapperClass}>
  <div className="mt-5 lg:mt-0 md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Classificatie</div><div>{getFriendlyDealerCategory(dealerCategory)}</div>
    <div className="font-semibold">Provisie Dealer</div><div>&euro; {intTo2DecStr(dealDetail.commissionAmountDealer)}</div>
    <div className="font-semibold">Provisie SLD</div><div>&euro; {intTo2DecStr(dealDetail.commissionAmountSLD)}</div>
  </div>
  <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Fase</div><div>{dealDetail.phase}</div>
    {/* <div className="col-span-2"><Select set={dealDetail.isArchive ? 'isArchive_phase' : 'phase'} name="phase" placeholder="Fase" value={dealDetail.phase} onChange={valueChangeHandler} /></div> */}
    <div className="font-semibold">Datum registratie</div><div className="flex xh-[50px] items-center">{dealDetail.createdDate ? getDateTimeFromDate(dealDetail.createdDate) : ""}</div>
    <div className="font-semibold">Source</div><div>{dealDetail.source} {dealDetail?.sourceOfRequest && <>({dealDetail.sourceOfRequest})</>}</div>

    {dealDetail.offerSignedDate!=="0001-01-01T00:00:00Z" && <><div className="font-semibold">Tekendatum offerte</div><div>{localDate(dealDetail.offerSignedDate)}</div></>}
    {dealDetail.isArchive && dealDetail.startDateContract!=="0001-01-01T00:00:00Z" && <><div className="font-semibold">Startdatum contract</div><div>{localDate(dealDetail.startDateContract)}</div></>}
    {/* <div className="font-semibold">Startdatum contract</div><div>{dealDetail.startDateContract==="0001-01-01T00:00:00Z" ? new Date().toLocaleDateString("nl-NL") : localDate(dealDetail.startDateContract)}</div> */}
  </div>
  {(dealDetail?.salesDescription || dealDetail?.requestedInsurance) &&
  <div className="col-start-1 md:col-span-2 lg:col-span-4">
    <div className="font-semibold">Opmerking</div>
    <div className="whitespace-pre-wrap">{dealDetail?.salesDescription ? dealDetail?.salesDescription + "\n" : ""}
      {dealDetail?.requestedInsurance && "Ja, ik wil een verzekeringsvoorstel"}
    </div>
  </div>
  }
  <AssignUser dealDetail={dealDetail} onUpdate={updateHandler}  />

  <span className="absolute right-0 bottom-0 fa fa-pen-to-square p-5 cursor-pointer text-xl hover:scale-105" onClick={() => setDealPopup("sales")}></span>
  {dealPopup==="sales" && createPortal(
    <Suspense fallback={<div>Loading ...</div>}>
      <Modal childrenOnly={true} show={true} onClose={() => closeModalHandler('sales')}>
        <SalesForm dealDetail={dealDetail} title="Verkoopgegevens aanpassen" onClose={() => closeModalHandler('sales')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler} />
      </Modal>
    </Suspense>, document.body)
  }
</div>

<div className="text-[#1a2a52] relative bg-white shadow-xl rounded-xl py-10 px-5 lg:py-5 lg:px-10 flex flex-col mb-5">
  <div className="flex flex-row justify-between">
      <div>
        <div className="font-semibold text-lg">{dealDetail.customerDetail.companyName}</div>
        <div className="opacity-50 text-md mt-3">{dealDetail.customerDetail.address},{dealDetail.customerDetail.postalCode}{" " + dealDetail.customerDetail.place}</div>
      </div>
      <div><img src={dealDetail.dealerDetail?.logoImageUrl} className="max-h-[36px]" loading="lazy" /></div>
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-x-10 items-start mt-3">
    <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Tel</div><div>{dealDetail.customerDetail.phone}</div>
        <div className="font-semibold">E-mailadres</div><div>{dealDetail.customerDetail.email}</div>
        <div className="font-semibold">KvK-nummer</div><div>{dealDetail.customerDetail.chamberNumber}</div>
        <div className="font-semibold">IBAN</div><div>{dealDetail.customerDetail.iban}</div>
        <div className="font-semibold">Rijbewijsnummer</div><div>{dealDetail.customerDetail.driverLicenseNumber}</div>
        <div className="font-semibold">Datum oprichting</div><div>{localDate(dealDetail.customerDetail.dateofEsteb)}</div>
    </div>
    <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Aanhef</div><div>{dealDetail.customerDetail.salutation}</div>
        <div className="font-semibold">Voornaam</div><div>{dealDetail.customerDetail.firstName}</div>
        <div className="font-semibold">Achternaam</div><div>{dealDetail.customerDetail.lastName}</div>
        <div className="font-semibold">Geboortedatum</div><div>{localDate(dealDetail.customerDetail.dateofBirth)}</div>
    </div>
    <div className="col-span-1 text-end mb-5 lg:mb-0">
      <Button type="button" onClick={() => setDealPopup("dealer")}>Bekijk autobedrijf</Button>
    </div>
  </div>

  <div className="grid grid-cols-1 md:flex md:flex-col justify-between w-full">
    <div className="flex flex-col items-end">
      {dealPopup==="dealer" && createPortal(
        <Modal show={true}
          onClose={() => closeModalHandler('dealer')}
          title={dealDetail.dealerDetail.name}
        >
          <div className="grid grid-cols-2 gap-3 py-5">
            <div>
              <div className="font-semibold">{dealDetail.dealerDetail.name} ({dealDetail.dealerDetail.dealerID})</div>
              <div>kvk: {dealDetail.dealerDetail.chamberNumber}</div>
              <div>{dealDetail.dealerDetail.address}</div>
              <div>{dealDetail.dealerDetail.postalCode} {dealDetail.dealerDetail.place}</div>
              <div className="mt-3 underline">
              <NavLink to={`/crm/dealers/${dealDetail.dealerDetail.dealerID}`}>Bekijk autobedrijf</NavLink>
              </div>
            </div>
            <div>
              {(dealDetail?.dealerDetail?.contactFirstName || dealDetail?.dealerDetail?.contactLastName) && <>
              <div className="font-semibold">Hoofdcontactpersoon</div>
              <div>{dealDetail?.dealerDetail?.contactFirstName} {dealDetail?.dealerDetail?.contactLastName}</div>
              </>}
              <div>{dealDetail.dealerDetail.telephone}</div>
              <div>{dealDetail.dealerDetail.phone}</div>
              <div><a href={`mailto:${dealDetail.dealerDetail.email}`}>{dealDetail.dealerDetail.email}</a></div>
              <div><a href={dealDetail.dealerDetail.website} target="_blank" rel="noreferrer">{dealDetail.dealerDetail.website}</a></div>
            </div>
            <div className="col-start-2">
              <Select options={dealers && dealers.length && dealers.map(dealer => ({ "label": dealer.label, "value": dealer.dealerID }))} name="dealerID" placeholder="dealerID" selected={dealDetail.dealerID} value={dealDetail.dealerID} onChange={moveDealToDealerHandler} />
            </div>
            {/* <div className="flex flex-row gap-x-3">
              <Button className="btn-secondary" type="button" onClick={() => closeModalHandler('dealer')}>Annuleren</Button>
              <Button className="btn-primary bg-gradient" type="button" onClick={() => closeModalHandler('dealer')}>Opslaan</Button>
            </div> */}
          </div>
        </Modal>
        ,document.body)
      }
    </div>
  </div>


  <div className="grid grid-cols-5 xl:flex-row justify-between w-full gap-y-1 lg:gap-y-3 font-semibold">
      <>
        <span className="absolute right-0 bottom-0 fa fa-pen-to-square p-5 cursor-pointer text-xl hover:scale-105" onClick={() => setDealPopup("customer")}></span>
        {dealPopup==="customer" && createPortal(
          <Suspense fallback={<div>Loading ...</div>}>
            <Modal category="calc" childrenOnly={true} show={true} onClose={() => closeModalHandler('customer')}>
              <CustomerForm title="Klantgegevens aanpassen" onClose={() => closeModalHandler('customer')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler} dealID={dealDetail.dealID} customerDetail={dealDetail.customerDetail} customerID={dealDetail.customerDetail.customerID} />
            </Modal>
          </Suspense>, document.body)
        }
      </>
  </div>
</div>

{dealDetail.saleType==="Verzekering" && <>{VoertuigGegevens}</>}

{dealDetail.saleType==="FinancialLease" &&
<>
{VoertuigGegevens}
<div className={blokWrapperClass}>
  <div className="order-3 lg:order-1 lg:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Aanschaf{/btw/i.test(dealDetail.typeOfDeal) && ' Incl.'}</div><div className="">&euro; {formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">BTW</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail.vat) : "0"}</div>
    <div className="font-semibold">Aanschaf Excl.</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail.amountpurchaseExclVAT) : formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">Aanbetaling</div><div>&euro; {dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : "0"}</div>
    <div className="font-semibold">Inruil</div><div>&euro; {dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : "0"}</div>
    <div className="font-semibold">Inlossing</div><div>&euro; {dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : "0"}</div>
  </div>
  <div className="order-2 lg:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Leasebedrag</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
    <div className="font-semibold">Slottermijn</div><div>&euro; {dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : "0"}</div>
    <div className="font-semibold">Looptijd</div><div>{dealDetail.duration} maanden</div>
    <div className="font-semibold">Maandbedrag</div><div>&euro; {formattedPMT_v2(dealDetail.monthlyamount)}</div>
    <div className="font-semibold">Rente</div><div>{dealDetail.interest}%</div>
    <div className="font-semibold">Handelingsfee</div><div>&euro; {dealDetail.administrationCost===0 ? "0": intTo2DecStr(dealDetail.administrationCost)}</div>
  </div>
  <div className="order-last lg:col-span-3 flex flex-col gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="flex flex-row items-center gap-x-3">
          <div className="font-semibold">Vervolgafspraak</div>
          <span className="fa fa-pen-to-square xp-5 cursor-pointer text-xl hover:scale-105" onClick={() => setDealPopup("additionalTerms")}></span>
        </div>
        <div className="whitespace-pre-wrap">{dealDetail?.description ? dealDetail?.description : "NNB"}</div>
  </div>
  {/^hi/.test(dealDetail?.chosenBank) && <div className="order-last flex flex-col col-span-full mt-3 text-[red] font-semibold">Let op! Deze bank rekent €150,-  administratiekosten excl. BTW.</div>}

  <div className="flex flex-col order-3 md:col-span-4 lg:col-span-2 xl:col-span-1 items-end justify-end mt-auto">
    <div className="mb-5 max-w-xs">{dealDetail.saleType !== "BtwFinanciering" && <Select disabled set="chosenBank" value={dealDetail.chosenBank} label="Gekozen bank" name="chosenBank" />}</div>
    {(dealDetail.saleType==="FinancialLease" && !dealDetail.isArchive) && <div className="flex flex-row items-end justify-end"><Button size="xs" className="btn-secondary" type="button" onClick={reCalculateHandler}>Financiering aanpassen</Button></div>}
  </div>
</div>
</>
}

{(dealDetail.saleType==="FinancialLease") &&
  <div className="col-span-2 grid grid-cols-2 md:flex md:flex-row justify-between gap-3 mb-5">
    <BankSelector status={dealDetail['hlStatus']} dealDetail={dealDetail} prefix="hl" title="Hiltermann Lease" onClick={() => showCalcHandler((/^hi_/.test(dealDetail?.chosenBank)) ? dealDetail?.chosenBank : "hi_2")} />
    <BankSelector status={dealDetail['dfStatus']} dealDetail={dealDetail} prefix="df" title="Dutch Finance" onClick={() => showCalcHandler((/^df_/.test(dealDetail?.chosenBank)) ? dealDetail?.chosenBank : "df")} />
    <BankSelector status={dealDetail['caStatus']} dealDetail={dealDetail} prefix="ca" title="Findio" onClick={() => showCalcHandler((/^fi_/.test(dealDetail?.chosenBank)) ? dealDetail?.chosenBank : "fi")} />
    <BankSelector status={dealDetail['alStatus']} dealDetail={dealDetail} prefix="al" title="Alphabet" onClick={() => showCalcHandler((/^al_/.test(dealDetail?.chosenBank)) ? dealDetail?.chosenBank : "al_2")} />
  </div>
}

{dealPopup==="additionalTerms" && createPortal(
  <Suspense fallback={<div>Loading ...</div>}>
    <Modal childrenOnly={true} show={true} onClose={() => closeModalHandler('additionalTerms')}>
    <AdditionalTermsForm title="Vervolgafspraak aanpassen" onClose={() => closeModalHandler('additionalTerms')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler}
      dealDetail={dealDetail} detail={true}
    />
  </Modal>
  </Suspense>, document.body)}

{(dealDetail.saleType==="Verzekering") &&
<div className="flex flex-row col-span-2 items-center justify-end">
  {dealPopup==="insurance" && createPortal(
  <Suspense fallback={<div>Loading ...</div>}>
    <Modal childrenOnly={true} show={true} onClose={() => closeModalHandler('insurance')}>
    <InsuranceForm title="Verzekering aanpassen" onClose={() => closeModalHandler('insurance')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler}
      dealDetail={dealDetail} detail={true}
    />
  </Modal>
  </Suspense>, document.body)}
</div>
}

{(dealDetail.saleType!=="BtwFinanciering") &&
<div className="flex flex-row col-span-2 items-center justify-end">
  {dealPopup==="vehicle" && createPortal(
  <Suspense fallback={<div>Loading ...</div>}>
    <Modal childrenOnly={true} show={true} onClose={() => closeModalHandler('vehicle')}>
    <VehicleForm title="Voertuig aanpassen" onOtherVehicle={() => setShowPopup("otherVehicle")} onClose={() => closeModalHandler('vehicle')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler}
      vehicle={dealDetail.vehicleDetail} dealID={dealID} typeOfDeal={dealDetail.typeOfDeal} customerID={dealDetail.customerDetail.customerID} dealDetail={dealDetail} detail={true}
    />
  </Modal>
  </Suspense>, document.body)}
</div>
}

{(dealDetail.saleType==="Verzekering") &&
<div className={blokWrapperClass}>
  <div className="md:col-span-3 xl:col-span-3 grid grid-cols-1 md:grid-cols-2 items-center gap-y-1 lg:gap-y-3">
    <div className="font-semibold">Verkoopprijs incl. BTW</div><div>&euro; {formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">Type verzekering</div><div>{dealDetail.typeOfInsurance}</div>
    <div className="font-semibold">Schadevrije jaren</div><div>{dealDetail.damageFreeYearsInsurance} </div>
    <div className="font-semibold">Schade afgelopen 5 jaar</div><div>{dealDetail.damageHistoryInsurance}</div>
    <div className="font-semibold">Kilometers per jaar</div><div>{dealDetail.kmPerYear ? getFriendlyKmPerYear(dealDetail.kmPerYear) : ""}</div>
  </div>
  <span className="absolute right-0 bottom-0 fa fa-pen-to-square p-5 cursor-pointer text-xl hover:scale-105" onClick={() => setDealPopup("insurance")}></span>
</div>
}

{(dealDetail.saleType==="Verzekering") &&
<>
  <div className="flex flex-row col-span-2 items-center justify-end">
    {dealPopup==="statusScreen" && createPortal(
    <Suspense fallback={<div>Loading ...</div>}>
      <Modal title="Actua status" show={true} onClose={() => closeModalHandler('statusScreen')}>
        <div className="grid grid-cols-4 gap-x-5">

          {!dealDetail?.actuaInsuranceRequestFailed ? <>
          <div className="font-semibold">Health check</div><span className={`col-span-3 fa ${healthResponse?.isSuccessStatusCode ? "fa-check text-[green]" : "fa-xmark text-[red]"} flex flex-row w-[16px] justify-center`}></span>

          <div className="font-semibold">Statuscode</div><div className="col-span-3">{healthResponse?.statusCode}</div>
          <div className="font-semibold">Bericht</div><div className="col-span-3">{healthResponse?.message}</div>

          <div className="col-span-4 text-xl font-bold mt-5">Status verzekering</div>
          {/* <div className="font-semibold">Titel</div><div>{latestStatus?.title}</div>
          <div className="font-semibold">Statuscode</div><div>{latestStatus?.status}</div>
          <div className="font-semibold">traceId</div><div>{latestStatus?.traceId}</div> */}

          {latestStatus?.id && <><div className="font-semibold">ID</div><div className="col-span-3">{latestStatus?.id}</div></>}
          {latestStatus?.insuranceOfferID && <><div className="font-semibold">Insurance Offer ID</div><div className="col-span-3">{latestStatus?.insuranceOfferID}</div></>}
          {latestStatus?.dealID && <><div className="font-semibold">Verkoop ID</div><div className="col-span-3">{latestStatus?.dealID}</div></>}
          {dealDetail?.insuranceApplicationLink && <><div className="font-semibold">Klant Login URL</div><div className="col-span-3 break-all">{dealDetail?.insuranceApplicationLink && <a href={dealDetail?.insuranceApplicationLink} target="_blank" className="underline">{dealDetail?.insuranceApplicationLink}</a>}</div>
          {latestStatus?.stage || requestStatus?.data && <>
          <div className="flex flex-row items-center justify-start gap-x-2">
            <span className="font-semibold ">Fase</span>
            <div className="group relative">
              <span className="fa fa-square-info cursor-pointer"></span>   
              <div className="group-hover:flex flex-col hidden shadow-xl border border-slate-500 xw-3/4 p-3 absolute bottom-5 left-0 bg-slate-100 rounded-xl select-none w-[640px] text-sm">
                <div className="">Mogelijke response:</div>
                <div className="pl-2">
                  <div>- Ongewijzigd sinds de lead is gemaakt. (created)</div>
                  <div>- Gegevens zijn ingevuld sinds de creatie door de klant. (data)</div>
                  <div>- De klant heeft een van de beschikbare premies geselecteerd. (premium_selection)</div>
                  <div>- De klant heeft de verzekering afgerond. (completed)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            {latestStatus?.stage==="created" || requestStatus?.data[0]?.stage==="created" && "Ongewijzigd sinds de lead is gemaakt."}
            {latestStatus?.stage==="data" || requestStatus?.data[0]?.stage==="data" && "Gegevens zijn ingevuld sinds de creatie door de klant."}
            {latestStatus?.stage==="premium_selection" || requestStatus?.data[0]?.stage==="premium_selection" && "De klant heeft een van de beschikbare premies geselecteerd."}
            {latestStatus?.stage==="completed" || requestStatus?.data[0]?.stage==="completed" && "De klant heeft de verzekering afgerond."}
            &nbsp;({latestStatus?.stage ? latestStatus?.stage : ""}{requestStatus?.data ? requestStatus?.data[0]?.stage : ""})
          </div></>}
          {/* The stage the client was last in. Can be either one of the following:
              - created: Unchanged since lead has been created.
              - data: Data has been filled since creation by the client.
              - premium_selection: The client selected one of the premiums available.
              - completed: The client has completed the insurance.
           */}</>}
          {latestStatus?.dataCompletion || requestStatus?.data && <>
          <div className="flex flex-row items-center justify-start gap-x-2">
            <span className="font-semibold">Gegevens voltooiing</span>
            <div className="group relative">
              <span className="fa fa-square-info cursor-pointer"></span>   
              <div className="group-hover:flex flex-col hidden shadow-xl border border-slate-500 xw-3/4 p-3 absolute bottom-5 left-0 bg-slate-100 rounded-xl select-none w-[640px] text-sm">
                <div className="">Mogelijke response:</div>
                <div className="pl-2">
                  <div>- Ongewijzigd sinds de lead is gemaakt. (original)</div>
                  <div>- NAW-gegevens etc zijn ingevuld. (basic)</div>
                  <div>- De klant heeft een van de beschikbare premies geselecteerd. (premium_selection)</div>
                  <div>- Alle gegevens die nodig zijn voor het afsluiten van de verzekering worden opgeslagen. (inclusief IBAN enz.). (completed)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            {(latestStatus?.dataCompletion==="original" || requestStatus?.data[0]?.data_completion==="original") && "Ongewijzigd sinds de lead is gemaakt."}
            {(latestStatus?.dataCompletion==="basic" || requestStatus?.data[0]?.data_completion==="basic") && "NAW-gegevens etc zijn ingevuld."}
            {(latestStatus?.dataCompletion==="completed" || requestStatus?.data[0]?.data_completion==="completed") && "Alle gegevens die nodig zijn voor het afsluiten van de verzekering worden opgeslagen. (inclusief IBAN enz.)."}
            &nbsp;({latestStatus?.dataCompletion ? latestStatus?.dataCompletion : ""}{requestStatus?.data[0]?.data_completion ? requestStatus?.data[0]?.data_completion : ""})
          </div>
          {/* Did the client complete all basic data in our system.
            - original: unchanged since lead has been created.
            - basic: NAW-details etc has been filled.
            - completed: All details required for insurance finalization are saved. (including IBAN etc).
          */}</>}
          {latestStatus?.createdAt && <><div className="font-semibold">Datum aangemaakt</div><div className="col-span-3">{getDateTimeFromDate(latestStatus?.createdAt)}</div></>}
          {latestStatus?.updatedAt && <><div className="font-semibold">Datum bijgewerkt</div><div className="col-span-3">{getDateTimeFromDate(latestStatus?.updatedAt)}</div></>}
          {latestStatus?.insuranceStartDate && <><div className="font-semibold">Startdatum verzekering</div><div className="col-span-3">{latestStatus?.insuranceStartDate}</div></>}
          {latestStatus?.preValidationFailed && <><div className="font-semibold">preValidationFailed</div><div className="col-span-3">{JSON.stringify(latestStatus?.preValidationFailed)}</div></>}
          {latestStatus?.preValidationErrors && <><div className="font-semibold">preValidationErrors</div><div className="col-span-3">{JSON.stringify(latestStatus?.preValidationErrors)}</div></>}
          {latestStatus?.preValidationErrors && <><div className="font-semibold">preValidationErrors</div><div className="col-span-3">{JSON.stringify(latestStatus?.preValidationErrors)}</div></>}

          {requestStatus?.data && <><div className="font-semibold">Insurance Offer ID</div><div className="col-span-3">{requestStatus?.data[0]?.offerte_id}</div></>}
          {requestStatus?.data && <><div className="font-semibold">Datum aangemaakt</div><div className="col-span-3">{requestStatus?.data[0]?.created_at}</div></>}
          {requestStatus?.data && <><div className="font-semibold">Datum bijgewerkt</div><div className="col-span-3">{requestStatus?.data[0]?.updated_at}</div></>}
          {requestStatus?.data && <><div className="font-semibold">Startdatum verzekering</div><div className="col-span-3">{requestStatus?.data[0]?.insurance_start_date ? requestStatus?.data[0]?.insurance_start_date : "-"}</div></>}

          <div className="font-semibold col-span-4">Originele response</div>
          {/* <div className="col-span-4">{JSON.stringify(submitResponse)}</div> */}
          <div className="bg-slate-100 col-span-4 text-sm xp-5 font-code">{JSON.stringify(requestStatus)}</div>

          <div className="bg-slate-100 col-span-4 text-sm xp-5 font-code">{JSON.stringify(latestStatus)}</div>

          </>
          :
          <>
          <div className="font-semibold">Statuscode</div><div className="col-span-3">{submitResponse?.status}</div>
          {submitResponse?.missingFields && <><div className="font-semibold">Ontbrekende velden</div><div className="col-span-3">{submitResponse?.missingFields}</div></>}
          <div className="font-semibold">Bericht</div><div className="col-span-3">{JSON.parse(submitResponse?.actuaResponse).message}</div>
          {getErrorsFromResponse(submitResponse) && <><div className="font-semibold">Errors</div><div className="col-span-3">{getErrorsFromResponse(submitResponse)}</div></>}
         
          <div className="font-semibold col-span-2">Originele response</div>
          <div className="bg-slate-100 col-span-4 text-sm xp-5 font-code">{JSON.stringify(submitResponse)}</div>



          </>
        }


        </div>



    </Modal>
    </Suspense>, document.body)}
  </div>

  <div className="flex flex-row gap-x-5">
  {dealDetail?.actuaInsuranceRequestFailed
  ?
  <Button type="button" onClick={submitActuaRequestHandler}>Verzekering indienen</Button>
  :
  <Button type="button" onClick={getActuaStatusHandler}>Status verzekering ophalen (Actua)</Button>
  }


  </div>
</>}

{(dealDetail.saleType==="BtwFinanciering") &&
<div className={blokWrapperClass}>
  <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Te financieren</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
  </div>
</div>
}

{/* <div className="flex flex-row items-center justify-end">
  {changeInfoInDeal && createPortal(
  <Suspense fallback={<div>Loading ...</div>}>
    <Modal xl={true} childrenOnly={true} show={changeInfoInDeal} onClose={() => closeModalHandler('additionalInfo')}>
    <AdditionalInfoRequest dealDetail={dealDetail} documents={documents} userDetails={userDetails} onClose={() => closeModalHandler('additionalInfo')} onUpdate={() => updateWidgetHandler("update_additionalInfo")} />
  </Modal>
  </Suspense>, document.body)}
</div> */}

</form>

{loadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 chosenBank={dealDetail?.chosenBank} method={pdfMethod} hostname={pathName} ondervoorbehoud={ondervoorbehoud} onStartLoad={() => setShowPopup(false)} onLoadData={(data) => submitSendOffer(data)} onDone={() => setLoadPdf(false)} dealDetail={dealDetail} /></Suspense>}

<Modal title="Geef op" show={showPhaseChoice} onClose={() => setShowPhaseChoice(false)}>
  <Select ref={phaseRef} set={dealDetail.isArchive ? 'phase' : 'isArchive_phase'} name="phase" placeholder="Fase" />
  {dealDetail.isArchive
    ?
    <Button type="button" className="btn-primary" onClick={(obj) => archiveDealHandler(dealDetail, 'reset')}>Dearchiveren bevestigen</Button>
    :
    <Button type="button" className="btn-primary" onClick={(obj) => archiveDealHandler(dealDetail)}>Archiveren bevestigen</Button>
  }
</Modal>

<div className="text-xs lg:text-base mt-5">
  {(dealDetail.saleType==="FinancialLease" && showCalculator) &&
  <Suspense fallback={<div>Loading ...</div>}>
  <Modal category="calc" title={bankDetail.bankName ? bankDetail.bankName : "Financiering aanpassen"} show={showModal} onClose={hideCalcHandler}>

  <CalculatorV3 size="xs"
    recalculate={true}
    fixedAmountPurchase={dealDetail.amountpurchase}
    fixedAmountPurchaseExclVAT={dealDetail.amountpurchaseExclVAT}
    age={dealDetail?.vehicleDetail?.age}
    companyAge={dealDetail?.customerDetail?.age}
    inModal={true}
    onUpdate={() => setDealChangeCounter(prevState => prevState + 1)}
    onDownloadPdf={downloadPDFHandler}
    onSendPdf={() => setShowPopup("sendOffer")}
    offerCustomer={dealDetail.offerCustomer}
    onClose={hideCalcHandler} 
    currentCalculation={{
      "Aanschaf Incl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchase),
      "BTW": "\u20AC "+formatThousandNotation(dealDetail.vat),
      "Aanschaf Excl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchaseExclVAT),
      "Aanbetaling": "\u20AC "+(dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : 0),
      "Inruil": "\u20AC "+(dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : 0),
      "Inlossing": "\u20AC "+(dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : 0),
      "Te financieren": "\u20AC "+formatThousandNotation(dealDetail.leaseAmount),
      "Slottermijn": "\u20AC "+(dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : 0),
      "Looptijd": dealDetail.duration+" maanden",
      "Maandbedrag": "\u20AC "+dealDetail.monthlyamount,
      "typeOfDeal": dealDetail.typeOfDeal,
      "Rente": dealDetail.interest+"%",
      "Provisiepercentage": dealDetail.provisiePercentage+"%"
    }}
    ondervoorbehoud={ondervoorbehoud}
    calculatorGoal={calculatorGoal}
    relatedID={dealDetail.relatedID}
    dealID={dealDetail.dealID}
    typeOfDeal={dealDetail.typeOfDeal}
    disableTitle={true}
    firstRegistrationDate={dealDetail.vehicleDetail.firstRegistrationDate}
    vehicleType={dealDetail.vehicleDetail.vehicleType}
    restBPM={dealDetail.vehicleDetail.restBPM}
    disableMaxSlottermijn={true}
    slottermijnTouched={true}
    showDifference={true}
    fixedBank={true}
    administrationCost={dealDetail.administrationCost}      
    {...calcDataToPass}
    contractInLossing={dealDetail.contractInLossing}
    kentekenInLossing={dealDetail.kentekenInLossing}
    bankInLossing={dealDetail.bankInLossing}
    bankDetail={bankDetail}
    dealerCategory={dealDetail?.dealerCategory}
    sourceOfDealer={dealDetail?.dealerDetail?.sourceOfDealer} />  

{/* <Calculator2 size="xs"
    onDownloadPdf={downloadPDFHandler}
    onSendPdf={() => setShowPopup(true)}
    onUpdate={() => {}}
    offerCustomer={dealDetail.offerCustomer}
    onUpdateBankOnly={() => {}}
    onClose={hideCalcHandler} 
    currentCalculation={{
      "Aanschaf Incl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchase),
      "BTW": "\u20AC "+formatThousandNotation(dealDetail.vat),
      "Aanschaf Excl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchaseExclVAT),
      "Aanbetaling": "\u20AC "+(dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : 0),
      "Inruil": "\u20AC "+(dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : 0),
      "Inlossing": "\u20AC "+(dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : 0),
      "Te financieren": "\u20AC "+formatThousandNotation(dealDetail.leaseAmount),
      "Slottermijn": "\u20AC "+(dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : 0),
      "Looptijd": dealDetail.duration+" maanden",
      "Maandbedrag": "\u20AC "+dealDetail.monthlyamount,
      "typeOfDeal": dealDetail.typeOfDeal,
      "Rente": dealDetail.interest+"%",
      "Provisiepercentage": dealDetail.provisiePercentage+"%"
    }}
    ondervoorbehoud={ondervoorbehoud}
    calculatorGoal={calculatorGoal}
    relatedID={dealDetail.relatedID}
    dealID={dealDetail.dealID}
    typeOfDeal={dealDetail.typeOfDeal}
    disableTitle={true}
    firstRegistrationDate={dealDetail.vehicleDetail.firstRegistrationDate}
    vehicleType={dealDetail.vehicleDetail.vehicleType}
    restBPM={dealDetail.vehicleDetail.restBPM}
    disableMaxSlottermijn={true}
    slottermijnTouched={true}
    showDifference={true}
    fixedBank={true}
    administrationCost={intTo2DecStr(dealDetail.administrationCost)}      
    {...calcDataToPass}
    contractInLossing={dealDetail.contractInLossing}
    kentekenInLossing={dealDetail.kentekenInLossing}
    bankInLossing={dealDetail.bankInLossing}
    /> */}
    
  </Modal>
  </Suspense>
  }

{dealPopup==="requestInsuranceByActua" && createPortal(
    <Suspense fallback={<div>Loading ...</div>}>
      <Modal childrenOnly={true} show={true} onClose={() => closeModalHandler('requestInsuranceByActua')}>
        <RequestInsuranceByActuaForm title="Verzekering aanvragen" onClose={() => closeModalHandler('requestInsuranceByActua')} disableBackDropHandler={true} inModal={true} onUpdate={updateHandler}
        dealDetail={dealDetail} detail={true}
        />
      </Modal>
    </Suspense>, document.body)
  }


  <DocAndActivityBlock2 sourceOfComponent="deal" source={dealDetail.source} updateCounter={dealChangeCounter} dealID={dealDetail.dealID} dealDetail={dealDetail} onUpdate={() => setDealChangeCounter(prevState => prevState + 1)} />
</div>
{showPopup==="sendOffer" && <Popup2 actionLabel="Ja, versturen" cancelLabel="Nee, annuleren" visible={true} onSubmit={popupSubmitHandler} onCancel={() => setShowPopup(false)} title="Offerte versturen aan de klant?" message={["Weet u zeker dat u dit voorstel wilt doen aan uw klant?. Wij sturen uw klant een mail met de definitieve offerte en het verzoek de benodigde persoonsgegevens aan te leveren."]} />}
{showPopup==="otherVehicle" && <Popup2 actionLabel="Ja, doorgaan" cancelLabel="Nee, annuleren" visible={true} onSubmit={otherVehicleHandler} onCancel={() => setShowPopup(false)} title="Doorgaan?" message={["Weet u zeker dat u wilt doorgaan? De huidige verkoop wordt gearchiveerd."]} />}

</>
  );
};

export default DealForm;