import React from "react";
import Select3 from "../Select/Select3";

const DateSelects = ( { register, errors, name, getValues, placeholder, onBlur, onChange, minYear, maxYear, errorMessage, inputValue } ) => {
    const days = Array.from({ length: 31 }, (_, index) => index + 1);
    const months = ['januari','februari','maart','april','mei','juni','juli','augustus','september','oktober','november','december'];
    const currentYear = new Date().getFullYear();

    if(!minYear) minYear = currentYear - 100;
    if(!maxYear) maxYear = new Date().getFullYear();
    // const maxYear = new Date().getFullYear(); //; currentYear - 18;
    const years = Array.from({ length: maxYear - minYear + 1 }, (_, index) => maxYear - index);

    return (
<div className="grid grid-cols-3 relative">
                        <Select3
                        onChange={onChange}
                        objKey={name}
                        name="day"
                        register={register}
                        fieldToValidate="Kies dag"
                        disableFirst={true}
                        placeholder={placeholder ? placeholder : ""}
                        inputValue={getValues(`${name}.day`)}
                        selectClasses="rounded-r-none"
                        errorMessage={errors?.[name]?.day?.message}
                        >
                                        <option value=""></option>
                                        {days.map((day) => {
                                        var value = day;
                                        if(value<=9) value = "0"+value;
                                        return (<option key={day} value={value}>{day}</option>);
                                        })}
                                    </Select3>
                                    <Select3
                                    onChange={onChange}
                                    objKey={name}
                                    name="month"
                                    register={register}
                                    fieldToValidate="Kies maand"
                                    disableFirst={true}
                                    placeholder="Maand"
                                    inputValue={getValues(`${name}.month`)}
                                    selectClasses="!rounded-none border-x-0"
                                    errorMessage={errors?.[name]?.month?.message}
                                    >
                                        <option value=""></option>
                                        {months.map((month, index) => {
                                        var value = index + 1;
                                        if(value<=9) value = "0"+value;
                                        return (<option key={month} value={value}>{month}</option>);
                                        })}
                                    </Select3>
                                    <Select3
                                    onChange={onChange}
                                    objKey={name}
                                    name="year"
                                    register={register}
                                    fieldToValidate="Kies jaar"
                                    disableFirst={true}
                                    placeholder="Jaar"
                                    selectClasses="!rounded-l-none"
                                    inputValue={getValues(`${name}.year`)}
                                    errorMessage={errors?.[name]?.year?.message}
                                    >
                                        <option value=""></option>
                                        {years.map((year) => {
                                        return (<option key={year} value={year}>{year}</option>);
                                        })}
                                    </Select3>
                                    {errorMessage && <span className="col-span-3 text-xs text-[red] absolute left-0 -bottom-4 select-none">{errorMessage}</span>}
                        </div>
    )
};

export default DateSelects;