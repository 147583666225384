import BtwMargeForm from "components/Forms/BtwMargeForm/BtwMargeForm";
import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import useHead from "hooks/use-head";

export const StapBtwMarge = () => {
  useHead({title: "Btw / Marge"});
  
  return (
    <>
      <div className="container mx-auto px-5 max-w-full mb-10 border-b border-b-slate-200">
          <ProgressBar />
      </div>
      <Container>
        <BtwMargeForm />
      </Container>
    </>
  );
};

export default StapBtwMarge;
