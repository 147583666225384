import useAuth from "hooks/use-auth";
import { formattedPMT_v2 } from "lib/calc";
import { getDateTimeFromDate } from "lib/helpers";
import { useNavigate } from "react-router";

const DealsPerDealer = ({deals, phase, title, emptyMessage}) => {
  const navigate = useNavigate();
  const { role } = useAuth();

  var dealData = deals?.data;
  if(!dealData?.length) return <></>;
  if(phase==="Lopend") dealData = dealData.filter(deal => deal.phase==="Lopend");
  if(phase==="!Lopend") dealData = dealData.filter(deal => deal.phase!=="Lopend");

  return (
        <div className="flex flex-col lg:bg-slate-100 rounded-xl lg:p-10 mb-5">       
        <h3 className="font-semibold">{title}</h3>      

        <div>{dealData && dealData.map(deal => {
          return (<div key={deal.dealID} className="flex flex-col lg:grid lg:grid-cols-7 lg:items-center cursor-pointer text-sm border-b hover:bg-slate-500 hover:text-white " onClick={() => navigate(`${role.includes("Admin") ? `/crm` : ``}/verkopen/`+deal.dealID)}>
                <div className="col-span-2 flex flex-row gap-x-3">
                  <span className="w-10">{deal.dealID}</span>
                  <span>{deal.phase}</span>
                </div>
                <div className="grow col-span-2 flex flex-col">
                  <span>{deal.customerDetail.companyName}</span>
                  <span>{deal.customerDetail.firstName} {deal.customerDetail.lastName}</span>
                </div>
                <div className="">{deal.vehicleDetail.make} {deal.vehicleDetail.model}</div>
                <div>{getDateTimeFromDate(deal.createdDate)}</div>
                <div className={`${(deal.phase==="Lopend"&&deal.isArchive===true) && "font-semibold text-xl"} ${deal.isArchive===false && "text-xl"} ${(deal.isArchive===true&&deal.phase!=="Lopend") && "line-through"} text-end`}>&euro; {formattedPMT_v2(deal.commissionAmountDealer)}</div>
          </div>)
        })}
        {!dealData?.length && <div>{emptyMessage}</div>}
      </div>           
    </div>
    )
}

export default DealsPerDealer;