import { validateFormField } from "lib/validation";

export const Input3 = ({register, name, inputValue, fieldToValidate, required, placeholder, placeholdersm, errorMessage}) => {
    var focussedOrWithValue = !!inputValue;

    return (
        <div className="flex flex-col relative mb-3">
            <input {...register(name, { validate: (value) => validateFormField(fieldToValidate, value, required) })} placeholder="" className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                `}
            spellCheck={false}
            autoComplete="off"
            />

            {placeholder && <label className={`
            ease-in-out
            duration-200
            transition-all
            peer-focus:text-slate-600
            peer-focus:text-xs
            peer-focus:top-1
            ${focussedOrWithValue ? "top-1 text-xs" : "top-3"}
            select-none absolute left-3 text-slate-500 pointer-events-none
            `}>{placeholder}</label>}

            {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-2">{errorMessage}</span>}
        </div>
    )
};

export const Select3 = ({register, name, inputValue, fieldToValidate, required, placeholder, placeholdersm, errorMessage, children, selectClasses, onChange}) => {
    var focussedOrWithValue = !!inputValue;

    return (
    <div className={`flex flex-col relative mb-3`}>
    <select {...register(name, { validate: (value) => validateFormField(fieldToValidate, value, required), onChange: e => onChange ? onChange(name, e.target.value, name) : null })} placeholder="" className={
        `peer
        ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
        rounded-xl border border-slate-300
        ${selectClasses}
        `}
    spellCheck={false}
    autoComplete="off">
        {children}
    </select>

    {placeholder && <label className={`
    ease-in-out
    duration-200
    transition-all
    peer-focus:text-slate-600
    peer-focus:text-xs
    peer-focus:top-1
    ${focussedOrWithValue ? "top-1 text-xs" : "top-3"}
    select-none absolute left-3 text-slate-500 pointer-events-none
    `}>{placeholder}</label>}

    {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-2">{errorMessage}</span>}
    </div>
    );
}