import { useState, useRef } from "react";
import { createPortal } from "react-dom";

import useEmail from "hooks/email/use-email";
import Modal from "components/hoc/Modal";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

const EmailPreview = ({emailLogId, onClose}) => {
    const dispatch = useDispatch();
    const iframeRef = useRef();
    const { emailLog } = useEmail("getEmailLogById", emailLogId);
    const [iframeHeightTemplate, setIframeHeightTemplate] = useState("");

    const onIframeLoad = (src) => {
         var contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight + 50;
         setIframeHeightTemplate(contentHeight);
    }

    const closeHandler = () => {
        onClose();
        dispatch(uiActions.hideBackdrop());
    }
    
    return (
        <>
             {createPortal(<><Modal category="calc" title={emailLog?.subject} show={true} onClose={closeHandler}>
                <div className="container w-screen">
                    <iframe ref={iframeRef} srcDoc={emailLog?.htmlBody} width="100%" height={iframeHeightTemplate} onLoad={onIframeLoad}></iframe>
                </div>
                </Modal>
           </>, document.body)}
           </>
    )
}

export default EmailPreview;