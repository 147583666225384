import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";
import { uiActions } from "store/ui-slice";
import { aesDecrypt } from "lib/helpers";

export const useUser = (init, initQuery=null) => {
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();

    const [users, setUsers] = useState([]);

    const resetChangelog = useCallback(async() => {
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/User/ResetChangeLog", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }  
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Waarde van de changelog is voor alle gebruikers gereset!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Er is iets fout gegaan bij het resetten van de changelog!", type: "danger" }));
        }
    },[]);

    const setChangeLog = useCallback(async ({src, booleanValue}) => {
        if(!src || !booleanValue) return;
        dispatch(uiActions.showBackdrop());

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/User/SetChangeLog?src="+src+"&showChangeLog="+booleanValue, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }  
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Succesvol aangepast!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Er is iets fout gegaan bij het aanpassen. Probeer opnieuw", type: "danger" }));
        }
    },[]);
    
    const setTermAndConditions = useCallback(async ({src, booleanValue}) => {
        if(!src || !booleanValue) return;
        dispatch(uiActions.showBackdrop());

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/User/SetTermAndConditions?src="+src+"&showTermsAndConditions="+booleanValue, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }  
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Succesvol aangepast!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Er is iets fout gegaan bij het aanpassen. Probeer opnieuw", type: "danger" }));
        }
    },[]);

    const resetTermsAndConditions = useCallback(async () => {
        dispatch(uiActions.showBackdrop());

        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/User/ResetTermAndConditions", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }  
            });
            var respJson = await resp.json();
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Waarde van de terms and conditions is voor alle gebruikers gereset!", type: "success" }));
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Er is iets fout gegaan bij het resetten van de terms and conditions!", type: "danger" }));
        }
    },[]);

    const getUsers = useCallback(async () => {
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/User', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }
            });
    
            var respText = await resp.text();
            var responseIv = resp.headers.get("key");
            var respData = aesDecrypt(respText, responseIv);
            setUsers(respData);
        } catch (err) {
            console.log(err);
        }
    },[]);

    useEffect(() => {
        if(init==="getUsers") getUsers();
    }, [getUsers]);

    return {
        users,
        getUsers,
        resetChangelog,
        resetTermsAndConditions,
        setTermAndConditions,
        setChangeLog
    }
}

export default useUser;