import { sendRequest } from "lib/calc"
import { uiActions } from "./ui-slice";
import { dealerActions } from "./dealer-slice";
import { setError } from "lib/helpers";

export const getDealerById = (id, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/' + id, headers: { 'Authorization': 'Bearer ' + jwt } });
            dispatch(dealerActions.setSingleDealer(responseData));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}

export const newDealer = (dealerObj, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/registerDealerV2', method: "POST", headers: {'Authorization': 'Bearer ' + jwt }, body: dealerObj }, "formData");
            if(responseData.status==="Error") {
                throw new Error();
            }
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Dealer succesvol aangemaakt!", type: "success"}));
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Fout bij het aanmaken van de dealer!", type: "danger"}));
            return JSON.parse(err.message);
        }
    }
}

export const clearDealer = () => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            dispatch(dealerActions.clearDealer());
            dispatch(uiActions.hideBackdrop());
        } catch(err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}