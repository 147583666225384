import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAuth from "../use-auth";
import { escapeSpecialChars, sortDataByKey, transformPhoneToDigitsOnly } from "lib/voertuig";
import { aesDecrypt, aesEncrypt, dateObjToDateISO, noDotAndInteger, setError, transformToValidVehicleType } from "lib/helpers";
import { uiActions } from "store/ui-slice";
import { dealActions, resetDeal } from "store/deal-slice";
import { useNavigate } from "react-router";
import { dossierActions } from "store/dossier-slice";
import { progressActions } from "store/progress-slice";
import { counterActions } from "store/counter-slice";

export const useDealV2 = (init, initQuery=null) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token: jwt } = useAuth();

    const [deal, setDeal] = useState({});
    const [archivedDeals, setArchivedDeals] = useState([]);
    const [deals, setDeals] = useState([]);
    const [dealFilters, setDealFilters] = useState({});
    
    const dossier = useSelector(state => state.dossier);
    
    const transformDeal = (deal, saleType) => {
        const { dealerDetail, customerDetail, vehicleDetail, calcDetail } = deal;
    
        var dealer = {...dealerDetail};
        var customer = {...customerDetail};
        var vehicle = {...vehicleDetail};
        var calc = {...calcDetail};
        
        dealer.dealerID = dealer?.dealerID ? dealer?.dealerID : "";
        dealer.dealerCategory = dealer?.dealerCategory ? dealer?.dealerCategory : "B"; // B is default 50/50
       
        dealer.name = dealer?.name ? dealer.name : ""; 
        if(!dealer?.phone) dealer.phone = dealer?.telephone || "";
        if(!dealer?.telephone) dealer.telephone = dealer?.phone || "";
    
        if(calc?.typeOfDeal) calc.typeOfDeal = calc.typeOfDeal.replace(/m.*/i, "Marge").replace(/(b|v).*/i, "BTW"); // Personenauto|Bedrijfsauto
        
        calc.amountpurchase = calc.amountpurchase ? noDotAndInteger(calc.amountpurchase) : 0;
        calc.amountpurchaseExclVAT = calc.amountpurchaseExclVAT ? noDotAndInteger(calc.amountpurchaseExclVAT) : "";

        calc.commissionAmountDealer = calc.commissionAmountDealer ? Number(calc.commissionAmountDealer) : 0;
        calc.commissionAmountSLD = calc.commissionAmountSLD ? Number(calc.commissionAmountSLD) : 0;
        calc.provisiePercentage = calc.provisiePercentage ? +calc.provisiePercentage : "";
        calc.tradeInAmount = calc.tradeInAmount ? noDotAndInteger(calc.tradeInAmount) : 0;
        calc.leaseAmount = calc.leaseAmount ? noDotAndInteger(calc.leaseAmount) : 0;
        calc.totalToPay = calc.totalToPay ? noDotAndInteger(calc.totalToPay) : 0;
        calc.downpayment = calc.downpayment ? noDotAndInteger(calc.downpayment) : 0;
        calc.repayment = calc.repayment ? noDotAndInteger(calc.repayment) : 0;

        calc.monthlyamount = calc.monthlyamount ? parseFloat(calc.monthlyamount) : 0;
        calc.finalpayment = calc.finalpayment ? noDotAndInteger(calc.finalpayment) : 0;
        calc.interest = calc.interest ? +calc.interest : "";
        calc.duration = calc.duration ? +calc.duration : 0;

        calc.contractInLossing = calc?.contractInLossing ? calc?.contractInLossing : "";
        calc.kentekenInLossing = calc?.kentekenInLossing ? calc?.kentekenInLossing : "";
        calc.bankInLossing = calc?.bankInLossing ? calc?.bankInLossing : "";
        
        calc.vat = calc.vat ? noDotAndInteger(calc.vat) : 0;
        if(/marge/i.test(calc?.typeOfDeal)) {
            calc.vat = 0;
            calc.amountpurchaseExclVAT = calc.amountpurchase;
        }      

        if(!calc.administrationCost) calc.administrationCost = 249;
        if(!calc.chosenBank) calc.chosenBank = "df_2";
        
        if(vehicle?.typeOfDeal) vehicle.typeOfDeal = (/^(BTW|Marge)$/.test(vehicle.typeOfDeal) ? vehicle.typeOfDeal : vehicle.typeOfDeal.replace(/m.*/i, "Marge").replace(/(b|v).*/i, "BTW")); // Personenauto|Bedrijfsauto
        vehicle.make = vehicle.make ? escapeSpecialChars(vehicle.make) :  "";
        vehicle.model = vehicle.model ? escapeSpecialChars(vehicle.model) :  "";
        vehicle.type = vehicle.type ? escapeSpecialChars(vehicle.type) :  "";
        vehicle.carrosserie = vehicle.carrosserie ? vehicle.carrosserie :  "";
                        
        vehicle.vehicleType = vehicle?.vehicleType ? transformToValidVehicleType(vehicle?.vehicleType) : "";
        vehicle.mileage = vehicle.mileage ? noDotAndInteger(vehicle.mileage) :  0;
        vehicle.firstRegistrationDate = (vehicle?.firstRegistrationDate?.day && vehicle?.firstRegistrationDate?.month && vehicle?.firstRegistrationDate?.year) ? dateObjToDateISO(vehicle.firstRegistrationDate) : "";
        vehicle.age = vehicle.age ? noDotAndInteger(vehicle.age) :  "";
        vehicle.listPrice = vehicle.listPrice ? noDotAndInteger(vehicle.listPrice) :  "";
        vehicle.positionJudgement = vehicle.positionJudgement ? vehicle.positionJudgement :  "";
        vehicle.licensePlate = vehicle.licensePlate ? vehicle.licensePlate :  "";
        vehicle.grossBPM = vehicle.grossBPM ? vehicle.grossBPM :  "";
        vehicle.fuelType = vehicle.fuelType ? vehicle.fuelType :  "";
        vehicle.engineCapacity = vehicle.engineCapacity ? vehicle.engineCapacity :  "";
        vehicle.occasionNumber = vehicle.occasionNumber ? vehicle.occasionNumber : 0;
        vehicle.massEmpty = vehicle?.massEmpty ? parseInt(vehicle.massEmpty) : 0;

        customer.dateofBirth = customer.dateofBirth ? dateObjToDateISO(customer.dateofBirth) : "";
        customer.salesDescription = customer.salesDescription ? customer.salesDescription : "";
        customer.companyName = customer.companyName ? escapeSpecialChars(customer.companyName) : "";
        customer.iban = customer.iban ? customer.iban.toUpperCase() : ""; 
        customer.phone = customer.phone ? transformPhoneToDigitsOnly(customer.phone) : "";
        // customer.dateofEsteb = customer.dateofEsteb ? dateToISO(customer.dateofEsteb) : "";
    
        // overwrite Insurance request with below values
        if(saleType==="Verzekering") {
            calc.commissionAmountDealer = 0;
            calc.commissionAmountSLD = 0;
        }

        return {
            dealer,
            customer,
            vehicle,
            calc
        };
    }

    const createDeal = useCallback(async (finalData, saleType="FinancialLease") => {
        dispatch(uiActions.showBackdrop());  

        var { dealer, customer, calc, vehicle } = transformDeal(finalData, saleType);

        var jsonData = {
            "requestedInsurance": customer.requestedInsurance || false,
            "contractInLossing": calc.contractInLossing,
            "kentekenInLossing": calc.kentekenInLossing,
            "bankInLossing": calc.bankInLossing,
            "dealerCategory": dealer.dealerCategory,
            "source": "Dealer-aanvraag",
            "salesDescription": customer.salesDescription,
            "dealerID": dealer.dealerID,
            "provisiePercentage": calc.provisiePercentage,
            "tradeInAmount": calc.tradeInAmount,
            "repayment": calc.repayment,
            "leaseAmount": calc.leaseAmount,
            "typeOfDeal": calc.typeOfDeal,
            "amountpurchase": calc.amountpurchase,
            "commissionAmountDealer": calc.commissionAmountDealer,
            "totalToPay": calc.totalToPay,
            "downpayment": calc.downpayment,
            "monthlyamount": calc.monthlyamount,
            "vat": calc.vat,
            "description": "",
            "duration": calc.duration,
            "administrationCost": calc.administrationCost,
            "commissionAmountSLD": calc.commissionAmountSLD,
            "amountpurchaseExclVAT": calc.amountpurchaseExclVAT,
            "damageFreeYearsInsurance": "",
            "damageHistoryInsurance": "",
            "typeOfInsurance": "",
            "kmPerYear": "",
            "chosenBank": calc.chosenBank,
            "finalpayment": calc.finalpayment,
            "interest": calc.interest,
            "phase": "Uw aanvraag is ontvangen",
            "saleType": ""+saleType,
            "customerDetail": {
                "rechtsvorm": customer.rechtsvorm,
                "sbiCodes": customer.sbiCodes,
                "latitude": customer.latitude,
                "longitude": customer.longitude,
                "companyName": customer.companyName,
                "email": customer.email,
                "salutation": customer.salutation,
                "firstName": customer.firstName,
                "lastName": customer.lastName,
                "dateofBirth": customer.dateofBirth,
                "driverLicenseNumber": customer.driverLicenseNumber,
                "phone": customer.phone,
                "iban": customer.iban,
                "addition": "",
                "type": "Indivisul",
                "address": customer.address,
                "chamberNumber": customer.chamberNumber,
                "dateofEsteb": customer.dateofEsteb,
                "postalCode": customer.postalCode,
                "place": customer.place,
                "age": customer.age
            },
            "vehicleDetail": {
              "type": vehicle.type,
              "occasionNumber": vehicle.occasionNumber,
              "make": vehicle.make,
              "model": vehicle.model,
              "marginVAT": 0,
              "mileage": vehicle.mileage,
              "vehicleType": vehicle.vehicleType,
              "firstRegistrationDate": vehicle.firstRegistrationDate,
              "age": vehicle.age,
              "engineCapacity": vehicle.engineCapacity,
              "fuelType": vehicle.fuelType,
              "grossBPM": vehicle.grossBPM,
              "licensePlate": vehicle.licensePlate,
              "listPrice": vehicle.listPrice,
              "positionJudgement": "",
              "restBPM": vehicle.restBPM,
              "carrosserie": vehicle.carrosserie,
              "massEmpty": vehicle?.massEmpty
            }
          };
          
        // Modify json for (Verzekering) Insurance request with below values
        if(saleType==="Verzekering") {
            jsonData.chosenBank = "";
            jsonData.amountpurchase = finalData?.amountpurchase ? noDotAndInteger(finalData.amountpurchase) : 0;

            jsonData.amountpurchaseExclVAT = 0;
            jsonData.phase = "Uw aanvraag wordt beoordeeld";
            jsonData.damageFreeYearsInsurance = finalData?.damageFreeYearsInsurance || "";
            jsonData.damageHistoryInsurance = finalData?.damageHistoryInsurance || "";
            jsonData.typeOfInsurance = finalData?.typeOfInsurance || "";
            jsonData.kmPerYear = finalData?.kmPerYear || "";

        }

        // Modify json for (Verzekering) Insurance request with below values
        if(saleType==="BtwFinanciering") {
            jsonData.chosenBank = "";
            jsonData.typeOfDeal = "BTW";
            jsonData.administrationCost = 0;
            jsonData.commissionAmountSLD = 50;
            jsonData.commissionAmountDealer = 50;
            jsonData.leaseAmount = finalData?.financeAmount ? noDotAndInteger(finalData?.financeAmount) : 0;
            jsonData.provisiePercentage = 0;
            jsonData.tradeInAmount = 0;
            jsonData.repayment = 0;
            jsonData.amountpurchase = 0;
            jsonData.totalToPay = 0;
            jsonData.downpayment = 0;
            jsonData.monthlyamount = 0;
            jsonData.vat = 0;
            jsonData.description = 0;
            jsonData.duration = 0;
            jsonData.finalpayment = 0;
            jsonData.interest = 0;
        }

        // Bij herberekening send dealID
        if(dossier?.recalculation) {
            if(dossier?.recalculate_for_dealid) jsonData.relatedID = dossier.recalculate_for_dealid;
            if(dossier?.hlContractNumber) jsonData.hlContractNumber = dossier.hlContractNumber;
            if(dossier?.dfContractNumber) jsonData.dfContractNumber = dossier.dfContractNumber;
            if(dossier?.caContractNumber) jsonData.caContractNumber = dossier.caContractNumber;
            if(dossier?.alContractNumber) jsonData.alContractNumber = dossier.alContractNumber;
        }
    
        try {
            var { bodyEncrypted, iv } = aesEncrypt(jsonData);
          
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/CreateDeal", {
                method: "POST",
                headers: {
                    "Key": iv,
                    "Authorization": "Bearer "+jwt
                },
                body: bodyEncrypted
            });
            var respText = await resp.text();
            if(resp?.status===400) throw new Error();

            var responseIv = resp.headers.get("key");
            var respData = aesDecrypt(respText, responseIv);
            dispatch(uiActions.hideBackdrop());
            return respData;
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Er is iets fout gegaan, controleer het formulier.", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    },[]);

    const getDealById = useCallback(async (dealID) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/Deal/${dealID}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedData = aesDecrypt(respText, iv);
        setDeal(decryptedData);
        return decryptedData;
    },[]);

    const getDeals = useCallback(async (searchQuery) => {
        setIsLoading(true);
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters"+(searchQuery ? "?"+searchQuery : ""), {
            headers: {
                "Authorization": "Bearer "+jwt
            }
        });
        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedDeals = aesDecrypt(respText, iv);
        setDeals(decryptedDeals);
        setIsLoading(false);
        return decryptedDeals;
    },[]);

    const updateDeal = async (data, attachment=null, action='update', touchBackdrop=true, createActivity=false) => {
        if(touchBackdrop) dispatch(uiActions.showBackdrop());
        var transformedData = {
            ...data,
            DealerDetail: {
                ...data.DealerDetail,
                importID: data.dealerDetail.importID ? data.dealerDetail.importID: "0"
            }
        }

        const { dealID } = transformedData;
        var { bodyEncrypted, iv } = aesEncrypt(transformedData);
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/"+dealID, {
                method: "PUT",
                headers: {
                    "Key": iv,
                    "Authorization": "Bearer "+jwt
                },
                body: bodyEncrypted
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData.message==="Deal updated") {
                setDeal(decryptedData);
                var message = "Deal succesvol bijgewerkt";
                switch(data.phase) {
                  case "Klant is akkoord met de offerte":
                    message = "Financiering succesvol gereserveerd";
                    break;
                  default: 
                    break;
                } 

                // createActivity here.
                
                if(touchBackdrop) dispatch(uiActions.hideBackdrop());
                dispatch(uiActions.setAlert({message: message, type: "success"}));
    
                // update deal data
                dispatch(dealActions.updateCounter());        
                return "SUCCESS";
            }
        } catch (err) {
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Deal kan niet bijgewerkt worden", type: "danger"}));
        }
    }

    const archiveDeal = async (dealID, data) => {
        dispatch(uiActions.showBackdrop());
        // var { bodyEncrypted, iv } = aesEncrypt(data);
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/"+dealID, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(data)
            });
            var responseIv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedDeal = aesDecrypt(respText, responseIv);
            var message = "Deal is succesvol ";
            var activityType;
            if(!data.isArchive) {
              message+="gedearchiveerd";
              activityType = "gedearchiveerd";
            }
            if(data.isArchive) {
              message+="gearchiveerd";
              activityType = "gearchiveerd";
            }
            // createActivity(activityType, data, jwt);
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: message, type: "success"}));
            setDeal(decryptedDeal);

            // update deal data
            dispatch(dealActions.updateCounter());           
            return "SUCCESS";
        } catch (err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.setAlert({message: "Deal kan niet bijgewerkt worden", type: "danger"}));
        }
    }
    
    const getArchivedDeals = useCallback(async (searchQuery) => {
        var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters?isArchive=true"+(searchQuery ? "&"+searchQuery : ""), {
            headers: {
                "Authorization": "Bearer "+jwt
            }
        });

        var iv = resp.headers.get("key");
        var respText = await resp.text();
        var decryptedDeals = aesDecrypt(respText, iv);
        setArchivedDeals(decryptedDeals);
        return decryptedDeals;
    },[]);

    const patchDeal = useCallback(async (dealID, dealData, touchBackdrop=true) => {
        if(touchBackdrop) dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/"+dealID, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                },
                body: JSON.stringify(dealData)
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            if(decryptedData?.message==="Deal updated") {
                if(touchBackdrop) dispatch(uiActions.hideBackdrop());
                dispatch(uiActions.setAlert({message: "Deal succesvol bijgewerkt", type: "success"}));
            }
            return "SUCCESS";
        } catch (err) {
            if(touchBackdrop) dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Deal kan niet bijgewerkt worden", type: "danger"}));
        }
    },[]);

    const countRecords = useCallback(async (inputData) => {
        var respData;

        if(inputData) {
            respData = inputData;
        } else {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/CountRecords", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var respData = aesDecrypt(respText, iv);
        }

        try {            
            const totalRecords = {};
            totalRecords.totalInbox = respData.filter(item => /(Uw aanvraag is ontvangen|Uw aanvraag wordt beoordeeld|Klant dient extra informatie aan te leveren|Controleer en verzend offerte)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalContractClosing = respData.filter(item => /(Contract wordt opgesteld door de bank|Contract ter ondertekening bij de klant|Contract ter ondertekening bij u|U dient het voertuig op naam van de klant te registreren|In afwachting van uitbetaling|Bevestiging van uitbetaling)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalSignedOffers = respData.filter(item => /(Klant is akkoord met de offerte)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalSales = respData.filter(item => /(Offerte verzonden)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            const updatedCounterData = {
                phaseRecords: {...respData},
                totalRecords
            }           
            dispatch(counterActions.count(updatedCounterData));
        } catch (err) {
            console.log("failed getting counters");
        }

    },[]);
    
    const getDealFilters = async () => {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/UniqueFilters", {
                headers: {
                    "Authorization": "Bearer "+jwt
                }
            });
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var decryptedData = aesDecrypt(respText, iv);
            var transformedData = sortDataByKey(decryptedData.dealers?.filter(item => item!=="").map(item => ({label: item.name, value: item.dealerID})),"label") || {};
            var finalData = {
                ...decryptedData,
                transformedData
            }
            setDealFilters(finalData);
    };

    const clearDealFromStore = (navigateToStart=true) => {
       dispatch(dossierActions.resetFetched());
       dispatch(resetDeal());
       dispatch(progressActions.reset());
       if(navigateToStart) navigate("/financiering/financial-lease");
    }

    useEffect(() => {
        if(init==="getDeals") getDeals(initQuery);
        if(init==="getDealById") getDealById(initQuery);
        if(init==="countRecords") countRecords();
    }, [getDealById, getDealById, countRecords]);
    
    return {
        isLoading,
        deal: useMemo(() => deal),
        deals: deals,
        archivedDeals,
        dealFilters: dealFilters,
        createDeal,
        getDeals,
        getDealById,
        getArchivedDeals,
        getDealFilters,
        updateDeal,
        patchDeal,
        archiveDeal,
        clearDealFromStore,
        countRecords
    }
}

export default useDealV2;