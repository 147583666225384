import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

const Modal = (props) => {
    const dispatch = useDispatch();
    const { show } = props;
    useEffect(() => {
        if(show) dispatch(uiActions.showBackdrop());
        if(!show) dispatch(uiActions.hideBackdrop());
    },[show]);

    const keydownHandler = (evt) => {
        if(evt.keyCode===27) props.onClose();
    }

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    },[]);
     
    return (
        <div className={`${show ? 'flex flex-col ' : 'hidden '}+"justify-center items-center fixed top-0 right-0 left-0 z-50 w-full h-full overflow-y-scroll`}>
            <div className={`flex flex-col relative w-full max-w-5xl h-full p-4 items-center ${props.category!=='calc' && 'justify-center'}`}>
                {/* Modal content */}
                <div className={`relative bg-white rounded-3xl ${props.xl ? 'w-full h-full' : ''}`}>
                {props.childrenOnly 
                ?
                <div>{props.children}</div>
                :
                <>
                    {/* Modal header */}
                    <div className="flex justify-between border-b bg-[#212853] text-white py-1 p-5 rounded-t-3xl items-center">
                        <h3 className="text-xl font-semibold text-gray-900">{props.title}</h3>
                        <span onClick={props.onClose} className="cursor-pointer p-5 fa fa-xmark text-xl"></span>

                        {/* <Button onClick={props.onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="defaultModal"> */}
                        {/* </Button> */}
                    </div>
                    {/* Modal body  */}
                    {props.xl ? props.children : <div className="p-6 space-y-6">{props.children}</div>}                    
                    </>}
                </div>
               
            </div>

        </div>
    )
}

export default Modal;