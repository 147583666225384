import React, { useState, useEffect, Suspense, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

import Button from "components/ui/Button/Button";
import Modal from "components/hoc/Modal";
import Popup2 from "components/ui/Popup/Popup2";
import Card from "components/ui/Card/Card";
import LicensePlate2 from "components/ui/LicensePlate/LicensePlate2";

import { getFormattedLicensePlate, isUnderCondition, rdwDateStrToDateObj } from "lib/helpers";
import { calcMaxDuration, formattedPMT_v2, formatThousandNotation, intTo2DecStr, localDate } from "lib/calc";
import { escapeSpecialChars } from "lib/voertuig";

import { uiActions } from "store/ui-slice";
import { dossierActions } from "store/dossier-slice";
import { dealActions } from "store/deal-slice";

import useAuth from "hooks/use-auth";
import useDealV2 from "hooks/deal/use-deal_v2";
import useDocument from "hooks/document/use-document";
import useActivity from "hooks/activity/use-activity";

const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));
const CalculatorV3 = React.lazy(() => import("components/Calculator/Calculator_v3"))  ;
const DocumentForm = React.lazy(() => import("components/Forms/DocumentForm/DocumentForm"));

const DealFormDealer = (props) => {
  const { role } = useAuth();
  const { createActivity, sendOffer } = useActivity();
  const { deal: dealDetail, getDealById, updateDeal, archiveDeal } = useDealV2();
  const dispatch = useDispatch();
  const params = useParams();

  const [dealChangeCounter, setDealChangeCounter] = useState(0);

  var { dealID } = params;

  useEffect(() => {
    getDealById(dealID);
  }, [getDealById, dealChangeCounter]);

  const location = useLocation();
  const [pathName, setPathName] = useState(false);

  const [offerIsSent, setOfferIsSent] = useState(false);

  useEffect(() => {
    setPathName(window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : ""));
  }, [location]);

  const navigate = useNavigate();

  const [dealPopup, setDealPopup] = useState(false); // vehicle|dealer|customer|sales|insurance

  // calculator related 
  const [showCalculator, setShowCalculator] = useState(false);
  const [calculatorGoal, setCalculatorGoal] = useState(""); // empty string: calculatie verkoopgegevens, bank: calculatie banken

  // bank related
  const [bankDetail, setBankDetail] = useState({
    bankName: "",
    calcsrc: "",
    prefix: "",
    status: "",
    message: "",
    response: "",
    responseDutch: "",
    requestNumber: "",
    amountpurchase: "",
    amountpurchaseExclVAT: "",
    contractNumber: "",
    downpayment: "",
    leaseAmount: "",
    vat: "",
    tradeInAmount: "",
    interest: "",
    finalpayment: "",
    duration: "",
    monthlyAmount: "",
    repayment: "",
    provisiePercentage: "",
    contractInLossing: "",
    kentekenInLossing: "",
    bankInLossing: "",
  });

  var calcDataToPass = (!calculatorGoal) ? {
    ...dealDetail,
    calcsrc: dealDetail.chosenBank
  } : bankDetail;

  const [pdfMethod, setPdfMethod] = useState("download");
  const [loadPdf, setLoadPdf] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const otherVehicleHandler = async () => {
    dispatch(dealActions.setCustomerData({
      ...dealDetail.customerDetail,
      ...(dealDetail?.customerDetail?.dateofBirth && {dateofBirth: rdwDateStrToDateObj(dealDetail.customerDetail.dateofBirth)})
    }));
    var dataToChange = {
      isArchive: true,
      phase: "Ander object"
    };
    var resp = await archiveDeal(dealID, dataToChange);
    if(resp==="SUCCESS") {
      dispatch(dossierActions.reCalculate({
        dealID: dealDetail.relatedID,
        hlContractNumber: dealDetail.hlContractNumber,
        dfContractNumber: dealDetail.dfContractNumber,
        caContractNumber: dealDetail.caContractNumber,
        alContractNumber: dealDetail.alContractNumber
      }));
      createActivity("Deal", "archive", { dealID: dealID }, dataToChange);
      navigate("/financiering/financial-lease");
    }
  }

  const reCalculateHandler = () => {
    setShowCalculator(true);
    setShowModal(true);
  }

  const popupSubmitHandler = () => {
    setPdfMethod("base64");
    setLoadPdf(true);
  }

  const downloadPDFHandler = () => {
    setPdfMethod("download");
    setLoadPdf(true)
  }

  const submitSendOffer = async (data) => {
    if (offerIsSent) return;
    var resp = await sendOffer(data, dealDetail);
    (resp==="SUCCESS") ? setOfferIsSent(true) : setOfferIsSent(false);
    setDealChangeCounter(prevState => prevState + 1);
    setLoadPdf(false);
  }

  const btwFinancieringHandler = () => {
    dispatch(dealActions.setCustomerData(dealDetail.customerDetail));
    navigate("/financiering/btw-financiering");
  }
  var ondervoorbehoud = isUnderCondition(dealDetail.phase);

  const closeModalHandler = (modalType) => {
    dispatch(uiActions.hideBackdrop());
    setDealPopup("");
  }

  const hideCalcHandler = () => {
    setCalculatorGoal("");
    setShowCalculator(false);
    setShowModal(false);
    setBankDetail({});
    dispatch(uiActions.hideBackdrop());
  }

  const { getDocuments } = useDocument();

  useEffect(() => {
    if(!dealID) return;
    getDocuments(dealID);
  }, [getDocuments]);

  const updateHandler = () => {
    setDealPopup("");
    setDealChangeCounter(prevState => prevState + 1);
  }


  if (!dealDetail.customerDetail || !pathName) return <>Laden...</>;
  if(!role || (!role.includes("User") && !role.includes("SubUser") && !role.includes("Admin"))) return <>Geen rechten</>; 

  const blokWrapperClass = "text-[#1a2a52] relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 items-start py-10 px-5 lg:py-5 lg:px-10 gap-y-0 md:gap-y-5 gap-x-10 bg-white shadow-xl rounded-xl mb-5";
  
  const VoertuigGegevens = (
    <div className={blokWrapperClass}>
      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Merk & model</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.make)} {escapeSpecialChars(dealDetail.vehicleDetail.model)}</div>
        {dealDetail.vehicleDetail.type && <><div className="font-semibold">Uitvoering</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.type)}</div></>}
        <div className="font-semibold">Kenteken</div><div className="scale-50 relative -left-1/4 h-[24px] bottom-1"><LicensePlate2 value={getFormattedLicensePlate(dealDetail.vehicleDetail.licensePlate)} /></div>
        <div className="font-semibold">Voertuigsoort</div><div>{dealDetail.vehicleDetail.vehicleType}</div>
      </div>
      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Kilometerstand</div><div>{formatThousandNotation(dealDetail.vehicleDetail.mileage)} km</div>
        <div className="font-semibold">BTW/Marge</div><div>{dealDetail.typeOfDeal}</div>
        {dealDetail.vehicleDetail.positionJudgement && <><div className="font-semibold">Tellerstandoordeel</div><div>{dealDetail.vehicleDetail.positionJudgement}</div></>}
        <div className="font-semibold">Eerste registratie</div>
        <div>{localDate(dealDetail.vehicleDetail.firstRegistrationDate)}</div>
      </div>
                <div className="flex flex-col gap-y-3 order-3 md:col-span-4 lg:col-span-2 xl:col-span-1 items-end justify-end h-full">
        <Button className="btn-secondary" type="button" onClick={() => setShowPopup("otherVehicle")}>
        <div className="flex flex-col">
          <span className="">Ander voertuig </span>
        </div>
        </Button>
        </div>
    </div>
  );

  return (
<>
<form className="flex flex-col text-xs max-w-7xl lg:text-base mt-5">

<div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
  <Button type="button" onClick={() => navigate(-1)}>Terug naar verkopen</Button>
</div>

<div className="text-[#1a2a52] relative bg-white shadow-xl rounded-xl py-10 px-5 lg:py-5 lg:px-10 flex flex-col mb-5">
  <div className="flex flex-row justify-between">
      <div>
        <div className="font-semibold text-lg">{dealDetail.customerDetail.companyName}</div>
        <div className="opacity-50 text-md mt-3">{dealDetail.customerDetail.address},{dealDetail.customerDetail.postalCode}{" " + dealDetail.customerDetail.place}</div>
      </div>
      <div><img src={dealDetail.dealerDetail?.logoImageUrl} className="max-h-[36px]" loading="lazy" /></div>
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-x-10 items-start mt-3">
    <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Tel</div><div>{dealDetail.customerDetail.phone}</div>
        <div className="font-semibold">E-mailadres</div><div>{dealDetail.customerDetail.email}</div>
        <div className="font-semibold">KvK-nummer</div><div>{dealDetail.customerDetail.chamberNumber}</div>
        <div className="font-semibold">IBAN</div><div>{dealDetail.customerDetail.iban}</div>
        <div className="font-semibold">Rijbewijsnummer</div><div>{dealDetail.customerDetail.driverLicenseNumber}</div>
        <div className="font-semibold">Datum oprichting</div><div>{localDate(dealDetail.customerDetail.dateofEsteb)}</div>
    </div>
    <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
        <div className="font-semibold">Aanhef</div><div>{dealDetail.customerDetail.salutation}</div>
        <div className="font-semibold">Voornaam</div><div>{dealDetail.customerDetail.firstName}</div>
        <div className="font-semibold">Achternaam</div><div>{dealDetail.customerDetail.lastName}</div>
        <div className="font-semibold">Geboortedatum</div><div>{localDate(dealDetail.customerDetail.dateofBirth)}</div>
    </div>
    <div className="col-span-1 text-end mb-5 lg:mb-0">
    </div>
  </div>

  <div className="grid grid-cols-1 md:flex md:flex-col justify-between w-full">
    <div className="flex flex-col items-end">
      {dealPopup==="dealer" && createPortal(
        <Modal show={true}
          onClose={() => closeModalHandler('dealer')}
          title={dealDetail.dealerDetail.name}
        >
          <div className="grid grid-cols-2 gap-3 py-5">
            <div>
              <div className="font-semibold">{dealDetail.dealerDetail.name} ({dealDetail.dealerDetail.dealerID})</div>
              <div>kvk: {dealDetail.dealerDetail.chamberNumber}</div>
              <div>{dealDetail.dealerDetail.address}</div>
              <div>{dealDetail.dealerDetail.postalCode} {dealDetail.dealerDetail.place}</div>
              <div className="mt-3 underline">
              </div>
            </div>
            <div>
              {(dealDetail?.dealerDetail?.contactFirstName || dealDetail?.dealerDetail?.contactLastName) && <>
              <div className="font-semibold">Hoofdcontactpersoon</div>
              <div>{dealDetail?.dealerDetail?.contactFirstName} {dealDetail?.dealerDetail?.contactLastName}</div>
              </>}
              <div>{dealDetail.dealerDetail.telephone}</div>
              <div>{dealDetail.dealerDetail.phone}</div>
              <div><a href={`mailto:${dealDetail.dealerDetail.email}`}>{dealDetail.dealerDetail.email}</a></div>
              <div><a href={dealDetail.dealerDetail.website} target="_blank" rel="noreferrer">{dealDetail.dealerDetail.website}</a></div>
            </div>
            <div className="col-start-2">
            </div>
            <div className="flex flex-row gap-x-3">
              <Button className="btn-secondary" type="button" onClick={() => closeModalHandler('dealer')}>Annuleren</Button>
              <Button className="btn-primary bg-gradient" type="button" onClick={() => closeModalHandler('dealer')}>Opslaan</Button>
            </div>
          </div>
        </Modal>
        ,document.body)
      }
    </div>
  </div>



</div>

{dealDetail.saleType==="Verzekering" && <>{VoertuigGegevens}</>}

{dealDetail.saleType==="FinancialLease" &&
<>
{VoertuigGegevens}
<div className={blokWrapperClass}>
  <div className="order-3 lg:order-1 lg:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Aanschaf{/btw/i.test(dealDetail.typeOfDeal) && ' Incl.'}</div><div className="">&euro; {formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">BTW</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail.vat) : "0"}</div>
    <div className="font-semibold">Aanschaf Excl.</div><div>&euro; {/btw/i.test(dealDetail.typeOfDeal) ? formatThousandNotation(dealDetail.amountpurchaseExclVAT) : formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">Aanbetaling</div><div>&euro; {dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : "0"}</div>
    <div className="font-semibold">Inruil</div><div>&euro; {dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : "0"}</div>
    <div className="font-semibold">Inlossing</div><div>&euro; {dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : "0"}</div>
  </div>
  <div className="order-2 lg:col-span-2 grid grid-cols-2 gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Leasebedrag</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
    <div className="font-semibold">Slottermijn</div><div>&euro; {dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : "0"}</div>
    <div className="font-semibold">Looptijd</div><div>{dealDetail.duration} maanden</div>
    <div className="font-semibold">Maandbedrag</div><div>&euro; {formattedPMT_v2(dealDetail.monthlyamount)}</div>
    <div className="font-semibold">Rente</div><div>{dealDetail.interest}%</div>
    <div className="font-semibold">Handelingsfee</div><div>&euro; {dealDetail.administrationCost===0 ? "0": intTo2DecStr(dealDetail.administrationCost)}</div>
  </div>
  {/^hi/.test(dealDetail?.chosenBank) && <div className="order-last flex flex-col col-span-full mt-3 text-[red] font-semibold">Let op! Deze bank rekent €150,-  administratiekosten excl. BTW.</div>}
  <div className="flex flex-col gap-y-3 order-3 md:col-span-4 lg:col-span-2 xl:col-span-1 items-end justify-end h-full">
  {dealDetail.saleType==="FinancialLease" &&
              <div className="flex flex-col gap-y-3">
                <Button className="btn-secondary flex flex-col" type="button" onClick={downloadPDFHandler}><span className="">Download offerte </span><span className="inline-block text-xs">{ondervoorbehoud && '(Onder voorbehoud)'}</span></Button>
                {(!ondervoorbehoud && /btw/i.test(dealDetail.typeOfDeal) && dealDetail.source!=="Website-aanvraag") && <Button className="btn-secondary" onClick={btwFinancieringHandler}>BTW-financiering</Button>}    
              </div>
              }

    { ( (/(Controleer en verzend offerte|Offerte verzonden|Klant is akkoord met de offerte)/.test(dealDetail.phase)) && (dealDetail.saleType==="FinancialLease") ) &&
              <>
              {(!offerIsSent) ?
              <Button className="btn-secondary" type="button" onClick={() => setShowPopup("sendOffer")} disabled={(offerIsSent)}>Offerte versturen</Button>
              :
              <div></div>
              // <Button className="btn-secondary opacity-50" type="button" onClick={() => dispatch(uiActions.setAlert({message: "Om de offerte te kunnen versturen dient u eerst de laatste wijzigingen op te slaan.", type: "danger", time: 5000}))}>Offerte versturen</Button>
              }
              </>
      }
    {(dealDetail.saleType==="FinancialLease" && !dealDetail.isArchive) && <div className="flex flex-row items-end justify-end"><Button size="xs" className="btn-secondary" type="button" onClick={reCalculateHandler}>Financiering aanpassen</Button></div>}
  </div>
</div>
</>
}


{(dealDetail.saleType==="Verzekering") &&
<div className={blokWrapperClass}>
  <div className="md:col-span-3 xl:col-span-3 grid grid-cols-1 md:grid-cols-2 items-center gap-y-1 lg:gap-y-3">
    <div className="font-semibold">Verkoopprijs incl. BTW</div><div>&euro; {formatThousandNotation(dealDetail.amountpurchase)}</div>
    <div className="font-semibold">Type verzekering</div><div>{dealDetail.typeOfInsurance}</div>
    <div className="font-semibold">Schadevrije jaren</div><div>{dealDetail.damageFreeYearsInsurance} </div>
    <div className="font-semibold">Schade afgelopen 5 jaar</div><div>{dealDetail.damageHistoryInsurance}</div>
    <div className="font-semibold">Kilometers per jaar</div><div>{dealDetail.kmPerYear}</div>
  </div>
</div>
}

{(dealDetail.saleType==="BtwFinanciering") &&
<div className={blokWrapperClass}>
  <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 items-center gap-y-1 lg:gap-y-3 mb-3 md:mb-0">
    <div className="font-semibold">Te financieren</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
  </div>
</div>
}

{/* <div className="flex flex-row items-center justify-end">
  {changeInfoInDeal && createPortal(
  <Suspense fallback={<div>Loading ...</div>}>
    <Modal xl={true} childrenOnly={true} show={changeInfoInDeal} onClose={() => closeModalHandler('additionalInfo')}>
    <AdditionalInfoRequest dealDetail={dealDetail} documents={documents} userDetails={userDetails} onClose={() => closeModalHandler('additionalInfo')} onUpdate={() => updateWidgetHandler("update_additionalInfo")} />
  </Modal>
  </Suspense>, document.body)}
</div> */}

</form>

{loadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 chosenBank={dealDetail?.chosenBank} method={pdfMethod} hostname={pathName} ondervoorbehoud={ondervoorbehoud} onStartLoad={() => setShowPopup(false)} onLoadData={(data) => submitSendOffer(data)} onDone={() => setLoadPdf(false)} dealDetail={dealDetail} /></Suspense>}

<div className="text-xs lg:text-base mt-5">
  {(dealDetail.saleType==="FinancialLease" && showCalculator) &&
  <Suspense fallback={<div>Loading ...</div>}>
  <Modal category="calc" title={bankDetail.bankName ? bankDetail.bankName : "Financiering aanpassen"} show={showModal} onClose={hideCalcHandler}>

  <CalculatorV3 size="xs"
    recalculate={true}
    fixedAmountPurchase={dealDetail.amountpurchase}
    fixedAmountPurchaseExclVAT={dealDetail.amountpurchaseExclVAT}
    age={dealDetail?.vehicleDetail?.age}
    companyAge={dealDetail?.customerDetail?.age}
    durationMax={dealDetail?.vehicleDetail?.age ? calcMaxDuration(dealDetail?.vehicleDetail?.age, 6) : null}
    inModal={true}
    onUpdate={() => setDealChangeCounter(prevState => prevState + 1)}
    onDownloadPdf={downloadPDFHandler}
    onSendPdf={() => setShowPopup(true)}
    offerCustomer={dealDetail.offerCustomer}
    onClose={hideCalcHandler} 
    currentCalculation={{
      "Aanschaf Incl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchase),
      "BTW": "\u20AC "+formatThousandNotation(dealDetail.vat),
      "Aanschaf Excl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchaseExclVAT),
      "Aanbetaling": "\u20AC "+(dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : 0),
      "Inruil": "\u20AC "+(dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : 0),
      "Inlossing": "\u20AC "+(dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : 0),
      "Te financieren": "\u20AC "+formatThousandNotation(dealDetail.leaseAmount),
      "Slottermijn": "\u20AC "+(dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : 0),
      "Looptijd": dealDetail.duration+" maanden",
      "Maandbedrag": "\u20AC "+dealDetail.monthlyamount,
      "typeOfDeal": dealDetail.typeOfDeal,
      "Rente": dealDetail.interest+"%",
      "Provisiepercentage": dealDetail.provisiePercentage+"%"
    }}
    ondervoorbehoud={ondervoorbehoud}
    calculatorGoal={calculatorGoal}
    relatedID={dealDetail.relatedID}
    dealID={dealDetail.dealID}
    typeOfDeal={dealDetail.typeOfDeal}
    disableTitle={true}
    firstRegistrationDate={dealDetail.vehicleDetail.firstRegistrationDate}
    vehicleType={dealDetail.vehicleDetail.vehicleType}
    restBPM={dealDetail.vehicleDetail.restBPM}
    disableMaxSlottermijn={true}
    slottermijnTouched={true}
    showDifference={true}
    fixedBank={true}
    administrationCost={dealDetail.administrationCost}      
    {...calcDataToPass}
    contractInLossing={dealDetail.contractInLossing}
    kentekenInLossing={dealDetail.kentekenInLossing}
    bankInLossing={dealDetail.bankInLossing}
    />
  </Modal>
  </Suspense>
  }



</div>

{(/BtwFinanciering|FinancialLease/i.test(dealDetail.saleType)) &&
<div className="text-xs lg:text-base mt-5">
<Card title={`Documenten`}>
  <div className="bg-white grid grid-cols-1 items-start rounded-b-xl">
    <div className={`flex flex-col gap-5 p-5 bg-white h-full rounded-b-xl`}>
        {dealDetail.saleType!=="Verzekering" &&
        <DocumentForm updated={dealChangeCounter} onUpdate={updateHandler} dealID={dealDetail.dealID} relatedID={dealDetail.relatedID} saleType={dealDetail.saleType} dealerName={dealDetail.dealerDetail.name} isArchive={dealDetail?.isArchive} />
    }
    </div>
  </div>
</Card>
</div>
}

{showPopup==="sendOffer" && <Popup2 actionLabel="Ja, versturen" cancelLabel="Nee, annuleren" visible={true} onSubmit={popupSubmitHandler} onCancel={() => setShowPopup(false)} title="Offerte versturen aan de klant?" message={["Weet u zeker dat u dit voorstel wilt doen aan uw klant?. Wij sturen uw klant een mail met de definitieve offerte en het verzoek de benodigde persoonsgegevens aan te leveren."]} />}
{showPopup==="otherVehicle" && <Popup2 actionLabel="Ja, doorgaan" cancelLabel="Nee, annuleren" visible={true} onSubmit={otherVehicleHandler} onCancel={() => setShowPopup(false)} title="Doorgaan?" message={["Weet u zeker dat u wilt doorgaan? De huidige verkoop wordt gearchiveerd."]} />}

</>
  );
};

export default DealFormDealer;