import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/Button/Button";

import DateSelects from "components/ui/DateSelects/DateSelects";
import useExternalVehicle from "hooks/externalvehicle/use-external-vehicle";

import Input3 from "components/ui/Input/Input3";
import Select3 from "components/ui/Select/Select3";

import { calcAgeInMonths, calcDefaultDuration, calcMaxDuration } from "lib/calc";

import { dateObjToDateRDWFormat } from "lib/helpers";
import useDealV2 from "hooks/deal/use-deal_v2";
import { dealActions } from "store/deal-slice";
import { calcRestBpm } from "components/Calculator/helpers";

const CarForm = () => {
    const { clearDealFromStore } = useDealV2();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    var { vehicleDetail } = useSelector(state => state.deal);
    const { currentCase, subCase } = useSelector(state => state.progress);

    var storedVehicleDetail;

    if(Storage) {
        try {
            storedVehicleDetail = JSON.parse(sessionStorage.getItem("vehicleDetail"));
        } catch {};
    }

    const { setFocus, watch, register, formState: { errors }, setValue, getValues, handleSubmit, setError, clearErrors } = useForm({
        mode: "onChange",
        defaultValues: {
            licensePlate: vehicleDetail?.licensePlate || storedVehicleDetail?.licensePlate || "",
            make: vehicleDetail?.make || storedVehicleDetail?.make || "",
            model: vehicleDetail?.model || storedVehicleDetail?.model || "",
            vehicleType: vehicleDetail?.vehicleType || storedVehicleDetail?.vehicleType || "",
            mileage: vehicleDetail?.mileage || storedVehicleDetail?.mileage || "",
            firstRegistrationDate: {
                day: vehicleDetail?.firstRegistrationDate?.day || storedVehicleDetail?.firstRegistrationDate?.day || "",
                month: vehicleDetail?.firstRegistrationDate?.month || storedVehicleDetail?.firstRegistrationDate?.month || "",
                year: vehicleDetail?.firstRegistrationDate?.year || storedVehicleDetail?.firstRegistrationDate?.year || "",
            }
        }
    });

    const { make, model, vehicleType, mileage, licensePlate } = watch();

    const [manualModel, setManualModel] = useState(0);
    const { makes, models, getUniqueMakeModel } = useExternalVehicle();

    useEffect(() => {
        getUniqueMakeModel(make);
    }, [getUniqueMakeModel, make]);

    useEffect(() => {
        if(licensePlate && !mileage) setFocus("mileage");
    }, []);

    const submitHandler = (vehicleData) => {
        var { dateInRdwFormat, firstRegistrationDateStr } = dateObjToDateRDWFormat(vehicleData.firstRegistrationDate);
        var age = calcAgeInMonths(dateInRdwFormat);
        var durationMax = calcMaxDuration(age, 6);
        var duration = calcDefaultDuration(durationMax);
        var vehicleData = {
            ...vehicleData,
            age: age,
            firstRegistrationDateStr: firstRegistrationDateStr
        };
        dispatch(dealActions.setCalcDetail({duration, duration, durationMax: durationMax}));
        dispatch(dealActions.setVehicleField({dataName: "mileage", dataValue: mileage}));

        (currentCase==="FinancialLease") ? navigate("/financiering/financial-lease/btw-marge") : navigate("/verzekering/btw-marge");
    };
    const submitErrorHandler = () => {};

    const calcFn = () => {
        // change firstRegistrationDate? -> recalculate age, maxDuration, restBPM
        var firstRegistrationDate = getValues("firstRegistrationDate");
        var { dateInRdwFormat, firstRegistrationDateStr } = dateObjToDateRDWFormat(firstRegistrationDate);
        var age = calcAgeInMonths(dateInRdwFormat);   
        var grossBPM = vehicleDetail?.grossBPM || 0;         
        
        var restBPM = calcRestBpm(vehicleType, age, grossBPM);
        var durationMax = calcMaxDuration(age, 6);
        var duration = calcDefaultDuration(durationMax);

        (durationMax<12) ? setError("firstRegistrationDate", { type: "custom", message: "Het voertuig is te oud"}) : clearErrors("firstRegistrationDate");
        
        return { age, firstRegistrationDateStr, restBPM, duration, durationMax };
    }

    const changeHandler = (fieldName, fieldValue, objName=false) => {
        var vehicle = getValues();

        var vehicleDataToStore = {
            ...vehicleDetail,
            ...vehicle,
        }

        if(Storage) sessionStorage.setItem("vehicleDetail", JSON.stringify(vehicleDataToStore));

        if(objName) {
            var { age, firstRegistrationDateStr, restBPM, duration, durationMax } = calcFn();

            // dispatch(dealActions.setCalcDetail({age: age, durationMax: durationMax}));
            var vehicleData = {
                age: age,
                firstRegistrationDateStr: firstRegistrationDateStr,
                restBPM: restBPM,
                duration: duration,
                durationMax: durationMax
            };
            dispatch(dealActions.setVehicleDetailByRDW(vehicleData));
            dispatch(dealActions.setDateForVehicle({objName, fieldName, fieldValue}));
            return;
        }


        dispatch(dealActions.setVehicleField({dataName: fieldName, dataValue: fieldValue}));
        if(fieldName==="vehicleType") {
            if(fieldValue==="Bedrijfsauto") dispatch(dealActions.setRestBPM(0));
            if(fieldValue==="Personenauto") {
                var { restBPM } = calcFn();
                dispatch(dealActions.setRestBPM(restBPM));
            }
        }
    }

    return (
        <>
        <h1 className="select-none">Voertuiginformatie</h1>
        <form onSubmit={handleSubmit(submitHandler, submitErrorHandler)} className="container mx-auto px-5 xl:px-0 select-none">
                    <div className="grid md:grid-cols-2 gap-3">
                        {!licensePlate && subCase!=="inventory" ?
                            <>
                                <div className="">
                                {makes && (
                                <Select3 onChange={changeHandler} name="make" fieldToValidate="Merk is een verplicht veld" required={true} register={register} errorMessage={errors?.make?.message} inputValue={make} placeholder="Merk" label="Merk">
                                {makes.map(item => {
                                    return <option key={item.label} value={item.id} selected={item.label===make}>{item.label}</option>
                                })}
                                </Select3>
                                )}
                                </div>
                                <div className="">
                                    <div className="flex flex-row items-center gap-x-3">
                                    {(models && !manualModel) ?
                                    <>
                                    <Select3 onChange={changeHandler} name="model" fieldToValidate="Model is een verplicht veld" required={true} register={register} errorMessage={errors?.model?.message} inputValue={model} placeholder={manualModel===0 ? "Model": "Model (uit modellenlijst)"} label="Model">
                                    {models.map(item => {
                                        return <option key={item.label} value={item.id} selected={item.label===model}>{item.label}</option>
                                    })}
                                    </Select3>
                                    </>
                                    :
                                    <>
                                    <Input3 onChange={changeHandler} name="model" fieldToValidate="Model is een verplicht veld" required={true} register={register} errorMessage={errors?.model?.message} inputValue={model} placeholder="Model (handmatig)" label="Model" />
                                    </>
                                    }
                                    <div className="select-none flex flex-row gap-x-2 p-2 items-center cursor-pointer hover:text-slate-600" onClick={() => setManualModel(prevState => !prevState)}>
                                        <span className={`fa ${manualModel ? "fa-list" : "fa-pen-to-square"}`}></span>
                                        <span>{manualModel ? "Kies uit modellenlijst" : "Handmatig invoeren"}</span>
                                    </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <Input3 onChange={changeHandler} name="make" fieldToValidate="Merk is een verplicht veld" register={register} required={true} errorMessage={errors?.make?.message} inputValue={make} placeholder="Merk" />
                                <Input3 onChange={changeHandler} name="model" fieldToValidate="Model is een verplicht veld" register={register} required={true} errorMessage={errors?.model?.message} inputValue={model} placeholder="Model" />
                            </>
                        }
                        <Select3 onChange={changeHandler} name="vehicleType" fieldToValidate="Voertuigsoort is een verplicht veld" register={register} required={true} errorMessage={errors?.vehicleType?.message} inputValue={vehicleType} placeholder="Voertuigsoort" label="Kies voertuigsoort">
                            <option value="Personenauto">Personenauto</option>
                            <option value="Bedrijfsauto">Bedrijfsauto</option>
                        </Select3>
                        <Input3 onBlur={changeHandler} name="mileage" fieldToValidate="mileage" required={true} register={register} errorMessage={errors?.mileage?.message} inputValue={mileage} placeholder="Kmstand" inputMode="numeric" />
                        <DateSelects errorMessage={errors?.firstRegistrationDate?.message} minYear={(new Date().getFullYear()-11)} onChange={changeHandler} name="firstRegistrationDate" getValues={getValues} register={register} errors={errors} label="Datum 1e toelating" placeholder="Datum 1e toelating" />
                    </div>
                    <div className="container mx-auto flex flex-row justify-between my-10">
                        <Button type="button" className="flex flex-row" onClick={clearDealFromStore}>
                            <span className="fa fa-chevron-left mr-2"></span>
                            <span>Aanvraag annuleren</span>
                        </Button>                
                        <Button type="submit" className="btn-primary flex flex-row">
                            <span>Volgende</span>
                            <div className="fa fa-chevron-right ml-2"></div>
                        </Button>              
                    </div>

        </form>
        </>
    )
}

export default CarForm;