import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Spinner from "components/ui/Spinner/Spinner";
import Alert from "components/ui/Alert/Alert";
import Header from "components/Header/Header";
import Popup from "components/ui/Popup/Popup";
import useAuth from "hooks/use-auth";
import useDealer from "hooks/use-dealer";
import Footer from "components/Footer/Footer";

// import TermsAndConditions from "components/ui/TermsAndConditions/TermsAndConditions";
// import ChangeLog from "components/ui/ChangeLog/ChangeLog";

function App() {
  const { activeFunnel } = useSelector(state => state.progress);
  const { userIsLoggedIn, role, token, userDetails } = useAuth();
  const { dealerID } = useDealer();

  useEffect(() => {
    document.body.classList.remove("crm");
  },[]);

  return (
    <div className="bg-gray-100 flex flex-col items-stretch w-full"> 
      {/* <div className="xbg-beeldmerk bg-no-repeat bg-center fixed w-full h-full opacity-5"></div> */}
      <Alert />
      <Spinner />
      <Header />
      {/* {userIsLoggedIn && userDetails?.showTermsAndConditions && <TermsAndConditions />}
      {userIsLoggedIn && userDetails?.showTermsAndConditions===false && userDetails?.showChangeLog && <ChangeLog />} */}
      <Popup />
      <Outlet />
      {(role && role.includes("Admin") && !dealerID) && <div className="w-full h-full bg-black opacity-80 z-40 fixed left-0 top-0"></div>}

      <Footer activeFunnel={activeFunnel} /> 
    </div>
  );
}

export default App;
