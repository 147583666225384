import CryptoJS from 'crypto-js';

import { escapeSpecialChars, getSelectOptions } from "./voertuig";
import { localDate } from "./calc";
import { dateToDutch } from "./kvk";

export const consoleLog = (message, forceDebug) => {
    if(!message) return;
    var debug = false;
    if(forceDebug) debug = true;
}

export const rdwDateStrToDateObj = (rdwDate) => {
  var date = new Date(rdwDate);
  var day = date.getDate();
  if(day<=9) day="0"+day;
  var month = date.getMonth() + 1;
  if(month<=9) month="0"+month;
  var year = ""+date.getFullYear();
  return {
    day: day,
    month: month,
    year: year
  }
}

export const localDateStrToDateObj = (localDate) => {
  if(!localDate) return {day: "", month: "", year: ""};
  var dateObj = {
    day: localDate.slice(0,2),
    month: localDate.slice(3,5),
    year: localDate.slice(6,10),
  }
  return dateObj;
}


export const transformToValidVehicleType = (vehicleType) => {
  if(/^(Personenauto|Bedrijfsauto)$/.test(vehicleType)) return vehicleType;
  if(/bedrijf/i.test(vehicleType)) {
    return "Bedrijfsauto";
  } else {
    return "Personenauto";
  }
}

export const dateObjToDateISO = (dateObj) => {
  var day = dateObj?.day;
  var month = parseInt(dateObj?.month) -1;
  var year = dateObj?.year;
  var dateISO = new Date(year,month,day).toISOString();
  return dateISO;
}

export const dateObjToDateRDWFormat = (firstRegDate) => {
  var firstRegistrationDateStr = Object.values(firstRegDate).join("-");
  var dateInRdwFormat = firstRegDate.year+"-"+firstRegDate.month+"-"+firstRegDate.day+"T00:00:00.000"; // 2021-10-15T00:00:00.000
  return {
      firstRegistrationDateStr: firstRegistrationDateStr,
      dateInRdwFormat: dateInRdwFormat
  };
}

export const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]!=="") {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export const setError = (err) => {

    var errorResponse, title, objKeys;
    console.log(JSON.stringify(err));
    if(err.message) errorResponse = JSON.parse(err.message);
    if(errorResponse) var { title } = errorResponse;
    
    const errors = [];
    if(errorResponse) {
      objKeys = Object.keys(errorResponse.errors);
      Object.values(errorResponse.errors).forEach(function(e,i) {
        errors.push(objKeys[i]+":"+e[0]);
      });
    }
    title = "";
    return { title, errors };
}

export const getDateTimeFromDate = (inputDate) => {
  if(!inputDate) return;
  var date = new Date(inputDate);
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  // var hours = date.getHours();
  // var minutes = date.getMinutes(); 
  if(hours<=9) hours="0"+hours;
  if(minutes<=9) minutes="0"+minutes;
  var createdTime = hours+":"+minutes; 
  return localDate(inputDate)+" "+createdTime;
}

export const setStorageItem = (itemName, itemData, storageType='session') => {
    if(!Storage || !itemName || !itemData) return;
    storageType==="local" ? localStorage.setItem(itemName, JSON.stringify(itemData)) : sessionStorage.setItem(itemName, JSON.stringify(itemData));
}

export const removeStorageItem = async (items, storageType='session') => {
    if(!Storage || !items.length) return;
    await items.forEach(function(itemName, i) {
        storageType==="local" ? localStorage.removeItem(itemName) : sessionStorage.removeItem(itemName);
    });
}

export const getStorageItem = (itemName, storageType='session') => {
    if(!Storage || !itemName) return;
    var returnValue = storageType==="local" ? JSON.parse(localStorage.getItem(itemName)) : JSON.parse(sessionStorage.getItem(itemName));
    return returnValue;
}

export const noDotAndInteger = (inputValue) => {
  if(typeof inputValue==="number") return inputValue;
  if(inputValue === null || inputValue===undefined) return;
  inputValue = inputValue.replace(".", "");
  inputValue = +inputValue;
  return inputValue;
}

export const getBase64ImageFromURL = (url, retryCounter=1) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      console.log("image error..", error);
      if(retryCounter>2) {
        reject(error);
        return;
      };
      retryCounter = retryCounter + 1;
      getBase64ImageFromURL(url, retryCounter);
    };

    img.src = url;
  });
}
export const mapToIdAndLabel = (inputArr) => {
  return inputArr.map(item => {
    return (
        {
            id: escapeSpecialChars(item),
            label: escapeSpecialChars(item)
        }
    )
  });
}

export const kvkDateToYYYYMMDD = kvkDate => {
  if(!kvkDate) return;
  if(typeof kvkDate==="integer") kvkDate = kvkDate.toString();
  var year = kvkDate.slice(0,4);
  var month = kvkDate.slice(4,6);
  var day = kvkDate.slice(6,8);
  var result = year+"-"+month+"-"+day;;
  return result;
}

export const kvkDateToISODate = kvkDate => {
  if(!kvkDate) return;
  if(typeof kvkDate==="integer") kvkDate = kvkDate.toString();
  var year = kvkDate.slice(0,4);
  var month = kvkDate.slice(4,6);
  var day = kvkDate.slice(6,8);
  var date = new Date(month+"/"+day+"/"+year).toISOString();
  return date;
}

export const monthsToYearsAndMonths = (totalMonths) => {
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  return { years, months };
}

// Generate a random IV (Initialization Vector)
export const generateRandomIV = () => {
  // You can customize the length of the IV as needed (e.g., 16 bytes for AES-128, 24 bytes for AES-192, or 32 bytes for AES-256)
  const ivLength = 16; // 16 bytes for AES-128
  var iv = CryptoJS.lib.WordArray.random(ivLength);
  // Convert the IV to a hexadecimal string representation
  const ivHex = iv.toString(CryptoJS.enc.Hex).slice(0,16);
  return ivHex;
}

export const aesEncrypt = (data) => {
      const iv = generateRandomIV();
      let cipher = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return {
      bodyEncrypted: cipher.toString(),
      iv: iv
    }
}

export const aesDecrypt = (encryptedData, iv) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (err) {
    console.log("Er is iets fout gegaan bij het decrypten van de data");
  }
  // return cipher.toString(CryptoJS.enc.Utf8);
}

export const fetchWithTimeout = (url, fetchOptions, timeout) => {
  return Promise.race([
    fetch(url, fetchOptions),
    new Promise((_, reject) =>
      setTimeout(() => {
        // console.log("request time out!");
        reject(new Error('Request timed out'));
      }, timeout)
    ),
  ]);
}

export const getInitials = (userDetails) => {
  const { firstName, lastName } = userDetails;
  var initials =  firstName ? firstName.slice(0, 1) : "";
  if(lastName) initials+=lastName.slice(0,1);
  initials = initials.toUpperCase();
  return initials;
}

export const getUserName = (userDetails) => {
  var respName;
  if(userDetails?.firstName) {
    respName = userDetails.firstName;
    if(userDetails?.lastName) respName+=" "+userDetails.lastName;
  } else if(userDetails?.lastName) {
    respName = userDetails.lastName;
  } else if(userDetails?.userName) {
    respName = userDetails.userName;
  } else {
    respName = userDetails?.userId || "Profiel niet ingevuld";
  }
  return respName;
}
export const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - startDate) / (24 * 3600 * 1000)) + 1;
    const weekNumber = Math.ceil((days + startDate.getDay()) / 7);
    return weekNumber;
  }

  export const monthLabels = [
    'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 
    'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
  ];

  export const generateDatesBetweenArr = (start, end) => {
    const dateArray = [];
    const currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // need to push currentDay too!
    dateArray.push(new Date(currentDate));

    var orderedDates = dateArray.sort((a,b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateA - dateB;
    }).map(date => dateToDutch(date));

    return orderedDates;
};

export const getExludedEmailDomains = (dealerDomain) => {
  var domainsToExclude = dealerDomain?.replace(/.*@(.*)/, "$1")
                                      .replace(/(gmail|googlemail|hetnet|hotmail|icloude|kpnmail|live|mail|outlook|planet|scherpeleasedeal|sld|xs4all|yahoo)\.(com|nl)$/i, "");
  return domainsToExclude;
}

export const organizeByDay = (data, dealerId=false, dateField="createdDate") => {
  // Create an object to store the new arrays organized by date
  const dateArrays = {};

  // Loop through the data and organize it by date
  data.forEach(item => {
    const { administrationCost,  commissionAmountSLD, commissionAmountDealer, chosenBank } = item;

    var dateFieldToUse = item[dateField];

    const date = new Date(dateFieldToUse);
    const dateString = localDate(date);

    if (!dateArrays[dateString]) {
      dateArrays[dateString] = [];
    }

    dateArrays[dateString].push({ commissionAmountSLD, chosenBank, commissionAmountDealer, administrationCost });
  });

  // Calculate totalAmount and count for each date
  Object.keys(dateArrays).forEach(dateString => {
    const dateData = dateArrays[dateString];
    let totalAmount = 0;
    let count = 0;

    dateData.forEach(item => {
      if(!dealerId) totalAmount += item.commissionAmountSLD+(item.administrationCost/2);
      if(dealerId) totalAmount += item.commissionAmountDealer+(item.administrationCost/2);
      count++;
    });

    dateArrays[dateString] = {
      totalAmount,
      count,
      label: dateString,
      dateLabel: dateString // You can format this date label as needed
    };
  });
  return dateArrays;
}

export const organizeByYearAndWeek = (data, dealerId=false, dateField="createdDate") => {
    const yearWeekArrays = {};

      // Loop through the data and organize it by week number and year
  data.forEach(item => {
    const { administrationCost, commissionAmountSLD, commissionAmountDealer, chosenBank } = item;

    var dateFieldToUse = item[dateField];

    const date = new Date(dateFieldToUse);
    const year = date.getFullYear();
    // Calculate the week number with Monday as the start of the week
    const mondayStartWeek = getWeekNumber(date);
    // Create a key with the format "year-weekNumber"
    const key = `${year}-${mondayStartWeek}`;
    if (!yearWeekArrays[key]) {
      yearWeekArrays[key] = [];
    }
    yearWeekArrays[key].push({ commissionAmountSLD, chosenBank, commissionAmountDealer, administrationCost });
  });

  // Calculate totalAmount and count for each week number and year combination
  Object.keys(yearWeekArrays).forEach(key => {
    const [year, weekNumber] = key.split('-');
    const weekData = yearWeekArrays[key];
    let totalAmount = 0;
    let count = 0;

    weekData.forEach(item => {
      if(!dealerId) totalAmount += item.commissionAmountSLD+(item.administrationCost/2);
      if(dealerId) totalAmount += item.commissionAmountDealer+(item.administrationCost/2);
      count++;
    });

    yearWeekArrays[key] = {
      totalAmount,
      count,
      label: `Week ${weekNumber}`,
      weekLabel: `Week ${weekNumber}`,
      yearLabel: year
    };
  });

  return yearWeekArrays;
}

export const organizeByYear = (data, dealerId=false, dateField="createdDate") => {
    const yearArrays = {};
    // console.log("data", data);

    // Loop through the data and organize it by year
    data.forEach(item => {
      const { administrationCost, commissionAmountSLD, commissionAmountDealer, chosenBank } = item;
      var dateFieldToUse = item[dateField];
      const date = new Date(dateFieldToUse);
      const year = date.getFullYear();
  
      if (!yearArrays[year]) {
        yearArrays[year] = [];
      }
  
      yearArrays[year].push({ commissionAmountSLD, chosenBank, commissionAmountDealer, administrationCost });
    });
  
    // Calculate totalAmount and count for each year
    Object.keys(yearArrays).forEach(year => {
      const yearData = yearArrays[year];
      let totalAmount = 0;
      let count = 0;
  
      yearData.forEach(item => {
        if(!dealerId) totalAmount += item.commissionAmountSLD+(item.administrationCost/2);
        if(dealerId) totalAmount += item.commissionAmountDealer+(item.administrationCost/2);
        count++;
      });
  
      yearArrays[year] = {
        totalAmount,
        count,
        label: year,
        yearLabel: year
      };
    });

    return yearArrays;
}
export const organizeByYearAndMonth = (data, dealerId=false, dateField="createdDate") => {
        // Create an object to store the new arrays organized by year and month
        const yearMonthArrays = {};
      
        // Loop through the data and organize it by year and month
        data.forEach(item => {
          const { administrationCost, commissionAmountSLD, commissionAmountDealer, chosenBank } = item;

          var dateFieldToUse = item[dateField];

          const date = new Date(dateFieldToUse);
          const year = date.getFullYear();
          const month = date.getMonth();
          const key = `${month}-${year}`;
          if (!yearMonthArrays[key]) {
            yearMonthArrays[key] = [];
          }
            yearMonthArrays[key].push({ commissionAmountSLD, chosenBank, commissionAmountDealer, administrationCost});
        });
      
        // Calculate totalAmount and count for each year and month combination
        Object.keys(yearMonthArrays).forEach(key => {
            const [month, year] = key.split('-');
            const monthData = yearMonthArrays[key];
            let totalAmount = 0;
            let count = 0;
      
            monthData.forEach(item => {
              if(!dealerId) totalAmount += item.commissionAmountSLD+(item.administrationCost/2);
              if(dealerId) totalAmount += item.commissionAmountDealer+(item.administrationCost/2);
              count++;
            });
      
            yearMonthArrays[key] = { totalAmount, count,
                label: monthLabels[Number(month)]+" "+year.slice(2,4),
                month: monthLabels[Number(month)],
                year: year };
          });
        return yearMonthArrays;
}
export const checkIfDateIsHistory = (dateString) => {
  const dateToCheck = new Date(dateString);
  const currentDate = new Date();
  
  // Extract the date parts (year, month, and day)
  const dateToCheckYear = dateToCheck.getFullYear();
  const dateToCheckMonth = dateToCheck.getMonth();
  const dateToCheckDay = dateToCheck.getDate();
  
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

    if (dateToCheckYear < currentYear) {
      return true; // Date is older
    } else if (dateToCheckYear > currentYear) {
      return false; // Date is newer
    } else if (dateToCheckMonth < currentMonth) {
      return true; // Date is older
    } else if (dateToCheckMonth > currentMonth) {
      return false; // Date is newer
    } else if (dateToCheckDay < currentDay) {
      return true; // Date is older
    } else if (dateToCheckDay > currentDay) {
      return false; // Date is newer
    } else {
      return 'eq'; // Date is the same
    }
  }

export const isDateInCurrentWeek = (dateString) => {
    const dateToCheck = new Date(dateString);
    const currentDate = new Date();
  
    // Calculate the start of the current week (Sunday)
    const currentWeekStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - (currentDate.getDay() - 1)
    );
  
    // Calculate the end of the current week (Saturday)
    const currentWeekEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (7 - currentDate.getDay())
    );
  
    return (
      dateToCheck >= currentWeekStart && dateToCheck <= currentWeekEnd
    );
  } 

  function transformDateFormat(inputDateString) {
    var inputDate = new Date(inputDateString);
    
    var day = inputDate.getUTCDate();
    var month = inputDate.getUTCMonth() + 1; // Months are zero-based
    var year = inputDate.getUTCFullYear();
    
    var hours = inputDate.getUTCHours();
    var minutes = inputDate.getUTCMinutes();
    var seconds = inputDate.getUTCSeconds();
    // var milliseconds = inputDate.getUTCMilliseconds();
    
    // Pad single-digit values with zero
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
  
    // Format the result
    var transformedDateString = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds + 'Z';
      return transformedDateString;
  }

  export const calculateElapsedTime = (activityTimeString, currentTimeString) => {
    const start = new Date(activityTimeString).getTime();
    if(!currentTimeString) currentTimeString = new Date().getTime();
    const end = currentTimeString;

    const timeDifference = end - start;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);
  
    if (yearsDifference > 0) {
      const roundedYears = Math.abs(yearsDifference);
      return `${roundedYears} jaar geleden`;
    } else if (monthsDifference > 0) {
      const roundedMonths = Math.abs(monthsDifference);
      return monthsDifference === 1 ? `${roundedMonths} maand geleden` : `${roundedMonths} maanden geleden`;
    } else if (daysDifference > 0) {
      const roundedDays = Math.abs(daysDifference);
      return daysDifference === 1 ? `${roundedDays} dag geleden` : `${roundedDays} dagen geleden`;
    } else if (hoursDifference > 0) {
      const roundedHours = Math.abs(hoursDifference);
      return `${roundedHours} uur geleden`;
    } else if (minutesDifference > 0) {
      const roundedMinutes = Math.abs(minutesDifference);
      return minutesDifference === 1 ? `${roundedMinutes} minuut geleden` : `${roundedMinutes} minuten geleden`;
    } else {
      return 'Zojuist';
    }
 
  }

  export const findObjectDifference = (obj1, obj2) => {
    const result = {};
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (obj2.hasOwnProperty(key)) {
          if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            const nestedDiff = findObjectDifference(obj1[key], obj2[key]);
            if (Object.keys(nestedDiff).length > 0) {
              result[key] = nestedDiff;
            }
          } else if (obj1[key] !== obj2[key]) {
            result[key] = obj2[key];
          }
        } else {
          result[key] = null;
        }
      }
    }
  
    for (const key in obj2) {
      if (!obj1.hasOwnProperty(key)) {
        result[key] = obj2[key];
      }
    }
  
    return result;
  };

  export const getWebsiteUrl = (dealDetail) => {
    var websiteUrl;
    if(dealDetail?.vehicleDetail?.occasionNumber?.length>1) {
      websiteUrl = "https://www.sld.nl/lease-voorraad/veh"+dealDetail.vehicleDetail.occasionNumber;
    } else if(/^https.*\d+$/.test(dealDetail.description)) {
      websiteUrl = dealDetail.description;
    } else if(dealDetail?.vehicleDetail?.licensePlate) {
      websiteUrl = "https://sld.nl/lease-voorraad/?licensePlate="+dealDetail.vehicleDetail?.licensePlate;
    }
    return websiteUrl;
  }

  export const isUnderCondition = (phase) => {
    return /(Uw aanvraag is ontvangen|Uw aanvraag wordt beoordeeld|Klant dient extra informatie aan te leveren)/.test(phase);
  }

  export const replaceKeys = (input) => {
    // insurance
    if(input==="typeOfInsurance") return "Type verzekering";
    if(input==="damageFreeYearsInsurance") return "Schadevrije jaren";
    if(input==="damageHistoryInsurance") return "Schade afgelopen 5 jaar";
    if(input==="kmPerYear") return "Kilometers per jaar";

    // sales
    if(input==="description") return "Beschrijving";
    if(input==="commissionAmountDealer") return "Provisie Dealer";
    if(input==="commissionAmountSLD") return "Provisie SLD";
    if(input==="source") return "Source";
    if(input==="phase") return "Fase";
    if(input==="startDateContract") return "Startdatum contract";
    if(input==="offerSignedDate") return "Tekendatum offerte";

    // customer
    if(input==="address") return "Adres";
    if(input==="place") return "Plaats";
    if(input==="postalCode") return "Postcode";
    if(input==="dateofEsteb") return "Datum oprichting";
    if(input==="dateofBirth") return "Geboortedatum";
    if(input==="salutation") return "Aanhef";
    if(input==="firstName") return "Voornaam";
    if(input==="salutation") return "Achternaam";
    if(input==="phone") return "Telefoon";
    if(input==="email") return "E-mailadres";
    if(input==="driverLicenseNumber") return "Rijbewijsnummer";
    if(input==="iban") return "IBAN";
    if(input==="chamberNumber") return "KvK nummer";
    if(input==="companyName") return "Bedrijfsnaam";
    
    // vehicle
    if(input==="make") return "Merk";
    if(input==="model") return "Model";
    if(input==="type") return "Type";
    if(input==="engineCapacity") return "Cilinderinhoud";
    if(input==="vehicleType") return "Voertuigsoort";
    if(input==="carrosserie") return "Carrosserie";
    if(input==="licensePlate") return "Kenteken";
    if(input==="fuelType") return "Brandstof";
    if(input==="mileage") return "Kilometerstand";
    if(input==="firstRegistrationDate") return "Eerste registratie";
    if(input==="listPrice") return "Catalogusprijs";

    if(input==="administrationCost") return "Handelingsfee";
    if(input==="typeOfDeal") return "BTW/Marge";
    if(/tradeinamount/i.test(input)) return "Inruil";
    if(/repayment/i.test(input)) return "Inlossing";
    if(/finalpayment/i.test(input)) return "Slottermijn";
    if(/interest/i.test(input)) return "Rente";
    if(/downpayment/i.test(input)) return "Aanbetaling";
    if(/duration$/i.test(input)) return "Looptijd";
    if(/monthlyamount/i.test(input)) return "Maandbedrag";
    if(/amountpurchase$/i.test(input)) return "Aanschaf";
    if(/amountpurchaseexclvat/i.test(input)) return "Aanschaf Excl.";
    if(/leaseamount/i.test(input)) return "Leasebedrag";
    if(/provisieper/i.test(input)) return "Provisiepercentage";
    if(/vat$/i.test(input)) return "BTW bedrag";
    if(/totaltopay/i.test(input)) return "Totaal aan te betalen";

    if(input==="documentName") return "Onderwerp";
    return input;
  } 

  export const replaceValues = (input) => {
    if(typeof input==="boolean") input = input.toString();
    if(/^\d{4}\-\d{2}\-\d{2}$/.test(input)) return localDate(input);
    if(/\.\d{3}Z$/.test(input)) return localDate(input);
    if(/^(v|\?|x|\!)$/.test(input)) return getSelectOptions("bankStatus").filter(item => item.value===input).map(item => item.label)[0];
    return input;
  } 

export const getFormattedLicensePlate = (lp) => {
  var licensePlate = lp?.replace(/-/g,""); // without dashes
  var formattedLicensePlate;
  switch(true) {
    case /^\d{2}[a-z]{4}/i.test(licensePlate) || /^[a-z]{2}\d{2}[a-z]{2}/i.test(licensePlate): formattedLicensePlate = licensePlate.replace(/^(\w{2})(\w{2})(\w{2})/i, "$1-$2-$3"); break; // 2 digits 4 letters OR 2 letters, 2 digits, 2 letters -> xx-xx-xx
    case /^\d{2}[a-z]{3}\d{1}/i.test(licensePlate) || /^[a-z]{2}\d{3}[a-z]{1}/i.test(licensePlate): formattedLicensePlate = licensePlate.replace(/^(\w{2})(\w{3})(\w{1})/i, "$1-$2-$3"); break; // 2 digits, 3 letters, 1 digit -> xx-xxx-x OR 2 letters, 3 digits, 1 letter -> xx-xxx-x
    case /^\d{1}[a-z]{3}\d{2}/i.test(licensePlate) || /^[a-z]{1}\d{3}[a-z]{2}/i.test(licensePlate): formattedLicensePlate = licensePlate.replace(/^(\w{1})(\w{3})(\w{2})/i, "$1-$2-$3"); break; // 1 digit, 3 letters, 2 digits -> x-xxx-xx OR 1 letter, 3 digits, 2 letters, x-xxx-xx
    case /^[a-z]{3}\d{2}[a-z]{1}/i.test(licensePlate): formattedLicensePlate = licensePlate.replace(/^(\w{3})(\w{2})(\w{1})/i, "$1-$2-$3"); break; // 3 letters, 2 digits, 1 letter
    case /^[a-z]{1}\d{2}[a-z]{3}/i.test(licensePlate): formattedLicensePlate = licensePlate.replace(/^(\w{1})(\w{2})(\w{3})/i, "$1-$2-$3"); break; // 1 letter, 2 digits, 3 letters          
    default: formattedLicensePlate = lp; break;
  }
  return formattedLicensePlate;
}  
export const renderTableRows = (obj, parentKey = '') => {
  let rows = [];
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        rows.push(
          <tr key={fullKey}>
            <td>{fullKey}</td>
            <td>{JSON.stringify(value)}</td>
          </tr>
        );
        rows = rows.concat(renderTableRows(value, fullKey));
      } else {
        rows.push(
          <tr key={fullKey}>
            <td>{fullKey}</td>
            <td>{typeof value === 'boolean' ? value.toString() : value}</td>
          </tr>
        );
      }
    }
  }
  
  return rows;
};

export const getErrorsFromResponse = (submitResponse) => {
  try {
    if (submitResponse?.actuaResponse) {
      const parsedResponse = JSON.parse(submitResponse?.actuaResponse);
      if (parsedResponse.errors) {
        try {
          return parsedResponse?.errors?.map(err => {
            return <div className="grid grid-cols-4"><div>{err?.field}</div><div className="col-span-3">{err?.error}</div></div>
          });
        } catch {
          return JSON.stringify(parsedResponse?.errors);
        }
      }
    }
  } catch (e) {
    console.error("Failed to parse response:", e);
  }
  return null; // Or an appropriate fallback value
};

export const safeParseInt = (value) => {
  if (typeof value === 'number' && !isNaN(value)) {
      return value;
  }
  
  if (typeof value === 'string') {
      // Remove all dots from the string
      value = value.replace(/\./g, '');
      
      if (value.trim() === '') {
          return 0;
      }
      
      const parsed = parseInt(value, 10);
      return isNaN(parsed) ? 0 : parsed;
  }
  
  return 0;
}