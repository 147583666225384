import Task from "./Task";
import AddTask from "./AddTask";
import { useEffect, useState, useRef } from "react";
import useTodo from "./use-todo";
import Button from "components/ui/Button/Button";
import classes from "./Todo.module.css";
import { checkIfDateIsHistory, getDateTimeFromDate, isDateInCurrentWeek } from "lib/helpers";

import useDealV2 from "hooks/deal/use-deal_v2";
import useDealerV2 from "hooks/dealer/use-dealer_v2";
import useCustomer from "hooks/customer/use-customer";
import SmartSelector from "components/ui/SmartSearch/SmartSelector";
import useUser from "hooks/use-user";
import useAuth from "hooks/use-auth";
import TaskDate from "./TaskDate";
import Select from "components/ui/Select/Select";
import TodoPrio from "./TodoPrio";
import { useSearchParams } from "react-router-dom";

const Todo = () => {
    const [searchParams] = useSearchParams();
    const { userDetails } = useAuth();

    const [userId, setUserId] = useState(userDetails?.id || null)
    // const id = null || userDetails?.id || null;
    const dealerRef = useRef();
    const dealRef = useRef();
    const customerRef = useRef();
    const userRef = useRef();

    const { deals, getDeals } = useDealV2();
    const { dealers, getDealers } = useDealerV2();
    const { customers, getCustomers } = useCustomer();
    const { adminList, getUser } = useUser();

    const [isFocussed, setIsFocussed] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const { todo, prepareUpdate, todos, createTodo, trashTodo, toggleComplete, getById, clearId, updateTodo, removeTodo, getTodos } = useTodo();
    
    useEffect(() => {
        getTodos(userId);
    }, [userId]);


    useEffect(() => {
        // for binding deal, dealer of customer to todo
        getDeals();
        getDealers();
        getCustomers();
        getUser('admin');
    }, []);

    // useEffect(() => {
    //     var rootDiv = document.getElementById("root");
    //     rootDiv.setAttribute("style", "background-image: url('/todo-bg.jpg'); background-repeat: no-repeat; background-size: cover;");
    // },[]);

    const addHandler = async (todo) => {
        var resp = await createTodo(todo);
        // if(resp) alert("YES", resp);
    }

    const completeHandler = (todo) => {
        toggleComplete(todo);
    }

    const removeHandler = (id) => {
        removeTodo(id);
    }

    const updateHandler = () => {
        setIsSaved(false);
        setIsFocussed(false);
        updateTodo(todo);
    }
    
    const getByIdHandler = (id) => {
        getById(id);
    }

    const clearIdHandler = () => {
        setIsSaved(false);
        setIsFocussed(false);
        clearId();
    }

    const keydownHandler = (evt) => {
        if(evt.keyCode===27) clearIdHandler();
    }

    const changePrioHandler = (id, prio) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            priority: prio
        },!!todo.todoId);
    }

    const changeHandler = (id, fieldName, fieldValue) => {
        // console.log("changeHandler");
        // console.log(id, fieldName, fieldValue);
        // updateTodo({
        //     ...todos.filter(item => item.todoId===id)[0],
        //     [fieldName]: fieldValue
        // });
    }

    const changeUserIdHandler = (id, fieldName, fieldValue) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            [fieldName]: fieldValue
        }, !!todo.todoId);
    }

    const linkDealHandler = (id, fieldName, fieldValue) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            [fieldName]: fieldValue
        });
    }

    const linkDealerHandler = (id, fieldName, fieldValue) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            [fieldName]: fieldValue
        });
    }

    const linkCustomerHandler = (id, fieldName, fieldValue) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            [fieldName]: fieldValue
        });
    }

    const deadlineChangeHandler = (id, date) => {
        updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            dueDate: date
        }, !!todo.todoId);
    }

    const toggleTrashHandler = async (id) => {
        await updateTodo({
            ...todos.filter(item => item.todoId===id)[0],
            isDeleted: true
        }); 
        await clearId();
    }

    const changeUserTodo = async (x=null,y=null,fieldValue) => {
        setUserId(fieldValue);
        await getTodos(fieldValue);
    }

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    },[]);

    return (
        <div>
            {/* <div>{updateCounter}</div> */}
            <div className="flex flex-row gap-x-3 justify-between">
                    <AddTask onAdd={addHandler} />
                    {adminList?.length &&
                        <SmartSelector value={userId} id={todo.todoId} onChange={changeUserTodo} name="userId" placeholder="Todo lijst van" label="Todo lijst van" options={adminList.map(admin => ({label: admin.email, value: admin.userrId}))} />
                    }
                    <Select value={searchParams.get("orderBy") ? searchParams.get("orderBy") : ""} name="orderBy" label="Sortering" placeholder="Sortering" onChange={evt => getTodos(null, {orderBy: evt.target.value, order: "ASC"})} options={[{label: "Titel", value: "title"},{label: "Deadline", value: "dueDate"},{label: "Prioriteit", value: "priority"},{label: "Datum van aanmaak", value: "createdDate"}]}>
                    </Select>
            </div>

            <div className="my-14">
                <div className="flex flex-col gap-y-1 my-5">
                    <div className="text-xl font-semibold">Over tijd</div>
                    {(todos && todos.filter(item => (item.isCompleted===false && item.isDeleted===false && checkIfDateIsHistory(item.dueDate)===true)).length>0)
                    ?
                    todos.filter(item => (item.isCompleted===false && item.isDeleted===false && checkIfDateIsHistory(item.dueDate)===true)).map(todo => {
                    return <Task
                    adminList={adminList}
                    key={todo.todoId}
                    onChangeUserId={changeUserIdHandler}
                    onGetById={getByIdHandler}
                    onDeadlineChange={deadlineChangeHandler}
                    onComplete={completeHandler}
                    onRemove={removeHandler}
                    onChangePrio={changePrioHandler}
                    todo={todo}
                    id={todo.todoId}
                    title={todo.title}
                    />;
                    })
                    :
                    <>Je punctualiteit is echt opmerkelijk. Dankzij jou kunnen we op schema blijven en zijn er geen taken die vertraging oplopen.</>}
                </div>   
            </div>

            <div className="my-14">
                <div className="flex flex-col gap-y-1 my-5">
                    <div className="text-xl font-semibold">Vandaag, {new Date().toLocaleDateString("nl-NL")}</div>
                </div>

                {todos && todos.filter(item => item.isCompleted===false && item.isDeleted===false && checkIfDateIsHistory(item.dueDate)==='eq').map(todo => {
                    return <Task
                    adminList={adminList}
                    key={todo.todoId}
                    onChangeUserId={changeUserIdHandler}
                    onGetById={getByIdHandler}
                    onDeadlineChange={deadlineChangeHandler}
                    onComplete={completeHandler}
                    onRemove={removeHandler}
                    onChangePrio={changePrioHandler}
                    todo={todo}
                    id={todo.todoId}
                    title={todo.title}
                    />;                })}
            </div>

            <div className="my-14">
                <div className="flex flex-col gap-y-1 my-5">
                    <div className="text-xl font-semibold">Deze week</div>
                </div>
                {todos && todos.filter(item => item.isCompleted===false && item.isDeleted===false && checkIfDateIsHistory(item.dueDate)===false && isDateInCurrentWeek(item.dueDate)===true).map(todo => {
                    return <Task
                    adminList={adminList}
                    key={todo.todoId}
                    onChangeUserId={changeUserIdHandler}
                    onGetById={getByIdHandler}
                    onDeadlineChange={deadlineChangeHandler}
                    onComplete={completeHandler}
                    onRemove={removeHandler}
                    onChangePrio={changePrioHandler}
                    todo={todo}
                    id={todo.todoId}
                    title={todo.title}
                    />;                })}
            </div>

            
            <div className="my-14">
                <div className="flex flex-col gap-y-1 my-5">
                    <div className="text-xl font-semibold">Aankomend</div>
                </div>
                {todos && todos.filter(item => item.isCompleted===false && item.isDeleted===false && checkIfDateIsHistory(item.dueDate)===false && isDateInCurrentWeek(item.dueDate)===false).map(todo => {
                    return <Task
                    adminList={adminList}
                    key={todo.todoId}
                    onChangeUserId={changeUserIdHandler}
                    onGetById={getByIdHandler}
                    onDeadlineChange={deadlineChangeHandler}
                    onComplete={completeHandler}
                    onRemove={removeHandler}
                    onChangePrio={changePrioHandler}
                    todo={todo}
                    id={todo.todoId}
                    title={todo.title}
                    />;                })}
            </div>

            <div className="my-14">
                <div className="text-xl font-bold">Afgerond</div>
                {todos && todos.filter(item => item.isCompleted===true && item.isDeleted===false).map(todo => {
                    return <Task
                    adminList={adminList}
                    key={todo.todoId}
                    onChangeUserId={changeUserIdHandler}
                    onGetById={getByIdHandler}
                    onDeadlineChange={deadlineChangeHandler}
                    onComplete={completeHandler}
                    onRemove={removeHandler}
                    onChangePrio={changePrioHandler}
                    todo={todo}
                    id={todo.todoId}
                    title={todo.title}
                    />})}
            </div>
            
            {todo?.todoId && (
            <div className="">
                <div className="fixed left-0 top-0 w-full h-full bg-black opacity-50 z-20"></div>
                <div className="fixed left-0 top-0 z-20 h-full w-full justify-center flex flex-col">
                    <div className="rounded-xl shadow-xl w-2/4 mx-auto h-4/5">
                        <div className="flex flex-row tems-center bg-[#1A2A52] rounded-t-xl justify-between py-3 pl-5 text-xl border-b border-slate-200">
                            <div className="text-white">Todo bewerken (ID: {todo.todoId})</div>
                            <div onClick={clearIdHandler}><span className="fa fa-xmark cursor-pointer text-white py-3 px-5"></span></div>
                        </div>
                        <div className="grid grid-cols-6 h-full shadow-xl rounded-xl">
                            <div className="col-span-4 p-10 bg-white rounded-bl-xl">

                                <div className="flex flex-row gap-x-5">
                                    <input onClick={() => completeHandler(todo)} className={`${classes.checkbox} cursor-pointer mt-3 h-4 w-4 border border-solid rounded-sm ${todo.priority===1 && 'accent-[#E50823] border-[#E50823]'} ${todo.priority===2 && 'accent-[orange] border-[orange]'} ${todo.priority===3 && 'accent-[blue] border-[blue]'}`} type="checkbox" />
                                    <div className="flex flex-col grow">
                                        <label className="w-full flex flex-row items-center gap-x-2 select-none cursor-pointer mb-2">
                                            <input onFocus={() => !isFocussed && setIsFocussed(true)} type="text" name="title" className="text-xl border border-solid rounded-xl w-full font-semibold p-2" value={todo.title} onChange={evt => prepareUpdate({...todo, [evt.target.name]: evt.target.value})} />
                                        </label>
                                        <textarea spellCheck={false} onFocus={() => !isFocussed && setIsFocussed(true)} name="description" className="p-2 rounded-xl border border-solid w-full mb-2" rows="10" value={todo.description} onChange={evt => prepareUpdate({...todo, [evt.target.name]: evt.target.value})}></textarea>
                                    </div>                                   
                                </div>        
                                {isFocussed && !isSaved &&
                                <div className="flex flex-row justify-end">
                                    <div className="flex flex-row gap-x-3">
                                        <Button type="button" className="!bg-slate-400" onClick={() => setIsFocussed(false)}>Annuleren</Button>
                                        <Button className="bg-gradient" type="button" onClick={updateHandler}>Opslaan</Button>
                                    </div>
                                </div>
                                }
                                <div className="flex flex-col pl-10 mt-10">
                                    <div className="text-xl font-semibold">Gekoppelde gegevens</div>
                                    <div className="italic mb-10">Met gekoppelde gegevens kun je een Deal, Autobedrijf of Klant koppelen aan deze todo</div> 
                                    <div className="grid grid-cols-3 justify-center gap-x-3">
                                        <div className="flex flex-col">
                                            {deals?.length && 
                                            <SmartSelector value={todo.dealId} id={todo.todoId} onChange={linkDealHandler} ref={dealRef} name="dealId" placeholder="Deal koppelen" label="Deal koppelen" options={deals.map(deal => ({label: ""+deal.dealID, value: deal.dealID}))} />
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            {dealers?.length && 
                                            <SmartSelector value={todo.dealerId} id={todo.todoId} onChange={linkDealerHandler} ref={dealerRef} name="dealerId" placeholder="Dealer koppelen" label="Dealer koppelen" options={dealers.map(dealer => ({label: dealer.name, value: dealer.dealerID}))} />
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            {customers?.length && 
                                            <SmartSelector value={todo.customerId} id={todo.todoId} onChange={linkCustomerHandler} ref={customerRef} name="customerId" placeholder="Klant koppelen" label="Klant koppelen" options={customers.map(customer => ({label: customer.companyName, value: customer.customerID}))} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col p-10 bg-slate-100 rounded-br-xl gap-y-10 col-span-2">
                                <div className="flex flex-col">
                                    <div className="font-semibold">Deadline</div>
                                    <TaskDate onDeadlineChange={deadlineChangeHandler} id={todo.todoId} todo={todo} />

                                    {/* <div>{localDate(todo.dueDate)} {todo.dueTime!=="0001-01-01T00:00:00Z" ? todo.dueTime : ""}</div> */}
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Prioriteit</div>
                                    <TodoPrio todo={todo} onChange={changePrioHandler} />
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Toegewezen persoon</div>
                                    {adminList?.length &&
                                    <SmartSelector id={todo.todoId} value={todo.userId} onChange={changeUserIdHandler} ref={userRef} name="userId" placeholder="Gebruiker koppelen" label="Gebruiker koppelen" options={adminList.map(admin => ({label: admin.email, value: admin.userrId}))} />
                                    }
                                </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Melder</div>
                                    <div>{getDateTimeFromDate(todo.createdDate)}</div>
                                    <div>{adminList?.filter(item=>item.userrId===todo.reporterId).map(y => y.email)[0]}</div>
                                    {/* {adminList?.length &&
                                    <SmartSelector id={todo.todoId} value={todo.reporterId} onChange={changeHandler} ref={userRef} name="reporterId" placeholder="Gebruiker koppelen" label="Gebruiker koppelen" options={adminList.map(admin => ({label: admin.email, value: admin.reporterId}))} />
                                    } */}
                                    </div>
                                <div className="flex flex-col">
                                    <div className="font-semibold">Bijgewerkt</div>
                                    <div>{getDateTimeFromDate(todo.updatedDate)}</div>
                                    <div>{adminList?.filter(item=>item.userrId===todo.userId).map(y => y.email)[0]}</div>
                                </div>
                                <Button type="button" className="!bg-[red]" onClick={() => toggleTrashHandler(todo.todoId)}>Verwijderen</Button>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            )}
        </div>
    )
}

export default Todo;