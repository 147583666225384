import { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import image from "components/0x0-Model3_20.jpg";
import useHead from "hooks/use-head";
import { authLoginWithUserPwdCode, userLogin } from "store/auth-actions";
import AuthMessage from "./AuthMessage";
import useAuthentication from "hooks/use-authentication";
import { uiActions } from "store/ui-slice";

const Auth = (props) => {
    const { auth, resetPassword, forgotPassword, dealerPasswordConfirmation, forgotPwdCalled } = useAuthentication();
    const { userIsLoggedIn, extraCheck, requestPwd, backToLogin } = auth;
    const { alert } = useSelector(state => state.ui);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const initEmail = searchParams.get("email") || "";
    const initToken = searchParams.get("token") || "";
    const initCode = searchParams.get("code") || "";

    const [user, setUser] = useState({
        email: initEmail,
        pwd: "",
        confirmpwd: "",
        token: initToken,
        code: initCode
    });

    const [pwdTouched, setPwdTouched] = useState(false);
    const [confirmPwdTouched, setConfirmPwdTouched] = useState(false);
    const pwdValid = ((pwdTouched || user.pwd.length) && confirmPwdTouched) && (user.pwd===user.confirmpwd);

    // 1 lowercase, 1 uppercase, 1 digit, 1 special char, no space
    const pwdContainsEverything = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/.test(user.pwd) && !/\s/.test(user.pwd);

    const [pwdStrength, setPwdStrength] = useState(0);
    const [errorMessage, setErrorMessage] = useState(false);

    const [authCase, setAuthCase] = useState(props.case);
    const [authCaseTitle, setAuthCaseTitle] = useState("Inloggen");

    useHead({title: authCaseTitle});

    const [formIsValid, setFormIsValid] = useState(false);

    const [emailTouched, setEmailTouched] = useState(!!initEmail);
    const emailValid = emailTouched && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(user.email);

    const [codeTouched, setCodeTouched] = useState(false);
    const codeValid = codeTouched && user.code.length === 6;

    useEffect(() => {
        dispatch(uiActions.hideAlert());
    },[authCase, dispatch]);

    useEffect(() => {
        if(extraCheck) setAuthCase("extra-controle");
        if(requestPwd && authCase!=="forgotpwd") setAuthCase("loginuserpwd");
    },[requestPwd, extraCheck, authCase]);

    useEffect(() => {
        if(backToLogin) {
            setAuthCase("login");
            setUser(prevState => {
                return {
                    ...prevState,
                    code: "",
                    pwd: "",
                    confirmpwd: "",
                }
            });
        }
    },[backToLogin]);
    
    useEffect(() => {
        if(/login/.test(authCase)) setAuthCaseTitle("Inloggen");
        if(authCase==="forgotpwd") setAuthCaseTitle("Wachtwoord vergeten");
        if(authCase==="activate") setAuthCaseTitle("Account activeren");
        if(authCase==="resetpwd") {
            if(!user.token) navigate("/");
            setAuthCaseTitle("Wachtwoord reset");
        }
        if(authCase==="extra-controle") setAuthCaseTitle("Code bevestigen");

    },[authCase]);

    const { code: userCode, pwd: userPwd, email: userEmail } = user;

    useEffect(() => {
        if(codeTouched&&pwdTouched&&emailTouched) return;
        if(!codeTouched && userCode) {
            setCodeTouched(true);
        }
        if(!pwdTouched && userPwd) {
            setPwdTouched(true);
        }
        if(!emailTouched && userEmail) {
            setEmailTouched(true);
        }
    }, [userCode,userPwd,userEmail]);

    useEffect(() => {
        switch(true) {
            case /login|forgotpwd/.test(authCase):
                emailValid ? setFormIsValid(true) : setFormIsValid(false);
                break;
            case /resetpwd|activate/.test(authCase):
                (emailValid && pwdValid && pwdContainsEverything) ? setFormIsValid(true) : setFormIsValid(false);
                break;
            case /extra-controle/.test(authCase):
                (codeValid) ? setFormIsValid(true) : setFormIsValid(false);
                break;
            default:
                break;
            }
    },[emailValid, pwdValid, codeValid, authCase]);

    useEffect(() => {
        if(userIsLoggedIn&&authCase!=="resetpwd") navigate("/financiering/financial-lease");
    },[userIsLoggedIn]);

    const pwdStrengthHandler = useCallback(evt => {
        // Alles wat niet mag is Zwak, of empty
        // Alles tot 8 karakters is Matig
        // Alles van 8 tot 10 karakters Sterk
        // Alles van 10 tot 255 karakters is Extra Sterk
        var userPwd = evt.target.value;
        var strength = 0;
        if(userPwd==="") {
            strength = 0;
        } else if(userPwd&&!pwdContainsEverything) {
            strength = 1;
        } else if(userPwd.length<8) {
            strength = 2;
        } else if (userPwd.length>=8&&userPwd.length<10) {
            strength = 3;
        } else {
            strength = 4;
        } 
        setPwdStrength(strength);
    },[pwdContainsEverything]);
    
    const validatePwd = () => {
        if(user.pwd!==user.confirmpwd) {
            setErrorMessage("De wachtwoorden komen niet overeen");
        } else {
            setErrorMessage(false);
        }
    }

    const changeHandler = useCallback((evt) => {
        var fieldName = evt.target.name;
        var fieldValue = evt.target.value;
        if(fieldValue) fieldValue = fieldValue.trim();
        setUser(prevState => {
            return {
                ...prevState,
                [fieldName]: fieldValue
            }
        });
    },[]);

    const submitHandler = useCallback((evt) => {
        evt.preventDefault();
        switch(true) {
            case /^activate-subuser$/.test(authCase):
                validatePwd();
                dealerPasswordConfirmation({
                    "userName": user.email,
                    "password": user.pwd,
                    "code": user.code
                }, true); // true for subuser confirmation
                break;
            case /activate/.test(authCase):
                validatePwd();
                dealerPasswordConfirmation({
                    "userName": user.email,
                    "password": user.pwd,
                    "code": user.code
                });
                break;
            case /^login$/.test(authCase):
                dispatch(userLogin({
                    userName: user.email,
                    password: user.pwd
                }));
                break;     
            case /extra-controle/.test(authCase):
                dispatch(authLoginWithUserPwdCode({
                    userName: user.email,
                    code: user.code,
                    password: user.pwd
                }));     
                break;
            case /forgotpwd/.test(authCase):
                forgotPassword({email: user.email});
                break;
            case /resetpwd/.test(authCase):
                validatePwd();
                resetPassword({
                    password: user.pwd,
                    confirmPassword: user.confirmpwd,
                    email: user.email,
                    token: user.token
                });
                break;
            default: 
                break;
        }
    },[authCase, user]);

    const changeAuthCaseHandler = (caseType) => {
        if(caseType==="forgotpwd") navigate("/wachtwoord-vergeten");
        if(caseType==="login") navigate("/");
    }

    if(forgotPwdCalled) return <AuthMessage title="U heeft een mail ontvangen" content={["U heeft een mail ontvangen om uw wachtwoord te kunnen resetten."]} />;

    return (
        <>
        <div className="container mx-auto px-5 h-full my-5">
        <div className="flex flex-row h-full items-center justify-center md:justify-start">
        <div className="fixed top-0 left-0 w-full h-full bg-no-repeat bg-center lg:bg-left-top lg:bg-cover opacity -z-10 scale-x-flip xscale-y-flip" style={{backgroundImage:`url(${image})`}}></div>
        <form onSubmit={submitHandler} className="border-slate-100 border justify-start gap-y-5 flex flex-col max-w-md p-10 shadow-xl rounded-xl bg-white-transparent">
            <h1 className="text-2xl mb-0 flex flex-row justify-start items-center whitespace-nowrap">
                <span className="fa fa-lock pr-4"></span>
                <span>{authCaseTitle}</span>
            </h1>

            <div className="">
                {authCase==="login" && <>Log in met uw e-mailadres en wachtwoord.</>}
                {authCase==="forgotpwd" && <>Vul uw e-mailadres in. U ontvangt een mail met daarin de instructies om uw wachtwoord opnieuw in te stellen.</>}
                {authCase==="extra-controle" && <>Vul de verificatiecode in die u per mail heeft ontvangen.</>}
            </div>
            <div className="flex flex-col text-left gap-y-3">
                {authCase==="extra-controle" && <Input onKeyUp={() => (!codeTouched && setCodeTouched(true))} placeholder="Code invoeren" label="Code" onChange={changeHandler} type="text" name="code" value={user.code} />}
                {authCase!=="extra-controle" && <Input onKeyUp={() => (!emailTouched && setEmailTouched(true))} invalid={!emailValid} placeholder="E-mailadres" label="E-mailadres" onChange={changeHandler} type="text" name="email" value={user.email} />}
                {(/(activate|resetpwd|loginuserpwd|^login$)/.test(authCase)) &&
                <>
                <Input onKeyUp={pwdStrengthHandler} onBlur={() => setPwdTouched(true)} type="password" name="pwd" label="wachtwoord" placeholder="wachtwoord" onChange={changeHandler} value={user.pwd} />
                {/activate|resetpwd/.test(authCase) && (
                    <>
                    <Input type="password" name="confirmpwd" onKeyUp={() => setConfirmPwdTouched(true)} label="Bevestig wachtwoord" placeholder="Bevestig wachtwoord" onChange={changeHandler} onBlur={() => validatePwd()} value={user.confirmpwd} />
                <div className="flex flex-col text-md">
                    <div className="text-[red]">
                        {errorMessage}&nbsp;
                    </div>
                    <div className="grid grid-cols-4 gap-x-1">
                        <div className={`rounded-l-xl h-1.5 ${!pwdStrength ? 'bg-slate-500' : ''} ${pwdStrength===1 ? 'bg-[red]' : ''} ${pwdStrength>1&&pwdStrength<3 ? 'bg-[orange]' : ''} ${pwdStrength>=3 ? 'bg-[green]' : ''}`}></div>
                        <div className={`h-1.5 ${!pwdStrength || pwdStrength<2 ? 'bg-slate-500' : ''} ${pwdStrength===2 ? 'bg-[red]' : ''} ${pwdStrength>1&&pwdStrength<3 ? 'bg-[orange]' : ''} ${pwdStrength>=3 ? 'bg-[green]' : ''}`}></div>
                        <div className={`h-1.5 ${pwdStrength>=3 ? 'bg-[green]': 'bg-slate-500'}`}></div>
                        <div className={`rounded-r-xl h-1.5 ${pwdStrength>=4 ? 'bg-[green]': 'bg-slate-500'}`}></div>
                    </div>
                    <div className="flex flex-row items-center gap-x-2 text-md mt-2">
                        <div><span className="">Sterkte:</span>
                        {pwdStrength===1 && <> Zwak</> }
                            {pwdStrength===2 && <> Matig</> }
                            {pwdStrength===3 && <> Sterk</> }
                            {pwdStrength===4 && <> Extra sterk</> }

                        </div>
                    </div>
                </div>
                </>
                )}
                </>
                }
            </div>
            {(alert.message && alert.visible) && <div className="p-2 text-xs rounded-xl border-2 bg-[#f8d7da] border-[#f5c6cb] color-[#721c24]">{alert.message}</div>}

{/activate|resetpwd/.test(authCase) && 
<div className="p-2 text-xs rounded-xl border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460]">
    <p className="mb-1">Het wachtwoord dient te bestaan uit tenminste:</p>
    <div className="flex flex-col">
        {/* <li>min. 4 karakters</li> */}
        {/* <li>max. 32 karakters</li> */}
        <div className="pl-1 flex flex-row gap-x-3 items-center"><span className="fa fa-check text-xs"></span><span>1 cijfer (0 t/m 9)</span></div>
        <div className="pl-1 flex flex-row gap-x-3 items-center"><span className="fa fa-check text-xs"></span><span>1 hoofdletter (A t/m Z)</span></div>
        <div className="pl-1 flex flex-row gap-x-3 items-center"><span className="fa fa-check text-xs"></span><span>1 kleine letter (a t/m z)</span></div>
        <div className="pl-1 flex flex-row gap-x-3 items-center"><span className="fa fa-check text-xs"></span><span>1 speciaal karakter (bijvoorbeeld %,!,$,#)</span></div>
    </div>
</div>
}
            <div className="flex flex-row justify-between items-center gap-x-5">
                
                {/* <div>pwdContainsEverything: {JSON.stringify(pwdContainsEverything)}</div> */}
                <Button disabled={!formIsValid && !/loginuserpwd/.test(authCase)} onClick={submitHandler}>
                    {/login/.test(authCase) && <>Inloggen</>}
                    {/activate/.test(authCase) && <>Activeren</>}    
                    {/(resetpwd|forgotpwd)/.test(authCase) && <>Versturen</>}          
                    {/(extra-controle)/.test(authCase) && <>Bevestigen</>}          
                </Button>
                {!/(login|extra-controle|activate)/.test(authCase) && <div className="text-xs cursor-pointer" onClick={() => changeAuthCaseHandler('login')}>Inloggen</div>}             
                {/login/.test(authCase) && <div className="text-xs cursor-pointer" onClick={() => changeAuthCaseHandler('forgotpwd')}>Wachtwoord vergeten</div>}                       
            </div>
        </form>
        </div>
        </div>
        </>
    )
}

export default Auth;