import React, { useState, useEffect, useRef, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";
import useFormValidation from "hooks/use-form-validation";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import InputColor from "components/ui/InputColor/InputColor";
import { dateToDutch } from "lib/kvk";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import DropArea from "components/ui/DropArea/DropArea";
import { uiActions } from "store/ui-slice";
import Toggle from "components/ui/Toggle/Toggle";
import SmartSearch from "components/ui/SmartSearch/SmartSearch";
import DocAndActivityBlock from "components/DocAndActivityBlock/DocAndActivityBlock";
import useDealV2 from "hooks/deal/use-deal_v2";

import DealsPerDealer from "components/Tables/DealsPerDealer";
import NewSubUserForm from "./NewSubUserForm";
import SubUserForm from "./SubUserForm";
import useDealerV2 from "hooks/dealer/use-dealer_v2";
import DocAndActivityBlock2 from "components/DocAndActivityBlock/DocAndActivityBlock2";
import { createPortal } from "react-dom";

const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));
const DealerCharts = React.lazy(() => import("components/Dashboard/DealerCharts"));
const ManagementDashboard = React.lazy(() => import("components/Dashboard/ManagementDashboard"));

const DealerForm = (props) => {
  const [updateCounter, setUpdateCounter] = useState(0);
  const [showSubUser, setShowSubUser] = useState(false);

  const importIDRef = useRef();
  const ratingRef = useRef();

  const { archiveDealer, getDealerById, getAllDealers, dealer, dealerOptions: dealersData, dealerChangeHandler, updateDealer } = useDealerV2();

  const [sentMail, setSentMail] = useState({
    activation: false,
    forgotpwd: false
  });

  const [showChart, setShowChart] = useState(false);

  const [searchImportId, setSearchImportId] = useState(false);
  const [isSaved, setIsSaved] = useState(0);
  // const [dealer, setDealer] = useState(''); 
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [logoDeleted, setLogoDeleted] = useState(false);
  const logoImageUrlFormRef = useRef();
  const isActiveRef = useRef();

    const params = useParams();
    const auth = useAuth();
    const { token: jwt, userInfo, role } = auth;
    const dispatch = useDispatch();
    var { dealerID } = params;
    if(!dealerID) dealerID = userInfo.dealerID;

    const { deals, archivedDeals, getDeals, getArchivedDeals} = useDealV2();

    useEffect(() => {
      if(!dealerID) return;
      getDeals("isArchive=false&dealerId="+dealerID);
      getArchivedDeals("dealerID="+dealerID+"&pageSize=9999");
    }, [dealerID]);

    useEffect(() => {
          if(!dealerID) return;
          getDealerById(dealerID);
          getAllDealers(2);

    }, [getDealerById, getAllDealers, dealerID, isSaved, dispatch, jwt, updateCounter]);

    useEffect(() => {
      (!dealer.logoImageUrl) ? setSentMail(prevState => ({...prevState, activation: true})) : setSentMail(prevState => ({...prevState, activation: false}));
    },[dealer]);

    const submitFormHandler = async (evt) => {
      evt.preventDefault();

      const updatedDealer = {
        ...dealer
      }     
      updatedDealer.logoImageUrlForm = logoImageUrlFormRef.current?.files[0];
      if(logoDeleted) updatedDealer.logoImageUrl = "";
      updatedDealer.postalCode = (updatedDealer.postalCode) ? updatedDealer.postalCode.replace(/\s/g, "").toUpperCase() : "";
      updatedDealer.isActive = isActiveRef.current.checked;
      updatedDealer.rating = ratingRef.current.value || 0;

      if(updatedDealer.dealerDomain && !/http(s)?:\/\//.test(updatedDealer.dealerDomain)) updatedDealer.dealerDomain = "https://"+updatedDealer.dealerDomain;

      if(!updatedDealer?.dealerDomain) updatedDealer.dealerDomain = "https://dealermodule.sld.nl";

      const dealerID = dealer.dealerID;
      const keys = Object.keys(updatedDealer);
      const values = Object.values(updatedDealer);
      const updatedFormData = new FormData();
      keys.forEach(function(key,index) {
        if(/dealerUserDetail|subUserDetails|userId/.test(key)) return;
        var value = values[index] || "";
        if(value) {
          updatedFormData.append(key, value);
        }
      });
      updatedFormData.isActive = isActiveRef.current.checked;
      const response = await updateDealer(dealerID, updatedFormData, jwt);
      if(response==="SUCCESS") setIsSaved(prevState => prevState + 1);
    };
  
    const archiveDealerHandler = (obj, reset) => {
        const updatedDealer = {
          ...obj,
          isArchive: (reset ? false : true)
        }
        const dealerID = dealer.dealerID;
        const keys = Object.keys(updatedDealer);
        const values = Object.values(updatedDealer);
        const updatedFormData = new FormData();
        keys.forEach(function(key,index) {
          if(values[index]===null || /dealerUserDetail|subUserDetails/.test(key)) return;
          updatedFormData.append(key, values[index]);
        });
        archiveDealer(dealerID, updatedFormData);
    }

    const sendMailHandler = (mailCase, dealerId) => {
      dispatch(uiActions.showBackdrop());

      var url;
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+jwt
        }
      }

      if(mailCase==="activation") {
        url = "/api/Authenticate/resendEmailVerificationToDealerV2?dealerId="+dealerId;
      }
      if(mailCase==="forgotpwd") {
        url = "/api/Authenticate/forgot-password";
        options.method = "POST";
        options.body = JSON.stringify({
          "email": dealer.email
        });
      }

      fetch(process.env.REACT_APP_SLD_API_BASE_URL+url, options)
      .then(() => {
        setSentMail(prevState => {
          return {
            ...prevState,
            [mailCase]: true
          }
        });
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({visible: true, type: "success", message: "Er is een e-mail verstuurd"}));
      }).catch(() => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({visible: true, type: "danger", message: "Er is wat fout gegaan bij het versturen. Probeer opnieuw"}));
      });
    }

    const [formIsValid] = useState(false);
    const { ref: ibanRef, hasError: ibanInputHasError } = useFormValidation();
    
    if(!dealer?.name) return <>Laden...</>;

    const star = String.fromCharCode(9733);

    return (
      <>
        <Button className="ml-5" type="button" onClick={() => setShowChart(prevState => !prevState)}>{!showChart ? "Bekijk dealer statistieken" : "Statistieken verbergen"}</Button>
        {showChart && 
        <>
        <Suspense fallback={<div>Loading ...</div>}><ManagementDashboard dealerId={dealer.dealerID} dealer={dealer} /></Suspense>
        <Suspense fallback={<div>Loading ...</div>}><DealerCharts dealerId={dealer.dealerID} dealer={dealer} /></Suspense>
        </>
        }
        <form className="text-xs lg:text-base mt-5" onSubmit={submitFormHandler}>
        <Card title={dealer.name ? (dealer.name + (dealer.chamberNumber!=null ? ' (KvK '+dealer.chamberNumber+')' : "")) : 'Autobedrijf aanmaken'} titleEnd={dealer.dealerID}>
          <div className="bg-white grid md:grid-cols-3 items-start group">
            <div className="grid col-span-2 gap-3 p-5">
              <Accordion open={true} summary="Bedrijfsgegevens">
                <div className="grid grid-cols-2 gap-3 py-5">
                  <Input value={dealer.name} onChange={dealerChangeHandler} placeholder="Bedrijfsnaam" name="name" />           
                  <Input value={dealer.chamberNumber} onChange={dealerChangeHandler} placeholder="KvK nummer" name="chamberNumber" />           
                  {role.includes("Admin") &&
                  <Select set="dealerCategory" value={dealer.dealerCategory} errorMessage="Classificatie is een verplicht veld" onChange={dealerChangeHandler} label="Classificatie" name="dealerCategory" />
                  }
                  <Input value={dealer.postalCode} onChange={dealerChangeHandler} placeholder="Postcode" name="postalCode" />
                  <Input value={dealer.address} onChange={dealerChangeHandler} placeholder="Adres" name="address" />      
                  <Input value={dealer.place} onChange={dealerChangeHandler} placeholder="Plaats" name="place" />
                  <Input hasError={ibanInputHasError} errorMessage="IBAN dient uit 18 karakters te bestaan" name="iban" placeholder="IBAN" value={dealer.iban} ref={ibanRef} onChange={dealerChangeHandler} />
                  <Input value={dealer.vaTnumber} onChange={dealerChangeHandler} placeholder="BTW nummer" name="vaTnumber" />
                  {role.includes("Admin") &&
                  <div className="grid grid-cols-2 gap-3">
                    <Input disabled={true} value={dealer.importID} name="importID" label="Import ID" placeholder="Import ID" onChange={dealerChangeHandler} />
                    <Button type="button" onClick={() => setSearchImportId(true)}><span className="fa fa-magnifying-glass"></span></Button>
                    <div className={`${searchImportId ? 'col-span-2' : 'hidden'}`}>                    
                      <SmartSearch ref={importIDRef} value={dealer.importID} onChange2={dealerChangeHandler} name="importID" options={dealersData} label="Import ID" placeholder="Import ID" />
                    </div>
                  </div>
                  }
                  <Input value={dealer.website} onChange={dealerChangeHandler} placeholder="Website" name="website" />
                </div>
              </Accordion>                 
              <Accordion open={true} summary="Hoofdcontactpersoon">
                <div className="grid grid-cols-2 gap-3 py-5">
                <Input value={dealer.contactFirstName} onChange={dealerChangeHandler} placeholder="Voornaam" name="contactFirstName" />
                <Input value={dealer.contactLastName} onChange={dealerChangeHandler} placeholder="Achternaam" name="contactLastName" />
                <Input value={dealer.telephone} onChange={dealerChangeHandler} placeholder="Telefoonnummer" name="telephone" />
                <Input value={dealer.phone} placeholder="Mobiel nummer" onChange={dealerChangeHandler} name="phone" />
                <Input value={dealer.email} onChange={dealerChangeHandler} placeholder="E-mail" name="email" />
                </div>
              </Accordion>
              <Accordion summary="Vormgeving">
                <div className="grid grid-cols-2 gap-3 py-5">
                  <div className="col-span-2 gap-3 flex flex-col">
                  <DropArea onAdd={() => setLogoDeleted(false)} onDelete={() => setLogoDeleted(true)} ref={logoImageUrlFormRef} value={dealer.logoImageUrl} uploadText="Upload logo (.PNG)" name="logoImageUrlForm" />
                  <InputColor placeholder="Primaire kleur" name="primaryColor" onChange={dealerChangeHandler} value={dealer.primaryColor} />
                  <InputColor placeholder="Button tekstkleur" name="buttonTextColor" onChange={dealerChangeHandler} value={dealer.buttonTextColor} />  
                  </div>
                </div>
              </Accordion>
              {role.includes("Admin") &&
              <>
              <Accordion summary="Gebruikers">
                 <NewSubUserForm dealerId={dealer.dealerID} onUpdate={() => setUpdateCounter(prevState => prevState+1)} />
                 <SubUserForm updateCounter={updateCounter} dealerId={dealer.dealerID} onUpdate={() => setUpdateCounter(prevState => prevState+1)} />
                </Accordion>
              </>
              }

              {role.includes("Admin") &&
              <>
              <Accordion summary="Voorraadmodule">
                  <div className="text-sm rounded-xl border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] p-3 w-fit leading-relaxed">
                      <p className="font-semibold">Vereisten voor een werkende dealermodule</p>
                      <div className="flex flex-col pl-3">
                        <div className="flex flex-col mb-2">
                          <div>1. Onderstaand veld "Subdomein" dient gevuld te zijn.</div>
                          <div>Is deze leeg? Vul dan "https://dealermodule.sld.nl" in, of kies voor opslaan en dan wordt deze automatisch gevuld.</div>
                        </div>
                        <div className="flex flex-col">
                          <div>2. De voertuigen van de klant staan op SLD.nl</div>
                          <div>Klik op 'Bekijk voorraadmodule'. Is "Subdomein" gevuld en staan er <u>geen</u> voertuigen? Dan dient de klant de koppeling SLD aan te zetten en voertuigen te selecteren in het voorraadbeheersysteem. Let op dat hier enige tijd overheen kan gaan.</div>
                        </div>
                      </div>
                    </div>
                  <div className="grid grid-cols-2 gap-3 py-5 items-center">
                    {/* <span className="break-words col-span-2 bg-slate-100 xborder xp-1 xborder-slate-300 text-sm">{dealer.dealerToken}</span> */}
                    <Input name="dealerDomain" value={dealer.dealerDomain} label="Subdomein" placeholder="Subdomein" onChange={dealerChangeHandler} />
                    {/* <div className="grid grid-cols-3">
                        <span className="col-span-2">AccesstoCreateDeal</span><span>{JSON.stringify(dealer.hasAccesstoCreateDeal)}</span>
                        <span className="col-span-2">AccesstoGetDealer</span><span>{JSON.stringify(dealer.hasAccesstoGetDealer)}</span>
                        <span className="col-span-2">AccesstoGetVehicleFilters</span><span>{JSON.stringify(dealer.hasAccesstoGetVehicleFilters)}</span>
                        <span className="col-span-2">AccesstoGetVehicles</span><span>{JSON.stringify(dealer.hasAccesstoGetVehicles)}</span>
                    </div> */}
                    <a href={`${dealer.dealerDomain ? dealer.dealerDomain : 'https://dealermodule.sld.nl'}?api=${dealer.dealerToken}`} rel="noreferrer" target="_blank" className="btn btn-secondary h-fit">Bekijk voorraadmodule</a>
                  </div>

                  <div className="text-xs flex flex-col">
                  <p className="mb-3">Beste {dealer.name},</p>
                  <p className="mb-3">Om de dealermodule van SLD in te laden op uw eigen website. Kunt u onderstaande verzoek direct doorsturen naar uw eigen webbouwer.</p>
                  <p className="mb-3">Beste webbouwer,</p>
                  <p className="mb-3">Ik zou graag de dealermodule van SLD op onze website inladen. Hierbij het verzoek om onderstaande code toe te voegen.</p>
                  <div className="mb-3 flex flex-col bg-slate-200">
                    <code>&lt;script type="text/javascript" src="https://dealermodule.sld.nl/iframeResizer.min.js"&gt;&lt;/script&gt;</code>
                    <code>&lt;div data-api="{dealer.dealerToken}" data-domain="{dealer.dealerDomain ? dealer.dealerDomain : 'https://dealermodule.sld.nl'}" id="sld_dealermodule"&gt;&lt;/div&gt;</code>
                  </div>
                  <div>Let op: Plaats de div met id "sld_dealermodule" op de plaats waar de voorraadmodule van SLD ingeladen dient te worden.</div>
                  <div></div>
                </div>

              </Accordion>
              </>
            }
            </div>
            <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full justify-between">
              <div className="">
                <div className="flex flex-col gap-y-5 mb-5">
                  {/* <div className="flex flex-row items-center gap-x-2">
                    <div className={`rounded-full w-8 h-8 p-0 m-0 items-center ${dealer.isActive ? 'bg-[#008002]': 'bg-[#FF0000]'}`}></div>
                    <div>{dealer.isActive ? "Actief" : "Niet actief"}</div>
                  </div> */}
                  <div className="flex flex-col">
                    <div>Datum aangemaakt {dateToDutch(dealer.createdDate)}</div>
                    <div>Laatst bijgewerkt {dateToDutch(dealer.updatedDate)}</div>
                  </div>
                  <div>Partner: {dealer?.sourceOfDealer ? dealer?.sourceOfDealer : "SLD"}</div>
                  <div><Toggle label="Actief" ref={isActiveRef} checked={dealer.isActive ? true : false} /></div>
                  <div><Select set="statusDealer" value={dealer.status} onChange={dealerChangeHandler} label="Dealer status" name="status" /></div>
                  <div className="flex flex-row gap-x-1">
                    <Select ref={ratingRef} className="w-full" value={dealer.rating} onChange={dealerChangeHandler} label="Rating" name="rating"
                    options={[{label: star, value: "1"}, {label: star+star, value: "2"},{label: star+star+star, value: "3"},{label: star+star+star+star, value: "4"},{label: star+star+star+star+star, value: "5"}]} />
                  </div>
                </div>
                <div className="flex flex-row gap-5">
                <Button className="bg-gradient" data-disabled={!formIsValid}>Opslaan</Button>
                {(role.includes("Admin")) &&
                  <>
                  {!dealer.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveDealerHandler(dealer)}>Archiveren</Button>}
                  {dealer.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveDealerHandler(dealer, 'reset')}>Herstellen uit archief</Button>}
                  </>
                }
                </div>
                <div className="flex flex-col mt-5">
                  <Button type="button" onClick={() => setDownloadPdf(true)}>Test PDF</Button>
                </div>
                <div className="flex flex-col mt-10 gap-3">
                  <Button type="button" onClick={() => sendMailHandler('activation', dealer.dealerID)} disabled={sentMail.activation}>Verstuur activatiemail</Button>
                  <Button type="button" onClick={() => sendMailHandler('forgotpwd')} disabled={sentMail.forgotpwd}>Verstuur wachtwoord reset</Button>
                </div>
              </div>
            </div>

          </div>
        </Card>

        
      </form>

      <div className="mt-5">
        {/* <DocAndActivityBlock id={dealer.dealerID} typeOfActivity="dealerActivity" /> */}

        <DocAndActivityBlock2 sourceOfComponent="dealer" updateCounter={updateCounter} dealerID={dealer.dealerID} dealerDetail={dealer} onUpdate={() => setUpdateCounter(prevState => prevState + 1)} />

      </div>

      {(deals?.data?.length>0 || archivedDeals?.data?.length>0) && 
      <div className="mt-5 p-10 rounded-xl shadow-xl bg-white">
          {deals?.data?.length>0 && <DealsPerDealer title="Openstaande aanvragen" emptyMessage="Er zijn geen openstaande aanvragen." deals={deals} />}
          {archivedDeals?.data?.length>0 && 
          <>          
          <DealsPerDealer title="Afgesloten contracten" emptyMessage="Er zijn geen afgesloten contracten." deals={archivedDeals} phase="Lopend" />
          <DealsPerDealer title="Gearchiveerd" emptyMessage="Er zijn geen gearchiveerde deals." deals={archivedDeals} phase="!Lopend" />
          </>
          }
      </div>
      }

      {downloadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 dealerDetail={dealer} hostname={"https://"+window.location.hostname} onDone={() => setDownloadPdf(false)} /></Suspense>}
    </>
    )
}

export default DealerForm;