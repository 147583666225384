import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { uiActions } from "store/ui-slice";

const useBackDrop = () => {
    const dispatch = useDispatch();
    const backdrop = useSelector(state => state.ui.backdrop);

    useEffect(() => {
        dispatch(uiActions.hideBackdrop());
    },[dispatch]);

    return backdrop;
}

export default useBackDrop; 
