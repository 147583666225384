import { formatThousandNotation, formattedPMT_v2 } from "lib/calc";

const CalcSummary = ({calcInput}) => {
    return (
        <>
        <div className="p-1 gap-5">
            <div className="text-2xl font-semibold">Overzicht aanbetalingen:</div>
            {/btw/i.test(calcInput.typeOfDeal) &&
            <div className="grid grid-cols-7">
                <div className="col-span-4 text-md">BTW</div>
                <div className="col-span-2 text-md font-semibold">&euro; {calcInput.vat ? <>{formatThousandNotation(calcInput.vat, false)}</> : <>0</>}</div>
                <div>+</div>
            </div>
            }
            <div className="grid grid-cols-7">
                <div className="col-span-4 text-md">Aanbetaling</div>
                <div className="col-span-2 text-md font-semibold">&euro; {calcInput.downpayment ? <>{formatThousandNotation(calcInput.downpayment, false)}</> : <>0</>}</div>
                <div>+</div>
            </div>
            <div className="grid grid-cols-7 border-t">
                <div className="col-span-4 text-md">Totaal aan te betalen</div>
                <div className="col-span-2 text-xl font-semibold">&euro; {calcInput.totalToPay ? <>{formatThousandNotation(calcInput.totalToPay, false)}</> : <>0</>}</div>
                <div>&nbsp;</div>
            </div>

            <div className="text-2xl font-semibold mt-5">Samenvatting financiering:</div>
            <div className="grid grid-cols-7">
                <div className="col-span-4 text-md">Te financieren bedrag</div>
                <div className="col-span-2 text-md font-semibold">&euro; {calcInput.leaseAmount ? <>{formatThousandNotation(calcInput.leaseAmount, false)}</> : <>0</>}</div>
                <div>&nbsp;</div>
            </div>
            <div className="grid grid-cols-7">
                <div className="col-span-4 text-md">Slottermijn</div>
                <div className="col-span-2 text-md font-semibold">&euro; {calcInput.finalpayment ? <>{formatThousandNotation(calcInput.finalpayment, false)}</> : <>0</>}</div>
                <div>&nbsp;</div>
            </div>
            <div className="grid grid-cols-7">
                <div className="col-span-4 text-md">Looptijd</div>
                <div className="col-span-2 text-md font-semibold">{calcInput.duration ? <>{formatThousandNotation(calcInput.duration, false)} maanden</> : <>0</>}</div>
                <div>&nbsp;</div>
            </div>
                                    
            {(calcInput.provisiePercentage) &&
            <div className="grid grid-cols-7 items-center border-t">
                <div className="col-span-4 text-md">Maandbedrag</div>
                <div className="col-span-2 text-xl font-semibold">&euro; {calcInput.duration ? <>{formattedPMT_v2(calcInput.monthlyamount, false)}</> : <>0</>}</div>
                <div>&nbsp;</div>
            </div>
            }
        </div>
        
    </>
    )
}

export default CalcSummary;