import { useState, Suspense, useEffect } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Table, { Td, Thead, Tr } from "components/ui/Table/Table";
import Button from "components/ui/Button/Button";

import TokenDetail from "./token-detail";
import useAuthenticate from "hooks/authenticate/use-authenticate";
import { Input3 } from "components/ui/FormFields/FormFields";
import Modal from "components/hoc/Modal";
import { uiActions } from "store/ui-slice";

const Tokens = () => {
    const dispatch = useDispatch();
   
    const { register, formState: { errors }, watch, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: {
            email: ""
        }
    });

    const [updateCounter, setUpdateCounter] = useState(0);

    const { tokens, createToken, getTokens } = useAuthenticate("getTokens");

    const [tokenDetailData, setTokenDetailData] = useState({});
    const watchAll = watch();

    const closeHandler = () => {
        dispatch(uiActions.hideBackdrop());
        setTokenDetailData({});
    }

    const submitHandler = async (formData) => {
        const resp = await createToken(formData);
        if(resp==="SUCCESS") {
            setUpdateCounter(prevState => prevState + 1);
        }
    }
    const updateHandler = () => {
        closeHandler();
        setUpdateCounter(prevState => prevState + 1);
    }

    useEffect(() => {
        if(!updateCounter) return;
        getTokens();
    }, [updateCounter, getTokens]);

    return (
        <div>
            <h1>API keys</h1>

            <form onSubmit={handleSubmit(submitHandler)} className="flex flex-row gap-x-3 items-center xmb-5 p-5 rounded-xl w-content">
                <Input3 name="email" type="email" fieldToValidate="email" register={register} inputValue={watchAll.email} placeholder="E-mailadres" label="E-mailadres" errorMessage={errors?.email?.message} />
                <Button>Token toevoegen</Button>
            </form>
           
            <Table>
                <Thead className="">
                <Tr>
                    <Td className="text-white">ID</Td>
                    <Td className="text-white">E-mail</Td>
                    <Td className="text-white">Token</Td>
                </Tr>
                </Thead>
                <tbody>
                {tokens && tokens.map(data => {
                    return (
                        <Tr key={data.id} className="cursor-pointer" onClick={() => setTokenDetailData(data)}>
                            <Td>{data.id}</Td>
                            <Td>{data.email}</Td>
                            <Td>{data.token}</Td>
                        </Tr>
                    )
                })}
                </tbody>
            </Table>
            
            {tokenDetailData?.id && createPortal(
            <Suspense fallback={<div>Loading ...</div>}>
            <Modal show={true} onClose={closeHandler} title={`${tokenDetailData?.email} (${tokenDetailData?.id})`}>
                <TokenDetail data={tokenDetailData} onUpdate={updateHandler} />
            </Modal>
            </Suspense>, document.body)}

        </div>

    )
}

export default Tokens;