import { useEffect, useState } from "react";

const DealProgressBar = ({className, phase, showPhase }) => {
    const [color, setColor] = useState("");
    const [percentage, setPercentage] = useState("0");

    useEffect(() => {
        var bgColor = "green", perc = "";
        switch(true) {
            case /Uw aanvraag is ontvangen/.test(phase):
                perc = "10";
                bgColor = "orange";
                break;
            case /Uw aanvraag wordt beoordeeld/.test(phase):
                perc = "20";
                bgColor = "orange";
                break;    
            case /Klant dient extra informatie aan te leveren/.test(phase):
                perc = "25";
                bgColor = "red";
                break;
            case /Controleer en verzend offerte/.test(phase): perc = "30"; break;
            case /Offerte verzonden/.test(phase): perc = "40"; break;
            case /Klant is akkoord met de offerte/.test(phase): perc = "50"; break;
            case /Contract wordt opgesteld door de bank/.test(phase): perc = "60"; break;
            case /Contract ter ondertekening bij de klant/.test(phase): perc = "70"; break;
            case /Contract ter ondertekening bij u/.test(phase): perc = "80"; break;
            case /U dient het voertuig op naam te registreren/.test(phase): perc = "90"; break;
            case /In afwachting van uitbetaling/.test(phase): perc = "95"; break;
            case /Bevestiging van uitbetaling/.test(phase): perc = "100"; break;
        }
        setColor(bgColor);
        setPercentage(perc);
    }, [phase]);

    return (
    <div className="grid grid-cols-2 items-center gap-x-3">
        <div className={`bg-slate-200 w-full rounded-xl select-none ${className && className}`}>
            <div className={`text-white text-sm py-1 px-5 justify-center flex rounded-l-xl ${percentage==="100" && "rounded-r-xl"}`} style={{width: percentage+"%", backgroundColor: color}}>
                <span>{percentage}%</span>
            </div>
        </div>
        {showPhase && <div>{phase}</div>}
    </div>
    )
}

export default DealProgressBar;