import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";
import Card from "components/ui/Card/Card";
import Select3 from "components/ui/Select/Select3";
import Input3 from "components/ui/Input/Input3";
import useActivity from "hooks/activity/use-activity";
import useActua from "hooks/actua/use-actua";
import { toyyyymmdd } from "lib/calc";
import useKvk from "hooks/kvk/use-kvk";
import { uiActions } from "store/ui-slice";
import useRdw from "hooks/rdw/use-rdw";

const RequestInsuranceByActuaForm = ( { title, inModal, onClose, onUpdate, dealDetail }) => {
    const { role } = useAuth();

    const [nextStep, setNextStep] = useState(false);

    const dispatch = useDispatch();
    const { customerDetail, vehicleDetail, dealerDetail } = dealDetail;

    var sellerName = "";
    if(dealerDetail?.contactFirstName) {
        sellerName = dealerDetail.contactFirstName;
        if(dealerDetail?.contactLastName) sellerName+=" "+dealerDetail.contactLastName;
    } else if(dealerDetail?.contactLastName) {
        sellerName = dealerDetail.contactLastName;
    }
    
    const defaultValues = {
        dealerName: dealerDetail?.name || "",
        chamberNumber: customerDetail?.chamberNumber || "",
        customerIsMainDriver: false,
        sellerName: sellerName,
        sellerEmail: dealerDetail?.email || "",
        salutation: customerDetail?.salutation || "",
        initials: "",
        preposition: "",
        customerName: customerDetail?.lastName || "",
        address: customerDetail?.address || "",
        postalCode: customerDetail?.postalCode || "",
        place: customerDetail?.place || "",
        phone: customerDetail?.phone || "",
        email: customerDetail?.email || "",
        dateofBirth: customerDetail?.dateofBirth ? toyyyymmdd(customerDetail?.dateofBirth) : "",
        dateofBirthMainDriver: "",
        damageFreeYearsInsurance: "",
        sbiCode: "",
        typeOfDeal: dealDetail?.typeOfDeal || "",
        licensePlate: vehicleDetail?.licensePlate || "",
        vehicleType: vehicleDetail?.vehicleType || "",
        make: vehicleDetail?.make || "",
        model: vehicleDetail?.model || "",
        type: vehicleDetail?.type || "",
        mileage: vehicleDetail?.mileage || "",
        firstRegistrationDate: vehicleDetail?.firstRegistrationDate ? toyyyymmdd(vehicleDetail?.firstRegistrationDate) : "",
        massEmpty: vehicleDetail?.massEmpty || "",
        listPrice: vehicleDetail?.listPrice || "",
        grossBPM: vehicleDetail?.grossBPM || "",
        kmPerYear: ""
    }

    const { register, formState: { errors }, watch, handleSubmit, setValue } = useForm({
      mode: "onChange",
      defaultValues: defaultValues
    });
    
    const watchAll = watch();
    const { typeOfDeal, grossBPM, listPrice } = watchAll;

    const { kvkData, getByKvk } = useKvk();
    const { rdwData } = useRdw('getByLicensePlate', vehicleDetail?.licensePlate);

    useEffect(() => {
        if(!rdwData?.catalogusprijs || !rdwData?.massa_ledig_voertuig) return;
        if(rdwData?.catalogusprijs) {
            var listPrice = rdwData?.catalogusprijs ? parseInt(rdwData?.catalogusprijs) : 0;
            var brutoBPM = parseInt(grossBPM);
            // priceEx = (catalogusprijs-brutobpm/1.21)+brutobpm
            if(typeOfDeal==="BTW") {
                listPrice = parseInt(( (listPrice-brutoBPM) / 1.21) + brutoBPM);
            }
            setValue("listPrice", listPrice);
        }
        if(rdwData?.massa_ledig_voertuig) setValue("massEmpty", rdwData?.massa_ledig_voertuig);

    }, [listPrice, rdwData, typeOfDeal]);

    useEffect(() => {
        if(!customerDetail?.sbiCodes) getByKvk(customerDetail?.chamberNumber);
    }, []);

    const { sbiStr } = kvkData;

    useEffect(() => {
        if(customerDetail?.sbiCodes) {
            setValue("sbiCode", JSON.parse(customerDetail?.sbiCodes).filter(item => item.indHoofdactiviteit==="Ja")[0].sbiCode);
            return;
        }
        if(!sbiStr) return;
        try {
            setValue("sbiCode", JSON.parse(sbiStr).filter(item => item.indHoofdactiviteit==="Ja")[0].sbiCode);
        } catch (err) {
            dispatch(uiActions.setAlert({message: "We kunnen de SBI-code van het bedrijf niet ophalen bij de KvK. Hierdoor kan de verzekering niet aangevraagd worden.", type: "danger", time: 15000}));
            onClose();
        }
    }, [sbiStr]);

    const { createInsurance } = useActua();
    const { createActivity } = useActivity();

    const submitHandler = async (formData) => { 
        var resp = await createInsurance(formData);
        if(resp==="SUCCESS") {
            onUpdate();
            createActivity("Deal", "submit", {dealID: dealDetail.dealID}, {"Verbonden bemiddelaar": "Nee", ...formData}, "Actua verzekering");
        } else if(resp) {
            setNextStep(resp);
            createActivity("Deal", "submit", {dealID: dealDetail.dealID}, {"Verbonden bemiddelaar": "Ja", ...formData}, "Actua verzekering");
        }      
    }

    if(!role.includes("Admin")) return <>Je hebt onvoldoende rechten</>; 

return (
    <form onSubmit={handleSubmit(submitHandler)} className={`text-xs lg:text-base max-h-screen ${inModal ? "mt-0" : "mt-5"}`}>
    <Card title={title} showClose={inModal} onClose={onClose}>
    {!nextStep && 
      <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl min-w-[1400px] max-w-screen">
        <div className="grid grid-cols-2 col-span-2 p-5 items-start gap-10">

            <div className="col-span-2 grid grid-cols-2 gap-x-10 gap-y-1">
                <span className="col-span-2 font-semiboldx mb-2 text-lg">Verkoper</span>
                <Input3 register={register} name="sellerName" fieldToValidate="Verkoper (naam) is een verplicht veld" placeholder="Verkoper (naam)" inputValue={watchAll.sellerName} errorMessage={errors?.sellerName?.message} />
                <Input3 register={register} name="sellerEmail" fieldToValidate="email" placeholder="Verkoper (email)" inputValue={watchAll.sellerEmail} errorMessage={errors?.sellerEmail?.message} />
            </div>

            <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                <span className="col-span-2 font-semiboldx mb-2 text-lg">Klant</span>
                <Select3 set="aanhef" register={register} name="salutation" fieldToValidate="Geslacht is een verplicht veld" placeholder="Geslacht" inputValue={watchAll.salutation} errorMessage={errors?.salutation?.message} /> 
                <Input3 register={register} name="initials" fieldToValidate="Voorletters is een verplicht veld" placeholder="Voorletters" inputValue={watchAll.initials} errorMessage={errors?.initials?.message} /> 
                <Input3 register={register} name="preposition" placeholder="Tussenvoegsels" inputValue={watchAll.preposition} /> 
                <Input3 register={register} name="customerName" fieldToValidate="Naam is een verplicht veld" placeholder="Naam" inputValue={watchAll.customerName} errorMessage={errors?.customerName?.message} /> 

                <div className="col-span-2 flex flex-col gap-y-1">
                    <Input3 register={register} type="date" name="dateofBirth" fieldToValidate="Geboortedatum is een verplicht veld" placeholder="Geboortedatum" inputValue={watchAll.dateofBirth} errorMessage={errors?.dateofBirth?.message} /> 
                    <Select3 register={register} name="customerIsMainDriver" fieldToValidate="Dit is een verplicht veld" inputValue={watchAll.customerIsMainDriver} placeholder="Klant is de hoofdbestuurder?" label="Klant is de hoofdbestuurder?" errorMessage={errors?.customerIsMainDriver?.message}>
                        <option value="y">Ja</option>
                        <option value="n">Nee</option>
                    </Select3>

                    {watchAll.customerIsMainDriver==="n" && 
                        <Input3 register={register} type="date" fieldToValidate="Geboortedatum is een verplicht veld" name="dateofBirthMainDriver" placeholder="Geboortedatum hoofdbestuurder" inputValue={watchAll.dateofBirthMainDriver} errorMessage={errors?.dateofBirthMainDriver?.message} /> 
                    }
                    <Input3 register={register} type="number" name="damageFreeYearsInsurance" fieldToValidate="Schadevrije jaren is een verplicht veld" placeholdersm="Schadevrije jaren" placeholder="Aantal geregistreerde schadevrije jaren" inputValue={watchAll.damageFreeYearsInsurance} errorMessage={errors?.damageFreeYearsInsurance?.message} />
                
                    <Select3 register={register} name="kmPerYear" type="number" fieldToValidate="Aantal kilometers per jaar" placeholder="Aantal kilometers per jaar" inputValue={watchAll.kmPerYear} errorMessage={errors?.kmPerYear?.message}>
                        <option value="5999">Minder dan 6.000 km</option>
                        <option value="11999">Minder dan 12.000 km</option>
                        <option value="19999">Minder dan 20.000 km</option>
                        <option value="20001">Meer dan 20.000 km</option>
                    </Select3>
                </div>
                
                <Input3 register={register} name="address" fieldToValidate="address" placeholder="Adres" inputValue={watchAll.address} errorMessage={errors?.address?.message} /> 
                <Input3 register={register} fieldToValidate="postalCode" name="postalCode" placeholder="Postcode" inputValue={watchAll.postalCode} errorMessage={errors?.postalCode?.message} /> 
                <Input3 register={register} name="place" fieldToValidate="place" placeholder="Plaats" inputValue={watchAll.place} errorMessage={errors?.place?.message} /> 

                <Input3 register={register} name="phone" fieldToValidate="phone" placeholder="Telefoonnummer" inputValue={watchAll.phone} errorMessage={errors?.phone?.message} /> 
                <Input3 register={register} fieldToValidate="email" name="email" placeholder="E-mailadres" inputValue={watchAll.email} errorMessage={errors?.email?.message} /> 


            </div>
            <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                <span className="col-span-2 font-semiboldx mb-2 text-lg">Voertuig</span>
                <Input3 register={register} name="licensePlate" fieldToValidate="Kenteken is een verplicht veld" placeholder="Kenteken" inputValue={watchAll.licensePlate} errorMessage={errors?.licensePlate?.message} />
                <Select3 register={register} name="vehicleType" fieldToValidate="Voertuigsoort is een verplicht veld" placeholder="Voertuigsoort" inputValue={watchAll.vehicleType} errorMessage={errors?.vehicleType?.message}>
                    <option>Personenauto</option>
                    <option>Bedrijfsauto</option>
                </Select3>
                <Input3 register={register} name="make" fieldToValidate="Merk is een verplicht veld" placeholder="Merk" inputValue={watchAll.make} errorMessage={errors?.make?.message} />
                <Input3 register={register} name="model" fieldToValidate="Model is een verplicht veld" placeholder="Model" inputValue={watchAll.model} errorMessage={errors?.model?.message} />
                <Input3 register={register} name="type" placeholder="Type" inputValue={watchAll.type} />
                <Input3 register={register} type="number" name="mileage" fieldToValidate="mileage" placeholder="Kilometerstand" inputValue={watchAll.mileage} errorMessage={errors?.mileage?.message} />
                <Input3 register={register} name="firstRegistrationDate" type="date" fieldToValidate="Eerste registratie is een verplicht veld" placeholder="Eerste registratie" inputValue={watchAll.firstRegistrationDate} errorMessage={errors?.firstRegistrationDate?.message} />
                <Input3 register={register} name="massEmpty" type="number" fieldToValidate="Massa ledig gewicht is een verplicht veld" placeholder="Massa ledig gewicht" inputValue={watchAll.massEmpty} errorMessage={errors?.massEmpty?.message} />
                <Select3 set="btw_marge" register={register} name="typeOfDeal" fieldToValidate="BTW/Marge is een verplicht veld" placeholder="BTW/Marge" inputValue={watchAll.typeOfDeal} errorMessage={errors?.typeOfDeal?.message} />
                <Input3 readOnly={true} register={register} name="listPrice" type="number" fieldToValidate="Catalogusprijs is een verplicht veld" placeholder="Catalogusprijs" inputValue={watchAll.listPrice} errorMessage={errors?.listPrice?.message} />
                {/* {watchAll.typeOfDeal==="BTW" && <Input3 register={register} name="grossBPM" type="number" fieldToValidate="Bruto BPM is een verplicht veld" placeholder="Bruto BPM" inputValue={watchAll.grossBPM} errorMessage={errors?.grossBPM?.message} />} */}

            </div>
        </div>
        <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
          <Button className="bg-gradient">Aanvraag versturen</Button>
        </div>
      </div>}
      {nextStep && (
    <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl min-w-[1400px] max-w-screen">
        <div className="grid grid-cols-2 col-span-2 items-start gap-10 p-20">
            <div className="flex flex-col gap-y-3 leading-normal">
                <span className="font-semibold text-xl">Offerte omzetten in verzekering</span>
                <div>Het is mogelijk de offerte verder in behandeling te nemen en direct om te zetten in een verzekering.</div>
            </div>
            <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
                <a href={nextStep} target="_blank" className="btn"><Button type="button" className="bg-gradient w-fit">Ga verder</Button></a>
            </div>

        </div>
        </div>)}
      
    </Card>
  </form>
    )
}

export default RequestInsuranceByActuaForm;