import { useState, useCallback, useEffect } from "react";
import useAuth from "../use-auth";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";
import useActivity from "hooks/activity/use-activity";
import { aesDecrypt } from "lib/helpers";

const useCustomer = (init, initQuery) => {
    const { createActivity } = useActivity();
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();

    const [customers, setCustomers] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState("");

    const fetchOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt
        }
    };

    const getCustomers = useCallback(async () => {
        setIsLoading(true);
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/Customer", fetchOptions);
            var respJson = await resp.json();
            setCustomers(respJson.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    },[]);

    const getCustomerById = useCallback(async (customerID, disableBackdrop=false) => {
        if(!customerID) return;
        setIsLoading(true);
        if(!disableBackdrop) dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/Customer/"+customerID, fetchOptions);
            var iv = resp.headers.get("key");
            var respText = await resp.text();
            var respData = aesDecrypt(respText, iv);
            if(respData?.rechtsvorm===null) respData.rechtsvorm = "";
            if(respData?.sbiCodes===null) respData.sbiCodes = "";
            setData(respData);
            setIsLoading(false);
            return respData;
            if(!disableBackdrop) dispatch(uiActions.hideBackdrop());
        } catch (err) {
            setIsLoading(false);
            if(!disableBackdrop) dispatch(uiActions.hideBackdrop());
        }
    },[]);


    const updateCustomer = useCallback(async (customerID, customerObj) => {
        setIsLoading(true);
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/Customer/"+customerID, 
            {
                ...fetchOptions,
                method: "PUT",
                body: JSON.stringify(customerObj)
            });
            if(resp.status!==200) throw new Error();
            setIsLoading(false);
            dispatch(uiActions.setAlert({message: "Klantgegevens succesvol aangepast.", type: "success"}));
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het aanpassen van de klantgegevens.", type: "danger"}));
            setIsLoading(false);
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    },[]);

    const patchCustomer = useCallback(async (customerID, customerObj, dealID) => {
        setIsLoading(true);
        dispatch(uiActions.showBackdrop());
        try {
            var resp = await fetch(process.env.REACT_APP_SLD_API_BASE_URL + "/api/Customer/"+customerID, 
            {
                ...fetchOptions,
                method: "PATCH",
                body: JSON.stringify(customerObj)
            });
            if(resp.status!==200) throw new Error();
            setIsLoading(false);
            // createActivity("Deal", "update", {dealID: dealID}, customerObj, "Klantgegevens");
            dispatch(uiActions.setAlert({message: "Klantgegevens succesvol aangepast.", type: "success"}));
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Fout bij het aanpassen van de klantgegevens.", type: "danger"}));
            setIsLoading(false);
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    }, []);   

    useEffect(() => {
        if(init==="getCustomerById") getCustomerById(initQuery);
    }, [getCustomerById]);

    return {
        isLoading,
        customerData: data,
        getCustomerById,
        updateCustomer,
        changeCustomerData: (name, value) => setData(prevState => ({...prevState, [name]: value})),
        changeCustomerObj: (customerObj) => setData(prevState => ({...prevState, ...customerObj})),
        customers: customers,
        getCustomers: getCustomers,
        patchCustomer: patchCustomer
    }

}

export default useCustomer;