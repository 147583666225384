import { validateFormField } from "lib/validation";

const TextArea3 = ({register, name, type, inputValue, fieldToValidate, required, placeholder, placeholdersm, errorMessage, onBlur, rows, cols, parentClasses }) => {
    var focussedOrWithValue = !!inputValue;

    return (
        <div className="flex flex-col relative mb-3">
            <textarea {...register(name, { onBlur: (e) => onBlur ? onBlur(name, e.target.value) : null, validate: (value) => validateFormField(fieldToValidate, value, required) })} placeholder="" className={
                `peer
                ${(focussedOrWithValue || inputValue==="" || inputValue===0) ? "px-3 pb-1 pt-5" : "p-3"}
                rounded-xl border border-slate-300
                ${parentClasses ? parentClasses : ""}
                `}
            spellCheck={false}
            autoComplete="off"
            defaultValue={inputValue}
            cols={cols ? cols : null}
            rows={rows ? rows : null}
            />
            {placeholder && <label className={`
            ease-in-out
            duration-200
            transition-all
            peer-focus:text-slate-600
            peer-focus:text-xs
            peer-focus:top-1
            ${focussedOrWithValue ? "top-1 text-xs" : "top-3"}
            select-none absolute left-3 text-slate-500 pointer-events-none
            `}>{placeholder}</label>}

            {errorMessage && <span className="text-xs text-[red] absolute -bottom-4 left-2">{errorMessage}</span>}
        </div>
    )
}

export default TextArea3;