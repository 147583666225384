import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        pageNumber: 1,
        totalRecords: "",
        customer: {},
        list: []
    },
    reducers: {
        setCustomers(state, actions) {
            state.list = actions.payload.data;
            state.totalRecords = actions.payload.totalRecords;
            state.pageNumber = actions.payload.pageNumber;
        },
        setSingleCustomer(state, actions) {
            state.customer = actions.payload;
        },
        setCustomerData(state, actions) {
            state.customer = {
                ...state.customer,
                ...actions.payload
            };
        }
    }
});

export const customerActions = customerSlice.actions;