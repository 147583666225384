import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getDateTimeFromDate, getFormattedLicensePlate, getUserName } from "lib/helpers";
import { escapeSpecialChars, friendlySaleType, getFriendlyBank } from "lib/voertuig";
import { formatThousandNotation, intTo2DecStr } from "lib/calc";

import useAuth from "hooks/use-auth";

import Button from "components/ui/Button/Button";
import Pagination from "components/Navigation/Pagination";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";
import BankSelector from "components/BankSelector/BankSelector";
import useDealV2 from "hooks/deal/use-deal_v2";
import LicensePlate2 from "components/ui/LicensePlate/LicensePlate2";

const AdminDealList = ({phase, saleType, isArchive}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { role } = useAuth();
  const navigate = useNavigate();
  
  const { getDeals, deals } = useDealV2();

  useEffect(() => {
    var query = phase ? "pageSize=99999&phase="+phase : searchParams;
    if(typeof isArchive==="boolean") query=query+"&isArchive="+isArchive;
    getDeals(query);
  }, [searchParams]);

  const { pageNumber, pageSize, totalRecords, data } = deals;

  if(searchParams.get("saleType")) saleType = searchParams.get("saleType");

    if(role && !role.includes("Admin")) return <div>Je hebt geen rechten om deze pagina te bekijken. Of je bent uitgelogd.</div>;

    return (
        <div className="text-xs lg:text-base max-w-8xl">
          {!phase && (
          <>
          <Zoekmachine searchFor="deals" />
          <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
            </>
          )}

          <div>
          <div className={`hidden lg:grid bg-sld-blue text-white rounded-xl p-3 mb-3 items-center grid-cols-2 md:grid-cols-5 gap-x-2 gap-y-2 px-5`}>
                <div className="">Autobedrijf</div>
                <div>Klantgegevens</div>
                {/(BtwFinanciering)/.test(saleType) ? <div>Te financieren bedrag</div> : <div>Voertuig</div>}
                <div className="rounded-tr-xl lg:rounded-tr-none">Financieringsvorm</div>
                <div className="rounded-tr-xl lg:rounded-tr-none">Status</div>
            </div>
            <div>
              {(totalRecords>0) 
              && 
              data.map((deal) => {
                const make = escapeSpecialChars(deal.vehicleDetail.make);
                const model = escapeSpecialChars(deal.vehicleDetail.model);
                const licensePlate = deal.vehicleDetail.licensePlate ? deal.vehicleDetail.licensePlate : 'NNB';
                if(deal.sourceOfRequest==="Actua") deal.dealerDetail.logoImageUrl = "/actua-logo.svg";

                var navPath = (role.includes("Admin")) ? "/crm/verkopen/"+deal.dealID : "/verkopen/"+deal.dealID;
                return (<div key={deal.dealID} id={deal.dealID} className={`grid grid-cols-2 md:grid-cols-5 gap-x-2 gap-y-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer`+(deal.isArchive ? ' opacity-50' : '')} onClick={() => navigate(navPath)}>
                  <div className="col-span-2 md:col-span-5 border-b border-solid border-slate-200">
                    <div className="flex flex-row justify-between text-sm opacity-75 border-b-slate-50">
                      <div className="flex flex-col sm:flex-row gap-x-2">
                        <div><span>Verkoop ID: </span><span>{deal.dealID}</span></div>
                        <span className="hidden sm:block opacity-25">|</span>
                        <div><span>{deal.source} {deal?.sourceOfRequest && <>({deal?.sourceOfRequest})</>}</span></div>
                      </div>
                      <div className="flex flex-col items-center sm:flex-row gap-x-2">
                        {deal.userId &&
                        <>
                        <span>{getUserName(deal.adminDetails)}</span>
                        <span className="hidden sm:block opacity-25">|</span>
                        </>
                        }
                        <span>{getDateTimeFromDate(deal.createdDate)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-x-5">
                      <div>
                        <div className="hidden lg:block">
                        {deal.dealerDetail.logoImageUrl ? <img alt="" className={`${deal?.sourceOfRequest==="Actua" && "w-[140px]"}`} title={deal.dealerDetail.name} style={{maxWidth: "140px"}} loading="lazy" src={deal.dealerDetail.logoImageUrl} /> : <div>{deal.dealerDetail.name}</div>}
                        </div>
                        <div className="lg:hidden">{deal.dealerDetail.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap overflow-hidden">{deal.customerDetail.companyName.slice(0, 25)}{deal.customerDetail.companyName?.length>25 && "..."}</span>
                    <span>{deal.customerDetail.postalCode && deal.customerDetail.postalCode+''}{deal.customerDetail.postalCode&&deal.customerDetail.place && ', '}{deal.customerDetail.place && deal.customerDetail.place}</span>
                  </div>
                  {/(BtwFinanciering)/.test(deal.saleType) && <div className="">&euro; {formatThousandNotation(deal.leaseAmount)}</div>}
                  {(/(FinancialLease|Verzekering)/.test(deal.saleType) || !deal.saleType) &&
                    <div className={`flex flex-col relative ${deal.saleType!=="FinancialLease" && 'mb-5'}`}> 
                      <div>{make}</div>
                      <div>{model}</div>
                      {/* {licensePlate && <div className="scale-50 relative -left-1/4 h-[24px] bottom-1"><LicensePlate2 value={licensePlate} /></div>}                 . */}
                      {licensePlate && <div className="scale-50 relative -left-1/4 h-[24px] bottom-1"><LicensePlate2 value={getFormattedLicensePlate(licensePlate)} /></div>}                 

                      {/* <div className="absolute top-full left-0"><KentekenPlaat2 kenteken={licensePlate}/></div> */}
                    </div>
                  }
                  <div className="flex flex-col">
                    {!saleType
                    ?
                    <div className="">{friendlySaleType(deal.saleType)}</div>
                    :
                    <div className="">{deal.createdDate ? getDateTimeFromDate(deal.createdDate) : ""}</div>
                  }
                    <div>&euro; {intTo2DecStr(deal.commissionAmountDealer)}</div>
                  </div> 

                  <div className="flex flex-col col-start-2 md:col-start-auto">
                    {deal.saleType==="FinancialLease" && 
                    <>
                    {(/verzonden|akkoord|opgesteld|onderteke|voertuig|uitbetaling/i.test(deal.phase) && deal.saleType==="FinancialLease") && <span className="bg-sld-blue opacity-75 text-white rounded-xl text-center">{getFriendlyBank(deal.chosenBank)}</span>}
                    <div className="grid grid-cols-4 gap-x-3 xscale-75 justify-between my-3">
                      <BankSelector size="xs" onClick={() => {}} prefix="hl" dealDetail={deal} title="HL" status={deal.hlStatus} />
                      <BankSelector size="xs" onClick={() => {}} prefix="df" dealDetail={deal} title="DF" status={deal.dfStatus} />
                      <BankSelector size="xs" onClick={() => {}} prefix="ca" dealDetail={deal} title="FI" status={deal.caStatus} />
                      <BankSelector size="xs" onClick={() => {}} prefix="al" dealDetail={deal} title="AL" status={deal.alStatus} />
                    </div>
                    </>
                    }
                    <div>{deal.phase}</div>
                  </div>

                </div>);
              })
              }
            </div>
              <div>&nbsp;</div>
          </div>

          {!totalRecords && 
          <div className="flex flex-row justify-between bg-white p-5 items-center">
            <span>Er zijn geen deals gevonden</span>
            <Button className="btn-secondary" onClick={() => setSearchParams("")}>reset filters</Button>
          </div>
          }
        <Pagination page={pageNumber} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
      </div>
    )
}

export default AdminDealList;