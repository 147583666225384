import classes from "./Todo.module.css";
import TaskDate from "./TaskDate";
import SmartSelector from "components/ui/SmartSearch/SmartSelector";
import TodoPrio from "./TodoPrio";

const Task = ({id, todo, title, onComplete, onRemove, onDeadlineChange, onGetById, onChangePrio, onChangeUserId, adminList}) => {
    return (
        <div key={"task"+id} className={`${todo.isCompleted===true && 'opacity-50 line-through'} flex flex-row gap-x-5 justify-start items-center rounded-xl shadow-xl px-5 mb-3`} style={{backgroundColor: `rgba(255,255,255,.85)`}}>
            
            <label htmlFor={id} className="flex flex-row gap-x-2 select-none px-5 cursor-pointer" onClick={() => onComplete(todo)}>
                 <input className={`${classes.checkbox} cursor-pointer h-4 w-4 border border-solid rounded-sm ${todo.priority===1 && 'accent-[#E50823] border-[#E50823]'} ${todo.priority===2 && 'accent-[orange] border-[orange]'} ${todo.priority===3 && 'accent-[blue] border-[blue]'}`} id={id} type="checkbox" />
            </label>
            
            <div className="flex flex-row grow cursor-pointer py-5 max-w-xl" onClick={() => onGetById(id)}>
                <div className="flex flex-col">
                    <div className="">{todo.title}</div>
                    <div className="text-xs">{todo.description.slice(0, 150)}</div>
                </div>
            </div>
           
            <div className="flex flex-row items-center gap-x-3">
                <TaskDate onDeadlineChange={onDeadlineChange} id={todo.todoId} todo={todo} />
                {adminList?.length &&
                    <SmartSelector id={todo.todoId} value={todo.userId} onChange={onChangeUserId} name="userId" placeholder="Gebruiker" label="Gebruiker" options={adminList.map(admin => ({label: admin.email, value: admin.userrId}))} />
                }
                <TodoPrio todo={todo} onChange={onChangePrio} />
                {/* <div onClick={() => onRemove(todo.todoId)}>Verwijderen</div> */}
            </div>
        </div>
    )
}

export default Task;