import { createSlice } from "@reduxjs/toolkit";

var initialState = {
    backdrop: true,
    notification: {
        visible: false,
        title: "",
        data: "",
        message: "",
        buttons: [],
        errors: []
    },
    alert: {
        visible: false,
        type: "",
        message: "",
        permanent: false,
        time: 2000
    }
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        showBackdrop(state) { state.backdrop = true },
        hideBackdrop(state) { state.backdrop = false },
        clearNotification(state)  { return initialState },
        setNotification(state, actions) {
            state.notification = {
                ...state.notification,
                ...actions.payload
            };
        },
        setAlert(state, actions) {
            state.alert = {
                ...actions.payload
            };
            state.alert.visible = true;
        },
        hideAlert(state) {
            state.alert.visible = false;
            state.alert.type = "";
            state.alert.message = "";
            state.alert.permanent = false;
            state.alert.time = 2000;
        }
    }
});

export const uiActions = uiSlice.actions;
